
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {BackupModuleSyncInfo} from './BackupModuleSyncInfo';
import {FieldModelBackupModuleSyncInfo} from './BackupModuleSyncInfo';

/**
 * BackupModuleInfo struct
 */
class BackupModuleInfo {
  /**
   * Initialize struct
   * @param {!BackupModuleSyncInfo=} configSyncInfo
   * @param {!BackupModuleSyncInfo=} storageAccountsSyncInfo
   * @param {!BackupModuleSyncInfo=} storageMarginsSyncInfo
   * @param {!BackupModuleSyncInfo=} storageAssetsSyncInfo
   * @param {!BackupModuleSyncInfo=} storageOrdersSyncInfo
   * @param {!BackupModuleSyncInfo=} storagePositionsSyncInfo
   * @param {!BackupModuleSyncInfo=} storageTradeHistorySyncInfo
   * @param {!BackupModuleSyncInfo=} drawingStorageSyncInfo
   * @param {!BackupModuleSyncInfo=} feedLatestTicksSyncInfo
   * @param {!BackupModuleSyncInfo=} historyChartBarsSyncInfo
   * @param {!BackupModuleSyncInfo=} storageTradeEventsSyncInfo
   * @param {!BackupModuleSyncInfo=} historyClientReportsSyncInfo
   * @constructor
   */
  constructor (configSyncInfo = new BackupModuleSyncInfo(), storageAccountsSyncInfo = new BackupModuleSyncInfo(), storageMarginsSyncInfo = new BackupModuleSyncInfo(), storageAssetsSyncInfo = new BackupModuleSyncInfo(), storageOrdersSyncInfo = new BackupModuleSyncInfo(), storagePositionsSyncInfo = new BackupModuleSyncInfo(), storageTradeHistorySyncInfo = new BackupModuleSyncInfo(), drawingStorageSyncInfo = new BackupModuleSyncInfo(), feedLatestTicksSyncInfo = new BackupModuleSyncInfo(), historyChartBarsSyncInfo = new BackupModuleSyncInfo(), storageTradeEventsSyncInfo = new BackupModuleSyncInfo(), historyClientReportsSyncInfo = new BackupModuleSyncInfo()) {
    this.ConfigSyncInfo = configSyncInfo
    this.StorageAccountsSyncInfo = storageAccountsSyncInfo
    this.StorageMarginsSyncInfo = storageMarginsSyncInfo
    this.StorageAssetsSyncInfo = storageAssetsSyncInfo
    this.StorageOrdersSyncInfo = storageOrdersSyncInfo
    this.StoragePositionsSyncInfo = storagePositionsSyncInfo
    this.StorageTradeHistorySyncInfo = storageTradeHistorySyncInfo
    this.DrawingStorageSyncInfo = drawingStorageSyncInfo
    this.FeedLatestTicksSyncInfo = feedLatestTicksSyncInfo
    this.HistoryChartBarsSyncInfo = historyChartBarsSyncInfo
    this.StorageTradeEventsSyncInfo = storageTradeEventsSyncInfo
    this.HistoryClientReportsSyncInfo = historyClientReportsSyncInfo
  }

  /**
   * Copy struct (shallow copy)
   * @this {!BackupModuleInfo}
   * @param {!BackupModuleInfo} other Other struct
   * @returns {!BackupModuleInfo} This struct
   */
  copy (other) {
    if (other.ConfigSyncInfo != null) {
      this.ConfigSyncInfo = BackupModuleSyncInfo.fromObject(other.ConfigSyncInfo)
    } else {
      this.ConfigSyncInfo = null
    }
    if (other.StorageAccountsSyncInfo != null) {
      this.StorageAccountsSyncInfo = BackupModuleSyncInfo.fromObject(other.StorageAccountsSyncInfo)
    } else {
      this.StorageAccountsSyncInfo = null
    }
    if (other.StorageMarginsSyncInfo != null) {
      this.StorageMarginsSyncInfo = BackupModuleSyncInfo.fromObject(other.StorageMarginsSyncInfo)
    } else {
      this.StorageMarginsSyncInfo = null
    }
    if (other.StorageAssetsSyncInfo != null) {
      this.StorageAssetsSyncInfo = BackupModuleSyncInfo.fromObject(other.StorageAssetsSyncInfo)
    } else {
      this.StorageAssetsSyncInfo = null
    }
    if (other.StorageOrdersSyncInfo != null) {
      this.StorageOrdersSyncInfo = BackupModuleSyncInfo.fromObject(other.StorageOrdersSyncInfo)
    } else {
      this.StorageOrdersSyncInfo = null
    }
    if (other.StoragePositionsSyncInfo != null) {
      this.StoragePositionsSyncInfo = BackupModuleSyncInfo.fromObject(other.StoragePositionsSyncInfo)
    } else {
      this.StoragePositionsSyncInfo = null
    }
    if (other.StorageTradeHistorySyncInfo != null) {
      this.StorageTradeHistorySyncInfo = BackupModuleSyncInfo.fromObject(other.StorageTradeHistorySyncInfo)
    } else {
      this.StorageTradeHistorySyncInfo = null
    }
    if (other.DrawingStorageSyncInfo != null) {
      this.DrawingStorageSyncInfo = BackupModuleSyncInfo.fromObject(other.DrawingStorageSyncInfo)
    } else {
      this.DrawingStorageSyncInfo = null
    }
    if (other.FeedLatestTicksSyncInfo != null) {
      this.FeedLatestTicksSyncInfo = BackupModuleSyncInfo.fromObject(other.FeedLatestTicksSyncInfo)
    } else {
      this.FeedLatestTicksSyncInfo = null
    }
    if (other.HistoryChartBarsSyncInfo != null) {
      this.HistoryChartBarsSyncInfo = BackupModuleSyncInfo.fromObject(other.HistoryChartBarsSyncInfo)
    } else {
      this.HistoryChartBarsSyncInfo = null
    }
    if (other.StorageTradeEventsSyncInfo != null) {
      this.StorageTradeEventsSyncInfo = BackupModuleSyncInfo.fromObject(other.StorageTradeEventsSyncInfo)
    } else {
      this.StorageTradeEventsSyncInfo = null
    }
    if (other.HistoryClientReportsSyncInfo != null) {
      this.HistoryClientReportsSyncInfo = BackupModuleSyncInfo.fromObject(other.HistoryClientReportsSyncInfo)
    } else {
      this.HistoryClientReportsSyncInfo = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!BackupModuleInfo}
   * @returns {!BackupModuleInfo} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new BackupModuleInfoModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new BackupModuleInfoModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!BackupModuleInfo}
   * @param {!BackupModuleInfo} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof BackupModuleInfo)) {
      throw new TypeError('Instance of BackupModuleInfo is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!BackupModuleInfo}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      ConfigSyncInfo: ((this.ConfigSyncInfo != null) ? this.ConfigSyncInfo : null),
      StorageAccountsSyncInfo: ((this.StorageAccountsSyncInfo != null) ? this.StorageAccountsSyncInfo : null),
      StorageMarginsSyncInfo: ((this.StorageMarginsSyncInfo != null) ? this.StorageMarginsSyncInfo : null),
      StorageAssetsSyncInfo: ((this.StorageAssetsSyncInfo != null) ? this.StorageAssetsSyncInfo : null),
      StorageOrdersSyncInfo: ((this.StorageOrdersSyncInfo != null) ? this.StorageOrdersSyncInfo : null),
      StoragePositionsSyncInfo: ((this.StoragePositionsSyncInfo != null) ? this.StoragePositionsSyncInfo : null),
      StorageTradeHistorySyncInfo: ((this.StorageTradeHistorySyncInfo != null) ? this.StorageTradeHistorySyncInfo : null),
      DrawingStorageSyncInfo: ((this.DrawingStorageSyncInfo != null) ? this.DrawingStorageSyncInfo : null),
      FeedLatestTicksSyncInfo: ((this.FeedLatestTicksSyncInfo != null) ? this.FeedLatestTicksSyncInfo : null),
      HistoryChartBarsSyncInfo: ((this.HistoryChartBarsSyncInfo != null) ? this.HistoryChartBarsSyncInfo : null),
      StorageTradeEventsSyncInfo: ((this.StorageTradeEventsSyncInfo != null) ? this.StorageTradeEventsSyncInfo : null),
      HistoryClientReportsSyncInfo: ((this.HistoryClientReportsSyncInfo != null) ? this.HistoryClientReportsSyncInfo : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return BackupModuleInfo.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!BackupModuleInfo} other Object value
   * @returns {!BackupModuleInfo} Created struct
   */
  static fromObject (other) {
    return new BackupModuleInfo().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!BackupModuleInfo}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return BackupModuleInfo.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!BackupModuleInfo}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 89
  }
}

export { BackupModuleInfo };

/**
 * Fast Binary Encoding BackupModuleInfo field model
 */
class FieldModelBackupModuleInfo extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.ConfigSyncInfo = new FieldModelBackupModuleSyncInfo(buffer, 4 + 4)
    this.StorageAccountsSyncInfo = new FieldModelBackupModuleSyncInfo(buffer, this.ConfigSyncInfo.fbeOffset + this.ConfigSyncInfo.fbeSize)
    this.StorageMarginsSyncInfo = new FieldModelBackupModuleSyncInfo(buffer, this.StorageAccountsSyncInfo.fbeOffset + this.StorageAccountsSyncInfo.fbeSize)
    this.StorageAssetsSyncInfo = new FieldModelBackupModuleSyncInfo(buffer, this.StorageMarginsSyncInfo.fbeOffset + this.StorageMarginsSyncInfo.fbeSize)
    this.StorageOrdersSyncInfo = new FieldModelBackupModuleSyncInfo(buffer, this.StorageAssetsSyncInfo.fbeOffset + this.StorageAssetsSyncInfo.fbeSize)
    this.StoragePositionsSyncInfo = new FieldModelBackupModuleSyncInfo(buffer, this.StorageOrdersSyncInfo.fbeOffset + this.StorageOrdersSyncInfo.fbeSize)
    this.StorageTradeHistorySyncInfo = new FieldModelBackupModuleSyncInfo(buffer, this.StoragePositionsSyncInfo.fbeOffset + this.StoragePositionsSyncInfo.fbeSize)
    this.DrawingStorageSyncInfo = new FieldModelBackupModuleSyncInfo(buffer, this.StorageTradeHistorySyncInfo.fbeOffset + this.StorageTradeHistorySyncInfo.fbeSize)
    this.FeedLatestTicksSyncInfo = new FieldModelBackupModuleSyncInfo(buffer, this.DrawingStorageSyncInfo.fbeOffset + this.DrawingStorageSyncInfo.fbeSize)
    this.HistoryChartBarsSyncInfo = new FieldModelBackupModuleSyncInfo(buffer, this.FeedLatestTicksSyncInfo.fbeOffset + this.FeedLatestTicksSyncInfo.fbeSize)
    this.StorageTradeEventsSyncInfo = new FieldModelBackupModuleSyncInfo(buffer, this.HistoryChartBarsSyncInfo.fbeOffset + this.HistoryChartBarsSyncInfo.fbeSize)
    this.HistoryClientReportsSyncInfo = new FieldModelBackupModuleSyncInfo(buffer, this.StorageTradeEventsSyncInfo.fbeOffset + this.StorageTradeEventsSyncInfo.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelBackupModuleInfo}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelBackupModuleInfo}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.ConfigSyncInfo.fbeSize + this.StorageAccountsSyncInfo.fbeSize + this.StorageMarginsSyncInfo.fbeSize + this.StorageAssetsSyncInfo.fbeSize + this.StorageOrdersSyncInfo.fbeSize + this.StoragePositionsSyncInfo.fbeSize + this.StorageTradeHistorySyncInfo.fbeSize + this.DrawingStorageSyncInfo.fbeSize + this.FeedLatestTicksSyncInfo.fbeSize + this.HistoryChartBarsSyncInfo.fbeSize + this.StorageTradeEventsSyncInfo.fbeSize + this.HistoryClientReportsSyncInfo.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelBackupModuleInfo}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.ConfigSyncInfo.fbeExtra + this.StorageAccountsSyncInfo.fbeExtra + this.StorageMarginsSyncInfo.fbeExtra + this.StorageAssetsSyncInfo.fbeExtra + this.StorageOrdersSyncInfo.fbeExtra + this.StoragePositionsSyncInfo.fbeExtra + this.StorageTradeHistorySyncInfo.fbeExtra + this.DrawingStorageSyncInfo.fbeExtra + this.FeedLatestTicksSyncInfo.fbeExtra + this.HistoryChartBarsSyncInfo.fbeExtra + this.StorageTradeEventsSyncInfo.fbeExtra + this.HistoryClientReportsSyncInfo.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelBackupModuleInfo}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelBackupModuleInfo.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelBackupModuleInfo}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 89
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelBackupModuleInfo}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelBackupModuleInfo}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.ConfigSyncInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ConfigSyncInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ConfigSyncInfo.fbeSize

    if ((fbeCurrentSize + this.StorageAccountsSyncInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StorageAccountsSyncInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StorageAccountsSyncInfo.fbeSize

    if ((fbeCurrentSize + this.StorageMarginsSyncInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StorageMarginsSyncInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StorageMarginsSyncInfo.fbeSize

    if ((fbeCurrentSize + this.StorageAssetsSyncInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StorageAssetsSyncInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StorageAssetsSyncInfo.fbeSize

    if ((fbeCurrentSize + this.StorageOrdersSyncInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StorageOrdersSyncInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StorageOrdersSyncInfo.fbeSize

    if ((fbeCurrentSize + this.StoragePositionsSyncInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StoragePositionsSyncInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StoragePositionsSyncInfo.fbeSize

    if ((fbeCurrentSize + this.StorageTradeHistorySyncInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StorageTradeHistorySyncInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StorageTradeHistorySyncInfo.fbeSize

    if ((fbeCurrentSize + this.DrawingStorageSyncInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DrawingStorageSyncInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DrawingStorageSyncInfo.fbeSize

    if ((fbeCurrentSize + this.FeedLatestTicksSyncInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.FeedLatestTicksSyncInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeedLatestTicksSyncInfo.fbeSize

    if ((fbeCurrentSize + this.HistoryChartBarsSyncInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HistoryChartBarsSyncInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HistoryChartBarsSyncInfo.fbeSize

    if ((fbeCurrentSize + this.StorageTradeEventsSyncInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StorageTradeEventsSyncInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StorageTradeEventsSyncInfo.fbeSize

    if ((fbeCurrentSize + this.HistoryClientReportsSyncInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HistoryClientReportsSyncInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HistoryClientReportsSyncInfo.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelBackupModuleInfo}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelBackupModuleInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelBackupModuleInfo}
   * @param {!BackupModuleInfo} fbeValue Default value, defaults is new BackupModuleInfo()
   * @returns {!BackupModuleInfo} BackupModuleInfo value
   */
  get (fbeValue = new BackupModuleInfo()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelBackupModuleInfo}
   * @param {!BackupModuleInfo} fbeValue BackupModuleInfo value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.ConfigSyncInfo.fbeSize) <= fbeStructSize) {
      fbeValue.ConfigSyncInfo = this.ConfigSyncInfo.get()
    } else {
      fbeValue.ConfigSyncInfo = new BackupModuleSyncInfo()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ConfigSyncInfo.fbeSize

    if ((fbeCurrentSize + this.StorageAccountsSyncInfo.fbeSize) <= fbeStructSize) {
      fbeValue.StorageAccountsSyncInfo = this.StorageAccountsSyncInfo.get()
    } else {
      fbeValue.StorageAccountsSyncInfo = new BackupModuleSyncInfo()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StorageAccountsSyncInfo.fbeSize

    if ((fbeCurrentSize + this.StorageMarginsSyncInfo.fbeSize) <= fbeStructSize) {
      fbeValue.StorageMarginsSyncInfo = this.StorageMarginsSyncInfo.get()
    } else {
      fbeValue.StorageMarginsSyncInfo = new BackupModuleSyncInfo()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StorageMarginsSyncInfo.fbeSize

    if ((fbeCurrentSize + this.StorageAssetsSyncInfo.fbeSize) <= fbeStructSize) {
      fbeValue.StorageAssetsSyncInfo = this.StorageAssetsSyncInfo.get()
    } else {
      fbeValue.StorageAssetsSyncInfo = new BackupModuleSyncInfo()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StorageAssetsSyncInfo.fbeSize

    if ((fbeCurrentSize + this.StorageOrdersSyncInfo.fbeSize) <= fbeStructSize) {
      fbeValue.StorageOrdersSyncInfo = this.StorageOrdersSyncInfo.get()
    } else {
      fbeValue.StorageOrdersSyncInfo = new BackupModuleSyncInfo()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StorageOrdersSyncInfo.fbeSize

    if ((fbeCurrentSize + this.StoragePositionsSyncInfo.fbeSize) <= fbeStructSize) {
      fbeValue.StoragePositionsSyncInfo = this.StoragePositionsSyncInfo.get()
    } else {
      fbeValue.StoragePositionsSyncInfo = new BackupModuleSyncInfo()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StoragePositionsSyncInfo.fbeSize

    if ((fbeCurrentSize + this.StorageTradeHistorySyncInfo.fbeSize) <= fbeStructSize) {
      fbeValue.StorageTradeHistorySyncInfo = this.StorageTradeHistorySyncInfo.get()
    } else {
      fbeValue.StorageTradeHistorySyncInfo = new BackupModuleSyncInfo()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StorageTradeHistorySyncInfo.fbeSize

    if ((fbeCurrentSize + this.DrawingStorageSyncInfo.fbeSize) <= fbeStructSize) {
      fbeValue.DrawingStorageSyncInfo = this.DrawingStorageSyncInfo.get()
    } else {
      fbeValue.DrawingStorageSyncInfo = new BackupModuleSyncInfo()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DrawingStorageSyncInfo.fbeSize

    if ((fbeCurrentSize + this.FeedLatestTicksSyncInfo.fbeSize) <= fbeStructSize) {
      fbeValue.FeedLatestTicksSyncInfo = this.FeedLatestTicksSyncInfo.get()
    } else {
      fbeValue.FeedLatestTicksSyncInfo = new BackupModuleSyncInfo()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeedLatestTicksSyncInfo.fbeSize

    if ((fbeCurrentSize + this.HistoryChartBarsSyncInfo.fbeSize) <= fbeStructSize) {
      fbeValue.HistoryChartBarsSyncInfo = this.HistoryChartBarsSyncInfo.get()
    } else {
      fbeValue.HistoryChartBarsSyncInfo = new BackupModuleSyncInfo()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HistoryChartBarsSyncInfo.fbeSize

    if ((fbeCurrentSize + this.StorageTradeEventsSyncInfo.fbeSize) <= fbeStructSize) {
      fbeValue.StorageTradeEventsSyncInfo = this.StorageTradeEventsSyncInfo.get()
    } else {
      fbeValue.StorageTradeEventsSyncInfo = new BackupModuleSyncInfo()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StorageTradeEventsSyncInfo.fbeSize

    if ((fbeCurrentSize + this.HistoryClientReportsSyncInfo.fbeSize) <= fbeStructSize) {
      fbeValue.HistoryClientReportsSyncInfo = this.HistoryClientReportsSyncInfo.get()
    } else {
      fbeValue.HistoryClientReportsSyncInfo = new BackupModuleSyncInfo()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HistoryClientReportsSyncInfo.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelBackupModuleInfo}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelBackupModuleInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelBackupModuleInfo}
   * @param {!BackupModuleInfo} fbeValue BackupModuleInfo value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelBackupModuleInfo}
   * @param {BackupModuleInfo} fbeValue BackupModuleInfo value
   */
  setFields (fbeValue) {
    this.ConfigSyncInfo.set(fbeValue.ConfigSyncInfo)
    this.StorageAccountsSyncInfo.set(fbeValue.StorageAccountsSyncInfo)
    this.StorageMarginsSyncInfo.set(fbeValue.StorageMarginsSyncInfo)
    this.StorageAssetsSyncInfo.set(fbeValue.StorageAssetsSyncInfo)
    this.StorageOrdersSyncInfo.set(fbeValue.StorageOrdersSyncInfo)
    this.StoragePositionsSyncInfo.set(fbeValue.StoragePositionsSyncInfo)
    this.StorageTradeHistorySyncInfo.set(fbeValue.StorageTradeHistorySyncInfo)
    this.DrawingStorageSyncInfo.set(fbeValue.DrawingStorageSyncInfo)
    this.FeedLatestTicksSyncInfo.set(fbeValue.FeedLatestTicksSyncInfo)
    this.HistoryChartBarsSyncInfo.set(fbeValue.HistoryChartBarsSyncInfo)
    this.StorageTradeEventsSyncInfo.set(fbeValue.StorageTradeEventsSyncInfo)
    this.HistoryClientReportsSyncInfo.set(fbeValue.HistoryClientReportsSyncInfo)
  }
}

export { FieldModelBackupModuleInfo };

/**
 * Fast Binary Encoding BackupModuleInfo model
 */
class BackupModuleInfoModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelBackupModuleInfo(this.buffer, 4)
  }

  /**
   * Get the BackupModuleInfo model
   * @this {!BackupModuleInfoModel}
   * @returns {!FieldModelBackupModuleInfo} model BackupModuleInfo model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!BackupModuleInfoModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!BackupModuleInfoModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return BackupModuleInfoModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!BackupModuleInfoModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelBackupModuleInfo.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!BackupModuleInfoModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!BackupModuleInfoModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!BackupModuleInfoModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!BackupModuleInfoModel}
   * @param {!BackupModuleInfo} value BackupModuleInfo value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!BackupModuleInfoModel}
   * @param {!BackupModuleInfo} value BackupModuleInfo value, defaults is new BackupModuleInfo()
   * @return {!object} Deserialized BackupModuleInfo value and its size
   */
  deserialize (value = new BackupModuleInfo()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new BackupModuleInfo(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new BackupModuleInfo(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!BackupModuleInfoModel}
   * @param {!number} prev Previous BackupModuleInfo model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { BackupModuleInfoModel };
