
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as feed from '../feed'
import * as history from '../history'
import * as storage from '../storage'
import * as client from '../client'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Response} from '../core/Response';
import {FieldModelResponse} from '../core/Response';
import {AccountMargin} from '../core/AccountMargin';
import {FieldModelAccountMargin} from '../core/AccountMargin';
import {Asset} from '../core/Asset';
import {FieldModelAsset} from '../core/Asset';

/**
 * TransferMoneyResponse struct
 */
class TransferMoneyResponse extends Response {
  /**
   * Initialize struct
   * @param {!Response=} parent
   * @param {AccountMargin=} srcMargin
   * @param {Asset=} srcAsset
   * @param {AccountMargin=} dstMargin
   * @param {Asset=} dstAsset
   * @param {!string=} comment
   * @constructor
   */
  constructor (parent = new Response(), srcMargin = null, srcAsset = null, dstMargin = null, dstAsset = null, comment = '') {
    super()
    super.copy(parent)
    this.SrcMargin = srcMargin
    this.SrcAsset = srcAsset
    this.DstMargin = dstMargin
    this.DstAsset = dstAsset
    this.Comment = comment
  }

  /**
   * Copy struct (shallow copy)
   * @this {!TransferMoneyResponse}
   * @param {!TransferMoneyResponse} other Other struct
   * @returns {!TransferMoneyResponse} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.SrcMargin != null) {
      this.SrcMargin = AccountMargin.fromObject(other.SrcMargin)
    } else {
      this.SrcMargin = null
    }
    if (other.SrcAsset != null) {
      this.SrcAsset = Asset.fromObject(other.SrcAsset)
    } else {
      this.SrcAsset = null
    }
    if (other.DstMargin != null) {
      this.DstMargin = AccountMargin.fromObject(other.DstMargin)
    } else {
      this.DstMargin = null
    }
    if (other.DstAsset != null) {
      this.DstAsset = Asset.fromObject(other.DstAsset)
    } else {
      this.DstAsset = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!TransferMoneyResponse}
   * @returns {!TransferMoneyResponse} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new TransferMoneyResponseModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new TransferMoneyResponseModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!TransferMoneyResponse}
   * @param {!TransferMoneyResponse} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TransferMoneyResponse)) {
      throw new TypeError('Instance of TransferMoneyResponse is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!TransferMoneyResponse}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      SrcMargin: ((this.SrcMargin != null) ? this.SrcMargin : null),
      SrcAsset: ((this.SrcAsset != null) ? this.SrcAsset : null),
      DstMargin: ((this.DstMargin != null) ? this.DstMargin : null),
      DstAsset: ((this.DstAsset != null) ? this.DstAsset : null),
      Comment: ((this.Comment != null) ? this.Comment : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return TransferMoneyResponse.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!TransferMoneyResponse} other Object value
   * @returns {!TransferMoneyResponse} Created struct
   */
  static fromObject (other) {
    return new TransferMoneyResponse().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!TransferMoneyResponse}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return TransferMoneyResponse.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!TransferMoneyResponse}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6281
  }
}

export { TransferMoneyResponse };

/**
 * Fast Binary Encoding TransferMoneyResponse field model
 */
class FieldModelTransferMoneyResponse extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelResponse(buffer, 4 + 4)
    this.SrcMargin = new fbe.FieldModelOptional(new FieldModelAccountMargin(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.SrcAsset = new fbe.FieldModelOptional(new FieldModelAsset(buffer, this.SrcMargin.fbeOffset + this.SrcMargin.fbeSize), buffer, this.SrcMargin.fbeOffset + this.SrcMargin.fbeSize)
    this.DstMargin = new fbe.FieldModelOptional(new FieldModelAccountMargin(buffer, this.SrcAsset.fbeOffset + this.SrcAsset.fbeSize), buffer, this.SrcAsset.fbeOffset + this.SrcAsset.fbeSize)
    this.DstAsset = new fbe.FieldModelOptional(new FieldModelAsset(buffer, this.DstMargin.fbeOffset + this.DstMargin.fbeSize), buffer, this.DstMargin.fbeOffset + this.DstMargin.fbeSize)
    this.Comment = new fbe.FieldModelString(buffer, this.DstAsset.fbeOffset + this.DstAsset.fbeSize)
  }

  /**
   * Get the core.Response field model
   * @this {!FieldModelTransferMoneyResponse}
   * @returns {!FieldModelResponse} core.Response field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelTransferMoneyResponse}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelTransferMoneyResponse}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.SrcMargin.fbeSize + this.SrcAsset.fbeSize + this.DstMargin.fbeSize + this.DstAsset.fbeSize + this.Comment.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelTransferMoneyResponse}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.SrcMargin.fbeExtra + this.SrcAsset.fbeExtra + this.DstMargin.fbeExtra + this.DstAsset.fbeExtra + this.Comment.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelTransferMoneyResponse}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelTransferMoneyResponse.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelTransferMoneyResponse}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6281
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelTransferMoneyResponse}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelTransferMoneyResponse}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SrcMargin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SrcMargin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SrcMargin.fbeSize

    if ((fbeCurrentSize + this.SrcAsset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SrcAsset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SrcAsset.fbeSize

    if ((fbeCurrentSize + this.DstMargin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DstMargin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DstMargin.fbeSize

    if ((fbeCurrentSize + this.DstAsset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DstAsset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DstAsset.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelTransferMoneyResponse}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelTransferMoneyResponse}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelTransferMoneyResponse}
   * @param {!TransferMoneyResponse} fbeValue Default value, defaults is new TransferMoneyResponse()
   * @returns {!TransferMoneyResponse} TransferMoneyResponse value
   */
  get (fbeValue = new TransferMoneyResponse()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelTransferMoneyResponse}
   * @param {!TransferMoneyResponse} fbeValue TransferMoneyResponse value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SrcMargin.fbeSize) <= fbeStructSize) {
      fbeValue.SrcMargin = this.SrcMargin.get()
    } else {
      fbeValue.SrcMargin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SrcMargin.fbeSize

    if ((fbeCurrentSize + this.SrcAsset.fbeSize) <= fbeStructSize) {
      fbeValue.SrcAsset = this.SrcAsset.get()
    } else {
      fbeValue.SrcAsset = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SrcAsset.fbeSize

    if ((fbeCurrentSize + this.DstMargin.fbeSize) <= fbeStructSize) {
      fbeValue.DstMargin = this.DstMargin.get()
    } else {
      fbeValue.DstMargin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DstMargin.fbeSize

    if ((fbeCurrentSize + this.DstAsset.fbeSize) <= fbeStructSize) {
      fbeValue.DstAsset = this.DstAsset.get()
    } else {
      fbeValue.DstAsset = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DstAsset.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelTransferMoneyResponse}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelTransferMoneyResponse}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelTransferMoneyResponse}
   * @param {!TransferMoneyResponse} fbeValue TransferMoneyResponse value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelTransferMoneyResponse}
   * @param {TransferMoneyResponse} fbeValue TransferMoneyResponse value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.SrcMargin.set(fbeValue.SrcMargin)
    this.SrcAsset.set(fbeValue.SrcAsset)
    this.DstMargin.set(fbeValue.DstMargin)
    this.DstAsset.set(fbeValue.DstAsset)
    this.Comment.set(fbeValue.Comment)
  }
}

export { FieldModelTransferMoneyResponse };

/**
 * Fast Binary Encoding TransferMoneyResponse model
 */
class TransferMoneyResponseModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelTransferMoneyResponse(this.buffer, 4)
  }

  /**
   * Get the TransferMoneyResponse model
   * @this {!TransferMoneyResponseModel}
   * @returns {!FieldModelTransferMoneyResponse} model TransferMoneyResponse model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!TransferMoneyResponseModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!TransferMoneyResponseModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return TransferMoneyResponseModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!TransferMoneyResponseModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelTransferMoneyResponse.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!TransferMoneyResponseModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!TransferMoneyResponseModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!TransferMoneyResponseModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!TransferMoneyResponseModel}
   * @param {!TransferMoneyResponse} value TransferMoneyResponse value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!TransferMoneyResponseModel}
   * @param {!TransferMoneyResponse} value TransferMoneyResponse value, defaults is new TransferMoneyResponse()
   * @return {!object} Deserialized TransferMoneyResponse value and its size
   */
  deserialize (value = new TransferMoneyResponse()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new TransferMoneyResponse(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new TransferMoneyResponse(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!TransferMoneyResponseModel}
   * @param {!number} prev Previous TransferMoneyResponse model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { TransferMoneyResponseModel };
