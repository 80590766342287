
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as feed from '../feed'
import * as history from '../history'
import * as storage from '../storage'
import * as client from '../client'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {LeverageProfileCreateResponse} from './LeverageProfileCreateResponse';
import {LeverageProfileLeverageMode} from '../core/LeverageProfileLeverageMode';
import {FieldModelLeverageProfileLeverageMode} from '../core/LeverageProfileLeverageMode';
import {LeverageProfileValue} from '../core/LeverageProfileValue';
import {FieldModelLeverageProfileValue} from '../core/LeverageProfileValue';

/**
 * LeverageProfileCreateRequest struct
 */
class LeverageProfileCreateRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {!string=} name
   * @param {!string=} comment
   * @param {!string=} description
   * @param {!LeverageProfileLeverageMode=} leverageMode
   * @param {!number=} leverageThreshold
   * @param {!number=} refreshTime
   * @param {!Array=} values
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), name = '', comment = '', description = '', leverageMode = new LeverageProfileLeverageMode(), leverageThreshold = 0, refreshTime = 0, values = []) {
    super()
    super.copy(parent)
    this.Name = name
    this.Comment = comment
    this.Description = description
    this.LeverageMode = leverageMode
    this.LeverageThreshold = leverageThreshold
    this.RefreshTime = refreshTime
    this.Values = values
  }

  /**
   * Copy struct (shallow copy)
   * @this {!LeverageProfileCreateRequest}
   * @param {!LeverageProfileCreateRequest} other Other struct
   * @returns {!LeverageProfileCreateRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.LeverageMode != null) {
      this.LeverageMode = LeverageProfileLeverageMode.fromObject(other.LeverageMode)
    } else {
      this.LeverageMode = null
    }
    if (other.LeverageThreshold != null) {
      this.LeverageThreshold = other.LeverageThreshold
    } else {
      this.LeverageThreshold = null
    }
    if (other.RefreshTime != null) {
      this.RefreshTime = other.RefreshTime
    } else {
      this.RefreshTime = null
    }
    if (other.Values != null) {
      this.Values = []
      for (let item of other.Values) {
        if (item != null) {
          let tempItem
          tempItem = LeverageProfileValue.fromObject(item)
          this.Values.push(tempItem)
        } else {
          this.Values.push(null)
        }
      }
    } else {
      this.Values = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!LeverageProfileCreateRequest}
   * @returns {!LeverageProfileCreateRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new LeverageProfileCreateRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new LeverageProfileCreateRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!LeverageProfileCreateRequest}
   * @param {!LeverageProfileCreateRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof LeverageProfileCreateRequest)) {
      throw new TypeError('Instance of LeverageProfileCreateRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!LeverageProfileCreateRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Name: ((this.Name != null) ? this.Name : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      Description: ((this.Description != null) ? this.Description : null),
      LeverageMode: ((this.LeverageMode != null) ? this.LeverageMode : null),
      LeverageThreshold: ((this.LeverageThreshold != null) ? this.LeverageThreshold : null),
      RefreshTime: ((this.RefreshTime != null) ? this.RefreshTime : null),
      Values: ((this.Values != null) ? Array.from(this.Values, item => ((item != null) ? item : null)) : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return LeverageProfileCreateRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!LeverageProfileCreateRequest} other Object value
   * @returns {!LeverageProfileCreateRequest} Created struct
   */
  static fromObject (other) {
    return new LeverageProfileCreateRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!LeverageProfileCreateRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return LeverageProfileCreateRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!LeverageProfileCreateRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6377
  }
}

export { LeverageProfileCreateRequest };

/**
 * Fast Binary Encoding LeverageProfileCreateRequest field model
 */
class FieldModelLeverageProfileCreateRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.Name = new fbe.FieldModelString(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.Comment = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.Description = new fbe.FieldModelString(buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.LeverageMode = new FieldModelLeverageProfileLeverageMode(buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.LeverageThreshold = new fbe.FieldModelUInt32(buffer, this.LeverageMode.fbeOffset + this.LeverageMode.fbeSize)
    this.RefreshTime = new fbe.FieldModelUInt32(buffer, this.LeverageThreshold.fbeOffset + this.LeverageThreshold.fbeSize)
    this.Values = new fbe.FieldModelVector(new FieldModelLeverageProfileValue(buffer, this.RefreshTime.fbeOffset + this.RefreshTime.fbeSize), buffer, this.RefreshTime.fbeOffset + this.RefreshTime.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelLeverageProfileCreateRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelLeverageProfileCreateRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelLeverageProfileCreateRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Name.fbeSize + this.Comment.fbeSize + this.Description.fbeSize + this.LeverageMode.fbeSize + this.LeverageThreshold.fbeSize + this.RefreshTime.fbeSize + this.Values.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelLeverageProfileCreateRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Name.fbeExtra + this.Comment.fbeExtra + this.Description.fbeExtra + this.LeverageMode.fbeExtra + this.LeverageThreshold.fbeExtra + this.RefreshTime.fbeExtra + this.Values.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelLeverageProfileCreateRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelLeverageProfileCreateRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelLeverageProfileCreateRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6377
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelLeverageProfileCreateRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelLeverageProfileCreateRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.LeverageMode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LeverageMode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LeverageMode.fbeSize

    if ((fbeCurrentSize + this.LeverageThreshold.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LeverageThreshold.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LeverageThreshold.fbeSize

    if ((fbeCurrentSize + this.RefreshTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RefreshTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RefreshTime.fbeSize

    if ((fbeCurrentSize + this.Values.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Values.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Values.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelLeverageProfileCreateRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelLeverageProfileCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelLeverageProfileCreateRequest}
   * @param {!LeverageProfileCreateRequest} fbeValue Default value, defaults is new LeverageProfileCreateRequest()
   * @returns {!LeverageProfileCreateRequest} LeverageProfileCreateRequest value
   */
  get (fbeValue = new LeverageProfileCreateRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelLeverageProfileCreateRequest}
   * @param {!LeverageProfileCreateRequest} fbeValue LeverageProfileCreateRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.LeverageMode.fbeSize) <= fbeStructSize) {
      fbeValue.LeverageMode = this.LeverageMode.get()
    } else {
      fbeValue.LeverageMode = new LeverageProfileLeverageMode()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LeverageMode.fbeSize

    if ((fbeCurrentSize + this.LeverageThreshold.fbeSize) <= fbeStructSize) {
      fbeValue.LeverageThreshold = this.LeverageThreshold.get()
    } else {
      fbeValue.LeverageThreshold = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LeverageThreshold.fbeSize

    if ((fbeCurrentSize + this.RefreshTime.fbeSize) <= fbeStructSize) {
      fbeValue.RefreshTime = this.RefreshTime.get()
    } else {
      fbeValue.RefreshTime = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RefreshTime.fbeSize

    if ((fbeCurrentSize + this.Values.fbeSize) <= fbeStructSize) {
      this.Values.get(fbeValue.Values)
    } else {
      fbeValue.Values.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Values.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelLeverageProfileCreateRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelLeverageProfileCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelLeverageProfileCreateRequest}
   * @param {!LeverageProfileCreateRequest} fbeValue LeverageProfileCreateRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelLeverageProfileCreateRequest}
   * @param {LeverageProfileCreateRequest} fbeValue LeverageProfileCreateRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Name.set(fbeValue.Name)
    this.Comment.set(fbeValue.Comment)
    this.Description.set(fbeValue.Description)
    this.LeverageMode.set(fbeValue.LeverageMode)
    this.LeverageThreshold.set(fbeValue.LeverageThreshold)
    this.RefreshTime.set(fbeValue.RefreshTime)
    this.Values.set(fbeValue.Values)
  }
}

export { FieldModelLeverageProfileCreateRequest };

/**
 * Fast Binary Encoding LeverageProfileCreateRequest model
 */
class LeverageProfileCreateRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelLeverageProfileCreateRequest(this.buffer, 4)
  }

  /**
   * Get the LeverageProfileCreateRequest model
   * @this {!LeverageProfileCreateRequestModel}
   * @returns {!FieldModelLeverageProfileCreateRequest} model LeverageProfileCreateRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!LeverageProfileCreateRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!LeverageProfileCreateRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return LeverageProfileCreateRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!LeverageProfileCreateRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelLeverageProfileCreateRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!LeverageProfileCreateRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!LeverageProfileCreateRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!LeverageProfileCreateRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!LeverageProfileCreateRequestModel}
   * @param {!LeverageProfileCreateRequest} value LeverageProfileCreateRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!LeverageProfileCreateRequestModel}
   * @param {!LeverageProfileCreateRequest} value LeverageProfileCreateRequest value, defaults is new LeverageProfileCreateRequest()
   * @return {!object} Deserialized LeverageProfileCreateRequest value and its size
   */
  deserialize (value = new LeverageProfileCreateRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new LeverageProfileCreateRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new LeverageProfileCreateRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!LeverageProfileCreateRequestModel}
   * @param {!number} prev Previous LeverageProfileCreateRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { LeverageProfileCreateRequestModel };
LeverageProfileCreateRequest.__has_response = true;
LeverageProfileCreateRequest.__response_class = LeverageProfileCreateResponse;
