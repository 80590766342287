
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as feed from '../feed'
import * as history from '../history'
import * as storage from '../storage'
import * as client from '../client'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {NewsProfileCreateResponse} from './NewsProfileCreateResponse';

/**
 * NewsProfileCreateRequest struct
 */
class NewsProfileCreateRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {!string=} name
   * @param {!string=} comment
   * @param {!string=} description
   * @param {!Date=} time
   * @param {!number=} beforeSeconds
   * @param {!number=} afterSeconds
   * @param {!UUID=} marginRateProfileId
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), name = '', comment = '', description = '', time = new Date(0), beforeSeconds = new UInt64(60, 0), afterSeconds = new UInt64(60, 0), marginRateProfileId = new UUID()) {
    super()
    super.copy(parent)
    this.Name = name
    this.Comment = comment
    this.Description = description
    this.Time = time
    this.BeforeSeconds = beforeSeconds
    this.AfterSeconds = afterSeconds
    this.MarginRateProfileId = marginRateProfileId
  }

  /**
   * Copy struct (shallow copy)
   * @this {!NewsProfileCreateRequest}
   * @param {!NewsProfileCreateRequest} other Other struct
   * @returns {!NewsProfileCreateRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.Time != null) {
      if (other.Time instanceof Date) {
        this.Time = new Date(other.Time.getTime())
      } else {
        this.Time = new Date(Math.round(other.Time / 1000000))
      }
    } else {
      this.Time = null
    }
    if (other.BeforeSeconds != null) {
      this.BeforeSeconds = UInt64.fromNumber(other.BeforeSeconds)
    } else {
      this.BeforeSeconds = null
    }
    if (other.AfterSeconds != null) {
      this.AfterSeconds = UInt64.fromNumber(other.AfterSeconds)
    } else {
      this.AfterSeconds = null
    }
    if (other.MarginRateProfileId != null) {
      this.MarginRateProfileId = new UUID(other.MarginRateProfileId)
    } else {
      this.MarginRateProfileId = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!NewsProfileCreateRequest}
   * @returns {!NewsProfileCreateRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new NewsProfileCreateRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new NewsProfileCreateRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!NewsProfileCreateRequest}
   * @param {!NewsProfileCreateRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof NewsProfileCreateRequest)) {
      throw new TypeError('Instance of NewsProfileCreateRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!NewsProfileCreateRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Name: ((this.Name != null) ? this.Name : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      Description: ((this.Description != null) ? this.Description : null),
      Time: ((this.Time != null) ? (this.Time.getTime() * 1000000) : null),
      BeforeSeconds: ((this.BeforeSeconds != null) ? this.BeforeSeconds.toNumber() : null),
      AfterSeconds: ((this.AfterSeconds != null) ? this.AfterSeconds.toNumber() : null),
      MarginRateProfileId: ((this.MarginRateProfileId != null) ? this.MarginRateProfileId.toString() : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return NewsProfileCreateRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!NewsProfileCreateRequest} other Object value
   * @returns {!NewsProfileCreateRequest} Created struct
   */
  static fromObject (other) {
    return new NewsProfileCreateRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!NewsProfileCreateRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return NewsProfileCreateRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!NewsProfileCreateRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6399
  }
}

export { NewsProfileCreateRequest };

/**
 * Fast Binary Encoding NewsProfileCreateRequest field model
 */
class FieldModelNewsProfileCreateRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.Name = new fbe.FieldModelString(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.Comment = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.Description = new fbe.FieldModelString(buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.Time = new fbe.FieldModelTimestamp(buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.BeforeSeconds = new fbe.FieldModelUInt64(buffer, this.Time.fbeOffset + this.Time.fbeSize)
    this.AfterSeconds = new fbe.FieldModelUInt64(buffer, this.BeforeSeconds.fbeOffset + this.BeforeSeconds.fbeSize)
    this.MarginRateProfileId = new fbe.FieldModelUUID(buffer, this.AfterSeconds.fbeOffset + this.AfterSeconds.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelNewsProfileCreateRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelNewsProfileCreateRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelNewsProfileCreateRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Name.fbeSize + this.Comment.fbeSize + this.Description.fbeSize + this.Time.fbeSize + this.BeforeSeconds.fbeSize + this.AfterSeconds.fbeSize + this.MarginRateProfileId.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelNewsProfileCreateRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Name.fbeExtra + this.Comment.fbeExtra + this.Description.fbeExtra + this.Time.fbeExtra + this.BeforeSeconds.fbeExtra + this.AfterSeconds.fbeExtra + this.MarginRateProfileId.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelNewsProfileCreateRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelNewsProfileCreateRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelNewsProfileCreateRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6399
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelNewsProfileCreateRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelNewsProfileCreateRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.Time.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Time.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Time.fbeSize

    if ((fbeCurrentSize + this.BeforeSeconds.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BeforeSeconds.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BeforeSeconds.fbeSize

    if ((fbeCurrentSize + this.AfterSeconds.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AfterSeconds.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AfterSeconds.fbeSize

    if ((fbeCurrentSize + this.MarginRateProfileId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MarginRateProfileId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginRateProfileId.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelNewsProfileCreateRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelNewsProfileCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelNewsProfileCreateRequest}
   * @param {!NewsProfileCreateRequest} fbeValue Default value, defaults is new NewsProfileCreateRequest()
   * @returns {!NewsProfileCreateRequest} NewsProfileCreateRequest value
   */
  get (fbeValue = new NewsProfileCreateRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelNewsProfileCreateRequest}
   * @param {!NewsProfileCreateRequest} fbeValue NewsProfileCreateRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.Time.fbeSize) <= fbeStructSize) {
      fbeValue.Time = this.Time.get()
    } else {
      fbeValue.Time = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Time.fbeSize

    if ((fbeCurrentSize + this.BeforeSeconds.fbeSize) <= fbeStructSize) {
      fbeValue.BeforeSeconds = this.BeforeSeconds.get(new UInt64(60, 0))
    } else {
      fbeValue.BeforeSeconds = new UInt64(60, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BeforeSeconds.fbeSize

    if ((fbeCurrentSize + this.AfterSeconds.fbeSize) <= fbeStructSize) {
      fbeValue.AfterSeconds = this.AfterSeconds.get(new UInt64(60, 0))
    } else {
      fbeValue.AfterSeconds = new UInt64(60, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AfterSeconds.fbeSize

    if ((fbeCurrentSize + this.MarginRateProfileId.fbeSize) <= fbeStructSize) {
      fbeValue.MarginRateProfileId = this.MarginRateProfileId.get()
    } else {
      fbeValue.MarginRateProfileId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MarginRateProfileId.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelNewsProfileCreateRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelNewsProfileCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelNewsProfileCreateRequest}
   * @param {!NewsProfileCreateRequest} fbeValue NewsProfileCreateRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelNewsProfileCreateRequest}
   * @param {NewsProfileCreateRequest} fbeValue NewsProfileCreateRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Name.set(fbeValue.Name)
    this.Comment.set(fbeValue.Comment)
    this.Description.set(fbeValue.Description)
    this.Time.set(fbeValue.Time)
    this.BeforeSeconds.set(fbeValue.BeforeSeconds)
    this.AfterSeconds.set(fbeValue.AfterSeconds)
    this.MarginRateProfileId.set(fbeValue.MarginRateProfileId)
  }
}

export { FieldModelNewsProfileCreateRequest };

/**
 * Fast Binary Encoding NewsProfileCreateRequest model
 */
class NewsProfileCreateRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelNewsProfileCreateRequest(this.buffer, 4)
  }

  /**
   * Get the NewsProfileCreateRequest model
   * @this {!NewsProfileCreateRequestModel}
   * @returns {!FieldModelNewsProfileCreateRequest} model NewsProfileCreateRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!NewsProfileCreateRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!NewsProfileCreateRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return NewsProfileCreateRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!NewsProfileCreateRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelNewsProfileCreateRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!NewsProfileCreateRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!NewsProfileCreateRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!NewsProfileCreateRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!NewsProfileCreateRequestModel}
   * @param {!NewsProfileCreateRequest} value NewsProfileCreateRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!NewsProfileCreateRequestModel}
   * @param {!NewsProfileCreateRequest} value NewsProfileCreateRequest value, defaults is new NewsProfileCreateRequest()
   * @return {!object} Deserialized NewsProfileCreateRequest value and its size
   */
  deserialize (value = new NewsProfileCreateRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new NewsProfileCreateRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new NewsProfileCreateRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!NewsProfileCreateRequestModel}
   * @param {!number} prev Previous NewsProfileCreateRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { NewsProfileCreateRequestModel };
NewsProfileCreateRequest.__has_response = true;
NewsProfileCreateRequest.__response_class = NewsProfileCreateResponse;
