import { UInt64 } from '@api/fbe/int64';
import { UUID } from '@api/fbe/uuid';
import { Manager } from '@api/fbe/core/Manager';
import { ManagerRight } from '@api/fbe/core/ManagerRight';
import { ManagerRights } from '@api/fbe/core/ManagerRights';
import { RightsMode } from '@api/fbe/core/RightsMode';
import { ParsedManager, ParsedManagerRights } from '@models';
import { ManagerCreateRequest } from '@api/fbe/manager/ManagerCreateRequest';
import { ManagerModifyRequest } from '@api/fbe/manager/ManagerModifyRequest';

export const adaptManagerRightsFromApi = (rights: ManagerRights): ParsedManagerRights => ({
    AccountGroups: rights.AccountGroups,
    SymbolGroups: rights.SymbolGroups,
    Accounts: rights.Accounts.toJSON(),
    Symbols: rights.Symbols.toJSON(),
    System: rights.System.toJSON(),
    Modules: rights.Modules.toJSON(),
    Platforms: rights.Platforms.toJSON(),
    Managers: rights.Managers.toJSON(),
    Currencies: rights.Currencies.toJSON(),
    Calendars: rights.Calendars.toJSON(),
    Profiles: rights.Profiles.toJSON(),
    DataFeeds: rights.DataFeeds.toJSON(),
    Gateways: rights.Gateways.toJSON(),
    TradingRoutes: rights.TradingRoutes.toJSON(),
    QuotesHistory: rights.QuotesHistory.toJSON(),
    Reports: rights.Reports.toJSON(),
    Monitoring: rights.Monitoring.toJSON(),
    Rights: Array.from(rights.Rights).map((right) => right.toJSON()),
});

export const adaptFromApi = (manager: Manager): ParsedManager => ({
    Login: manager.Login.toString(),
    SortOrder: manager.SortOrder,
    ManagerGroupId: manager.ManagerGroupId.toString(),
    Name: manager.Name,
    Comment: manager.Comment,
    Description: manager.Description,
    Email: manager.Email,
    IsEnabled: manager.IsEnabled,
    IsOTPEnabled: manager.IsOTPEnabled,
    IsManagerRightsSet: manager.IsManagerRightsSet,
    ManagerRights: manager.ManagerRights ? adaptManagerRightsFromApi(manager.ManagerRights) : null,
    AuthenticationType: manager.AuthenticationType.toJSON(),
    PasswordDigest: manager.PasswordDigest,
    OTPDigest: manager.OTPDigest,
    Password: '',
});

/* export const adaptManagerRightsToApi = (parsedRights: ParsedManagerRights): ApiManagerRights => ({
    AccountGroups: parsedRights.AccountGroups,
    SymbolGroups: parsedRights.SymbolGroups,
    Accounts: new RightsMode(parsedRights.Accounts),
    Symbols: new RightsMode(parsedRights.Symbols),
    System: new RightsMode(parsedRights.System),
    Modules: new RightsMode(parsedRights.Modules),
    Platforms: new RightsMode(parsedRights.Platforms),
    Managers: new RightsMode(parsedRights.Managers),
    Currencies: new RightsMode(parsedRights.Currencies),
    Calendars: new RightsMode(parsedRights.Calendars),
    Profiles: new RightsMode(parsedRights.Profiles),
    DataFeeds: new RightsMode(parsedRights.DataFeeds),
    Gateways: new RightsMode(parsedRights.Gateways),
    TradingRoutes: new RightsMode(parsedRights.TradingRoutes),
    QuotesHistory: new RightsMode(parsedRights.QuotesHistory),
    Reports: new RightsMode(parsedRights.Reports),
    Monitoring: new RightsMode(parsedRights.Monitoring),
    Rights: new Set(parsedRights.Rights.map((right) => new ManagerRight(right))),
}); */

export const adaptToApiCreate = (request: ManagerCreateRequest, parsedManager: ParsedManager): ManagerCreateRequest => {
    request.SortOrder = parsedManager.SortOrder;
    request.ManagerGroupId = parsedManager.ManagerGroupId ? UUID.fromString(parsedManager.ManagerGroupId) : null;
    request.Name = parsedManager.Name;
    request.Comment = parsedManager.Comment;
    request.Description = parsedManager.Description;
    request.Email = parsedManager.Email;
    request.IsEnabled = parsedManager.IsEnabled;
    request.IsOTPEnabled = parsedManager.IsOTPEnabled;
    request.IsManagerRightsSet = parsedManager.IsManagerRightsSet;
    request.AccessibleAccountGroups = parsedManager.ManagerRights ? parsedManager.ManagerRights.AccountGroups : [];
    request.AccessibleSymbolGroups = parsedManager.ManagerRights ? parsedManager.ManagerRights.SymbolGroups : [];
    request.Password = parsedManager.Password;
    if (request.IsManagerRightsSet) {
        request.AccountsRights = new RightsMode(3);
        request.SymbolsRights = new RightsMode(3);
        request.SystemRights = new RightsMode(3);
        request.ModulesRights = new RightsMode(3);
        request.PlatformsRights = new RightsMode(3);
        request.ManagersRights = new RightsMode(3);
        request.CurrenciesRights = new RightsMode(3);
        request.CalendarsRights = new RightsMode(3);
        request.ProfilesRights = new RightsMode(3);
        request.DataFeedsRights = new RightsMode(3);
        request.GatewaysRights = new RightsMode(3);
        request.TradingRoutesRights = new RightsMode(3);
        request.QuotesHistoryRights = new RightsMode(3);
        request.ReportsRights = new RightsMode(3);
        request.MonitoringRights =  new RightsMode(3);
        request.Rights = new Set(parsedManager.ManagerRights?.Rights.map((right) => new ManagerRight(right)) || []);
    }

    return request;
};

// TODO: Hidden for first release
// export const adaptToApiCreate = (request: ManagerCreateRequest, parsedManager: ParsedManager): ManagerCreateRequest => {
//     request.SortOrder = parsedManager.SortOrder;
//     request.ManagerGroupId = UUID.fromString(parsedManager.ManagerGroupId);
//     request.Name = parsedManager.Name;
//     request.Comment = parsedManager.Comment;
//     request.Description = parsedManager.Description;
//     request.Email = parsedManager.Email;
//     request.IsEnabled = parsedManager.IsEnabled;
//     request.IsOTPEnabled = parsedManager.IsOTPEnabled;
//     request.IsManagerRightsSet = parsedManager.IsManagerRightsSet;
//     request.AccessibleAccountGroups = parsedManager.ManagerRights ? parsedManager.ManagerRights.AccountGroups : [];
//     request.AccessibleSymbolGroups = parsedManager.ManagerRights ? parsedManager.ManagerRights.SymbolGroups : [];
//     request.Password = parsedManager.Password;
//     if (request.IsManagerRightsSet) {
//         request.AccountsRights = parsedManager.ManagerRights
//             ? new RightsMode(parsedManager.ManagerRights.Accounts)
//             : parsedManager.ManagerRights;
//         request.SymbolsRights = parsedManager.ManagerRights
//             ? new RightsMode(parsedManager.ManagerRights.Symbols)
//             : parsedManager.ManagerRights;
//         request.SystemRights = parsedManager.ManagerRights
//             ? new RightsMode(parsedManager.ManagerRights.System)
//             : parsedManager.ManagerRights;
//         request.ModulesRights = parsedManager.ManagerRights
//             ? new RightsMode(3)
//             : parsedManager.ManagerRights;
//         request.PlatformsRights = parsedManager.ManagerRights
//             ? new RightsMode(parsedManager.ManagerRights.Platforms)
//             : parsedManager.ManagerRights;
//         request.ManagersRights = parsedManager.ManagerRights
//             ? new RightsMode(parsedManager.ManagerRights.Managers)
//             : parsedManager.ManagerRights;
//         request.CurrenciesRights = parsedManager.ManagerRights
//             ? new RightsMode(parsedManager.ManagerRights.Currencies)
//             : parsedManager.ManagerRights;
//         request.CalendarsRights = parsedManager.ManagerRights
//             ? new RightsMode(parsedManager.ManagerRights.Calendars)
//             : parsedManager.ManagerRights;
//         request.ProfilesRights = parsedManager.ManagerRights
//             ? new RightsMode(parsedManager.ManagerRights.Profiles)
//             : parsedManager.ManagerRights;
//         request.DataFeedsRights = parsedManager.ManagerRights
//             ? new RightsMode(parsedManager.ManagerRights.DataFeeds)
//             : parsedManager.ManagerRights;
//         request.GatewaysRights = parsedManager.ManagerRights
//             ? new RightsMode(parsedManager.ManagerRights.Gateways)
//             : parsedManager.ManagerRights;
//         request.TradingRoutesRights = parsedManager.ManagerRights
//             ? new RightsMode(parsedManager.ManagerRights.TradingRoutes)
//             : parsedManager.ManagerRights;
//         request.QuotesHistoryRights = parsedManager.ManagerRights
//             ? new RightsMode(parsedManager.ManagerRights.QuotesHistory)
//             : parsedManager.ManagerRights;
//         request.ReportsRights = parsedManager.ManagerRights
//             ? new RightsMode(parsedManager.ManagerRights.Reports)
//             : parsedManager.ManagerRights;
//         request.MonitoringRights = parsedManager.ManagerRights
//             ? new RightsMode(parsedManager.ManagerRights.Monitoring)
//             : parsedManager.ManagerRights;
//         request.Rights = new Set(parsedManager.ManagerRights?.Rights.map((right) => new ManagerRight(right)) || []);
//     }

//     return request;
// };

export const adaptToApiModify = (request: ManagerModifyRequest, parsedManager: ParsedManager): ManagerModifyRequest => {
    request.Login = UInt64.fromString(parsedManager.Login);
    request.NewSortOrder = parsedManager.SortOrder;
    request.NewManagerGroupId = parsedManager.ManagerGroupId ? UUID.fromString(parsedManager.ManagerGroupId) : null;
    request.NewName = parsedManager.Name;
    request.NewComment = parsedManager.Comment;
    request.NewDescription = parsedManager.Description;
    request.NewEmail = parsedManager.Email;
    request.NewIsEnabled = parsedManager.IsEnabled;
    if (parsedManager.IsEnabled === null) request.NewIsEnabledReset = true;
    request.NewIsOTPEnabled = parsedManager.IsOTPEnabled;
    if (parsedManager.IsOTPEnabled === null) request.NewIsOTPEnabledReset = true;
    request.NewIsManagerRightsSet = parsedManager.IsManagerRightsSet;
    request.NewAccessibleAccountGroups = parsedManager.ManagerRights ? parsedManager.ManagerRights.AccountGroups : [];
    request.NewAccessibleSymbolGroups = parsedManager.ManagerRights ? parsedManager.ManagerRights.SymbolGroups : [];

    if (request.NewAccessibleAccountGroups.length === 0) {
        request.AccessibleAccountGroupsReset = true;
    }
    if (request.NewAccessibleSymbolGroups.length === 0) {
        request.AccessibleSymbolGroupsReset = true;
    }

    request.NewPassword = parsedManager.Password || null;
    if (parsedManager.IsManagerRightsSet) {
        request.NewAccountsRights = parsedManager.ManagerRights
            ? new RightsMode(parsedManager.ManagerRights.Accounts)
            : parsedManager.ManagerRights;
        request.NewSymbolsRights = parsedManager.ManagerRights
            ? new RightsMode(parsedManager.ManagerRights.Symbols)
            : parsedManager.ManagerRights;
        request.NewSystemRights = parsedManager.ManagerRights
            ? new RightsMode(parsedManager.ManagerRights.System)
            : parsedManager.ManagerRights;
        request.NewModulesRights = parsedManager.ManagerRights
            ? new RightsMode(parsedManager.ManagerRights.Modules)
            : parsedManager.ManagerRights;
        request.NewPlatformsRights = parsedManager.ManagerRights
            ? new RightsMode(parsedManager.ManagerRights.Platforms)
            : parsedManager.ManagerRights;
        request.NewManagersRights = parsedManager.ManagerRights
            ? new RightsMode(parsedManager.ManagerRights.Managers)
            : parsedManager.ManagerRights;
        request.NewCurrenciesRights = parsedManager.ManagerRights
            ? new RightsMode(parsedManager.ManagerRights.Currencies)
            : parsedManager.ManagerRights;
        request.NewCalendarsRights = parsedManager.ManagerRights
            ? new RightsMode(parsedManager.ManagerRights.Calendars)
            : parsedManager.ManagerRights;
        request.NewProfilesRights = parsedManager.ManagerRights
            ? new RightsMode(parsedManager.ManagerRights.Profiles)
            : parsedManager.ManagerRights;
        request.NewDataFeedsRights = parsedManager.ManagerRights
            ? new RightsMode(parsedManager.ManagerRights.DataFeeds)
            : parsedManager.ManagerRights;
        request.NewGatewaysRights = parsedManager.ManagerRights
            ? new RightsMode(parsedManager.ManagerRights.Gateways)
            : parsedManager.ManagerRights;
        request.NewTradingRoutesRights = parsedManager.ManagerRights
            ? new RightsMode(parsedManager.ManagerRights.TradingRoutes)
            : parsedManager.ManagerRights;
        request.NewQuotesHistoryRights = parsedManager.ManagerRights
            ? new RightsMode(parsedManager.ManagerRights.QuotesHistory)
            : parsedManager.ManagerRights;
        request.NewReportsRights = parsedManager.ManagerRights
            ? new RightsMode(parsedManager.ManagerRights.Reports)
            : parsedManager.ManagerRights;
        request.NewMonitoringRights = parsedManager.ManagerRights
            ? new RightsMode(parsedManager.ManagerRights.Monitoring)
            : parsedManager.ManagerRights;
        request.NewRights = new Set(parsedManager.ManagerRights?.Rights.map((right) => new ManagerRight(right)) || []);
    } else request.AllManagerRightsReset = true;

    return request;
};
