
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as feed from '../feed'
import * as history from '../history'
import * as storage from '../storage'
import * as client from '../client'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {ManagerModifyResponse} from './ManagerModifyResponse';
import {RightsMode} from '../core/RightsMode';
import {FieldModelRightsMode} from '../core/RightsMode';
import {ManagerRight} from '../core/ManagerRight';
import {FieldModelManagerRight} from '../core/ManagerRight';

/**
 * ManagerModifyRequest struct
 */
class ManagerModifyRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {!number=} login
   * @param {number=} newLogin
   * @param {number=} newSortOrder
   * @param {UUID=} newManagerGroupId
   * @param {string=} newManagerGroupName
   * @param {string=} newName
   * @param {string=} newComment
   * @param {string=} newDescription
   * @param {string=} newEmail
   * @param {boolean=} newIsEnabled
   * @param {!boolean=} newIsEnabledReset
   * @param {boolean=} newIsOTPEnabled
   * @param {!boolean=} newIsOTPEnabledReset
   * @param {!boolean=} allManagerRightsReset
   * @param {boolean=} newIsManagerRightsSet
   * @param {!Array=} newAccessibleAccountGroups
   * @param {!boolean=} accessibleAccountGroupsReset
   * @param {!Array=} newAccessibleSymbolGroups
   * @param {!boolean=} accessibleSymbolGroupsReset
   * @param {RightsMode=} newAccountsRights
   * @param {RightsMode=} newSymbolsRights
   * @param {RightsMode=} newSystemRights
   * @param {RightsMode=} newModulesRights
   * @param {RightsMode=} newPlatformsRights
   * @param {RightsMode=} newManagersRights
   * @param {RightsMode=} newCurrenciesRights
   * @param {RightsMode=} newCalendarsRights
   * @param {RightsMode=} newProfilesRights
   * @param {RightsMode=} newDataFeedsRights
   * @param {RightsMode=} newGatewaysRights
   * @param {RightsMode=} newTradingRoutesRights
   * @param {RightsMode=} newQuotesHistoryRights
   * @param {RightsMode=} newReportsRights
   * @param {RightsMode=} newMonitoringRights
   * @param {!Set=} newRights
   * @param {string=} newPassword
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), login = new UInt64(0, 0), newLogin = null, newSortOrder = null, newManagerGroupId = null, newManagerGroupName = null, newName = null, newComment = null, newDescription = null, newEmail = null, newIsEnabled = null, newIsEnabledReset = false, newIsOTPEnabled = null, newIsOTPEnabledReset = false, allManagerRightsReset = false, newIsManagerRightsSet = null, newAccessibleAccountGroups = [], accessibleAccountGroupsReset = false, newAccessibleSymbolGroups = [], accessibleSymbolGroupsReset = false, newAccountsRights = null, newSymbolsRights = null, newSystemRights = null, newModulesRights = null, newPlatformsRights = null, newManagersRights = null, newCurrenciesRights = null, newCalendarsRights = null, newProfilesRights = null, newDataFeedsRights = null, newGatewaysRights = null, newTradingRoutesRights = null, newQuotesHistoryRights = null, newReportsRights = null, newMonitoringRights = null, newRights = new Set(), newPassword = null) {
    super()
    super.copy(parent)
    this.Login = login
    this.NewLogin = newLogin
    this.NewSortOrder = newSortOrder
    this.NewManagerGroupId = newManagerGroupId
    this.NewManagerGroupName = newManagerGroupName
    this.NewName = newName
    this.NewComment = newComment
    this.NewDescription = newDescription
    this.NewEmail = newEmail
    this.NewIsEnabled = newIsEnabled
    this.NewIsEnabledReset = newIsEnabledReset
    this.NewIsOTPEnabled = newIsOTPEnabled
    this.NewIsOTPEnabledReset = newIsOTPEnabledReset
    this.AllManagerRightsReset = allManagerRightsReset
    this.NewIsManagerRightsSet = newIsManagerRightsSet
    this.NewAccessibleAccountGroups = newAccessibleAccountGroups
    this.AccessibleAccountGroupsReset = accessibleAccountGroupsReset
    this.NewAccessibleSymbolGroups = newAccessibleSymbolGroups
    this.AccessibleSymbolGroupsReset = accessibleSymbolGroupsReset
    this.NewAccountsRights = newAccountsRights
    this.NewSymbolsRights = newSymbolsRights
    this.NewSystemRights = newSystemRights
    this.NewModulesRights = newModulesRights
    this.NewPlatformsRights = newPlatformsRights
    this.NewManagersRights = newManagersRights
    this.NewCurrenciesRights = newCurrenciesRights
    this.NewCalendarsRights = newCalendarsRights
    this.NewProfilesRights = newProfilesRights
    this.NewDataFeedsRights = newDataFeedsRights
    this.NewGatewaysRights = newGatewaysRights
    this.NewTradingRoutesRights = newTradingRoutesRights
    this.NewQuotesHistoryRights = newQuotesHistoryRights
    this.NewReportsRights = newReportsRights
    this.NewMonitoringRights = newMonitoringRights
    this.NewRights = newRights
    this.NewPassword = newPassword
  }

  /**
   * Copy struct (shallow copy)
   * @this {!ManagerModifyRequest}
   * @param {!ManagerModifyRequest} other Other struct
   * @returns {!ManagerModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.NewLogin != null) {
      this.NewLogin = UInt64.fromNumber(other.NewLogin)
    } else {
      this.NewLogin = null
    }
    if (other.NewSortOrder != null) {
      this.NewSortOrder = other.NewSortOrder
    } else {
      this.NewSortOrder = null
    }
    if (other.NewManagerGroupId != null) {
      this.NewManagerGroupId = new UUID(other.NewManagerGroupId)
    } else {
      this.NewManagerGroupId = null
    }
    if (other.NewManagerGroupName != null) {
      this.NewManagerGroupName = other.NewManagerGroupName
    } else {
      this.NewManagerGroupName = null
    }
    if (other.NewName != null) {
      this.NewName = other.NewName
    } else {
      this.NewName = null
    }
    if (other.NewComment != null) {
      this.NewComment = other.NewComment
    } else {
      this.NewComment = null
    }
    if (other.NewDescription != null) {
      this.NewDescription = other.NewDescription
    } else {
      this.NewDescription = null
    }
    if (other.NewEmail != null) {
      this.NewEmail = other.NewEmail
    } else {
      this.NewEmail = null
    }
    if (other.NewIsEnabled != null) {
      this.NewIsEnabled = other.NewIsEnabled
    } else {
      this.NewIsEnabled = null
    }
    if (other.NewIsEnabledReset != null) {
      this.NewIsEnabledReset = other.NewIsEnabledReset
    } else {
      this.NewIsEnabledReset = null
    }
    if (other.NewIsOTPEnabled != null) {
      this.NewIsOTPEnabled = other.NewIsOTPEnabled
    } else {
      this.NewIsOTPEnabled = null
    }
    if (other.NewIsOTPEnabledReset != null) {
      this.NewIsOTPEnabledReset = other.NewIsOTPEnabledReset
    } else {
      this.NewIsOTPEnabledReset = null
    }
    if (other.AllManagerRightsReset != null) {
      this.AllManagerRightsReset = other.AllManagerRightsReset
    } else {
      this.AllManagerRightsReset = null
    }
    if (other.NewIsManagerRightsSet != null) {
      this.NewIsManagerRightsSet = other.NewIsManagerRightsSet
    } else {
      this.NewIsManagerRightsSet = null
    }
    if (other.NewAccessibleAccountGroups != null) {
      this.NewAccessibleAccountGroups = []
      for (let item of other.NewAccessibleAccountGroups) {
        if (item != null) {
          let tempItem
          tempItem = item
          this.NewAccessibleAccountGroups.push(tempItem)
        } else {
          this.NewAccessibleAccountGroups.push(null)
        }
      }
    } else {
      this.NewAccessibleAccountGroups = null
    }
    if (other.AccessibleAccountGroupsReset != null) {
      this.AccessibleAccountGroupsReset = other.AccessibleAccountGroupsReset
    } else {
      this.AccessibleAccountGroupsReset = null
    }
    if (other.NewAccessibleSymbolGroups != null) {
      this.NewAccessibleSymbolGroups = []
      for (let item of other.NewAccessibleSymbolGroups) {
        if (item != null) {
          let tempItem
          tempItem = item
          this.NewAccessibleSymbolGroups.push(tempItem)
        } else {
          this.NewAccessibleSymbolGroups.push(null)
        }
      }
    } else {
      this.NewAccessibleSymbolGroups = null
    }
    if (other.AccessibleSymbolGroupsReset != null) {
      this.AccessibleSymbolGroupsReset = other.AccessibleSymbolGroupsReset
    } else {
      this.AccessibleSymbolGroupsReset = null
    }
    if (other.NewAccountsRights != null) {
      this.NewAccountsRights = RightsMode.fromObject(other.NewAccountsRights)
    } else {
      this.NewAccountsRights = null
    }
    if (other.NewSymbolsRights != null) {
      this.NewSymbolsRights = RightsMode.fromObject(other.NewSymbolsRights)
    } else {
      this.NewSymbolsRights = null
    }
    if (other.NewSystemRights != null) {
      this.NewSystemRights = RightsMode.fromObject(other.NewSystemRights)
    } else {
      this.NewSystemRights = null
    }
    if (other.NewModulesRights != null) {
      this.NewModulesRights = RightsMode.fromObject(other.NewModulesRights)
    } else {
      this.NewModulesRights = null
    }
    if (other.NewPlatformsRights != null) {
      this.NewPlatformsRights = RightsMode.fromObject(other.NewPlatformsRights)
    } else {
      this.NewPlatformsRights = null
    }
    if (other.NewManagersRights != null) {
      this.NewManagersRights = RightsMode.fromObject(other.NewManagersRights)
    } else {
      this.NewManagersRights = null
    }
    if (other.NewCurrenciesRights != null) {
      this.NewCurrenciesRights = RightsMode.fromObject(other.NewCurrenciesRights)
    } else {
      this.NewCurrenciesRights = null
    }
    if (other.NewCalendarsRights != null) {
      this.NewCalendarsRights = RightsMode.fromObject(other.NewCalendarsRights)
    } else {
      this.NewCalendarsRights = null
    }
    if (other.NewProfilesRights != null) {
      this.NewProfilesRights = RightsMode.fromObject(other.NewProfilesRights)
    } else {
      this.NewProfilesRights = null
    }
    if (other.NewDataFeedsRights != null) {
      this.NewDataFeedsRights = RightsMode.fromObject(other.NewDataFeedsRights)
    } else {
      this.NewDataFeedsRights = null
    }
    if (other.NewGatewaysRights != null) {
      this.NewGatewaysRights = RightsMode.fromObject(other.NewGatewaysRights)
    } else {
      this.NewGatewaysRights = null
    }
    if (other.NewTradingRoutesRights != null) {
      this.NewTradingRoutesRights = RightsMode.fromObject(other.NewTradingRoutesRights)
    } else {
      this.NewTradingRoutesRights = null
    }
    if (other.NewQuotesHistoryRights != null) {
      this.NewQuotesHistoryRights = RightsMode.fromObject(other.NewQuotesHistoryRights)
    } else {
      this.NewQuotesHistoryRights = null
    }
    if (other.NewReportsRights != null) {
      this.NewReportsRights = RightsMode.fromObject(other.NewReportsRights)
    } else {
      this.NewReportsRights = null
    }
    if (other.NewMonitoringRights != null) {
      this.NewMonitoringRights = RightsMode.fromObject(other.NewMonitoringRights)
    } else {
      this.NewMonitoringRights = null
    }
    if (other.NewRights != null) {
      this.NewRights = new Set()
      for (let item of other.NewRights) {
        if (item != null) {
          let tempItem
          tempItem = ManagerRight.fromObject(item)
          this.NewRights.add(tempItem)
        } else {
          this.NewRights.add(null)
        }
      }
    } else {
      this.NewRights = null
    }
    if (other.NewPassword != null) {
      this.NewPassword = other.NewPassword
    } else {
      this.NewPassword = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!ManagerModifyRequest}
   * @returns {!ManagerModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ManagerModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ManagerModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!ManagerModifyRequest}
   * @param {!ManagerModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ManagerModifyRequest)) {
      throw new TypeError('Instance of ManagerModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!ManagerModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      NewLogin: ((this.NewLogin != null) ? this.NewLogin.toNumber() : null),
      NewSortOrder: ((this.NewSortOrder != null) ? this.NewSortOrder : null),
      NewManagerGroupId: ((this.NewManagerGroupId != null) ? this.NewManagerGroupId.toString() : null),
      NewManagerGroupName: ((this.NewManagerGroupName != null) ? this.NewManagerGroupName : null),
      NewName: ((this.NewName != null) ? this.NewName : null),
      NewComment: ((this.NewComment != null) ? this.NewComment : null),
      NewDescription: ((this.NewDescription != null) ? this.NewDescription : null),
      NewEmail: ((this.NewEmail != null) ? this.NewEmail : null),
      NewIsEnabled: ((this.NewIsEnabled != null) ? this.NewIsEnabled : null),
      NewIsEnabledReset: ((this.NewIsEnabledReset != null) ? this.NewIsEnabledReset : null),
      NewIsOTPEnabled: ((this.NewIsOTPEnabled != null) ? this.NewIsOTPEnabled : null),
      NewIsOTPEnabledReset: ((this.NewIsOTPEnabledReset != null) ? this.NewIsOTPEnabledReset : null),
      AllManagerRightsReset: ((this.AllManagerRightsReset != null) ? this.AllManagerRightsReset : null),
      NewIsManagerRightsSet: ((this.NewIsManagerRightsSet != null) ? this.NewIsManagerRightsSet : null),
      NewAccessibleAccountGroups: ((this.NewAccessibleAccountGroups != null) ? Array.from(this.NewAccessibleAccountGroups, item => ((item != null) ? item : null)) : null),
      AccessibleAccountGroupsReset: ((this.AccessibleAccountGroupsReset != null) ? this.AccessibleAccountGroupsReset : null),
      NewAccessibleSymbolGroups: ((this.NewAccessibleSymbolGroups != null) ? Array.from(this.NewAccessibleSymbolGroups, item => ((item != null) ? item : null)) : null),
      AccessibleSymbolGroupsReset: ((this.AccessibleSymbolGroupsReset != null) ? this.AccessibleSymbolGroupsReset : null),
      NewAccountsRights: ((this.NewAccountsRights != null) ? this.NewAccountsRights : null),
      NewSymbolsRights: ((this.NewSymbolsRights != null) ? this.NewSymbolsRights : null),
      NewSystemRights: ((this.NewSystemRights != null) ? this.NewSystemRights : null),
      NewModulesRights: ((this.NewModulesRights != null) ? this.NewModulesRights : null),
      NewPlatformsRights: ((this.NewPlatformsRights != null) ? this.NewPlatformsRights : null),
      NewManagersRights: ((this.NewManagersRights != null) ? this.NewManagersRights : null),
      NewCurrenciesRights: ((this.NewCurrenciesRights != null) ? this.NewCurrenciesRights : null),
      NewCalendarsRights: ((this.NewCalendarsRights != null) ? this.NewCalendarsRights : null),
      NewProfilesRights: ((this.NewProfilesRights != null) ? this.NewProfilesRights : null),
      NewDataFeedsRights: ((this.NewDataFeedsRights != null) ? this.NewDataFeedsRights : null),
      NewGatewaysRights: ((this.NewGatewaysRights != null) ? this.NewGatewaysRights : null),
      NewTradingRoutesRights: ((this.NewTradingRoutesRights != null) ? this.NewTradingRoutesRights : null),
      NewQuotesHistoryRights: ((this.NewQuotesHistoryRights != null) ? this.NewQuotesHistoryRights : null),
      NewReportsRights: ((this.NewReportsRights != null) ? this.NewReportsRights : null),
      NewMonitoringRights: ((this.NewMonitoringRights != null) ? this.NewMonitoringRights : null),
      NewRights: ((this.NewRights != null) ? Array.from(this.NewRights, item => ((item != null) ? item : null)) : null),
      NewPassword: ((this.NewPassword != null) ? this.NewPassword : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return ManagerModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!ManagerModifyRequest} other Object value
   * @returns {!ManagerModifyRequest} Created struct
   */
  static fromObject (other) {
    return new ManagerModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!ManagerModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return ManagerModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!ManagerModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6161
  }
}

export { ManagerModifyRequest };

/**
 * Fast Binary Encoding ManagerModifyRequest field model
 */
class FieldModelManagerModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.Login = new fbe.FieldModelUInt64(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.NewLogin = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.Login.fbeOffset + this.Login.fbeSize), buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.NewSortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.NewLogin.fbeOffset + this.NewLogin.fbeSize), buffer, this.NewLogin.fbeOffset + this.NewLogin.fbeSize)
    this.NewManagerGroupId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize), buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize)
    this.NewManagerGroupName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewManagerGroupId.fbeOffset + this.NewManagerGroupId.fbeSize), buffer, this.NewManagerGroupId.fbeOffset + this.NewManagerGroupId.fbeSize)
    this.NewName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewManagerGroupName.fbeOffset + this.NewManagerGroupName.fbeSize), buffer, this.NewManagerGroupName.fbeOffset + this.NewManagerGroupName.fbeSize)
    this.NewComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewName.fbeOffset + this.NewName.fbeSize), buffer, this.NewName.fbeOffset + this.NewName.fbeSize)
    this.NewDescription = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize), buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize)
    this.NewEmail = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize), buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize)
    this.NewIsEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewEmail.fbeOffset + this.NewEmail.fbeSize), buffer, this.NewEmail.fbeOffset + this.NewEmail.fbeSize)
    this.NewIsEnabledReset = new fbe.FieldModelBool(buffer, this.NewIsEnabled.fbeOffset + this.NewIsEnabled.fbeSize)
    this.NewIsOTPEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewIsEnabledReset.fbeOffset + this.NewIsEnabledReset.fbeSize), buffer, this.NewIsEnabledReset.fbeOffset + this.NewIsEnabledReset.fbeSize)
    this.NewIsOTPEnabledReset = new fbe.FieldModelBool(buffer, this.NewIsOTPEnabled.fbeOffset + this.NewIsOTPEnabled.fbeSize)
    this.AllManagerRightsReset = new fbe.FieldModelBool(buffer, this.NewIsOTPEnabledReset.fbeOffset + this.NewIsOTPEnabledReset.fbeSize)
    this.NewIsManagerRightsSet = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.AllManagerRightsReset.fbeOffset + this.AllManagerRightsReset.fbeSize), buffer, this.AllManagerRightsReset.fbeOffset + this.AllManagerRightsReset.fbeSize)
    this.NewAccessibleAccountGroups = new fbe.FieldModelVector(new fbe.FieldModelString(buffer, this.NewIsManagerRightsSet.fbeOffset + this.NewIsManagerRightsSet.fbeSize), buffer, this.NewIsManagerRightsSet.fbeOffset + this.NewIsManagerRightsSet.fbeSize)
    this.AccessibleAccountGroupsReset = new fbe.FieldModelBool(buffer, this.NewAccessibleAccountGroups.fbeOffset + this.NewAccessibleAccountGroups.fbeSize)
    this.NewAccessibleSymbolGroups = new fbe.FieldModelVector(new fbe.FieldModelString(buffer, this.AccessibleAccountGroupsReset.fbeOffset + this.AccessibleAccountGroupsReset.fbeSize), buffer, this.AccessibleAccountGroupsReset.fbeOffset + this.AccessibleAccountGroupsReset.fbeSize)
    this.AccessibleSymbolGroupsReset = new fbe.FieldModelBool(buffer, this.NewAccessibleSymbolGroups.fbeOffset + this.NewAccessibleSymbolGroups.fbeSize)
    this.NewAccountsRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.AccessibleSymbolGroupsReset.fbeOffset + this.AccessibleSymbolGroupsReset.fbeSize), buffer, this.AccessibleSymbolGroupsReset.fbeOffset + this.AccessibleSymbolGroupsReset.fbeSize)
    this.NewSymbolsRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.NewAccountsRights.fbeOffset + this.NewAccountsRights.fbeSize), buffer, this.NewAccountsRights.fbeOffset + this.NewAccountsRights.fbeSize)
    this.NewSystemRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.NewSymbolsRights.fbeOffset + this.NewSymbolsRights.fbeSize), buffer, this.NewSymbolsRights.fbeOffset + this.NewSymbolsRights.fbeSize)
    this.NewModulesRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.NewSystemRights.fbeOffset + this.NewSystemRights.fbeSize), buffer, this.NewSystemRights.fbeOffset + this.NewSystemRights.fbeSize)
    this.NewPlatformsRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.NewModulesRights.fbeOffset + this.NewModulesRights.fbeSize), buffer, this.NewModulesRights.fbeOffset + this.NewModulesRights.fbeSize)
    this.NewManagersRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.NewPlatformsRights.fbeOffset + this.NewPlatformsRights.fbeSize), buffer, this.NewPlatformsRights.fbeOffset + this.NewPlatformsRights.fbeSize)
    this.NewCurrenciesRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.NewManagersRights.fbeOffset + this.NewManagersRights.fbeSize), buffer, this.NewManagersRights.fbeOffset + this.NewManagersRights.fbeSize)
    this.NewCalendarsRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.NewCurrenciesRights.fbeOffset + this.NewCurrenciesRights.fbeSize), buffer, this.NewCurrenciesRights.fbeOffset + this.NewCurrenciesRights.fbeSize)
    this.NewProfilesRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.NewCalendarsRights.fbeOffset + this.NewCalendarsRights.fbeSize), buffer, this.NewCalendarsRights.fbeOffset + this.NewCalendarsRights.fbeSize)
    this.NewDataFeedsRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.NewProfilesRights.fbeOffset + this.NewProfilesRights.fbeSize), buffer, this.NewProfilesRights.fbeOffset + this.NewProfilesRights.fbeSize)
    this.NewGatewaysRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.NewDataFeedsRights.fbeOffset + this.NewDataFeedsRights.fbeSize), buffer, this.NewDataFeedsRights.fbeOffset + this.NewDataFeedsRights.fbeSize)
    this.NewTradingRoutesRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.NewGatewaysRights.fbeOffset + this.NewGatewaysRights.fbeSize), buffer, this.NewGatewaysRights.fbeOffset + this.NewGatewaysRights.fbeSize)
    this.NewQuotesHistoryRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.NewTradingRoutesRights.fbeOffset + this.NewTradingRoutesRights.fbeSize), buffer, this.NewTradingRoutesRights.fbeOffset + this.NewTradingRoutesRights.fbeSize)
    this.NewReportsRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.NewQuotesHistoryRights.fbeOffset + this.NewQuotesHistoryRights.fbeSize), buffer, this.NewQuotesHistoryRights.fbeOffset + this.NewQuotesHistoryRights.fbeSize)
    this.NewMonitoringRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.NewReportsRights.fbeOffset + this.NewReportsRights.fbeSize), buffer, this.NewReportsRights.fbeOffset + this.NewReportsRights.fbeSize)
    this.NewRights = new fbe.FieldModelSet(new FieldModelManagerRight(buffer, this.NewMonitoringRights.fbeOffset + this.NewMonitoringRights.fbeSize), buffer, this.NewMonitoringRights.fbeOffset + this.NewMonitoringRights.fbeSize)
    this.NewPassword = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewRights.fbeOffset + this.NewRights.fbeSize), buffer, this.NewRights.fbeOffset + this.NewRights.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelManagerModifyRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelManagerModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelManagerModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Login.fbeSize + this.NewLogin.fbeSize + this.NewSortOrder.fbeSize + this.NewManagerGroupId.fbeSize + this.NewManagerGroupName.fbeSize + this.NewName.fbeSize + this.NewComment.fbeSize + this.NewDescription.fbeSize + this.NewEmail.fbeSize + this.NewIsEnabled.fbeSize + this.NewIsEnabledReset.fbeSize + this.NewIsOTPEnabled.fbeSize + this.NewIsOTPEnabledReset.fbeSize + this.AllManagerRightsReset.fbeSize + this.NewIsManagerRightsSet.fbeSize + this.NewAccessibleAccountGroups.fbeSize + this.AccessibleAccountGroupsReset.fbeSize + this.NewAccessibleSymbolGroups.fbeSize + this.AccessibleSymbolGroupsReset.fbeSize + this.NewAccountsRights.fbeSize + this.NewSymbolsRights.fbeSize + this.NewSystemRights.fbeSize + this.NewModulesRights.fbeSize + this.NewPlatformsRights.fbeSize + this.NewManagersRights.fbeSize + this.NewCurrenciesRights.fbeSize + this.NewCalendarsRights.fbeSize + this.NewProfilesRights.fbeSize + this.NewDataFeedsRights.fbeSize + this.NewGatewaysRights.fbeSize + this.NewTradingRoutesRights.fbeSize + this.NewQuotesHistoryRights.fbeSize + this.NewReportsRights.fbeSize + this.NewMonitoringRights.fbeSize + this.NewRights.fbeSize + this.NewPassword.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelManagerModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Login.fbeExtra + this.NewLogin.fbeExtra + this.NewSortOrder.fbeExtra + this.NewManagerGroupId.fbeExtra + this.NewManagerGroupName.fbeExtra + this.NewName.fbeExtra + this.NewComment.fbeExtra + this.NewDescription.fbeExtra + this.NewEmail.fbeExtra + this.NewIsEnabled.fbeExtra + this.NewIsEnabledReset.fbeExtra + this.NewIsOTPEnabled.fbeExtra + this.NewIsOTPEnabledReset.fbeExtra + this.AllManagerRightsReset.fbeExtra + this.NewIsManagerRightsSet.fbeExtra + this.NewAccessibleAccountGroups.fbeExtra + this.AccessibleAccountGroupsReset.fbeExtra + this.NewAccessibleSymbolGroups.fbeExtra + this.AccessibleSymbolGroupsReset.fbeExtra + this.NewAccountsRights.fbeExtra + this.NewSymbolsRights.fbeExtra + this.NewSystemRights.fbeExtra + this.NewModulesRights.fbeExtra + this.NewPlatformsRights.fbeExtra + this.NewManagersRights.fbeExtra + this.NewCurrenciesRights.fbeExtra + this.NewCalendarsRights.fbeExtra + this.NewProfilesRights.fbeExtra + this.NewDataFeedsRights.fbeExtra + this.NewGatewaysRights.fbeExtra + this.NewTradingRoutesRights.fbeExtra + this.NewQuotesHistoryRights.fbeExtra + this.NewReportsRights.fbeExtra + this.NewMonitoringRights.fbeExtra + this.NewRights.fbeExtra + this.NewPassword.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelManagerModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelManagerModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelManagerModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6161
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelManagerModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelManagerModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.NewLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLogin.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewManagerGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewManagerGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewManagerGroupId.fbeSize

    if ((fbeCurrentSize + this.NewManagerGroupName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewManagerGroupName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewManagerGroupName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDescription.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewEmail.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewEmail.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewEmail.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabledReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsEnabledReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabledReset.fbeSize

    if ((fbeCurrentSize + this.NewIsOTPEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsOTPEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsOTPEnabledReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsOTPEnabledReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsOTPEnabledReset.fbeSize

    if ((fbeCurrentSize + this.AllManagerRightsReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AllManagerRightsReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AllManagerRightsReset.fbeSize

    if ((fbeCurrentSize + this.NewIsManagerRightsSet.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsManagerRightsSet.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsManagerRightsSet.fbeSize

    if ((fbeCurrentSize + this.NewAccessibleAccountGroups.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAccessibleAccountGroups.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccessibleAccountGroups.fbeSize

    if ((fbeCurrentSize + this.AccessibleAccountGroupsReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccessibleAccountGroupsReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccessibleAccountGroupsReset.fbeSize

    if ((fbeCurrentSize + this.NewAccessibleSymbolGroups.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAccessibleSymbolGroups.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccessibleSymbolGroups.fbeSize

    if ((fbeCurrentSize + this.AccessibleSymbolGroupsReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccessibleSymbolGroupsReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccessibleSymbolGroupsReset.fbeSize

    if ((fbeCurrentSize + this.NewAccountsRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAccountsRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccountsRights.fbeSize

    if ((fbeCurrentSize + this.NewSymbolsRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSymbolsRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolsRights.fbeSize

    if ((fbeCurrentSize + this.NewSystemRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSystemRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSystemRights.fbeSize

    if ((fbeCurrentSize + this.NewModulesRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewModulesRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewModulesRights.fbeSize

    if ((fbeCurrentSize + this.NewPlatformsRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPlatformsRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPlatformsRights.fbeSize

    if ((fbeCurrentSize + this.NewManagersRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewManagersRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewManagersRights.fbeSize

    if ((fbeCurrentSize + this.NewCurrenciesRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewCurrenciesRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCurrenciesRights.fbeSize

    if ((fbeCurrentSize + this.NewCalendarsRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewCalendarsRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCalendarsRights.fbeSize

    if ((fbeCurrentSize + this.NewProfilesRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewProfilesRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewProfilesRights.fbeSize

    if ((fbeCurrentSize + this.NewDataFeedsRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDataFeedsRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDataFeedsRights.fbeSize

    if ((fbeCurrentSize + this.NewGatewaysRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewGatewaysRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewGatewaysRights.fbeSize

    if ((fbeCurrentSize + this.NewTradingRoutesRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewTradingRoutesRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTradingRoutesRights.fbeSize

    if ((fbeCurrentSize + this.NewQuotesHistoryRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewQuotesHistoryRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewQuotesHistoryRights.fbeSize

    if ((fbeCurrentSize + this.NewReportsRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewReportsRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewReportsRights.fbeSize

    if ((fbeCurrentSize + this.NewMonitoringRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMonitoringRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMonitoringRights.fbeSize

    if ((fbeCurrentSize + this.NewRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewRights.fbeSize

    if ((fbeCurrentSize + this.NewPassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPassword.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelManagerModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelManagerModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelManagerModifyRequest}
   * @param {!ManagerModifyRequest} fbeValue Default value, defaults is new ManagerModifyRequest()
   * @returns {!ManagerModifyRequest} ManagerModifyRequest value
   */
  get (fbeValue = new ManagerModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelManagerModifyRequest}
   * @param {!ManagerModifyRequest} fbeValue ManagerModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.NewLogin.fbeSize) <= fbeStructSize) {
      fbeValue.NewLogin = this.NewLogin.get()
    } else {
      fbeValue.NewLogin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLogin.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.NewSortOrder = this.NewSortOrder.get()
    } else {
      fbeValue.NewSortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewManagerGroupId.fbeSize) <= fbeStructSize) {
      fbeValue.NewManagerGroupId = this.NewManagerGroupId.get()
    } else {
      fbeValue.NewManagerGroupId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewManagerGroupId.fbeSize

    if ((fbeCurrentSize + this.NewManagerGroupName.fbeSize) <= fbeStructSize) {
      fbeValue.NewManagerGroupName = this.NewManagerGroupName.get()
    } else {
      fbeValue.NewManagerGroupName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewManagerGroupName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) <= fbeStructSize) {
      fbeValue.NewName = this.NewName.get()
    } else {
      fbeValue.NewName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewComment = this.NewComment.get()
    } else {
      fbeValue.NewComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) <= fbeStructSize) {
      fbeValue.NewDescription = this.NewDescription.get()
    } else {
      fbeValue.NewDescription = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewEmail.fbeSize) <= fbeStructSize) {
      fbeValue.NewEmail = this.NewEmail.get()
    } else {
      fbeValue.NewEmail = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewEmail.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsEnabled = this.NewIsEnabled.get()
    } else {
      fbeValue.NewIsEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabledReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsEnabledReset = this.NewIsEnabledReset.get()
    } else {
      fbeValue.NewIsEnabledReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabledReset.fbeSize

    if ((fbeCurrentSize + this.NewIsOTPEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsOTPEnabled = this.NewIsOTPEnabled.get()
    } else {
      fbeValue.NewIsOTPEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.NewIsOTPEnabledReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsOTPEnabledReset = this.NewIsOTPEnabledReset.get()
    } else {
      fbeValue.NewIsOTPEnabledReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsOTPEnabledReset.fbeSize

    if ((fbeCurrentSize + this.AllManagerRightsReset.fbeSize) <= fbeStructSize) {
      fbeValue.AllManagerRightsReset = this.AllManagerRightsReset.get()
    } else {
      fbeValue.AllManagerRightsReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AllManagerRightsReset.fbeSize

    if ((fbeCurrentSize + this.NewIsManagerRightsSet.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsManagerRightsSet = this.NewIsManagerRightsSet.get()
    } else {
      fbeValue.NewIsManagerRightsSet = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsManagerRightsSet.fbeSize

    if ((fbeCurrentSize + this.NewAccessibleAccountGroups.fbeSize) <= fbeStructSize) {
      this.NewAccessibleAccountGroups.get(fbeValue.NewAccessibleAccountGroups)
    } else {
      fbeValue.NewAccessibleAccountGroups.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccessibleAccountGroups.fbeSize

    if ((fbeCurrentSize + this.AccessibleAccountGroupsReset.fbeSize) <= fbeStructSize) {
      fbeValue.AccessibleAccountGroupsReset = this.AccessibleAccountGroupsReset.get()
    } else {
      fbeValue.AccessibleAccountGroupsReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccessibleAccountGroupsReset.fbeSize

    if ((fbeCurrentSize + this.NewAccessibleSymbolGroups.fbeSize) <= fbeStructSize) {
      this.NewAccessibleSymbolGroups.get(fbeValue.NewAccessibleSymbolGroups)
    } else {
      fbeValue.NewAccessibleSymbolGroups.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccessibleSymbolGroups.fbeSize

    if ((fbeCurrentSize + this.AccessibleSymbolGroupsReset.fbeSize) <= fbeStructSize) {
      fbeValue.AccessibleSymbolGroupsReset = this.AccessibleSymbolGroupsReset.get()
    } else {
      fbeValue.AccessibleSymbolGroupsReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccessibleSymbolGroupsReset.fbeSize

    if ((fbeCurrentSize + this.NewAccountsRights.fbeSize) <= fbeStructSize) {
      fbeValue.NewAccountsRights = this.NewAccountsRights.get()
    } else {
      fbeValue.NewAccountsRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccountsRights.fbeSize

    if ((fbeCurrentSize + this.NewSymbolsRights.fbeSize) <= fbeStructSize) {
      fbeValue.NewSymbolsRights = this.NewSymbolsRights.get()
    } else {
      fbeValue.NewSymbolsRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolsRights.fbeSize

    if ((fbeCurrentSize + this.NewSystemRights.fbeSize) <= fbeStructSize) {
      fbeValue.NewSystemRights = this.NewSystemRights.get()
    } else {
      fbeValue.NewSystemRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSystemRights.fbeSize

    if ((fbeCurrentSize + this.NewModulesRights.fbeSize) <= fbeStructSize) {
      fbeValue.NewModulesRights = this.NewModulesRights.get()
    } else {
      fbeValue.NewModulesRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewModulesRights.fbeSize

    if ((fbeCurrentSize + this.NewPlatformsRights.fbeSize) <= fbeStructSize) {
      fbeValue.NewPlatformsRights = this.NewPlatformsRights.get()
    } else {
      fbeValue.NewPlatformsRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPlatformsRights.fbeSize

    if ((fbeCurrentSize + this.NewManagersRights.fbeSize) <= fbeStructSize) {
      fbeValue.NewManagersRights = this.NewManagersRights.get()
    } else {
      fbeValue.NewManagersRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewManagersRights.fbeSize

    if ((fbeCurrentSize + this.NewCurrenciesRights.fbeSize) <= fbeStructSize) {
      fbeValue.NewCurrenciesRights = this.NewCurrenciesRights.get()
    } else {
      fbeValue.NewCurrenciesRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCurrenciesRights.fbeSize

    if ((fbeCurrentSize + this.NewCalendarsRights.fbeSize) <= fbeStructSize) {
      fbeValue.NewCalendarsRights = this.NewCalendarsRights.get()
    } else {
      fbeValue.NewCalendarsRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCalendarsRights.fbeSize

    if ((fbeCurrentSize + this.NewProfilesRights.fbeSize) <= fbeStructSize) {
      fbeValue.NewProfilesRights = this.NewProfilesRights.get()
    } else {
      fbeValue.NewProfilesRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewProfilesRights.fbeSize

    if ((fbeCurrentSize + this.NewDataFeedsRights.fbeSize) <= fbeStructSize) {
      fbeValue.NewDataFeedsRights = this.NewDataFeedsRights.get()
    } else {
      fbeValue.NewDataFeedsRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDataFeedsRights.fbeSize

    if ((fbeCurrentSize + this.NewGatewaysRights.fbeSize) <= fbeStructSize) {
      fbeValue.NewGatewaysRights = this.NewGatewaysRights.get()
    } else {
      fbeValue.NewGatewaysRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewGatewaysRights.fbeSize

    if ((fbeCurrentSize + this.NewTradingRoutesRights.fbeSize) <= fbeStructSize) {
      fbeValue.NewTradingRoutesRights = this.NewTradingRoutesRights.get()
    } else {
      fbeValue.NewTradingRoutesRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTradingRoutesRights.fbeSize

    if ((fbeCurrentSize + this.NewQuotesHistoryRights.fbeSize) <= fbeStructSize) {
      fbeValue.NewQuotesHistoryRights = this.NewQuotesHistoryRights.get()
    } else {
      fbeValue.NewQuotesHistoryRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewQuotesHistoryRights.fbeSize

    if ((fbeCurrentSize + this.NewReportsRights.fbeSize) <= fbeStructSize) {
      fbeValue.NewReportsRights = this.NewReportsRights.get()
    } else {
      fbeValue.NewReportsRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewReportsRights.fbeSize

    if ((fbeCurrentSize + this.NewMonitoringRights.fbeSize) <= fbeStructSize) {
      fbeValue.NewMonitoringRights = this.NewMonitoringRights.get()
    } else {
      fbeValue.NewMonitoringRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMonitoringRights.fbeSize

    if ((fbeCurrentSize + this.NewRights.fbeSize) <= fbeStructSize) {
      this.NewRights.get(fbeValue.NewRights)
    } else {
      fbeValue.NewRights.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewRights.fbeSize

    if ((fbeCurrentSize + this.NewPassword.fbeSize) <= fbeStructSize) {
      fbeValue.NewPassword = this.NewPassword.get()
    } else {
      fbeValue.NewPassword = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPassword.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelManagerModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelManagerModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelManagerModifyRequest}
   * @param {!ManagerModifyRequest} fbeValue ManagerModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelManagerModifyRequest}
   * @param {ManagerModifyRequest} fbeValue ManagerModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Login.set(fbeValue.Login)
    this.NewLogin.set(fbeValue.NewLogin)
    this.NewSortOrder.set(fbeValue.NewSortOrder)
    this.NewManagerGroupId.set(fbeValue.NewManagerGroupId)
    this.NewManagerGroupName.set(fbeValue.NewManagerGroupName)
    this.NewName.set(fbeValue.NewName)
    this.NewComment.set(fbeValue.NewComment)
    this.NewDescription.set(fbeValue.NewDescription)
    this.NewEmail.set(fbeValue.NewEmail)
    this.NewIsEnabled.set(fbeValue.NewIsEnabled)
    this.NewIsEnabledReset.set(fbeValue.NewIsEnabledReset)
    this.NewIsOTPEnabled.set(fbeValue.NewIsOTPEnabled)
    this.NewIsOTPEnabledReset.set(fbeValue.NewIsOTPEnabledReset)
    this.AllManagerRightsReset.set(fbeValue.AllManagerRightsReset)
    this.NewIsManagerRightsSet.set(fbeValue.NewIsManagerRightsSet)
    this.NewAccessibleAccountGroups.set(fbeValue.NewAccessibleAccountGroups)
    this.AccessibleAccountGroupsReset.set(fbeValue.AccessibleAccountGroupsReset)
    this.NewAccessibleSymbolGroups.set(fbeValue.NewAccessibleSymbolGroups)
    this.AccessibleSymbolGroupsReset.set(fbeValue.AccessibleSymbolGroupsReset)
    this.NewAccountsRights.set(fbeValue.NewAccountsRights)
    this.NewSymbolsRights.set(fbeValue.NewSymbolsRights)
    this.NewSystemRights.set(fbeValue.NewSystemRights)
    this.NewModulesRights.set(fbeValue.NewModulesRights)
    this.NewPlatformsRights.set(fbeValue.NewPlatformsRights)
    this.NewManagersRights.set(fbeValue.NewManagersRights)
    this.NewCurrenciesRights.set(fbeValue.NewCurrenciesRights)
    this.NewCalendarsRights.set(fbeValue.NewCalendarsRights)
    this.NewProfilesRights.set(fbeValue.NewProfilesRights)
    this.NewDataFeedsRights.set(fbeValue.NewDataFeedsRights)
    this.NewGatewaysRights.set(fbeValue.NewGatewaysRights)
    this.NewTradingRoutesRights.set(fbeValue.NewTradingRoutesRights)
    this.NewQuotesHistoryRights.set(fbeValue.NewQuotesHistoryRights)
    this.NewReportsRights.set(fbeValue.NewReportsRights)
    this.NewMonitoringRights.set(fbeValue.NewMonitoringRights)
    this.NewRights.set(fbeValue.NewRights)
    this.NewPassword.set(fbeValue.NewPassword)
  }
}

export { FieldModelManagerModifyRequest };

/**
 * Fast Binary Encoding ManagerModifyRequest model
 */
class ManagerModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelManagerModifyRequest(this.buffer, 4)
  }

  /**
   * Get the ManagerModifyRequest model
   * @this {!ManagerModifyRequestModel}
   * @returns {!FieldModelManagerModifyRequest} model ManagerModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ManagerModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ManagerModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ManagerModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ManagerModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelManagerModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ManagerModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ManagerModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ManagerModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ManagerModifyRequestModel}
   * @param {!ManagerModifyRequest} value ManagerModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ManagerModifyRequestModel}
   * @param {!ManagerModifyRequest} value ManagerModifyRequest value, defaults is new ManagerModifyRequest()
   * @return {!object} Deserialized ManagerModifyRequest value and its size
   */
  deserialize (value = new ManagerModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new ManagerModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new ManagerModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ManagerModifyRequestModel}
   * @param {!number} prev Previous ManagerModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ManagerModifyRequestModel };
ManagerModifyRequest.__has_response = true;
ManagerModifyRequest.__response_class = ManagerModifyResponse;
