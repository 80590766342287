
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {TradeHistoryByIdResponse} from './TradeHistoryByIdResponse';

/**
 * TradeHistoryByIdRequest struct
 */
class TradeHistoryByIdRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {!number=} recordId
   * @constructor
   */
  constructor (parent = new Request(), recordId = new UInt64(0, 0)) {
    super()
    super.copy(parent)
    this.RecordId = recordId
  }

  /**
   * Copy struct (shallow copy)
   * @this {!TradeHistoryByIdRequest}
   * @param {!TradeHistoryByIdRequest} other Other struct
   * @returns {!TradeHistoryByIdRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.RecordId != null) {
      this.RecordId = UInt64.fromNumber(other.RecordId)
    } else {
      this.RecordId = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!TradeHistoryByIdRequest}
   * @returns {!TradeHistoryByIdRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new TradeHistoryByIdRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new TradeHistoryByIdRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!TradeHistoryByIdRequest}
   * @param {!TradeHistoryByIdRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TradeHistoryByIdRequest)) {
      throw new TypeError('Instance of TradeHistoryByIdRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!TradeHistoryByIdRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      RecordId: ((this.RecordId != null) ? this.RecordId.toNumber() : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return TradeHistoryByIdRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!TradeHistoryByIdRequest} other Object value
   * @returns {!TradeHistoryByIdRequest} Created struct
   */
  static fromObject (other) {
    return new TradeHistoryByIdRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!TradeHistoryByIdRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return TradeHistoryByIdRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!TradeHistoryByIdRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 4121
  }
}

export { TradeHistoryByIdRequest };

/**
 * Fast Binary Encoding TradeHistoryByIdRequest field model
 */
class FieldModelTradeHistoryByIdRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.RecordId = new fbe.FieldModelUInt64(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelTradeHistoryByIdRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelTradeHistoryByIdRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelTradeHistoryByIdRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.RecordId.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelTradeHistoryByIdRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.RecordId.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelTradeHistoryByIdRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelTradeHistoryByIdRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelTradeHistoryByIdRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 4121
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelTradeHistoryByIdRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelTradeHistoryByIdRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.RecordId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RecordId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RecordId.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelTradeHistoryByIdRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelTradeHistoryByIdRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelTradeHistoryByIdRequest}
   * @param {!TradeHistoryByIdRequest} fbeValue Default value, defaults is new TradeHistoryByIdRequest()
   * @returns {!TradeHistoryByIdRequest} TradeHistoryByIdRequest value
   */
  get (fbeValue = new TradeHistoryByIdRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelTradeHistoryByIdRequest}
   * @param {!TradeHistoryByIdRequest} fbeValue TradeHistoryByIdRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.RecordId.fbeSize) <= fbeStructSize) {
      fbeValue.RecordId = this.RecordId.get()
    } else {
      fbeValue.RecordId = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RecordId.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelTradeHistoryByIdRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelTradeHistoryByIdRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelTradeHistoryByIdRequest}
   * @param {!TradeHistoryByIdRequest} fbeValue TradeHistoryByIdRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelTradeHistoryByIdRequest}
   * @param {TradeHistoryByIdRequest} fbeValue TradeHistoryByIdRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.RecordId.set(fbeValue.RecordId)
  }
}

export { FieldModelTradeHistoryByIdRequest };

/**
 * Fast Binary Encoding TradeHistoryByIdRequest model
 */
class TradeHistoryByIdRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelTradeHistoryByIdRequest(this.buffer, 4)
  }

  /**
   * Get the TradeHistoryByIdRequest model
   * @this {!TradeHistoryByIdRequestModel}
   * @returns {!FieldModelTradeHistoryByIdRequest} model TradeHistoryByIdRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!TradeHistoryByIdRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!TradeHistoryByIdRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return TradeHistoryByIdRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!TradeHistoryByIdRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelTradeHistoryByIdRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!TradeHistoryByIdRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!TradeHistoryByIdRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!TradeHistoryByIdRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!TradeHistoryByIdRequestModel}
   * @param {!TradeHistoryByIdRequest} value TradeHistoryByIdRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!TradeHistoryByIdRequestModel}
   * @param {!TradeHistoryByIdRequest} value TradeHistoryByIdRequest value, defaults is new TradeHistoryByIdRequest()
   * @return {!object} Deserialized TradeHistoryByIdRequest value and its size
   */
  deserialize (value = new TradeHistoryByIdRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new TradeHistoryByIdRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new TradeHistoryByIdRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!TradeHistoryByIdRequestModel}
   * @param {!number} prev Previous TradeHistoryByIdRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { TradeHistoryByIdRequestModel };
TradeHistoryByIdRequest.__has_response = true;
TradeHistoryByIdRequest.__response_class = TradeHistoryByIdResponse;
