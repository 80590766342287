import React, { FC } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import styles from './ValidationMessage.module.scss';

type MessageType = string | JSX.Element | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | Merge<FieldError, (FieldError | undefined)[]> | undefined;

type Props = {
    message?: MessageType;
};

const arrMessagesToString = (message: MessageType): MessageType=> {
    try {
        if (!message) return '';
        if (Array.isArray(message)) return message.map(item => item?.message).join(', ');
        if (typeof message !== 'string' && 'message' in message) {
            return message.message?.toString();
        }
        return message;
    } catch (error) {
        return '';
    }

}

export const ValidationMessage: FC<Props> = ({ message }) => <p className={styles.validationMessage}>{arrMessagesToString(message)}</p>;
