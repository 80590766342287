
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * PositionUpdate struct
 */
class PositionUpdate {
  /**
   * Initialize struct
   * @param {!number=} key1
   * @param {!UUID=} key2
   * @param {number=} login
   * @param {number=} actualVersion
   * @param {UUID=} symbolId
   * @param {number=} amount
   * @param {Date=} created
   * @param {Date=} modified
   * @param {Date=} archived
   * @param {Date=} deleted
   * @constructor
   */
  constructor (key1 = new UInt64(0, 0), key2 = new UUID(), login = null, actualVersion = null, symbolId = null, amount = null, created = null, modified = null, archived = null, deleted = null) {
    this.Key1 = key1
    this.Key2 = key2
    this.Login = login
    this.ActualVersion = actualVersion
    this.SymbolId = symbolId
    this.Amount = amount
    this.Created = created
    this.Modified = modified
    this.Archived = archived
    this.Deleted = deleted
  }

  /**
   * Copy struct (shallow copy)
   * @this {!PositionUpdate}
   * @param {!PositionUpdate} other Other struct
   * @returns {!PositionUpdate} This struct
   */
  copy (other) {
    if (other.Key1 != null) {
      this.Key1 = UInt64.fromNumber(other.Key1)
    } else {
      this.Key1 = null
    }
    if (other.Key2 != null) {
      this.Key2 = new UUID(other.Key2)
    } else {
      this.Key2 = null
    }
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.ActualVersion != null) {
      this.ActualVersion = UInt64.fromNumber(other.ActualVersion)
    } else {
      this.ActualVersion = null
    }
    if (other.SymbolId != null) {
      this.SymbolId = new UUID(other.SymbolId)
    } else {
      this.SymbolId = null
    }
    if (other.Amount != null) {
      this.Amount = other.Amount
    } else {
      this.Amount = null
    }
    if (other.Created != null) {
      if (other.Created instanceof Date) {
        this.Created = new Date(other.Created.getTime())
      } else {
        this.Created = new Date(Math.round(other.Created / 1000000))
      }
    } else {
      this.Created = null
    }
    if (other.Modified != null) {
      if (other.Modified instanceof Date) {
        this.Modified = new Date(other.Modified.getTime())
      } else {
        this.Modified = new Date(Math.round(other.Modified / 1000000))
      }
    } else {
      this.Modified = null
    }
    if (other.Archived != null) {
      if (other.Archived instanceof Date) {
        this.Archived = new Date(other.Archived.getTime())
      } else {
        this.Archived = new Date(Math.round(other.Archived / 1000000))
      }
    } else {
      this.Archived = null
    }
    if (other.Deleted != null) {
      if (other.Deleted instanceof Date) {
        this.Deleted = new Date(other.Deleted.getTime())
      } else {
        this.Deleted = new Date(Math.round(other.Deleted / 1000000))
      }
    } else {
      this.Deleted = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!PositionUpdate}
   * @returns {!PositionUpdate} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new PositionUpdateModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new PositionUpdateModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!PositionUpdate}
   * @param {!PositionUpdate} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof PositionUpdate)) {
      throw new TypeError('Instance of PositionUpdate is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!PositionUpdate}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Key1: ((this.Key1 != null) ? this.Key1.toNumber() : null),
      Key2: ((this.Key2 != null) ? this.Key2.toString() : null),
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      ActualVersion: ((this.ActualVersion != null) ? this.ActualVersion.toNumber() : null),
      SymbolId: ((this.SymbolId != null) ? this.SymbolId.toString() : null),
      Amount: ((this.Amount != null) ? this.Amount : null),
      Created: ((this.Created != null) ? (this.Created.getTime() * 1000000) : null),
      Modified: ((this.Modified != null) ? (this.Modified.getTime() * 1000000) : null),
      Archived: ((this.Archived != null) ? (this.Archived.getTime() * 1000000) : null),
      Deleted: ((this.Deleted != null) ? (this.Deleted.getTime() * 1000000) : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return PositionUpdate.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!PositionUpdate} other Object value
   * @returns {!PositionUpdate} Created struct
   */
  static fromObject (other) {
    return new PositionUpdate().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!PositionUpdate}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return PositionUpdate.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!PositionUpdate}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 4171
  }
}

export { PositionUpdate };

/**
 * Fast Binary Encoding PositionUpdate field model
 */
class FieldModelPositionUpdate extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Key1 = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.Key2 = new fbe.FieldModelUUID(buffer, this.Key1.fbeOffset + this.Key1.fbeSize)
    this.Login = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.Key2.fbeOffset + this.Key2.fbeSize), buffer, this.Key2.fbeOffset + this.Key2.fbeSize)
    this.ActualVersion = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.Login.fbeOffset + this.Login.fbeSize), buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.SymbolId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.ActualVersion.fbeOffset + this.ActualVersion.fbeSize), buffer, this.ActualVersion.fbeOffset + this.ActualVersion.fbeSize)
    this.Amount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.SymbolId.fbeOffset + this.SymbolId.fbeSize), buffer, this.SymbolId.fbeOffset + this.SymbolId.fbeSize)
    this.Created = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.Amount.fbeOffset + this.Amount.fbeSize), buffer, this.Amount.fbeOffset + this.Amount.fbeSize)
    this.Modified = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.Created.fbeOffset + this.Created.fbeSize), buffer, this.Created.fbeOffset + this.Created.fbeSize)
    this.Archived = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.Modified.fbeOffset + this.Modified.fbeSize), buffer, this.Modified.fbeOffset + this.Modified.fbeSize)
    this.Deleted = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.Archived.fbeOffset + this.Archived.fbeSize), buffer, this.Archived.fbeOffset + this.Archived.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelPositionUpdate}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelPositionUpdate}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Key1.fbeSize + this.Key2.fbeSize + this.Login.fbeSize + this.ActualVersion.fbeSize + this.SymbolId.fbeSize + this.Amount.fbeSize + this.Created.fbeSize + this.Modified.fbeSize + this.Archived.fbeSize + this.Deleted.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelPositionUpdate}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Key1.fbeExtra + this.Key2.fbeExtra + this.Login.fbeExtra + this.ActualVersion.fbeExtra + this.SymbolId.fbeExtra + this.Amount.fbeExtra + this.Created.fbeExtra + this.Modified.fbeExtra + this.Archived.fbeExtra + this.Deleted.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelPositionUpdate}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelPositionUpdate.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelPositionUpdate}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 4171
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelPositionUpdate}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelPositionUpdate}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Key1.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Key1.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Key1.fbeSize

    if ((fbeCurrentSize + this.Key2.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Key2.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Key2.fbeSize

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ActualVersion.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.SymbolId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.Amount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Amount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Amount.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Created.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    if ((fbeCurrentSize + this.Modified.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Modified.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modified.fbeSize

    if ((fbeCurrentSize + this.Archived.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Archived.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Archived.fbeSize

    if ((fbeCurrentSize + this.Deleted.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deleted.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deleted.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelPositionUpdate}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelPositionUpdate}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelPositionUpdate}
   * @param {!PositionUpdate} fbeValue Default value, defaults is new PositionUpdate()
   * @returns {!PositionUpdate} PositionUpdate value
   */
  get (fbeValue = new PositionUpdate()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelPositionUpdate}
   * @param {!PositionUpdate} fbeValue PositionUpdate value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Key1.fbeSize) <= fbeStructSize) {
      fbeValue.Key1 = this.Key1.get()
    } else {
      fbeValue.Key1 = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Key1.fbeSize

    if ((fbeCurrentSize + this.Key2.fbeSize) <= fbeStructSize) {
      fbeValue.Key2 = this.Key2.get()
    } else {
      fbeValue.Key2 = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Key2.fbeSize

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) <= fbeStructSize) {
      fbeValue.ActualVersion = this.ActualVersion.get()
    } else {
      fbeValue.ActualVersion = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.SymbolId.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolId = this.SymbolId.get()
    } else {
      fbeValue.SymbolId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.Amount.fbeSize) <= fbeStructSize) {
      fbeValue.Amount = this.Amount.get()
    } else {
      fbeValue.Amount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Amount.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) <= fbeStructSize) {
      fbeValue.Created = this.Created.get()
    } else {
      fbeValue.Created = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    if ((fbeCurrentSize + this.Modified.fbeSize) <= fbeStructSize) {
      fbeValue.Modified = this.Modified.get()
    } else {
      fbeValue.Modified = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modified.fbeSize

    if ((fbeCurrentSize + this.Archived.fbeSize) <= fbeStructSize) {
      fbeValue.Archived = this.Archived.get()
    } else {
      fbeValue.Archived = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Archived.fbeSize

    if ((fbeCurrentSize + this.Deleted.fbeSize) <= fbeStructSize) {
      fbeValue.Deleted = this.Deleted.get()
    } else {
      fbeValue.Deleted = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deleted.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelPositionUpdate}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelPositionUpdate}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelPositionUpdate}
   * @param {!PositionUpdate} fbeValue PositionUpdate value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelPositionUpdate}
   * @param {PositionUpdate} fbeValue PositionUpdate value
   */
  setFields (fbeValue) {
    this.Key1.set(fbeValue.Key1)
    this.Key2.set(fbeValue.Key2)
    this.Login.set(fbeValue.Login)
    this.ActualVersion.set(fbeValue.ActualVersion)
    this.SymbolId.set(fbeValue.SymbolId)
    this.Amount.set(fbeValue.Amount)
    this.Created.set(fbeValue.Created)
    this.Modified.set(fbeValue.Modified)
    this.Archived.set(fbeValue.Archived)
    this.Deleted.set(fbeValue.Deleted)
  }
}

export { FieldModelPositionUpdate };

/**
 * Fast Binary Encoding PositionUpdate model
 */
class PositionUpdateModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelPositionUpdate(this.buffer, 4)
  }

  /**
   * Get the PositionUpdate model
   * @this {!PositionUpdateModel}
   * @returns {!FieldModelPositionUpdate} model PositionUpdate model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!PositionUpdateModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!PositionUpdateModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return PositionUpdateModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!PositionUpdateModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelPositionUpdate.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!PositionUpdateModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!PositionUpdateModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!PositionUpdateModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!PositionUpdateModel}
   * @param {!PositionUpdate} value PositionUpdate value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!PositionUpdateModel}
   * @param {!PositionUpdate} value PositionUpdate value, defaults is new PositionUpdate()
   * @return {!object} Deserialized PositionUpdate value and its size
   */
  deserialize (value = new PositionUpdate()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new PositionUpdate(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new PositionUpdate(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!PositionUpdateModel}
   * @param {!number} prev Previous PositionUpdate model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { PositionUpdateModel };
