
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as feed from '../feed'
import * as history from '../history'
import * as storage from '../storage'
import * as client from '../client'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {ModuleModifyResponse} from './ModuleModifyResponse';
import {ModuleType} from '../core/ModuleType';
import {FieldModelModuleType} from '../core/ModuleType';
import {LoggingSettings} from '../core/LoggingSettings';
import {FieldModelLoggingSettings} from '../core/LoggingSettings';
import {AccessServerSettings} from '../core/AccessServerSettings';
import {FieldModelAccessServerSettings} from '../core/AccessServerSettings';
import {ConfigServerSettings} from '../core/ConfigServerSettings';
import {FieldModelConfigServerSettings} from '../core/ConfigServerSettings';
import {FeedServerSettings} from '../core/FeedServerSettings';
import {FieldModelFeedServerSettings} from '../core/FeedServerSettings';
import {HistoryServerSettings} from '../core/HistoryServerSettings';
import {FieldModelHistoryServerSettings} from '../core/HistoryServerSettings';
import {StorageServerSettings} from '../core/StorageServerSettings';
import {FieldModelStorageServerSettings} from '../core/StorageServerSettings';
import {TradeServerSettings} from '../core/TradeServerSettings';
import {FieldModelTradeServerSettings} from '../core/TradeServerSettings';
import {ExecutionServerSettings} from '../core/ExecutionServerSettings';
import {FieldModelExecutionServerSettings} from '../core/ExecutionServerSettings';
import {BackupServerSettings} from '../core/BackupServerSettings';
import {FieldModelBackupServerSettings} from '../core/BackupServerSettings';
import {ExportServerSettings} from '../core/ExportServerSettings';
import {FieldModelExportServerSettings} from '../core/ExportServerSettings';
import {DrawingStorageServerSettings} from '../core/DrawingStorageServerSettings';
import {FieldModelDrawingStorageServerSettings} from '../core/DrawingStorageServerSettings';

/**
 * ModuleModifyRequest struct
 */
class ModuleModifyRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {UUID=} moduleId
   * @param {string=} moduleName
   * @param {number=} newSortOrder
   * @param {UUID=} newHostId
   * @param {string=} newHostName
   * @param {string=} newName
   * @param {string=} newComment
   * @param {string=} newDescription
   * @param {ModuleType=} newModuleType
   * @param {boolean=} newIsEnabled
   * @param {LoggingSettings=} newLoggingSettings
   * @param {AccessServerSettings=} newAccessServerSettings
   * @param {ConfigServerSettings=} newConfigServerSettings
   * @param {FeedServerSettings=} newFeedServerSettings
   * @param {HistoryServerSettings=} newHistoryServerSettings
   * @param {StorageServerSettings=} newStorageServerSettings
   * @param {TradeServerSettings=} newTradeServerSettings
   * @param {ExecutionServerSettings=} newExecutionServerSettings
   * @param {BackupServerSettings=} newBackupServerSettings
   * @param {ExportServerSettings=} newExportServerSettings
   * @param {string=} newPassword
   * @param {!boolean=} newPasswordReset
   * @param {DrawingStorageServerSettings=} newDrawingStorageServerSettings
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), moduleId = null, moduleName = null, newSortOrder = null, newHostId = null, newHostName = null, newName = null, newComment = null, newDescription = null, newModuleType = null, newIsEnabled = null, newLoggingSettings = null, newAccessServerSettings = null, newConfigServerSettings = null, newFeedServerSettings = null, newHistoryServerSettings = null, newStorageServerSettings = null, newTradeServerSettings = null, newExecutionServerSettings = null, newBackupServerSettings = null, newExportServerSettings = null, newPassword = null, newPasswordReset = false, newDrawingStorageServerSettings = null) {
    super()
    super.copy(parent)
    this.ModuleId = moduleId
    this.ModuleName = moduleName
    this.NewSortOrder = newSortOrder
    this.NewHostId = newHostId
    this.NewHostName = newHostName
    this.NewName = newName
    this.NewComment = newComment
    this.NewDescription = newDescription
    this.NewModuleType = newModuleType
    this.NewIsEnabled = newIsEnabled
    this.NewLoggingSettings = newLoggingSettings
    this.NewAccessServerSettings = newAccessServerSettings
    this.NewConfigServerSettings = newConfigServerSettings
    this.NewFeedServerSettings = newFeedServerSettings
    this.NewHistoryServerSettings = newHistoryServerSettings
    this.NewStorageServerSettings = newStorageServerSettings
    this.NewTradeServerSettings = newTradeServerSettings
    this.NewExecutionServerSettings = newExecutionServerSettings
    this.NewBackupServerSettings = newBackupServerSettings
    this.NewExportServerSettings = newExportServerSettings
    this.NewPassword = newPassword
    this.NewPasswordReset = newPasswordReset
    this.NewDrawingStorageServerSettings = newDrawingStorageServerSettings
  }

  /**
   * Copy struct (shallow copy)
   * @this {!ModuleModifyRequest}
   * @param {!ModuleModifyRequest} other Other struct
   * @returns {!ModuleModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.ModuleId != null) {
      this.ModuleId = new UUID(other.ModuleId)
    } else {
      this.ModuleId = null
    }
    if (other.ModuleName != null) {
      this.ModuleName = other.ModuleName
    } else {
      this.ModuleName = null
    }
    if (other.NewSortOrder != null) {
      this.NewSortOrder = other.NewSortOrder
    } else {
      this.NewSortOrder = null
    }
    if (other.NewHostId != null) {
      this.NewHostId = new UUID(other.NewHostId)
    } else {
      this.NewHostId = null
    }
    if (other.NewHostName != null) {
      this.NewHostName = other.NewHostName
    } else {
      this.NewHostName = null
    }
    if (other.NewName != null) {
      this.NewName = other.NewName
    } else {
      this.NewName = null
    }
    if (other.NewComment != null) {
      this.NewComment = other.NewComment
    } else {
      this.NewComment = null
    }
    if (other.NewDescription != null) {
      this.NewDescription = other.NewDescription
    } else {
      this.NewDescription = null
    }
    if (other.NewModuleType != null) {
      this.NewModuleType = ModuleType.fromObject(other.NewModuleType)
    } else {
      this.NewModuleType = null
    }
    if (other.NewIsEnabled != null) {
      this.NewIsEnabled = other.NewIsEnabled
    } else {
      this.NewIsEnabled = null
    }
    if (other.NewLoggingSettings != null) {
      this.NewLoggingSettings = LoggingSettings.fromObject(other.NewLoggingSettings)
    } else {
      this.NewLoggingSettings = null
    }
    if (other.NewAccessServerSettings != null) {
      this.NewAccessServerSettings = AccessServerSettings.fromObject(other.NewAccessServerSettings)
    } else {
      this.NewAccessServerSettings = null
    }
    if (other.NewConfigServerSettings != null) {
      this.NewConfigServerSettings = ConfigServerSettings.fromObject(other.NewConfigServerSettings)
    } else {
      this.NewConfigServerSettings = null
    }
    if (other.NewFeedServerSettings != null) {
      this.NewFeedServerSettings = FeedServerSettings.fromObject(other.NewFeedServerSettings)
    } else {
      this.NewFeedServerSettings = null
    }
    if (other.NewHistoryServerSettings != null) {
      this.NewHistoryServerSettings = HistoryServerSettings.fromObject(other.NewHistoryServerSettings)
    } else {
      this.NewHistoryServerSettings = null
    }
    if (other.NewStorageServerSettings != null) {
      this.NewStorageServerSettings = StorageServerSettings.fromObject(other.NewStorageServerSettings)
    } else {
      this.NewStorageServerSettings = null
    }
    if (other.NewTradeServerSettings != null) {
      this.NewTradeServerSettings = TradeServerSettings.fromObject(other.NewTradeServerSettings)
    } else {
      this.NewTradeServerSettings = null
    }
    if (other.NewExecutionServerSettings != null) {
      this.NewExecutionServerSettings = ExecutionServerSettings.fromObject(other.NewExecutionServerSettings)
    } else {
      this.NewExecutionServerSettings = null
    }
    if (other.NewBackupServerSettings != null) {
      this.NewBackupServerSettings = BackupServerSettings.fromObject(other.NewBackupServerSettings)
    } else {
      this.NewBackupServerSettings = null
    }
    if (other.NewExportServerSettings != null) {
      this.NewExportServerSettings = ExportServerSettings.fromObject(other.NewExportServerSettings)
    } else {
      this.NewExportServerSettings = null
    }
    if (other.NewPassword != null) {
      this.NewPassword = other.NewPassword
    } else {
      this.NewPassword = null
    }
    if (other.NewPasswordReset != null) {
      this.NewPasswordReset = other.NewPasswordReset
    } else {
      this.NewPasswordReset = null
    }
    if (other.NewDrawingStorageServerSettings != null) {
      this.NewDrawingStorageServerSettings = DrawingStorageServerSettings.fromObject(other.NewDrawingStorageServerSettings)
    } else {
      this.NewDrawingStorageServerSettings = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!ModuleModifyRequest}
   * @returns {!ModuleModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ModuleModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ModuleModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!ModuleModifyRequest}
   * @param {!ModuleModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ModuleModifyRequest)) {
      throw new TypeError('Instance of ModuleModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!ModuleModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      ModuleId: ((this.ModuleId != null) ? this.ModuleId.toString() : null),
      ModuleName: ((this.ModuleName != null) ? this.ModuleName : null),
      NewSortOrder: ((this.NewSortOrder != null) ? this.NewSortOrder : null),
      NewHostId: ((this.NewHostId != null) ? this.NewHostId.toString() : null),
      NewHostName: ((this.NewHostName != null) ? this.NewHostName : null),
      NewName: ((this.NewName != null) ? this.NewName : null),
      NewComment: ((this.NewComment != null) ? this.NewComment : null),
      NewDescription: ((this.NewDescription != null) ? this.NewDescription : null),
      NewModuleType: ((this.NewModuleType != null) ? this.NewModuleType : null),
      NewIsEnabled: ((this.NewIsEnabled != null) ? this.NewIsEnabled : null),
      NewLoggingSettings: ((this.NewLoggingSettings != null) ? this.NewLoggingSettings : null),
      NewAccessServerSettings: ((this.NewAccessServerSettings != null) ? this.NewAccessServerSettings : null),
      NewConfigServerSettings: ((this.NewConfigServerSettings != null) ? this.NewConfigServerSettings : null),
      NewFeedServerSettings: ((this.NewFeedServerSettings != null) ? this.NewFeedServerSettings : null),
      NewHistoryServerSettings: ((this.NewHistoryServerSettings != null) ? this.NewHistoryServerSettings : null),
      NewStorageServerSettings: ((this.NewStorageServerSettings != null) ? this.NewStorageServerSettings : null),
      NewTradeServerSettings: ((this.NewTradeServerSettings != null) ? this.NewTradeServerSettings : null),
      NewExecutionServerSettings: ((this.NewExecutionServerSettings != null) ? this.NewExecutionServerSettings : null),
      NewBackupServerSettings: ((this.NewBackupServerSettings != null) ? this.NewBackupServerSettings : null),
      NewExportServerSettings: ((this.NewExportServerSettings != null) ? this.NewExportServerSettings : null),
      NewPassword: ((this.NewPassword != null) ? this.NewPassword : null),
      NewPasswordReset: ((this.NewPasswordReset != null) ? this.NewPasswordReset : null),
      NewDrawingStorageServerSettings: ((this.NewDrawingStorageServerSettings != null) ? this.NewDrawingStorageServerSettings : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return ModuleModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!ModuleModifyRequest} other Object value
   * @returns {!ModuleModifyRequest} Created struct
   */
  static fromObject (other) {
    return new ModuleModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!ModuleModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return ModuleModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!ModuleModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6056
  }
}

export { ModuleModifyRequest };

/**
 * Fast Binary Encoding ModuleModifyRequest field model
 */
class FieldModelModuleModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.ModuleId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.ModuleName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.ModuleId.fbeOffset + this.ModuleId.fbeSize), buffer, this.ModuleId.fbeOffset + this.ModuleId.fbeSize)
    this.NewSortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.ModuleName.fbeOffset + this.ModuleName.fbeSize), buffer, this.ModuleName.fbeOffset + this.ModuleName.fbeSize)
    this.NewHostId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize), buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize)
    this.NewHostName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewHostId.fbeOffset + this.NewHostId.fbeSize), buffer, this.NewHostId.fbeOffset + this.NewHostId.fbeSize)
    this.NewName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewHostName.fbeOffset + this.NewHostName.fbeSize), buffer, this.NewHostName.fbeOffset + this.NewHostName.fbeSize)
    this.NewComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewName.fbeOffset + this.NewName.fbeSize), buffer, this.NewName.fbeOffset + this.NewName.fbeSize)
    this.NewDescription = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize), buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize)
    this.NewModuleType = new fbe.FieldModelOptional(new FieldModelModuleType(buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize), buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize)
    this.NewIsEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewModuleType.fbeOffset + this.NewModuleType.fbeSize), buffer, this.NewModuleType.fbeOffset + this.NewModuleType.fbeSize)
    this.NewLoggingSettings = new fbe.FieldModelOptional(new FieldModelLoggingSettings(buffer, this.NewIsEnabled.fbeOffset + this.NewIsEnabled.fbeSize), buffer, this.NewIsEnabled.fbeOffset + this.NewIsEnabled.fbeSize)
    this.NewAccessServerSettings = new fbe.FieldModelOptional(new FieldModelAccessServerSettings(buffer, this.NewLoggingSettings.fbeOffset + this.NewLoggingSettings.fbeSize), buffer, this.NewLoggingSettings.fbeOffset + this.NewLoggingSettings.fbeSize)
    this.NewConfigServerSettings = new fbe.FieldModelOptional(new FieldModelConfigServerSettings(buffer, this.NewAccessServerSettings.fbeOffset + this.NewAccessServerSettings.fbeSize), buffer, this.NewAccessServerSettings.fbeOffset + this.NewAccessServerSettings.fbeSize)
    this.NewFeedServerSettings = new fbe.FieldModelOptional(new FieldModelFeedServerSettings(buffer, this.NewConfigServerSettings.fbeOffset + this.NewConfigServerSettings.fbeSize), buffer, this.NewConfigServerSettings.fbeOffset + this.NewConfigServerSettings.fbeSize)
    this.NewHistoryServerSettings = new fbe.FieldModelOptional(new FieldModelHistoryServerSettings(buffer, this.NewFeedServerSettings.fbeOffset + this.NewFeedServerSettings.fbeSize), buffer, this.NewFeedServerSettings.fbeOffset + this.NewFeedServerSettings.fbeSize)
    this.NewStorageServerSettings = new fbe.FieldModelOptional(new FieldModelStorageServerSettings(buffer, this.NewHistoryServerSettings.fbeOffset + this.NewHistoryServerSettings.fbeSize), buffer, this.NewHistoryServerSettings.fbeOffset + this.NewHistoryServerSettings.fbeSize)
    this.NewTradeServerSettings = new fbe.FieldModelOptional(new FieldModelTradeServerSettings(buffer, this.NewStorageServerSettings.fbeOffset + this.NewStorageServerSettings.fbeSize), buffer, this.NewStorageServerSettings.fbeOffset + this.NewStorageServerSettings.fbeSize)
    this.NewExecutionServerSettings = new fbe.FieldModelOptional(new FieldModelExecutionServerSettings(buffer, this.NewTradeServerSettings.fbeOffset + this.NewTradeServerSettings.fbeSize), buffer, this.NewTradeServerSettings.fbeOffset + this.NewTradeServerSettings.fbeSize)
    this.NewBackupServerSettings = new fbe.FieldModelOptional(new FieldModelBackupServerSettings(buffer, this.NewExecutionServerSettings.fbeOffset + this.NewExecutionServerSettings.fbeSize), buffer, this.NewExecutionServerSettings.fbeOffset + this.NewExecutionServerSettings.fbeSize)
    this.NewExportServerSettings = new fbe.FieldModelOptional(new FieldModelExportServerSettings(buffer, this.NewBackupServerSettings.fbeOffset + this.NewBackupServerSettings.fbeSize), buffer, this.NewBackupServerSettings.fbeOffset + this.NewBackupServerSettings.fbeSize)
    this.NewPassword = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewExportServerSettings.fbeOffset + this.NewExportServerSettings.fbeSize), buffer, this.NewExportServerSettings.fbeOffset + this.NewExportServerSettings.fbeSize)
    this.NewPasswordReset = new fbe.FieldModelBool(buffer, this.NewPassword.fbeOffset + this.NewPassword.fbeSize)
    this.NewDrawingStorageServerSettings = new fbe.FieldModelOptional(new FieldModelDrawingStorageServerSettings(buffer, this.NewPasswordReset.fbeOffset + this.NewPasswordReset.fbeSize), buffer, this.NewPasswordReset.fbeOffset + this.NewPasswordReset.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelModuleModifyRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelModuleModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelModuleModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.ModuleId.fbeSize + this.ModuleName.fbeSize + this.NewSortOrder.fbeSize + this.NewHostId.fbeSize + this.NewHostName.fbeSize + this.NewName.fbeSize + this.NewComment.fbeSize + this.NewDescription.fbeSize + this.NewModuleType.fbeSize + this.NewIsEnabled.fbeSize + this.NewLoggingSettings.fbeSize + this.NewAccessServerSettings.fbeSize + this.NewConfigServerSettings.fbeSize + this.NewFeedServerSettings.fbeSize + this.NewHistoryServerSettings.fbeSize + this.NewStorageServerSettings.fbeSize + this.NewTradeServerSettings.fbeSize + this.NewExecutionServerSettings.fbeSize + this.NewBackupServerSettings.fbeSize + this.NewExportServerSettings.fbeSize + this.NewPassword.fbeSize + this.NewPasswordReset.fbeSize + this.NewDrawingStorageServerSettings.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelModuleModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.ModuleId.fbeExtra + this.ModuleName.fbeExtra + this.NewSortOrder.fbeExtra + this.NewHostId.fbeExtra + this.NewHostName.fbeExtra + this.NewName.fbeExtra + this.NewComment.fbeExtra + this.NewDescription.fbeExtra + this.NewModuleType.fbeExtra + this.NewIsEnabled.fbeExtra + this.NewLoggingSettings.fbeExtra + this.NewAccessServerSettings.fbeExtra + this.NewConfigServerSettings.fbeExtra + this.NewFeedServerSettings.fbeExtra + this.NewHistoryServerSettings.fbeExtra + this.NewStorageServerSettings.fbeExtra + this.NewTradeServerSettings.fbeExtra + this.NewExecutionServerSettings.fbeExtra + this.NewBackupServerSettings.fbeExtra + this.NewExportServerSettings.fbeExtra + this.NewPassword.fbeExtra + this.NewPasswordReset.fbeExtra + this.NewDrawingStorageServerSettings.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelModuleModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelModuleModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelModuleModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6056
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelModuleModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelModuleModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.ModuleId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ModuleId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModuleId.fbeSize

    if ((fbeCurrentSize + this.ModuleName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ModuleName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModuleName.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewHostId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewHostId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewHostId.fbeSize

    if ((fbeCurrentSize + this.NewHostName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewHostName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewHostName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDescription.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewModuleType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewModuleType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewModuleType.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize

    if ((fbeCurrentSize + this.NewLoggingSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewLoggingSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLoggingSettings.fbeSize

    if ((fbeCurrentSize + this.NewAccessServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAccessServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccessServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewConfigServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewConfigServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewConfigServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewFeedServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewFeedServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewFeedServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewHistoryServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewHistoryServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewHistoryServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewStorageServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStorageServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStorageServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewTradeServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewTradeServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTradeServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewExecutionServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewExecutionServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExecutionServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewBackupServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewBackupServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewBackupServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewExportServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewExportServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExportServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewPassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPassword.fbeSize

    if ((fbeCurrentSize + this.NewPasswordReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPasswordReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPasswordReset.fbeSize

    if ((fbeCurrentSize + this.NewDrawingStorageServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDrawingStorageServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDrawingStorageServerSettings.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelModuleModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelModuleModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelModuleModifyRequest}
   * @param {!ModuleModifyRequest} fbeValue Default value, defaults is new ModuleModifyRequest()
   * @returns {!ModuleModifyRequest} ModuleModifyRequest value
   */
  get (fbeValue = new ModuleModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelModuleModifyRequest}
   * @param {!ModuleModifyRequest} fbeValue ModuleModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.ModuleId.fbeSize) <= fbeStructSize) {
      fbeValue.ModuleId = this.ModuleId.get()
    } else {
      fbeValue.ModuleId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModuleId.fbeSize

    if ((fbeCurrentSize + this.ModuleName.fbeSize) <= fbeStructSize) {
      fbeValue.ModuleName = this.ModuleName.get()
    } else {
      fbeValue.ModuleName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModuleName.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.NewSortOrder = this.NewSortOrder.get()
    } else {
      fbeValue.NewSortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewHostId.fbeSize) <= fbeStructSize) {
      fbeValue.NewHostId = this.NewHostId.get()
    } else {
      fbeValue.NewHostId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewHostId.fbeSize

    if ((fbeCurrentSize + this.NewHostName.fbeSize) <= fbeStructSize) {
      fbeValue.NewHostName = this.NewHostName.get()
    } else {
      fbeValue.NewHostName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewHostName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) <= fbeStructSize) {
      fbeValue.NewName = this.NewName.get()
    } else {
      fbeValue.NewName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewComment = this.NewComment.get()
    } else {
      fbeValue.NewComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) <= fbeStructSize) {
      fbeValue.NewDescription = this.NewDescription.get()
    } else {
      fbeValue.NewDescription = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewModuleType.fbeSize) <= fbeStructSize) {
      fbeValue.NewModuleType = this.NewModuleType.get()
    } else {
      fbeValue.NewModuleType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewModuleType.fbeSize

    if ((fbeCurrentSize + this.NewIsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsEnabled = this.NewIsEnabled.get()
    } else {
      fbeValue.NewIsEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsEnabled.fbeSize

    if ((fbeCurrentSize + this.NewLoggingSettings.fbeSize) <= fbeStructSize) {
      fbeValue.NewLoggingSettings = this.NewLoggingSettings.get()
    } else {
      fbeValue.NewLoggingSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLoggingSettings.fbeSize

    if ((fbeCurrentSize + this.NewAccessServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.NewAccessServerSettings = this.NewAccessServerSettings.get()
    } else {
      fbeValue.NewAccessServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccessServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewConfigServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.NewConfigServerSettings = this.NewConfigServerSettings.get()
    } else {
      fbeValue.NewConfigServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewConfigServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewFeedServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.NewFeedServerSettings = this.NewFeedServerSettings.get()
    } else {
      fbeValue.NewFeedServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewFeedServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewHistoryServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.NewHistoryServerSettings = this.NewHistoryServerSettings.get()
    } else {
      fbeValue.NewHistoryServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewHistoryServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewStorageServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.NewStorageServerSettings = this.NewStorageServerSettings.get()
    } else {
      fbeValue.NewStorageServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStorageServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewTradeServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.NewTradeServerSettings = this.NewTradeServerSettings.get()
    } else {
      fbeValue.NewTradeServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTradeServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewExecutionServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.NewExecutionServerSettings = this.NewExecutionServerSettings.get()
    } else {
      fbeValue.NewExecutionServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExecutionServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewBackupServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.NewBackupServerSettings = this.NewBackupServerSettings.get()
    } else {
      fbeValue.NewBackupServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewBackupServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewExportServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.NewExportServerSettings = this.NewExportServerSettings.get()
    } else {
      fbeValue.NewExportServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewExportServerSettings.fbeSize

    if ((fbeCurrentSize + this.NewPassword.fbeSize) <= fbeStructSize) {
      fbeValue.NewPassword = this.NewPassword.get()
    } else {
      fbeValue.NewPassword = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPassword.fbeSize

    if ((fbeCurrentSize + this.NewPasswordReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewPasswordReset = this.NewPasswordReset.get()
    } else {
      fbeValue.NewPasswordReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPasswordReset.fbeSize

    if ((fbeCurrentSize + this.NewDrawingStorageServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.NewDrawingStorageServerSettings = this.NewDrawingStorageServerSettings.get()
    } else {
      fbeValue.NewDrawingStorageServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDrawingStorageServerSettings.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelModuleModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelModuleModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelModuleModifyRequest}
   * @param {!ModuleModifyRequest} fbeValue ModuleModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelModuleModifyRequest}
   * @param {ModuleModifyRequest} fbeValue ModuleModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.ModuleId.set(fbeValue.ModuleId)
    this.ModuleName.set(fbeValue.ModuleName)
    this.NewSortOrder.set(fbeValue.NewSortOrder)
    this.NewHostId.set(fbeValue.NewHostId)
    this.NewHostName.set(fbeValue.NewHostName)
    this.NewName.set(fbeValue.NewName)
    this.NewComment.set(fbeValue.NewComment)
    this.NewDescription.set(fbeValue.NewDescription)
    this.NewModuleType.set(fbeValue.NewModuleType)
    this.NewIsEnabled.set(fbeValue.NewIsEnabled)
    this.NewLoggingSettings.set(fbeValue.NewLoggingSettings)
    this.NewAccessServerSettings.set(fbeValue.NewAccessServerSettings)
    this.NewConfigServerSettings.set(fbeValue.NewConfigServerSettings)
    this.NewFeedServerSettings.set(fbeValue.NewFeedServerSettings)
    this.NewHistoryServerSettings.set(fbeValue.NewHistoryServerSettings)
    this.NewStorageServerSettings.set(fbeValue.NewStorageServerSettings)
    this.NewTradeServerSettings.set(fbeValue.NewTradeServerSettings)
    this.NewExecutionServerSettings.set(fbeValue.NewExecutionServerSettings)
    this.NewBackupServerSettings.set(fbeValue.NewBackupServerSettings)
    this.NewExportServerSettings.set(fbeValue.NewExportServerSettings)
    this.NewPassword.set(fbeValue.NewPassword)
    this.NewPasswordReset.set(fbeValue.NewPasswordReset)
    this.NewDrawingStorageServerSettings.set(fbeValue.NewDrawingStorageServerSettings)
  }
}

export { FieldModelModuleModifyRequest };

/**
 * Fast Binary Encoding ModuleModifyRequest model
 */
class ModuleModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelModuleModifyRequest(this.buffer, 4)
  }

  /**
   * Get the ModuleModifyRequest model
   * @this {!ModuleModifyRequestModel}
   * @returns {!FieldModelModuleModifyRequest} model ModuleModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ModuleModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ModuleModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ModuleModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ModuleModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelModuleModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ModuleModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ModuleModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ModuleModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ModuleModifyRequestModel}
   * @param {!ModuleModifyRequest} value ModuleModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ModuleModifyRequestModel}
   * @param {!ModuleModifyRequest} value ModuleModifyRequest value, defaults is new ModuleModifyRequest()
   * @return {!object} Deserialized ModuleModifyRequest value and its size
   */
  deserialize (value = new ModuleModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new ModuleModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new ModuleModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ModuleModifyRequestModel}
   * @param {!number} prev Previous ModuleModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ModuleModifyRequestModel };
ModuleModifyRequest.__has_response = true;
ModuleModifyRequest.__response_class = ModuleModifyResponse;
