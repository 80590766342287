
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as exposed from '../exposed'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {AccountingType} from '../core/AccountingType';
import {FieldModelAccountingType} from '../core/AccountingType';
import {ReportsSubscriptionType} from '../core/ReportsSubscriptionType';
import {FieldModelReportsSubscriptionType} from '../core/ReportsSubscriptionType';
import {SocialTradingAccountType} from '../core/SocialTradingAccountType';
import {FieldModelSocialTradingAccountType} from '../core/SocialTradingAccountType';
import {SocialTradingMasterInfo} from './SocialTradingMasterInfo';
import {FieldModelSocialTradingMasterInfo} from './SocialTradingMasterInfo';
import {SocialTradingInvestorInfo} from './SocialTradingInvestorInfo';
import {FieldModelSocialTradingInvestorInfo} from './SocialTradingInvestorInfo';

/**
 * AccountInfo struct
 */
class AccountInfo {
  /**
   * Initialize struct
   * @param {!number=} login
   * @param {!number=} actualVersion
   * @param {!string=} name
   * @param {!string=} country
   * @param {!string=} city
   * @param {!string=} state
   * @param {!string=} zipCode
   * @param {!string=} address
   * @param {!string=} phone
   * @param {!string=} email
   * @param {!string=} description
   * @param {!boolean=} isDemo
   * @param {!boolean=} isEnabled
   * @param {!boolean=} isOTPEnabled
   * @param {!boolean=} isTradeEnabled
   * @param {!boolean=} isInvestor
   * @param {!AccountingType=} accountingType
   * @param {!Date=} created
   * @param {!Date=} modified
   * @param {!Date=} archived
   * @param {!Date=} deleted
   * @param {!ReportsSubscriptionType=} reportsSubscriptionType
   * @param {!string=} localLanguageName
   * @param {!boolean=} isSwapEnabled
   * @param {!SocialTradingAccountType=} socialTradingAccountType
   * @param {SocialTradingMasterInfo=} socialTradingMasterInfo
   * @param {SocialTradingInvestorInfo=} socialTradingInvestorInfo
   * @constructor
   */
  constructor (login = new UInt64(0, 0), actualVersion = new UInt64(0, 0), name = '', country = '', city = '', state = '', zipCode = '', address = '', phone = '', email = '', description = '', isDemo = false, isEnabled = false, isOTPEnabled = false, isTradeEnabled = false, isInvestor = false, accountingType = new AccountingType(), created = new Date(0), modified = new Date(0), archived = new Date(0), deleted = new Date(0), reportsSubscriptionType = new ReportsSubscriptionType(), localLanguageName = '', isSwapEnabled = false, socialTradingAccountType = new SocialTradingAccountType(), socialTradingMasterInfo = null, socialTradingInvestorInfo = null) {
    this.Login = login
    this.ActualVersion = actualVersion
    this.Name = name
    this.Country = country
    this.City = city
    this.State = state
    this.ZipCode = zipCode
    this.Address = address
    this.Phone = phone
    this.Email = email
    this.Description = description
    this.IsDemo = isDemo
    this.IsEnabled = isEnabled
    this.IsOTPEnabled = isOTPEnabled
    this.IsTradeEnabled = isTradeEnabled
    this.IsInvestor = isInvestor
    this.AccountingType = accountingType
    this.Created = created
    this.Modified = modified
    this.Archived = archived
    this.Deleted = deleted
    this.ReportsSubscriptionType = reportsSubscriptionType
    this.LocalLanguageName = localLanguageName
    this.IsSwapEnabled = isSwapEnabled
    this.SocialTradingAccountType = socialTradingAccountType
    this.SocialTradingMasterInfo = socialTradingMasterInfo
    this.SocialTradingInvestorInfo = socialTradingInvestorInfo
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AccountInfo}
   * @param {!AccountInfo} other Other struct
   * @returns {!AccountInfo} This struct
   */
  copy (other) {
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.ActualVersion != null) {
      this.ActualVersion = UInt64.fromNumber(other.ActualVersion)
    } else {
      this.ActualVersion = null
    }
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Country != null) {
      this.Country = other.Country
    } else {
      this.Country = null
    }
    if (other.City != null) {
      this.City = other.City
    } else {
      this.City = null
    }
    if (other.State != null) {
      this.State = other.State
    } else {
      this.State = null
    }
    if (other.ZipCode != null) {
      this.ZipCode = other.ZipCode
    } else {
      this.ZipCode = null
    }
    if (other.Address != null) {
      this.Address = other.Address
    } else {
      this.Address = null
    }
    if (other.Phone != null) {
      this.Phone = other.Phone
    } else {
      this.Phone = null
    }
    if (other.Email != null) {
      this.Email = other.Email
    } else {
      this.Email = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.IsDemo != null) {
      this.IsDemo = other.IsDemo
    } else {
      this.IsDemo = null
    }
    if (other.IsEnabled != null) {
      this.IsEnabled = other.IsEnabled
    } else {
      this.IsEnabled = null
    }
    if (other.IsOTPEnabled != null) {
      this.IsOTPEnabled = other.IsOTPEnabled
    } else {
      this.IsOTPEnabled = null
    }
    if (other.IsTradeEnabled != null) {
      this.IsTradeEnabled = other.IsTradeEnabled
    } else {
      this.IsTradeEnabled = null
    }
    if (other.IsInvestor != null) {
      this.IsInvestor = other.IsInvestor
    } else {
      this.IsInvestor = null
    }
    if (other.AccountingType != null) {
      this.AccountingType = AccountingType.fromObject(other.AccountingType)
    } else {
      this.AccountingType = null
    }
    if (other.Created != null) {
      if (other.Created instanceof Date) {
        this.Created = new Date(other.Created.getTime())
      } else {
        this.Created = new Date(Math.round(other.Created / 1000000))
      }
    } else {
      this.Created = null
    }
    if (other.Modified != null) {
      if (other.Modified instanceof Date) {
        this.Modified = new Date(other.Modified.getTime())
      } else {
        this.Modified = new Date(Math.round(other.Modified / 1000000))
      }
    } else {
      this.Modified = null
    }
    if (other.Archived != null) {
      if (other.Archived instanceof Date) {
        this.Archived = new Date(other.Archived.getTime())
      } else {
        this.Archived = new Date(Math.round(other.Archived / 1000000))
      }
    } else {
      this.Archived = null
    }
    if (other.Deleted != null) {
      if (other.Deleted instanceof Date) {
        this.Deleted = new Date(other.Deleted.getTime())
      } else {
        this.Deleted = new Date(Math.round(other.Deleted / 1000000))
      }
    } else {
      this.Deleted = null
    }
    if (other.ReportsSubscriptionType != null) {
      this.ReportsSubscriptionType = ReportsSubscriptionType.fromObject(other.ReportsSubscriptionType)
    } else {
      this.ReportsSubscriptionType = null
    }
    if (other.LocalLanguageName != null) {
      this.LocalLanguageName = other.LocalLanguageName
    } else {
      this.LocalLanguageName = null
    }
    if (other.IsSwapEnabled != null) {
      this.IsSwapEnabled = other.IsSwapEnabled
    } else {
      this.IsSwapEnabled = null
    }
    if (other.SocialTradingAccountType != null) {
      this.SocialTradingAccountType = SocialTradingAccountType.fromObject(other.SocialTradingAccountType)
    } else {
      this.SocialTradingAccountType = null
    }
    if (other.SocialTradingMasterInfo != null) {
      this.SocialTradingMasterInfo = SocialTradingMasterInfo.fromObject(other.SocialTradingMasterInfo)
    } else {
      this.SocialTradingMasterInfo = null
    }
    if (other.SocialTradingInvestorInfo != null) {
      this.SocialTradingInvestorInfo = SocialTradingInvestorInfo.fromObject(other.SocialTradingInvestorInfo)
    } else {
      this.SocialTradingInvestorInfo = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AccountInfo}
   * @returns {!AccountInfo} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccountInfoModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccountInfoModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AccountInfo}
   * @param {!AccountInfo} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AccountInfo)) {
      throw new TypeError('Instance of AccountInfo is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AccountInfo}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      ActualVersion: ((this.ActualVersion != null) ? this.ActualVersion.toNumber() : null),
      Name: ((this.Name != null) ? this.Name : null),
      Country: ((this.Country != null) ? this.Country : null),
      City: ((this.City != null) ? this.City : null),
      State: ((this.State != null) ? this.State : null),
      ZipCode: ((this.ZipCode != null) ? this.ZipCode : null),
      Address: ((this.Address != null) ? this.Address : null),
      Phone: ((this.Phone != null) ? this.Phone : null),
      Email: ((this.Email != null) ? this.Email : null),
      Description: ((this.Description != null) ? this.Description : null),
      IsDemo: ((this.IsDemo != null) ? this.IsDemo : null),
      IsEnabled: ((this.IsEnabled != null) ? this.IsEnabled : null),
      IsOTPEnabled: ((this.IsOTPEnabled != null) ? this.IsOTPEnabled : null),
      IsTradeEnabled: ((this.IsTradeEnabled != null) ? this.IsTradeEnabled : null),
      IsInvestor: ((this.IsInvestor != null) ? this.IsInvestor : null),
      AccountingType: ((this.AccountingType != null) ? this.AccountingType : null),
      Created: ((this.Created != null) ? (this.Created.getTime() * 1000000) : null),
      Modified: ((this.Modified != null) ? (this.Modified.getTime() * 1000000) : null),
      Archived: ((this.Archived != null) ? (this.Archived.getTime() * 1000000) : null),
      Deleted: ((this.Deleted != null) ? (this.Deleted.getTime() * 1000000) : null),
      ReportsSubscriptionType: ((this.ReportsSubscriptionType != null) ? this.ReportsSubscriptionType : null),
      LocalLanguageName: ((this.LocalLanguageName != null) ? this.LocalLanguageName : null),
      IsSwapEnabled: ((this.IsSwapEnabled != null) ? this.IsSwapEnabled : null),
      SocialTradingAccountType: ((this.SocialTradingAccountType != null) ? this.SocialTradingAccountType : null),
      SocialTradingMasterInfo: ((this.SocialTradingMasterInfo != null) ? this.SocialTradingMasterInfo : null),
      SocialTradingInvestorInfo: ((this.SocialTradingInvestorInfo != null) ? this.SocialTradingInvestorInfo : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AccountInfo.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AccountInfo} other Object value
   * @returns {!AccountInfo} Created struct
   */
  static fromObject (other) {
    return new AccountInfo().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AccountInfo}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AccountInfo.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AccountInfo}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 5067
  }
}

export { AccountInfo };

/**
 * Fast Binary Encoding AccountInfo field model
 */
class FieldModelAccountInfo extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Login = new fbe.FieldModelUInt64(buffer, 4 + 4)
    this.ActualVersion = new fbe.FieldModelUInt64(buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.Name = new fbe.FieldModelString(buffer, this.ActualVersion.fbeOffset + this.ActualVersion.fbeSize)
    this.Country = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.City = new fbe.FieldModelString(buffer, this.Country.fbeOffset + this.Country.fbeSize)
    this.State = new fbe.FieldModelString(buffer, this.City.fbeOffset + this.City.fbeSize)
    this.ZipCode = new fbe.FieldModelString(buffer, this.State.fbeOffset + this.State.fbeSize)
    this.Address = new fbe.FieldModelString(buffer, this.ZipCode.fbeOffset + this.ZipCode.fbeSize)
    this.Phone = new fbe.FieldModelString(buffer, this.Address.fbeOffset + this.Address.fbeSize)
    this.Email = new fbe.FieldModelString(buffer, this.Phone.fbeOffset + this.Phone.fbeSize)
    this.Description = new fbe.FieldModelString(buffer, this.Email.fbeOffset + this.Email.fbeSize)
    this.IsDemo = new fbe.FieldModelBool(buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.IsEnabled = new fbe.FieldModelBool(buffer, this.IsDemo.fbeOffset + this.IsDemo.fbeSize)
    this.IsOTPEnabled = new fbe.FieldModelBool(buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize)
    this.IsTradeEnabled = new fbe.FieldModelBool(buffer, this.IsOTPEnabled.fbeOffset + this.IsOTPEnabled.fbeSize)
    this.IsInvestor = new fbe.FieldModelBool(buffer, this.IsTradeEnabled.fbeOffset + this.IsTradeEnabled.fbeSize)
    this.AccountingType = new FieldModelAccountingType(buffer, this.IsInvestor.fbeOffset + this.IsInvestor.fbeSize)
    this.Created = new fbe.FieldModelTimestamp(buffer, this.AccountingType.fbeOffset + this.AccountingType.fbeSize)
    this.Modified = new fbe.FieldModelTimestamp(buffer, this.Created.fbeOffset + this.Created.fbeSize)
    this.Archived = new fbe.FieldModelTimestamp(buffer, this.Modified.fbeOffset + this.Modified.fbeSize)
    this.Deleted = new fbe.FieldModelTimestamp(buffer, this.Archived.fbeOffset + this.Archived.fbeSize)
    this.ReportsSubscriptionType = new FieldModelReportsSubscriptionType(buffer, this.Deleted.fbeOffset + this.Deleted.fbeSize)
    this.LocalLanguageName = new fbe.FieldModelString(buffer, this.ReportsSubscriptionType.fbeOffset + this.ReportsSubscriptionType.fbeSize)
    this.IsSwapEnabled = new fbe.FieldModelBool(buffer, this.LocalLanguageName.fbeOffset + this.LocalLanguageName.fbeSize)
    this.SocialTradingAccountType = new FieldModelSocialTradingAccountType(buffer, this.IsSwapEnabled.fbeOffset + this.IsSwapEnabled.fbeSize)
    this.SocialTradingMasterInfo = new fbe.FieldModelOptional(new FieldModelSocialTradingMasterInfo(buffer, this.SocialTradingAccountType.fbeOffset + this.SocialTradingAccountType.fbeSize), buffer, this.SocialTradingAccountType.fbeOffset + this.SocialTradingAccountType.fbeSize)
    this.SocialTradingInvestorInfo = new fbe.FieldModelOptional(new FieldModelSocialTradingInvestorInfo(buffer, this.SocialTradingMasterInfo.fbeOffset + this.SocialTradingMasterInfo.fbeSize), buffer, this.SocialTradingMasterInfo.fbeOffset + this.SocialTradingMasterInfo.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelAccountInfo}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccountInfo}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Login.fbeSize + this.ActualVersion.fbeSize + this.Name.fbeSize + this.Country.fbeSize + this.City.fbeSize + this.State.fbeSize + this.ZipCode.fbeSize + this.Address.fbeSize + this.Phone.fbeSize + this.Email.fbeSize + this.Description.fbeSize + this.IsDemo.fbeSize + this.IsEnabled.fbeSize + this.IsOTPEnabled.fbeSize + this.IsTradeEnabled.fbeSize + this.IsInvestor.fbeSize + this.AccountingType.fbeSize + this.Created.fbeSize + this.Modified.fbeSize + this.Archived.fbeSize + this.Deleted.fbeSize + this.ReportsSubscriptionType.fbeSize + this.LocalLanguageName.fbeSize + this.IsSwapEnabled.fbeSize + this.SocialTradingAccountType.fbeSize + this.SocialTradingMasterInfo.fbeSize + this.SocialTradingInvestorInfo.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccountInfo}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Login.fbeExtra + this.ActualVersion.fbeExtra + this.Name.fbeExtra + this.Country.fbeExtra + this.City.fbeExtra + this.State.fbeExtra + this.ZipCode.fbeExtra + this.Address.fbeExtra + this.Phone.fbeExtra + this.Email.fbeExtra + this.Description.fbeExtra + this.IsDemo.fbeExtra + this.IsEnabled.fbeExtra + this.IsOTPEnabled.fbeExtra + this.IsTradeEnabled.fbeExtra + this.IsInvestor.fbeExtra + this.AccountingType.fbeExtra + this.Created.fbeExtra + this.Modified.fbeExtra + this.Archived.fbeExtra + this.Deleted.fbeExtra + this.ReportsSubscriptionType.fbeExtra + this.LocalLanguageName.fbeExtra + this.IsSwapEnabled.fbeExtra + this.SocialTradingAccountType.fbeExtra + this.SocialTradingMasterInfo.fbeExtra + this.SocialTradingInvestorInfo.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccountInfo}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccountInfo.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccountInfo}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 5067
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccountInfo}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccountInfo}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ActualVersion.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Country.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Country.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Country.fbeSize

    if ((fbeCurrentSize + this.City.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.City.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.City.fbeSize

    if ((fbeCurrentSize + this.State.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.State.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.State.fbeSize

    if ((fbeCurrentSize + this.ZipCode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ZipCode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ZipCode.fbeSize

    if ((fbeCurrentSize + this.Address.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Address.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Address.fbeSize

    if ((fbeCurrentSize + this.Phone.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Phone.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Phone.fbeSize

    if ((fbeCurrentSize + this.Email.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Email.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Email.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.IsDemo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsDemo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsDemo.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.IsOTPEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsOTPEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.IsTradeEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsTradeEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeEnabled.fbeSize

    if ((fbeCurrentSize + this.IsInvestor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsInvestor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsInvestor.fbeSize

    if ((fbeCurrentSize + this.AccountingType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountingType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountingType.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Created.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    if ((fbeCurrentSize + this.Modified.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Modified.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modified.fbeSize

    if ((fbeCurrentSize + this.Archived.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Archived.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Archived.fbeSize

    if ((fbeCurrentSize + this.Deleted.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deleted.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deleted.fbeSize

    if ((fbeCurrentSize + this.ReportsSubscriptionType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ReportsSubscriptionType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ReportsSubscriptionType.fbeSize

    if ((fbeCurrentSize + this.LocalLanguageName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LocalLanguageName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LocalLanguageName.fbeSize

    if ((fbeCurrentSize + this.IsSwapEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsSwapEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsSwapEnabled.fbeSize

    if ((fbeCurrentSize + this.SocialTradingAccountType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingAccountType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingAccountType.fbeSize

    if ((fbeCurrentSize + this.SocialTradingMasterInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingMasterInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingMasterInfo.fbeSize

    if ((fbeCurrentSize + this.SocialTradingInvestorInfo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingInvestorInfo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingInvestorInfo.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccountInfo}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccountInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccountInfo}
   * @param {!AccountInfo} fbeValue Default value, defaults is new AccountInfo()
   * @returns {!AccountInfo} AccountInfo value
   */
  get (fbeValue = new AccountInfo()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccountInfo}
   * @param {!AccountInfo} fbeValue AccountInfo value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.ActualVersion.fbeSize) <= fbeStructSize) {
      fbeValue.ActualVersion = this.ActualVersion.get()
    } else {
      fbeValue.ActualVersion = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ActualVersion.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Country.fbeSize) <= fbeStructSize) {
      fbeValue.Country = this.Country.get()
    } else {
      fbeValue.Country = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Country.fbeSize

    if ((fbeCurrentSize + this.City.fbeSize) <= fbeStructSize) {
      fbeValue.City = this.City.get()
    } else {
      fbeValue.City = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.City.fbeSize

    if ((fbeCurrentSize + this.State.fbeSize) <= fbeStructSize) {
      fbeValue.State = this.State.get()
    } else {
      fbeValue.State = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.State.fbeSize

    if ((fbeCurrentSize + this.ZipCode.fbeSize) <= fbeStructSize) {
      fbeValue.ZipCode = this.ZipCode.get()
    } else {
      fbeValue.ZipCode = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ZipCode.fbeSize

    if ((fbeCurrentSize + this.Address.fbeSize) <= fbeStructSize) {
      fbeValue.Address = this.Address.get()
    } else {
      fbeValue.Address = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Address.fbeSize

    if ((fbeCurrentSize + this.Phone.fbeSize) <= fbeStructSize) {
      fbeValue.Phone = this.Phone.get()
    } else {
      fbeValue.Phone = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Phone.fbeSize

    if ((fbeCurrentSize + this.Email.fbeSize) <= fbeStructSize) {
      fbeValue.Email = this.Email.get()
    } else {
      fbeValue.Email = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Email.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.IsDemo.fbeSize) <= fbeStructSize) {
      fbeValue.IsDemo = this.IsDemo.get()
    } else {
      fbeValue.IsDemo = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsDemo.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsEnabled = this.IsEnabled.get()
    } else {
      fbeValue.IsEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.IsOTPEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsOTPEnabled = this.IsOTPEnabled.get()
    } else {
      fbeValue.IsOTPEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.IsTradeEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsTradeEnabled = this.IsTradeEnabled.get()
    } else {
      fbeValue.IsTradeEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeEnabled.fbeSize

    if ((fbeCurrentSize + this.IsInvestor.fbeSize) <= fbeStructSize) {
      fbeValue.IsInvestor = this.IsInvestor.get()
    } else {
      fbeValue.IsInvestor = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsInvestor.fbeSize

    if ((fbeCurrentSize + this.AccountingType.fbeSize) <= fbeStructSize) {
      fbeValue.AccountingType = this.AccountingType.get()
    } else {
      fbeValue.AccountingType = new AccountingType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountingType.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) <= fbeStructSize) {
      fbeValue.Created = this.Created.get()
    } else {
      fbeValue.Created = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    if ((fbeCurrentSize + this.Modified.fbeSize) <= fbeStructSize) {
      fbeValue.Modified = this.Modified.get()
    } else {
      fbeValue.Modified = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modified.fbeSize

    if ((fbeCurrentSize + this.Archived.fbeSize) <= fbeStructSize) {
      fbeValue.Archived = this.Archived.get()
    } else {
      fbeValue.Archived = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Archived.fbeSize

    if ((fbeCurrentSize + this.Deleted.fbeSize) <= fbeStructSize) {
      fbeValue.Deleted = this.Deleted.get()
    } else {
      fbeValue.Deleted = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deleted.fbeSize

    if ((fbeCurrentSize + this.ReportsSubscriptionType.fbeSize) <= fbeStructSize) {
      fbeValue.ReportsSubscriptionType = this.ReportsSubscriptionType.get()
    } else {
      fbeValue.ReportsSubscriptionType = new ReportsSubscriptionType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ReportsSubscriptionType.fbeSize

    if ((fbeCurrentSize + this.LocalLanguageName.fbeSize) <= fbeStructSize) {
      fbeValue.LocalLanguageName = this.LocalLanguageName.get()
    } else {
      fbeValue.LocalLanguageName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LocalLanguageName.fbeSize

    if ((fbeCurrentSize + this.IsSwapEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsSwapEnabled = this.IsSwapEnabled.get()
    } else {
      fbeValue.IsSwapEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsSwapEnabled.fbeSize

    if ((fbeCurrentSize + this.SocialTradingAccountType.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingAccountType = this.SocialTradingAccountType.get()
    } else {
      fbeValue.SocialTradingAccountType = new SocialTradingAccountType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingAccountType.fbeSize

    if ((fbeCurrentSize + this.SocialTradingMasterInfo.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingMasterInfo = this.SocialTradingMasterInfo.get()
    } else {
      fbeValue.SocialTradingMasterInfo = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingMasterInfo.fbeSize

    if ((fbeCurrentSize + this.SocialTradingInvestorInfo.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingInvestorInfo = this.SocialTradingInvestorInfo.get()
    } else {
      fbeValue.SocialTradingInvestorInfo = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingInvestorInfo.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccountInfo}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccountInfo}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccountInfo}
   * @param {!AccountInfo} fbeValue AccountInfo value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccountInfo}
   * @param {AccountInfo} fbeValue AccountInfo value
   */
  setFields (fbeValue) {
    this.Login.set(fbeValue.Login)
    this.ActualVersion.set(fbeValue.ActualVersion)
    this.Name.set(fbeValue.Name)
    this.Country.set(fbeValue.Country)
    this.City.set(fbeValue.City)
    this.State.set(fbeValue.State)
    this.ZipCode.set(fbeValue.ZipCode)
    this.Address.set(fbeValue.Address)
    this.Phone.set(fbeValue.Phone)
    this.Email.set(fbeValue.Email)
    this.Description.set(fbeValue.Description)
    this.IsDemo.set(fbeValue.IsDemo)
    this.IsEnabled.set(fbeValue.IsEnabled)
    this.IsOTPEnabled.set(fbeValue.IsOTPEnabled)
    this.IsTradeEnabled.set(fbeValue.IsTradeEnabled)
    this.IsInvestor.set(fbeValue.IsInvestor)
    this.AccountingType.set(fbeValue.AccountingType)
    this.Created.set(fbeValue.Created)
    this.Modified.set(fbeValue.Modified)
    this.Archived.set(fbeValue.Archived)
    this.Deleted.set(fbeValue.Deleted)
    this.ReportsSubscriptionType.set(fbeValue.ReportsSubscriptionType)
    this.LocalLanguageName.set(fbeValue.LocalLanguageName)
    this.IsSwapEnabled.set(fbeValue.IsSwapEnabled)
    this.SocialTradingAccountType.set(fbeValue.SocialTradingAccountType)
    this.SocialTradingMasterInfo.set(fbeValue.SocialTradingMasterInfo)
    this.SocialTradingInvestorInfo.set(fbeValue.SocialTradingInvestorInfo)
  }
}

export { FieldModelAccountInfo };

/**
 * Fast Binary Encoding AccountInfo model
 */
class AccountInfoModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccountInfo(this.buffer, 4)
  }

  /**
   * Get the AccountInfo model
   * @this {!AccountInfoModel}
   * @returns {!FieldModelAccountInfo} model AccountInfo model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccountInfoModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccountInfoModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccountInfoModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccountInfoModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccountInfo.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccountInfoModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccountInfoModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccountInfoModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccountInfoModel}
   * @param {!AccountInfo} value AccountInfo value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccountInfoModel}
   * @param {!AccountInfo} value AccountInfo value, defaults is new AccountInfo()
   * @return {!object} Deserialized AccountInfo value and its size
   */
  deserialize (value = new AccountInfo()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AccountInfo(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AccountInfo(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccountInfoModel}
   * @param {!number} prev Previous AccountInfo model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccountInfoModel };
