
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * HistoryServerSettings struct
 */
class HistoryServerSettings {
  /**
   * Initialize struct
   * @param {!number=} historyPort
   * @param {!string=} historyPath
   * @param {!boolean=} useSsl
   * @param {!string=} deprecated1
   * @param {!string=} deprecated2
   * @param {!string=} clientReportsPath
   * @param {number=} outgoingBufferSizeLimit
   * @param {!number=} chartBarsSlowQueryLogThreshold
   * @param {!number=} ticksSlowQueryLogThreshold
   * @param {!number=} requestsThreadPoolSize
   * @param {!number=} chartBarsUpdatePeriod
   * @param {!number=} clientReportsSlowQueryLogThreshold
   * @constructor
   */
  constructor (historyPort = 6120, historyPath = '../history', useSsl = false, deprecated1 = '', deprecated2 = '', clientReportsPath = '../confirms', outgoingBufferSizeLimit = null, chartBarsSlowQueryLogThreshold = new UInt64(500000000, 0), ticksSlowQueryLogThreshold = new UInt64(500000000, 0), requestsThreadPoolSize = 4, chartBarsUpdatePeriod = 1000, clientReportsSlowQueryLogThreshold = new UInt64(100000000, 0)) {
    this.HistoryPort = historyPort
    this.HistoryPath = historyPath
    this.UseSsl = useSsl
    this.Deprecated1 = deprecated1
    this.Deprecated2 = deprecated2
    this.ClientReportsPath = clientReportsPath
    this.OutgoingBufferSizeLimit = outgoingBufferSizeLimit
    this.ChartBarsSlowQueryLogThreshold = chartBarsSlowQueryLogThreshold
    this.TicksSlowQueryLogThreshold = ticksSlowQueryLogThreshold
    this.RequestsThreadPoolSize = requestsThreadPoolSize
    this.ChartBarsUpdatePeriod = chartBarsUpdatePeriod
    this.ClientReportsSlowQueryLogThreshold = clientReportsSlowQueryLogThreshold
  }

  /**
   * Copy struct (shallow copy)
   * @this {!HistoryServerSettings}
   * @param {!HistoryServerSettings} other Other struct
   * @returns {!HistoryServerSettings} This struct
   */
  copy (other) {
    if (other.HistoryPort != null) {
      this.HistoryPort = other.HistoryPort
    } else {
      this.HistoryPort = null
    }
    if (other.HistoryPath != null) {
      this.HistoryPath = other.HistoryPath
    } else {
      this.HistoryPath = null
    }
    if (other.UseSsl != null) {
      this.UseSsl = other.UseSsl
    } else {
      this.UseSsl = null
    }
    if (other.Deprecated1 != null) {
      this.Deprecated1 = other.Deprecated1
    } else {
      this.Deprecated1 = null
    }
    if (other.Deprecated2 != null) {
      this.Deprecated2 = other.Deprecated2
    } else {
      this.Deprecated2 = null
    }
    if (other.ClientReportsPath != null) {
      this.ClientReportsPath = other.ClientReportsPath
    } else {
      this.ClientReportsPath = null
    }
    if (other.OutgoingBufferSizeLimit != null) {
      this.OutgoingBufferSizeLimit = UInt64.fromNumber(other.OutgoingBufferSizeLimit)
    } else {
      this.OutgoingBufferSizeLimit = null
    }
    if (other.ChartBarsSlowQueryLogThreshold != null) {
      this.ChartBarsSlowQueryLogThreshold = UInt64.fromNumber(other.ChartBarsSlowQueryLogThreshold)
    } else {
      this.ChartBarsSlowQueryLogThreshold = null
    }
    if (other.TicksSlowQueryLogThreshold != null) {
      this.TicksSlowQueryLogThreshold = UInt64.fromNumber(other.TicksSlowQueryLogThreshold)
    } else {
      this.TicksSlowQueryLogThreshold = null
    }
    if (other.RequestsThreadPoolSize != null) {
      this.RequestsThreadPoolSize = other.RequestsThreadPoolSize
    } else {
      this.RequestsThreadPoolSize = null
    }
    if (other.ChartBarsUpdatePeriod != null) {
      this.ChartBarsUpdatePeriod = other.ChartBarsUpdatePeriod
    } else {
      this.ChartBarsUpdatePeriod = null
    }
    if (other.ClientReportsSlowQueryLogThreshold != null) {
      this.ClientReportsSlowQueryLogThreshold = UInt64.fromNumber(other.ClientReportsSlowQueryLogThreshold)
    } else {
      this.ClientReportsSlowQueryLogThreshold = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!HistoryServerSettings}
   * @returns {!HistoryServerSettings} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new HistoryServerSettingsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new HistoryServerSettingsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!HistoryServerSettings}
   * @param {!HistoryServerSettings} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof HistoryServerSettings)) {
      throw new TypeError('Instance of HistoryServerSettings is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!HistoryServerSettings}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      HistoryPort: ((this.HistoryPort != null) ? this.HistoryPort : null),
      HistoryPath: ((this.HistoryPath != null) ? this.HistoryPath : null),
      UseSsl: ((this.UseSsl != null) ? this.UseSsl : null),
      Deprecated1: ((this.Deprecated1 != null) ? this.Deprecated1 : null),
      Deprecated2: ((this.Deprecated2 != null) ? this.Deprecated2 : null),
      ClientReportsPath: ((this.ClientReportsPath != null) ? this.ClientReportsPath : null),
      OutgoingBufferSizeLimit: ((this.OutgoingBufferSizeLimit != null) ? this.OutgoingBufferSizeLimit.toNumber() : null),
      ChartBarsSlowQueryLogThreshold: ((this.ChartBarsSlowQueryLogThreshold != null) ? this.ChartBarsSlowQueryLogThreshold.toNumber() : null),
      TicksSlowQueryLogThreshold: ((this.TicksSlowQueryLogThreshold != null) ? this.TicksSlowQueryLogThreshold.toNumber() : null),
      RequestsThreadPoolSize: ((this.RequestsThreadPoolSize != null) ? this.RequestsThreadPoolSize : null),
      ChartBarsUpdatePeriod: ((this.ChartBarsUpdatePeriod != null) ? this.ChartBarsUpdatePeriod : null),
      ClientReportsSlowQueryLogThreshold: ((this.ClientReportsSlowQueryLogThreshold != null) ? this.ClientReportsSlowQueryLogThreshold.toNumber() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return HistoryServerSettings.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!HistoryServerSettings} other Object value
   * @returns {!HistoryServerSettings} Created struct
   */
  static fromObject (other) {
    return new HistoryServerSettings().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!HistoryServerSettings}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return HistoryServerSettings.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!HistoryServerSettings}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 10
  }
}

export { HistoryServerSettings };

/**
 * Fast Binary Encoding HistoryServerSettings field model
 */
class FieldModelHistoryServerSettings extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.HistoryPort = new fbe.FieldModelUInt16(buffer, 4 + 4)
    this.HistoryPath = new fbe.FieldModelString(buffer, this.HistoryPort.fbeOffset + this.HistoryPort.fbeSize)
    this.UseSsl = new fbe.FieldModelBool(buffer, this.HistoryPath.fbeOffset + this.HistoryPath.fbeSize)
    this.Deprecated1 = new fbe.FieldModelString(buffer, this.UseSsl.fbeOffset + this.UseSsl.fbeSize)
    this.Deprecated2 = new fbe.FieldModelString(buffer, this.Deprecated1.fbeOffset + this.Deprecated1.fbeSize)
    this.ClientReportsPath = new fbe.FieldModelString(buffer, this.Deprecated2.fbeOffset + this.Deprecated2.fbeSize)
    this.OutgoingBufferSizeLimit = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.ClientReportsPath.fbeOffset + this.ClientReportsPath.fbeSize), buffer, this.ClientReportsPath.fbeOffset + this.ClientReportsPath.fbeSize)
    this.ChartBarsSlowQueryLogThreshold = new fbe.FieldModelUInt64(buffer, this.OutgoingBufferSizeLimit.fbeOffset + this.OutgoingBufferSizeLimit.fbeSize)
    this.TicksSlowQueryLogThreshold = new fbe.FieldModelUInt64(buffer, this.ChartBarsSlowQueryLogThreshold.fbeOffset + this.ChartBarsSlowQueryLogThreshold.fbeSize)
    this.RequestsThreadPoolSize = new fbe.FieldModelUInt32(buffer, this.TicksSlowQueryLogThreshold.fbeOffset + this.TicksSlowQueryLogThreshold.fbeSize)
    this.ChartBarsUpdatePeriod = new fbe.FieldModelUInt32(buffer, this.RequestsThreadPoolSize.fbeOffset + this.RequestsThreadPoolSize.fbeSize)
    this.ClientReportsSlowQueryLogThreshold = new fbe.FieldModelUInt64(buffer, this.ChartBarsUpdatePeriod.fbeOffset + this.ChartBarsUpdatePeriod.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelHistoryServerSettings}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelHistoryServerSettings}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.HistoryPort.fbeSize + this.HistoryPath.fbeSize + this.UseSsl.fbeSize + this.Deprecated1.fbeSize + this.Deprecated2.fbeSize + this.ClientReportsPath.fbeSize + this.OutgoingBufferSizeLimit.fbeSize + this.ChartBarsSlowQueryLogThreshold.fbeSize + this.TicksSlowQueryLogThreshold.fbeSize + this.RequestsThreadPoolSize.fbeSize + this.ChartBarsUpdatePeriod.fbeSize + this.ClientReportsSlowQueryLogThreshold.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelHistoryServerSettings}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.HistoryPort.fbeExtra + this.HistoryPath.fbeExtra + this.UseSsl.fbeExtra + this.Deprecated1.fbeExtra + this.Deprecated2.fbeExtra + this.ClientReportsPath.fbeExtra + this.OutgoingBufferSizeLimit.fbeExtra + this.ChartBarsSlowQueryLogThreshold.fbeExtra + this.TicksSlowQueryLogThreshold.fbeExtra + this.RequestsThreadPoolSize.fbeExtra + this.ChartBarsUpdatePeriod.fbeExtra + this.ClientReportsSlowQueryLogThreshold.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelHistoryServerSettings}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelHistoryServerSettings.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelHistoryServerSettings}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 10
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelHistoryServerSettings}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelHistoryServerSettings}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.HistoryPort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HistoryPort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HistoryPort.fbeSize

    if ((fbeCurrentSize + this.HistoryPath.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HistoryPath.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HistoryPath.fbeSize

    if ((fbeCurrentSize + this.UseSsl.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.UseSsl.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UseSsl.fbeSize

    if ((fbeCurrentSize + this.Deprecated1.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated1.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated1.fbeSize

    if ((fbeCurrentSize + this.Deprecated2.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated2.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated2.fbeSize

    if ((fbeCurrentSize + this.ClientReportsPath.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientReportsPath.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientReportsPath.fbeSize

    if ((fbeCurrentSize + this.OutgoingBufferSizeLimit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OutgoingBufferSizeLimit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OutgoingBufferSizeLimit.fbeSize

    if ((fbeCurrentSize + this.ChartBarsSlowQueryLogThreshold.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ChartBarsSlowQueryLogThreshold.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ChartBarsSlowQueryLogThreshold.fbeSize

    if ((fbeCurrentSize + this.TicksSlowQueryLogThreshold.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TicksSlowQueryLogThreshold.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TicksSlowQueryLogThreshold.fbeSize

    if ((fbeCurrentSize + this.RequestsThreadPoolSize.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RequestsThreadPoolSize.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestsThreadPoolSize.fbeSize

    if ((fbeCurrentSize + this.ChartBarsUpdatePeriod.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ChartBarsUpdatePeriod.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ChartBarsUpdatePeriod.fbeSize

    if ((fbeCurrentSize + this.ClientReportsSlowQueryLogThreshold.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ClientReportsSlowQueryLogThreshold.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientReportsSlowQueryLogThreshold.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelHistoryServerSettings}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelHistoryServerSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelHistoryServerSettings}
   * @param {!HistoryServerSettings} fbeValue Default value, defaults is new HistoryServerSettings()
   * @returns {!HistoryServerSettings} HistoryServerSettings value
   */
  get (fbeValue = new HistoryServerSettings()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelHistoryServerSettings}
   * @param {!HistoryServerSettings} fbeValue HistoryServerSettings value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.HistoryPort.fbeSize) <= fbeStructSize) {
      fbeValue.HistoryPort = this.HistoryPort.get(6120)
    } else {
      fbeValue.HistoryPort = 6120
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HistoryPort.fbeSize

    if ((fbeCurrentSize + this.HistoryPath.fbeSize) <= fbeStructSize) {
      fbeValue.HistoryPath = this.HistoryPath.get('../history')
    } else {
      fbeValue.HistoryPath = '../history'
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HistoryPath.fbeSize

    if ((fbeCurrentSize + this.UseSsl.fbeSize) <= fbeStructSize) {
      fbeValue.UseSsl = this.UseSsl.get(false)
    } else {
      fbeValue.UseSsl = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UseSsl.fbeSize

    if ((fbeCurrentSize + this.Deprecated1.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated1 = this.Deprecated1.get()
    } else {
      fbeValue.Deprecated1 = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated1.fbeSize

    if ((fbeCurrentSize + this.Deprecated2.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated2 = this.Deprecated2.get()
    } else {
      fbeValue.Deprecated2 = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated2.fbeSize

    if ((fbeCurrentSize + this.ClientReportsPath.fbeSize) <= fbeStructSize) {
      fbeValue.ClientReportsPath = this.ClientReportsPath.get('../confirms')
    } else {
      fbeValue.ClientReportsPath = '../confirms'
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientReportsPath.fbeSize

    if ((fbeCurrentSize + this.OutgoingBufferSizeLimit.fbeSize) <= fbeStructSize) {
      fbeValue.OutgoingBufferSizeLimit = this.OutgoingBufferSizeLimit.get()
    } else {
      fbeValue.OutgoingBufferSizeLimit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OutgoingBufferSizeLimit.fbeSize

    if ((fbeCurrentSize + this.ChartBarsSlowQueryLogThreshold.fbeSize) <= fbeStructSize) {
      fbeValue.ChartBarsSlowQueryLogThreshold = this.ChartBarsSlowQueryLogThreshold.get(new UInt64(500000000, 0))
    } else {
      fbeValue.ChartBarsSlowQueryLogThreshold = new UInt64(500000000, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ChartBarsSlowQueryLogThreshold.fbeSize

    if ((fbeCurrentSize + this.TicksSlowQueryLogThreshold.fbeSize) <= fbeStructSize) {
      fbeValue.TicksSlowQueryLogThreshold = this.TicksSlowQueryLogThreshold.get(new UInt64(500000000, 0))
    } else {
      fbeValue.TicksSlowQueryLogThreshold = new UInt64(500000000, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TicksSlowQueryLogThreshold.fbeSize

    if ((fbeCurrentSize + this.RequestsThreadPoolSize.fbeSize) <= fbeStructSize) {
      fbeValue.RequestsThreadPoolSize = this.RequestsThreadPoolSize.get(4)
    } else {
      fbeValue.RequestsThreadPoolSize = 4
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestsThreadPoolSize.fbeSize

    if ((fbeCurrentSize + this.ChartBarsUpdatePeriod.fbeSize) <= fbeStructSize) {
      fbeValue.ChartBarsUpdatePeriod = this.ChartBarsUpdatePeriod.get(1000)
    } else {
      fbeValue.ChartBarsUpdatePeriod = 1000
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ChartBarsUpdatePeriod.fbeSize

    if ((fbeCurrentSize + this.ClientReportsSlowQueryLogThreshold.fbeSize) <= fbeStructSize) {
      fbeValue.ClientReportsSlowQueryLogThreshold = this.ClientReportsSlowQueryLogThreshold.get(new UInt64(100000000, 0))
    } else {
      fbeValue.ClientReportsSlowQueryLogThreshold = new UInt64(100000000, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ClientReportsSlowQueryLogThreshold.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelHistoryServerSettings}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelHistoryServerSettings}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelHistoryServerSettings}
   * @param {!HistoryServerSettings} fbeValue HistoryServerSettings value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelHistoryServerSettings}
   * @param {HistoryServerSettings} fbeValue HistoryServerSettings value
   */
  setFields (fbeValue) {
    this.HistoryPort.set(fbeValue.HistoryPort)
    this.HistoryPath.set(fbeValue.HistoryPath)
    this.UseSsl.set(fbeValue.UseSsl)
    this.Deprecated1.set(fbeValue.Deprecated1)
    this.Deprecated2.set(fbeValue.Deprecated2)
    this.ClientReportsPath.set(fbeValue.ClientReportsPath)
    this.OutgoingBufferSizeLimit.set(fbeValue.OutgoingBufferSizeLimit)
    this.ChartBarsSlowQueryLogThreshold.set(fbeValue.ChartBarsSlowQueryLogThreshold)
    this.TicksSlowQueryLogThreshold.set(fbeValue.TicksSlowQueryLogThreshold)
    this.RequestsThreadPoolSize.set(fbeValue.RequestsThreadPoolSize)
    this.ChartBarsUpdatePeriod.set(fbeValue.ChartBarsUpdatePeriod)
    this.ClientReportsSlowQueryLogThreshold.set(fbeValue.ClientReportsSlowQueryLogThreshold)
  }
}

export { FieldModelHistoryServerSettings };

/**
 * Fast Binary Encoding HistoryServerSettings model
 */
class HistoryServerSettingsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelHistoryServerSettings(this.buffer, 4)
  }

  /**
   * Get the HistoryServerSettings model
   * @this {!HistoryServerSettingsModel}
   * @returns {!FieldModelHistoryServerSettings} model HistoryServerSettings model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!HistoryServerSettingsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!HistoryServerSettingsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return HistoryServerSettingsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!HistoryServerSettingsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelHistoryServerSettings.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!HistoryServerSettingsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!HistoryServerSettingsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!HistoryServerSettingsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!HistoryServerSettingsModel}
   * @param {!HistoryServerSettings} value HistoryServerSettings value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!HistoryServerSettingsModel}
   * @param {!HistoryServerSettings} value HistoryServerSettings value, defaults is new HistoryServerSettings()
   * @return {!object} Deserialized HistoryServerSettings value and its size
   */
  deserialize (value = new HistoryServerSettings()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new HistoryServerSettings(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new HistoryServerSettings(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!HistoryServerSettingsModel}
   * @param {!number} prev Previous HistoryServerSettings model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { HistoryServerSettingsModel };
