
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as feed from '../feed'
import * as history from '../history'
import * as storage from '../storage'
import * as client from '../client'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {ModuleCreateResponse} from './ModuleCreateResponse';
import {ModuleType} from '../core/ModuleType';
import {FieldModelModuleType} from '../core/ModuleType';
import {LoggingSettings} from '../core/LoggingSettings';
import {FieldModelLoggingSettings} from '../core/LoggingSettings';
import {AccessServerSettings} from '../core/AccessServerSettings';
import {FieldModelAccessServerSettings} from '../core/AccessServerSettings';
import {ConfigServerSettings} from '../core/ConfigServerSettings';
import {FieldModelConfigServerSettings} from '../core/ConfigServerSettings';
import {FeedServerSettings} from '../core/FeedServerSettings';
import {FieldModelFeedServerSettings} from '../core/FeedServerSettings';
import {HistoryServerSettings} from '../core/HistoryServerSettings';
import {FieldModelHistoryServerSettings} from '../core/HistoryServerSettings';
import {StorageServerSettings} from '../core/StorageServerSettings';
import {FieldModelStorageServerSettings} from '../core/StorageServerSettings';
import {TradeServerSettings} from '../core/TradeServerSettings';
import {FieldModelTradeServerSettings} from '../core/TradeServerSettings';
import {ExecutionServerSettings} from '../core/ExecutionServerSettings';
import {FieldModelExecutionServerSettings} from '../core/ExecutionServerSettings';
import {BackupServerSettings} from '../core/BackupServerSettings';
import {FieldModelBackupServerSettings} from '../core/BackupServerSettings';
import {ExportServerSettings} from '../core/ExportServerSettings';
import {FieldModelExportServerSettings} from '../core/ExportServerSettings';
import {DrawingStorageServerSettings} from '../core/DrawingStorageServerSettings';
import {FieldModelDrawingStorageServerSettings} from '../core/DrawingStorageServerSettings';

/**
 * ModuleCreateRequest struct
 */
class ModuleCreateRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {number=} sortOrder
   * @param {UUID=} hostId
   * @param {string=} hostName
   * @param {!string=} name
   * @param {!string=} comment
   * @param {!string=} description
   * @param {!ModuleType=} moduleType
   * @param {!boolean=} isEnabled
   * @param {LoggingSettings=} loggingSettings
   * @param {AccessServerSettings=} accessServerSettings
   * @param {ConfigServerSettings=} configServerSettings
   * @param {FeedServerSettings=} feedServerSettings
   * @param {HistoryServerSettings=} historyServerSettings
   * @param {StorageServerSettings=} storageServerSettings
   * @param {TradeServerSettings=} tradeServerSettings
   * @param {ExecutionServerSettings=} executionServerSettings
   * @param {BackupServerSettings=} backupServerSettings
   * @param {ExportServerSettings=} exportServerSettings
   * @param {string=} password
   * @param {DrawingStorageServerSettings=} drawingStorageServerSettings
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), sortOrder = null, hostId = null, hostName = null, name = '', comment = '', description = '', moduleType = new ModuleType(), isEnabled = false, loggingSettings = null, accessServerSettings = null, configServerSettings = null, feedServerSettings = null, historyServerSettings = null, storageServerSettings = null, tradeServerSettings = null, executionServerSettings = null, backupServerSettings = null, exportServerSettings = null, password = null, drawingStorageServerSettings = null) {
    super()
    super.copy(parent)
    this.SortOrder = sortOrder
    this.HostId = hostId
    this.HostName = hostName
    this.Name = name
    this.Comment = comment
    this.Description = description
    this.ModuleType = moduleType
    this.IsEnabled = isEnabled
    this.LoggingSettings = loggingSettings
    this.AccessServerSettings = accessServerSettings
    this.ConfigServerSettings = configServerSettings
    this.FeedServerSettings = feedServerSettings
    this.HistoryServerSettings = historyServerSettings
    this.StorageServerSettings = storageServerSettings
    this.TradeServerSettings = tradeServerSettings
    this.ExecutionServerSettings = executionServerSettings
    this.BackupServerSettings = backupServerSettings
    this.ExportServerSettings = exportServerSettings
    this.Password = password
    this.DrawingStorageServerSettings = drawingStorageServerSettings
  }

  /**
   * Copy struct (shallow copy)
   * @this {!ModuleCreateRequest}
   * @param {!ModuleCreateRequest} other Other struct
   * @returns {!ModuleCreateRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.SortOrder != null) {
      this.SortOrder = other.SortOrder
    } else {
      this.SortOrder = null
    }
    if (other.HostId != null) {
      this.HostId = new UUID(other.HostId)
    } else {
      this.HostId = null
    }
    if (other.HostName != null) {
      this.HostName = other.HostName
    } else {
      this.HostName = null
    }
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.ModuleType != null) {
      this.ModuleType = ModuleType.fromObject(other.ModuleType)
    } else {
      this.ModuleType = null
    }
    if (other.IsEnabled != null) {
      this.IsEnabled = other.IsEnabled
    } else {
      this.IsEnabled = null
    }
    if (other.LoggingSettings != null) {
      this.LoggingSettings = LoggingSettings.fromObject(other.LoggingSettings)
    } else {
      this.LoggingSettings = null
    }
    if (other.AccessServerSettings != null) {
      this.AccessServerSettings = AccessServerSettings.fromObject(other.AccessServerSettings)
    } else {
      this.AccessServerSettings = null
    }
    if (other.ConfigServerSettings != null) {
      this.ConfigServerSettings = ConfigServerSettings.fromObject(other.ConfigServerSettings)
    } else {
      this.ConfigServerSettings = null
    }
    if (other.FeedServerSettings != null) {
      this.FeedServerSettings = FeedServerSettings.fromObject(other.FeedServerSettings)
    } else {
      this.FeedServerSettings = null
    }
    if (other.HistoryServerSettings != null) {
      this.HistoryServerSettings = HistoryServerSettings.fromObject(other.HistoryServerSettings)
    } else {
      this.HistoryServerSettings = null
    }
    if (other.StorageServerSettings != null) {
      this.StorageServerSettings = StorageServerSettings.fromObject(other.StorageServerSettings)
    } else {
      this.StorageServerSettings = null
    }
    if (other.TradeServerSettings != null) {
      this.TradeServerSettings = TradeServerSettings.fromObject(other.TradeServerSettings)
    } else {
      this.TradeServerSettings = null
    }
    if (other.ExecutionServerSettings != null) {
      this.ExecutionServerSettings = ExecutionServerSettings.fromObject(other.ExecutionServerSettings)
    } else {
      this.ExecutionServerSettings = null
    }
    if (other.BackupServerSettings != null) {
      this.BackupServerSettings = BackupServerSettings.fromObject(other.BackupServerSettings)
    } else {
      this.BackupServerSettings = null
    }
    if (other.ExportServerSettings != null) {
      this.ExportServerSettings = ExportServerSettings.fromObject(other.ExportServerSettings)
    } else {
      this.ExportServerSettings = null
    }
    if (other.Password != null) {
      this.Password = other.Password
    } else {
      this.Password = null
    }
    if (other.DrawingStorageServerSettings != null) {
      this.DrawingStorageServerSettings = DrawingStorageServerSettings.fromObject(other.DrawingStorageServerSettings)
    } else {
      this.DrawingStorageServerSettings = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!ModuleCreateRequest}
   * @returns {!ModuleCreateRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ModuleCreateRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ModuleCreateRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!ModuleCreateRequest}
   * @param {!ModuleCreateRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ModuleCreateRequest)) {
      throw new TypeError('Instance of ModuleCreateRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!ModuleCreateRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      SortOrder: ((this.SortOrder != null) ? this.SortOrder : null),
      HostId: ((this.HostId != null) ? this.HostId.toString() : null),
      HostName: ((this.HostName != null) ? this.HostName : null),
      Name: ((this.Name != null) ? this.Name : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      Description: ((this.Description != null) ? this.Description : null),
      ModuleType: ((this.ModuleType != null) ? this.ModuleType : null),
      IsEnabled: ((this.IsEnabled != null) ? this.IsEnabled : null),
      LoggingSettings: ((this.LoggingSettings != null) ? this.LoggingSettings : null),
      AccessServerSettings: ((this.AccessServerSettings != null) ? this.AccessServerSettings : null),
      ConfigServerSettings: ((this.ConfigServerSettings != null) ? this.ConfigServerSettings : null),
      FeedServerSettings: ((this.FeedServerSettings != null) ? this.FeedServerSettings : null),
      HistoryServerSettings: ((this.HistoryServerSettings != null) ? this.HistoryServerSettings : null),
      StorageServerSettings: ((this.StorageServerSettings != null) ? this.StorageServerSettings : null),
      TradeServerSettings: ((this.TradeServerSettings != null) ? this.TradeServerSettings : null),
      ExecutionServerSettings: ((this.ExecutionServerSettings != null) ? this.ExecutionServerSettings : null),
      BackupServerSettings: ((this.BackupServerSettings != null) ? this.BackupServerSettings : null),
      ExportServerSettings: ((this.ExportServerSettings != null) ? this.ExportServerSettings : null),
      Password: ((this.Password != null) ? this.Password : null),
      DrawingStorageServerSettings: ((this.DrawingStorageServerSettings != null) ? this.DrawingStorageServerSettings : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return ModuleCreateRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!ModuleCreateRequest} other Object value
   * @returns {!ModuleCreateRequest} Created struct
   */
  static fromObject (other) {
    return new ModuleCreateRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!ModuleCreateRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return ModuleCreateRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!ModuleCreateRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6054
  }
}

export { ModuleCreateRequest };

/**
 * Fast Binary Encoding ModuleCreateRequest field model
 */
class FieldModelModuleCreateRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.SortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.HostId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.SortOrder.fbeOffset + this.SortOrder.fbeSize), buffer, this.SortOrder.fbeOffset + this.SortOrder.fbeSize)
    this.HostName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.HostId.fbeOffset + this.HostId.fbeSize), buffer, this.HostId.fbeOffset + this.HostId.fbeSize)
    this.Name = new fbe.FieldModelString(buffer, this.HostName.fbeOffset + this.HostName.fbeSize)
    this.Comment = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.Description = new fbe.FieldModelString(buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.ModuleType = new FieldModelModuleType(buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.IsEnabled = new fbe.FieldModelBool(buffer, this.ModuleType.fbeOffset + this.ModuleType.fbeSize)
    this.LoggingSettings = new fbe.FieldModelOptional(new FieldModelLoggingSettings(buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize), buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize)
    this.AccessServerSettings = new fbe.FieldModelOptional(new FieldModelAccessServerSettings(buffer, this.LoggingSettings.fbeOffset + this.LoggingSettings.fbeSize), buffer, this.LoggingSettings.fbeOffset + this.LoggingSettings.fbeSize)
    this.ConfigServerSettings = new fbe.FieldModelOptional(new FieldModelConfigServerSettings(buffer, this.AccessServerSettings.fbeOffset + this.AccessServerSettings.fbeSize), buffer, this.AccessServerSettings.fbeOffset + this.AccessServerSettings.fbeSize)
    this.FeedServerSettings = new fbe.FieldModelOptional(new FieldModelFeedServerSettings(buffer, this.ConfigServerSettings.fbeOffset + this.ConfigServerSettings.fbeSize), buffer, this.ConfigServerSettings.fbeOffset + this.ConfigServerSettings.fbeSize)
    this.HistoryServerSettings = new fbe.FieldModelOptional(new FieldModelHistoryServerSettings(buffer, this.FeedServerSettings.fbeOffset + this.FeedServerSettings.fbeSize), buffer, this.FeedServerSettings.fbeOffset + this.FeedServerSettings.fbeSize)
    this.StorageServerSettings = new fbe.FieldModelOptional(new FieldModelStorageServerSettings(buffer, this.HistoryServerSettings.fbeOffset + this.HistoryServerSettings.fbeSize), buffer, this.HistoryServerSettings.fbeOffset + this.HistoryServerSettings.fbeSize)
    this.TradeServerSettings = new fbe.FieldModelOptional(new FieldModelTradeServerSettings(buffer, this.StorageServerSettings.fbeOffset + this.StorageServerSettings.fbeSize), buffer, this.StorageServerSettings.fbeOffset + this.StorageServerSettings.fbeSize)
    this.ExecutionServerSettings = new fbe.FieldModelOptional(new FieldModelExecutionServerSettings(buffer, this.TradeServerSettings.fbeOffset + this.TradeServerSettings.fbeSize), buffer, this.TradeServerSettings.fbeOffset + this.TradeServerSettings.fbeSize)
    this.BackupServerSettings = new fbe.FieldModelOptional(new FieldModelBackupServerSettings(buffer, this.ExecutionServerSettings.fbeOffset + this.ExecutionServerSettings.fbeSize), buffer, this.ExecutionServerSettings.fbeOffset + this.ExecutionServerSettings.fbeSize)
    this.ExportServerSettings = new fbe.FieldModelOptional(new FieldModelExportServerSettings(buffer, this.BackupServerSettings.fbeOffset + this.BackupServerSettings.fbeSize), buffer, this.BackupServerSettings.fbeOffset + this.BackupServerSettings.fbeSize)
    this.Password = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.ExportServerSettings.fbeOffset + this.ExportServerSettings.fbeSize), buffer, this.ExportServerSettings.fbeOffset + this.ExportServerSettings.fbeSize)
    this.DrawingStorageServerSettings = new fbe.FieldModelOptional(new FieldModelDrawingStorageServerSettings(buffer, this.Password.fbeOffset + this.Password.fbeSize), buffer, this.Password.fbeOffset + this.Password.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelModuleCreateRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelModuleCreateRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelModuleCreateRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.SortOrder.fbeSize + this.HostId.fbeSize + this.HostName.fbeSize + this.Name.fbeSize + this.Comment.fbeSize + this.Description.fbeSize + this.ModuleType.fbeSize + this.IsEnabled.fbeSize + this.LoggingSettings.fbeSize + this.AccessServerSettings.fbeSize + this.ConfigServerSettings.fbeSize + this.FeedServerSettings.fbeSize + this.HistoryServerSettings.fbeSize + this.StorageServerSettings.fbeSize + this.TradeServerSettings.fbeSize + this.ExecutionServerSettings.fbeSize + this.BackupServerSettings.fbeSize + this.ExportServerSettings.fbeSize + this.Password.fbeSize + this.DrawingStorageServerSettings.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelModuleCreateRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.SortOrder.fbeExtra + this.HostId.fbeExtra + this.HostName.fbeExtra + this.Name.fbeExtra + this.Comment.fbeExtra + this.Description.fbeExtra + this.ModuleType.fbeExtra + this.IsEnabled.fbeExtra + this.LoggingSettings.fbeExtra + this.AccessServerSettings.fbeExtra + this.ConfigServerSettings.fbeExtra + this.FeedServerSettings.fbeExtra + this.HistoryServerSettings.fbeExtra + this.StorageServerSettings.fbeExtra + this.TradeServerSettings.fbeExtra + this.ExecutionServerSettings.fbeExtra + this.BackupServerSettings.fbeExtra + this.ExportServerSettings.fbeExtra + this.Password.fbeExtra + this.DrawingStorageServerSettings.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelModuleCreateRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelModuleCreateRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelModuleCreateRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6054
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelModuleCreateRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelModuleCreateRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.HostId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HostId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HostId.fbeSize

    if ((fbeCurrentSize + this.HostName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HostName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HostName.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.ModuleType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ModuleType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModuleType.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.LoggingSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LoggingSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoggingSettings.fbeSize

    if ((fbeCurrentSize + this.AccessServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccessServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccessServerSettings.fbeSize

    if ((fbeCurrentSize + this.ConfigServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ConfigServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ConfigServerSettings.fbeSize

    if ((fbeCurrentSize + this.FeedServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.FeedServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeedServerSettings.fbeSize

    if ((fbeCurrentSize + this.HistoryServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.HistoryServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HistoryServerSettings.fbeSize

    if ((fbeCurrentSize + this.StorageServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StorageServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StorageServerSettings.fbeSize

    if ((fbeCurrentSize + this.TradeServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradeServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeServerSettings.fbeSize

    if ((fbeCurrentSize + this.ExecutionServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExecutionServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExecutionServerSettings.fbeSize

    if ((fbeCurrentSize + this.BackupServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BackupServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BackupServerSettings.fbeSize

    if ((fbeCurrentSize + this.ExportServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExportServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExportServerSettings.fbeSize

    if ((fbeCurrentSize + this.Password.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Password.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Password.fbeSize

    if ((fbeCurrentSize + this.DrawingStorageServerSettings.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DrawingStorageServerSettings.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DrawingStorageServerSettings.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelModuleCreateRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelModuleCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelModuleCreateRequest}
   * @param {!ModuleCreateRequest} fbeValue Default value, defaults is new ModuleCreateRequest()
   * @returns {!ModuleCreateRequest} ModuleCreateRequest value
   */
  get (fbeValue = new ModuleCreateRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelModuleCreateRequest}
   * @param {!ModuleCreateRequest} fbeValue ModuleCreateRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.SortOrder = this.SortOrder.get()
    } else {
      fbeValue.SortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.HostId.fbeSize) <= fbeStructSize) {
      fbeValue.HostId = this.HostId.get()
    } else {
      fbeValue.HostId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HostId.fbeSize

    if ((fbeCurrentSize + this.HostName.fbeSize) <= fbeStructSize) {
      fbeValue.HostName = this.HostName.get()
    } else {
      fbeValue.HostName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HostName.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.ModuleType.fbeSize) <= fbeStructSize) {
      fbeValue.ModuleType = this.ModuleType.get()
    } else {
      fbeValue.ModuleType = new ModuleType()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModuleType.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsEnabled = this.IsEnabled.get()
    } else {
      fbeValue.IsEnabled = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.LoggingSettings.fbeSize) <= fbeStructSize) {
      fbeValue.LoggingSettings = this.LoggingSettings.get()
    } else {
      fbeValue.LoggingSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoggingSettings.fbeSize

    if ((fbeCurrentSize + this.AccessServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.AccessServerSettings = this.AccessServerSettings.get()
    } else {
      fbeValue.AccessServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccessServerSettings.fbeSize

    if ((fbeCurrentSize + this.ConfigServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.ConfigServerSettings = this.ConfigServerSettings.get()
    } else {
      fbeValue.ConfigServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ConfigServerSettings.fbeSize

    if ((fbeCurrentSize + this.FeedServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.FeedServerSettings = this.FeedServerSettings.get()
    } else {
      fbeValue.FeedServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeedServerSettings.fbeSize

    if ((fbeCurrentSize + this.HistoryServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.HistoryServerSettings = this.HistoryServerSettings.get()
    } else {
      fbeValue.HistoryServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.HistoryServerSettings.fbeSize

    if ((fbeCurrentSize + this.StorageServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.StorageServerSettings = this.StorageServerSettings.get()
    } else {
      fbeValue.StorageServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StorageServerSettings.fbeSize

    if ((fbeCurrentSize + this.TradeServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.TradeServerSettings = this.TradeServerSettings.get()
    } else {
      fbeValue.TradeServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeServerSettings.fbeSize

    if ((fbeCurrentSize + this.ExecutionServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.ExecutionServerSettings = this.ExecutionServerSettings.get()
    } else {
      fbeValue.ExecutionServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExecutionServerSettings.fbeSize

    if ((fbeCurrentSize + this.BackupServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.BackupServerSettings = this.BackupServerSettings.get()
    } else {
      fbeValue.BackupServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BackupServerSettings.fbeSize

    if ((fbeCurrentSize + this.ExportServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.ExportServerSettings = this.ExportServerSettings.get()
    } else {
      fbeValue.ExportServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExportServerSettings.fbeSize

    if ((fbeCurrentSize + this.Password.fbeSize) <= fbeStructSize) {
      fbeValue.Password = this.Password.get()
    } else {
      fbeValue.Password = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Password.fbeSize

    if ((fbeCurrentSize + this.DrawingStorageServerSettings.fbeSize) <= fbeStructSize) {
      fbeValue.DrawingStorageServerSettings = this.DrawingStorageServerSettings.get()
    } else {
      fbeValue.DrawingStorageServerSettings = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DrawingStorageServerSettings.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelModuleCreateRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelModuleCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelModuleCreateRequest}
   * @param {!ModuleCreateRequest} fbeValue ModuleCreateRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelModuleCreateRequest}
   * @param {ModuleCreateRequest} fbeValue ModuleCreateRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.SortOrder.set(fbeValue.SortOrder)
    this.HostId.set(fbeValue.HostId)
    this.HostName.set(fbeValue.HostName)
    this.Name.set(fbeValue.Name)
    this.Comment.set(fbeValue.Comment)
    this.Description.set(fbeValue.Description)
    this.ModuleType.set(fbeValue.ModuleType)
    this.IsEnabled.set(fbeValue.IsEnabled)
    this.LoggingSettings.set(fbeValue.LoggingSettings)
    this.AccessServerSettings.set(fbeValue.AccessServerSettings)
    this.ConfigServerSettings.set(fbeValue.ConfigServerSettings)
    this.FeedServerSettings.set(fbeValue.FeedServerSettings)
    this.HistoryServerSettings.set(fbeValue.HistoryServerSettings)
    this.StorageServerSettings.set(fbeValue.StorageServerSettings)
    this.TradeServerSettings.set(fbeValue.TradeServerSettings)
    this.ExecutionServerSettings.set(fbeValue.ExecutionServerSettings)
    this.BackupServerSettings.set(fbeValue.BackupServerSettings)
    this.ExportServerSettings.set(fbeValue.ExportServerSettings)
    this.Password.set(fbeValue.Password)
    this.DrawingStorageServerSettings.set(fbeValue.DrawingStorageServerSettings)
  }
}

export { FieldModelModuleCreateRequest };

/**
 * Fast Binary Encoding ModuleCreateRequest model
 */
class ModuleCreateRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelModuleCreateRequest(this.buffer, 4)
  }

  /**
   * Get the ModuleCreateRequest model
   * @this {!ModuleCreateRequestModel}
   * @returns {!FieldModelModuleCreateRequest} model ModuleCreateRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ModuleCreateRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ModuleCreateRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ModuleCreateRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ModuleCreateRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelModuleCreateRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ModuleCreateRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ModuleCreateRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ModuleCreateRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ModuleCreateRequestModel}
   * @param {!ModuleCreateRequest} value ModuleCreateRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ModuleCreateRequestModel}
   * @param {!ModuleCreateRequest} value ModuleCreateRequest value, defaults is new ModuleCreateRequest()
   * @return {!object} Deserialized ModuleCreateRequest value and its size
   */
  deserialize (value = new ModuleCreateRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new ModuleCreateRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new ModuleCreateRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ModuleCreateRequestModel}
   * @param {!number} prev Previous ModuleCreateRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ModuleCreateRequestModel };
ModuleCreateRequest.__has_response = true;
ModuleCreateRequest.__response_class = ModuleCreateResponse;
