
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {DemoAccountCreateResponse} from './DemoAccountCreateResponse';
import {AccountingType} from '../core/AccountingType';
import {FieldModelAccountingType} from '../core/AccountingType';

/**
 * DemoAccountCreateRequest struct
 */
class DemoAccountCreateRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {!string=} name
   * @param {!string=} email
   * @param {!string=} description
   * @param {string=} password
   * @param {string=} investorPassword
   * @param {string=} phonePassword
   * @param {AccountingType=} accountingType
   * @param {string=} balanceCurrency
   * @param {number=} balance
   * @param {number=} credit
   * @param {number=} leverage
   * @constructor
   */
  constructor (parent = new Request(), name = '', email = '', description = '', password = null, investorPassword = null, phonePassword = null, accountingType = null, balanceCurrency = null, balance = null, credit = null, leverage = null) {
    super()
    super.copy(parent)
    this.Name = name
    this.Email = email
    this.Description = description
    this.Password = password
    this.InvestorPassword = investorPassword
    this.PhonePassword = phonePassword
    this.AccountingType = accountingType
    this.BalanceCurrency = balanceCurrency
    this.Balance = balance
    this.Credit = credit
    this.Leverage = leverage
  }

  /**
   * Copy struct (shallow copy)
   * @this {!DemoAccountCreateRequest}
   * @param {!DemoAccountCreateRequest} other Other struct
   * @returns {!DemoAccountCreateRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Email != null) {
      this.Email = other.Email
    } else {
      this.Email = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.Password != null) {
      this.Password = other.Password
    } else {
      this.Password = null
    }
    if (other.InvestorPassword != null) {
      this.InvestorPassword = other.InvestorPassword
    } else {
      this.InvestorPassword = null
    }
    if (other.PhonePassword != null) {
      this.PhonePassword = other.PhonePassword
    } else {
      this.PhonePassword = null
    }
    if (other.AccountingType != null) {
      this.AccountingType = AccountingType.fromObject(other.AccountingType)
    } else {
      this.AccountingType = null
    }
    if (other.BalanceCurrency != null) {
      this.BalanceCurrency = other.BalanceCurrency
    } else {
      this.BalanceCurrency = null
    }
    if (other.Balance != null) {
      this.Balance = other.Balance
    } else {
      this.Balance = null
    }
    if (other.Credit != null) {
      this.Credit = other.Credit
    } else {
      this.Credit = null
    }
    if (other.Leverage != null) {
      this.Leverage = other.Leverage
    } else {
      this.Leverage = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!DemoAccountCreateRequest}
   * @returns {!DemoAccountCreateRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new DemoAccountCreateRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new DemoAccountCreateRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!DemoAccountCreateRequest}
   * @param {!DemoAccountCreateRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof DemoAccountCreateRequest)) {
      throw new TypeError('Instance of DemoAccountCreateRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!DemoAccountCreateRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Name: ((this.Name != null) ? this.Name : null),
      Email: ((this.Email != null) ? this.Email : null),
      Description: ((this.Description != null) ? this.Description : null),
      Password: ((this.Password != null) ? this.Password : null),
      InvestorPassword: ((this.InvestorPassword != null) ? this.InvestorPassword : null),
      PhonePassword: ((this.PhonePassword != null) ? this.PhonePassword : null),
      AccountingType: ((this.AccountingType != null) ? this.AccountingType : null),
      BalanceCurrency: ((this.BalanceCurrency != null) ? this.BalanceCurrency : null),
      Balance: ((this.Balance != null) ? this.Balance : null),
      Credit: ((this.Credit != null) ? this.Credit : null),
      Leverage: ((this.Leverage != null) ? this.Leverage : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return DemoAccountCreateRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!DemoAccountCreateRequest} other Object value
   * @returns {!DemoAccountCreateRequest} Created struct
   */
  static fromObject (other) {
    return new DemoAccountCreateRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!DemoAccountCreateRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return DemoAccountCreateRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!DemoAccountCreateRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 7005
  }
}

export { DemoAccountCreateRequest };

/**
 * Fast Binary Encoding DemoAccountCreateRequest field model
 */
class FieldModelDemoAccountCreateRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.Name = new fbe.FieldModelString(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.Email = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.Description = new fbe.FieldModelString(buffer, this.Email.fbeOffset + this.Email.fbeSize)
    this.Password = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.Description.fbeOffset + this.Description.fbeSize), buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.InvestorPassword = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.Password.fbeOffset + this.Password.fbeSize), buffer, this.Password.fbeOffset + this.Password.fbeSize)
    this.PhonePassword = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.InvestorPassword.fbeOffset + this.InvestorPassword.fbeSize), buffer, this.InvestorPassword.fbeOffset + this.InvestorPassword.fbeSize)
    this.AccountingType = new fbe.FieldModelOptional(new FieldModelAccountingType(buffer, this.PhonePassword.fbeOffset + this.PhonePassword.fbeSize), buffer, this.PhonePassword.fbeOffset + this.PhonePassword.fbeSize)
    this.BalanceCurrency = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.AccountingType.fbeOffset + this.AccountingType.fbeSize), buffer, this.AccountingType.fbeOffset + this.AccountingType.fbeSize)
    this.Balance = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.BalanceCurrency.fbeOffset + this.BalanceCurrency.fbeSize), buffer, this.BalanceCurrency.fbeOffset + this.BalanceCurrency.fbeSize)
    this.Credit = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.Balance.fbeOffset + this.Balance.fbeSize), buffer, this.Balance.fbeOffset + this.Balance.fbeSize)
    this.Leverage = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.Credit.fbeOffset + this.Credit.fbeSize), buffer, this.Credit.fbeOffset + this.Credit.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelDemoAccountCreateRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelDemoAccountCreateRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelDemoAccountCreateRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Name.fbeSize + this.Email.fbeSize + this.Description.fbeSize + this.Password.fbeSize + this.InvestorPassword.fbeSize + this.PhonePassword.fbeSize + this.AccountingType.fbeSize + this.BalanceCurrency.fbeSize + this.Balance.fbeSize + this.Credit.fbeSize + this.Leverage.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelDemoAccountCreateRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Name.fbeExtra + this.Email.fbeExtra + this.Description.fbeExtra + this.Password.fbeExtra + this.InvestorPassword.fbeExtra + this.PhonePassword.fbeExtra + this.AccountingType.fbeExtra + this.BalanceCurrency.fbeExtra + this.Balance.fbeExtra + this.Credit.fbeExtra + this.Leverage.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelDemoAccountCreateRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelDemoAccountCreateRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelDemoAccountCreateRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 7005
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelDemoAccountCreateRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelDemoAccountCreateRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Email.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Email.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Email.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.Password.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Password.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Password.fbeSize

    if ((fbeCurrentSize + this.InvestorPassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.InvestorPassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.InvestorPassword.fbeSize

    if ((fbeCurrentSize + this.PhonePassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PhonePassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PhonePassword.fbeSize

    if ((fbeCurrentSize + this.AccountingType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountingType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountingType.fbeSize

    if ((fbeCurrentSize + this.BalanceCurrency.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BalanceCurrency.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceCurrency.fbeSize

    if ((fbeCurrentSize + this.Balance.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Balance.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Balance.fbeSize

    if ((fbeCurrentSize + this.Credit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Credit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Credit.fbeSize

    if ((fbeCurrentSize + this.Leverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Leverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Leverage.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelDemoAccountCreateRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelDemoAccountCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelDemoAccountCreateRequest}
   * @param {!DemoAccountCreateRequest} fbeValue Default value, defaults is new DemoAccountCreateRequest()
   * @returns {!DemoAccountCreateRequest} DemoAccountCreateRequest value
   */
  get (fbeValue = new DemoAccountCreateRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelDemoAccountCreateRequest}
   * @param {!DemoAccountCreateRequest} fbeValue DemoAccountCreateRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Email.fbeSize) <= fbeStructSize) {
      fbeValue.Email = this.Email.get()
    } else {
      fbeValue.Email = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Email.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.Password.fbeSize) <= fbeStructSize) {
      fbeValue.Password = this.Password.get()
    } else {
      fbeValue.Password = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Password.fbeSize

    if ((fbeCurrentSize + this.InvestorPassword.fbeSize) <= fbeStructSize) {
      fbeValue.InvestorPassword = this.InvestorPassword.get()
    } else {
      fbeValue.InvestorPassword = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.InvestorPassword.fbeSize

    if ((fbeCurrentSize + this.PhonePassword.fbeSize) <= fbeStructSize) {
      fbeValue.PhonePassword = this.PhonePassword.get()
    } else {
      fbeValue.PhonePassword = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PhonePassword.fbeSize

    if ((fbeCurrentSize + this.AccountingType.fbeSize) <= fbeStructSize) {
      fbeValue.AccountingType = this.AccountingType.get()
    } else {
      fbeValue.AccountingType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountingType.fbeSize

    if ((fbeCurrentSize + this.BalanceCurrency.fbeSize) <= fbeStructSize) {
      fbeValue.BalanceCurrency = this.BalanceCurrency.get()
    } else {
      fbeValue.BalanceCurrency = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BalanceCurrency.fbeSize

    if ((fbeCurrentSize + this.Balance.fbeSize) <= fbeStructSize) {
      fbeValue.Balance = this.Balance.get()
    } else {
      fbeValue.Balance = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Balance.fbeSize

    if ((fbeCurrentSize + this.Credit.fbeSize) <= fbeStructSize) {
      fbeValue.Credit = this.Credit.get()
    } else {
      fbeValue.Credit = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Credit.fbeSize

    if ((fbeCurrentSize + this.Leverage.fbeSize) <= fbeStructSize) {
      fbeValue.Leverage = this.Leverage.get()
    } else {
      fbeValue.Leverage = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Leverage.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelDemoAccountCreateRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelDemoAccountCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelDemoAccountCreateRequest}
   * @param {!DemoAccountCreateRequest} fbeValue DemoAccountCreateRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelDemoAccountCreateRequest}
   * @param {DemoAccountCreateRequest} fbeValue DemoAccountCreateRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Name.set(fbeValue.Name)
    this.Email.set(fbeValue.Email)
    this.Description.set(fbeValue.Description)
    this.Password.set(fbeValue.Password)
    this.InvestorPassword.set(fbeValue.InvestorPassword)
    this.PhonePassword.set(fbeValue.PhonePassword)
    this.AccountingType.set(fbeValue.AccountingType)
    this.BalanceCurrency.set(fbeValue.BalanceCurrency)
    this.Balance.set(fbeValue.Balance)
    this.Credit.set(fbeValue.Credit)
    this.Leverage.set(fbeValue.Leverage)
  }
}

export { FieldModelDemoAccountCreateRequest };

/**
 * Fast Binary Encoding DemoAccountCreateRequest model
 */
class DemoAccountCreateRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelDemoAccountCreateRequest(this.buffer, 4)
  }

  /**
   * Get the DemoAccountCreateRequest model
   * @this {!DemoAccountCreateRequestModel}
   * @returns {!FieldModelDemoAccountCreateRequest} model DemoAccountCreateRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!DemoAccountCreateRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!DemoAccountCreateRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return DemoAccountCreateRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!DemoAccountCreateRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelDemoAccountCreateRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!DemoAccountCreateRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!DemoAccountCreateRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!DemoAccountCreateRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!DemoAccountCreateRequestModel}
   * @param {!DemoAccountCreateRequest} value DemoAccountCreateRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!DemoAccountCreateRequestModel}
   * @param {!DemoAccountCreateRequest} value DemoAccountCreateRequest value, defaults is new DemoAccountCreateRequest()
   * @return {!object} Deserialized DemoAccountCreateRequest value and its size
   */
  deserialize (value = new DemoAccountCreateRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new DemoAccountCreateRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new DemoAccountCreateRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!DemoAccountCreateRequestModel}
   * @param {!number} prev Previous DemoAccountCreateRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { DemoAccountCreateRequestModel };
DemoAccountCreateRequest.__has_response = true;
DemoAccountCreateRequest.__response_class = DemoAccountCreateResponse;
