import { QUOTE_HISTORY_PAGES } from "@pages/types/pagesTypes";
import { adminPages, adminSections } from "../routes/utils";

export const UI_VERSION: string = process.env.REACT_APP_BUILD_ID || process.env.REACT_APP_VERSION || '0';
export const APP_INSIGHTS_KEY: string = process.env.REACT_APP_APP_INSIGHTS_KEY || '';

export const allRoutes = {
    [adminSections.administration.key]: [],
    [adminPages.system.key]: [],
    [adminPages.modules.key]: [],
    [adminPages.platforms.key]: [],
    [adminPages.currencies.key]: [],
    [adminPages.symbols.key]: [],
    [adminPages.accounts.key]: [],
    [adminPages.managers.key]: [],
    [adminPages.calendars.key]: [],
    [adminPages.profiles.key]: [],
    [adminPages.dataFeeds.key]: [],
    [adminPages.tradingRoutes.key]: [],
    [adminPages.gateways.key]: [],
    [adminPages.quoteHistory.key]: [],
    [adminPages.sessions.key]: [],
    [adminPages.riskManagement.key]: [],
    [adminSections.reports.key]: [],
    [adminSections.monitoring.key]: [],
    [adminPages.balanceTransactions.key]: [],
    [adminPages.statements.key]: [],
    [adminPages.market.key]: [],
    [adminPages.journal.key]: [],
    [adminPages.businessJournal.key]: [],
    [adminPages.modulesWatch.key]: [],
    [adminSections.settings.key]: [],
    [adminPages.mail.key]: [],
    [adminPages.emailTemplates.key]: [],
    [adminSections.hlb.key]: [],
    [adminPages.leverageProfiles.key]: [],
    [adminPages.hlbProfiles.key]: [],
};

export const releasedRoutes = {
    [adminSections.administration.key]: [],
    [adminPages.accounts.key]: [],
    [adminPages.managers.key]: [],
    [adminPages.quoteHistory.key]: [QUOTE_HISTORY_PAGES.CHARTS_BARS],
    [adminPages.riskManagement.key]: [],
    [adminSections.monitoring.key]: [],
    [adminPages.market.key]: [],
    [adminPages.symbols.key]: [],
};
