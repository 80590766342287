import { DaysOfWeek } from '@api/fbe/core/DaysOfWeek';
import { baseSorter } from '@components/TableExt/RowFilters/utils';
import { keyMap, t } from '@localization';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';

export const FORMAT_DT = 'HH:mm:ss DD.MM.YYYY';
export const FORMAT_TIMESTAMP = 'HH:mm:ss.SSS DD.MM.YYYY';
export const FORMAT_T = 'HH:mm:ss';
export const FORMAT_D = 'DD MMM YYYY';
export const FORMAT_DR = 'YYYY.MM.DD';
export const FORMAT_DR_DASH = 'YYYY-MM-DD';
export const NANOSEC_IN_MILLISEC = 1000000;
const NANOSEC_IN_SEC = 1000000000;
const NANOSEC_IN_DAY = 86400000000000;
export const MS_IN_DAY = 86400000;

export const getOffset = (): number => globalThis.managerTimezoneOffset ?? 0;

const nanosecondsValueToNumber = (value: number | string | null | undefined): number | null => {
    if (value === null || value === undefined || value === '') return null;
    const numValue = +value;
    if (Number.isNaN(numValue)) return null;
    return numValue;
};

const nanosecondsValueToMoment = (value: number | null): moment.Moment | null => {
    if (value === null) return null;
    const durationInMS = moment.duration(Math.floor(value / NANOSEC_IN_MILLISEC), 'milliseconds');
    return moment(durationInMS.as('milliseconds'));
};

export const nanosecondsTimeToMomemt = (value: number | string | null | undefined): moment.Moment | null => {
    if (typeof value !== 'number') return null;
    const numValue = nanosecondsValueToNumber(value);
    if (numValue === null || numValue <= 0 || numValue >= NANOSEC_IN_DAY) return moment('00:00:00', FORMAT_T);
    return nanosecondsValueToMoment(numValue);
};

export const stringTimeToMomemt = (value: string | null | undefined): moment.Moment | null => {
    if (typeof value !== 'string' || value === '') return null;
    return moment(value, FORMAT_T);
};

export const momentTimeToStringTime = (value: moment.Moment | null): string | null =>
    value ? value.format(FORMAT_T) : null;

export const nanosecondsToMomemt = (value: number | string | null | undefined): moment.Moment | null => {
    const numValue = nanosecondsValueToNumber(value);
    return nanosecondsValueToMoment(numValue);
};

export const nanosecondsToDate = (value: number | string | null | undefined): Date | null => {
    const numValue = nanosecondsValueToNumber(value);
    const momentValue = nanosecondsValueToMoment(numValue);
    return momentValue ? momentValue.toDate() : null;
};

export const timeToNanoseconds = (value: moment.Moment | Date | null): number | null => {
    if (value === null) return null;
    const momentValToParse = moment(value);
    const secondsAfterMidnight =
        momentValToParse.hours() * 3600 + momentValToParse.minutes() * 60 + momentValToParse.seconds();
    return secondsAfterMidnight * NANOSEC_IN_SEC;
};

export const momentToNanoseconds = (value: moment.Moment | null): number | null =>
    value ? value.valueOf() * NANOSEC_IN_MILLISEC : null;

export const nanosecondsTimeToFormattedTime = (value: number | null | undefined): string => {
    if (value === NANOSEC_IN_DAY) return '24:00:00';
    if (typeof value === 'number') {
        if (value < 0 || value >= NANOSEC_IN_DAY) {
            return t(keyMap.validations.invalidValue);
        }
        const momentValue = nanosecondsTimeToMomemt(value);
        return momentValue ? momentValue.format(FORMAT_T) : '';
    }
    return '';
};

export const nanosecondsToFormattedDateTime = (value: number | null | undefined): string => {
    if (typeof value === 'number' && value > 0) {
        const durationInMS = moment.duration(Math.floor(value / NANOSEC_IN_MILLISEC), 'milliseconds');
        return moment(durationInMS.as('milliseconds')).format(FORMAT_DT);
    }
    return '';
};

export const momentToFormattedTime = (value: moment.Moment | null | undefined): string =>
    value !== null && value !== undefined ? value.format(FORMAT_T) : '';

// milliseconds since the Unix Epoch
export const millisecondsToFormattedTime = (value: number | null | undefined): string => {
    if (typeof value === 'number') {
        const momentValue = moment(value);
        return momentValue ? momentValue.format(FORMAT_T) : '';
    }
    return '';
};

export const getFormattedDate = (
    value: Date | Moment | null | undefined,
    format?: string,
    isUTC0?: boolean,
): string => {
    if (!Date) return '';
    if (value instanceof Date)
        return !value || value.getTime() === 0
            ? ''
            : moment(value)
                  .utcOffset(isUTC0 ? 0 : getOffset())
                  .format(format || FORMAT_DT);
    if (value instanceof moment) return value.utcOffset(isUTC0 ? 0 : getOffset()).format(FORMAT_DT);
    return '';
};

export const searchDataFormatterOfTzOffset = (value: Date | null | undefined): Date | null =>
    value ? moment(value).add(getOffset(), 'm').toDate() : null;

export const daysOfWeek: {
    short: string[];
    shortLowered: string[];
    long: string[];
    longWorkingDays: string[];
    values: number[];
    fbeClassValues: number[];
} = {
    short: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
    shortLowered: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
    long: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    longWorkingDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    values: [2, 4, 8, 16, 32, 64, 1],
    fbeClassValues: [1, 2, 3, 4, 5, 6, 0],
};

export const monthsOfYear: { short: string[]; long: string[]; values: number[] } = {
    short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    long: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
};

export const rangePickerRanges: Record<string, () => [Moment, Moment]> = {
    [t(keyMap.common.today)]: () => [
        moment().add(getOffset(), 'm').startOf('day'),
        moment().add(getOffset(), 'm').endOf('day'),
    ],
    [t(keyMap.common.twoDays)]: () => [
        moment().add(getOffset(), 'm').add(-1, 'd').startOf('day'),
        moment().add(getOffset(), 'm').endOf('day'),
    ],
    [t(keyMap.common.week)]: () => [
        moment().add(getOffset(), 'm').startOf('week'),
        moment().add(getOffset(), 'm').endOf('day'),
    ],
    [t(keyMap.common.month)]: () => [
        moment().add(getOffset(), 'm').startOf('month'),
        moment().add(getOffset(), 'm').endOf('day'),
    ],
};

export const convertDays: (days: string[]) => DaysOfWeek = (days: string[]) => {
    const res = new DaysOfWeek(0);
    days.forEach((day) => {
        res.setFlags(DaysOfWeek[daysOfWeek.long[daysOfWeek.short.indexOf(day)]]);
    });
    return res;
};

type CurrentDateProps = {
    tickDelayMs?: number;
    tickAction?: (tickDate: Date | string) => void;
    format?: string;
};

export const useCurrentDate = (props?: CurrentDateProps) => {
    const [date, setDate] = useState<string | Date>(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setDate(props?.format ? moment(new Date()).format(props?.format) : new Date());
            if (props?.tickAction) props?.tickAction(date);
        }, props?.tickDelayMs || 1000);
        return () => {
            clearInterval(timer);
        };
    }, [props]);

    return { date };
};

export const getStartOfDayDate = (): Date => moment().add(getOffset(), 'm').startOf('day').toDate();

export const getEndOfDayDate = (): Date => moment().add(getOffset(), 'm').endOf('day').toDate();

export const getDateSeconds = (val: Date | null | undefined): number => (val ? Math.floor(val.valueOf() / 1000) : 0);

export const momentValueFromDate = (date?: Date | null): Moment | null => (date ? moment(date) : null);

export const momentValueFromDateWithOffset = (date?: Date | null): Moment | null =>
    date ? moment(date).utcOffset(getOffset()) : null;

export const momentWithOffset = (date?: Moment | null): Moment | null => (date ? date.utcOffset(getOffset()) : null);

export const monthNumberToMoment = (month?: number | null): moment.Moment | null => {
    if (typeof month !== 'number') return null;
    return moment().month(month - 1); // month:number starts from 1
};

export const getCountOfDaysInMonth = (month: number): number => moment(`2020-${month}`, 'YYYY-M').daysInMonth();

export const timeSorter = (a: moment.Moment | null | undefined, b: moment.Moment | null | undefined): number =>
    baseSorter(a ? a.format(FORMAT_T) : '', b ? b.format(FORMAT_T) : '');

export const isDateInPast = (value?: moment.Moment | Date | null): boolean => {
    if (!value) return false;
    return moment(value).utcOffset(getOffset()).isBefore(moment().utcOffset(getOffset()).startOf('day'));
};

export const formatInputChangesToOffset = (value?: moment.Moment | Date | null): Date | null =>
    value
        ? moment(value)
              .add(getOffset() * -1, 'm')
              .toDate()
        : null;

export const getOffsettedMomentDate = (value: Moment | null | undefined, withOffset?: boolean): Moment | null => {
    if (withOffset) {
        return momentWithOffset(value);
    }
    return value ?? null;
};

export const getDateUTCStringFromLocalDate = (value: Date): string => {
    const timeString = `${moment(value).utc().format(FORMAT_DR_DASH)}T00:00:00.000Z`;
    return timeString;
};
