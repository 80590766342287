
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as feed from '../feed'
import * as history from '../history'
import * as storage from '../storage'
import * as client from '../client'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {PlatformCreateResponse} from './PlatformCreateResponse';
import {FeaturesToggle} from '../core/FeaturesToggle';
import {FieldModelFeaturesToggle} from '../core/FeaturesToggle';

/**
 * PlatformCreateRequest struct
 */
class PlatformCreateRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {number=} sortOrder
   * @param {!string=} name
   * @param {!string=} comment
   * @param {!string=} description
   * @param {!string=} companyName
   * @param {!string=} address
   * @param {UUID=} timezoneId
   * @param {string=} timezoneName
   * @param {number=} rolloverTime
   * @param {number=} deprecated1
   * @param {string=} companyFullName
   * @param {string=} companyEmail
   * @param {string=} companyPhone
   * @param {string=} companyAddress
   * @param {string=} companyWebsite
   * @param {string=} companyDescription
   * @param {!number=} managerLoginRangeOffset
   * @param {!number=} managerLoginRangeSize
   * @param {!number=} accountLoginRangeOffset
   * @param {!number=} accountLoginRangeSize
   * @param {FeaturesToggle=} featuresToggle
   * @param {UUID=} dailyReportTemplateId
   * @param {string=} dailyReportTemplateName
   * @param {UUID=} monthlyReportTemplateId
   * @param {string=} monthlyReportTemplateName
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), sortOrder = null, name = '', comment = '', description = '', companyName = '', address = '', timezoneId = null, timezoneName = null, rolloverTime = null, deprecated1 = null, companyFullName = null, companyEmail = null, companyPhone = null, companyAddress = null, companyWebsite = null, companyDescription = null, managerLoginRangeOffset = new UInt64(0, 0), managerLoginRangeSize = new UInt64(0, 0), accountLoginRangeOffset = new UInt64(0, 0), accountLoginRangeSize = new UInt64(0, 0), featuresToggle = null, dailyReportTemplateId = null, dailyReportTemplateName = null, monthlyReportTemplateId = null, monthlyReportTemplateName = null) {
    super()
    super.copy(parent)
    this.SortOrder = sortOrder
    this.Name = name
    this.Comment = comment
    this.Description = description
    this.CompanyName = companyName
    this.Address = address
    this.TimezoneId = timezoneId
    this.TimezoneName = timezoneName
    this.RolloverTime = rolloverTime
    this.Deprecated1 = deprecated1
    this.CompanyFullName = companyFullName
    this.CompanyEmail = companyEmail
    this.CompanyPhone = companyPhone
    this.CompanyAddress = companyAddress
    this.CompanyWebsite = companyWebsite
    this.CompanyDescription = companyDescription
    this.ManagerLoginRangeOffset = managerLoginRangeOffset
    this.ManagerLoginRangeSize = managerLoginRangeSize
    this.AccountLoginRangeOffset = accountLoginRangeOffset
    this.AccountLoginRangeSize = accountLoginRangeSize
    this.FeaturesToggle = featuresToggle
    this.DailyReportTemplateId = dailyReportTemplateId
    this.DailyReportTemplateName = dailyReportTemplateName
    this.MonthlyReportTemplateId = monthlyReportTemplateId
    this.MonthlyReportTemplateName = monthlyReportTemplateName
  }

  /**
   * Copy struct (shallow copy)
   * @this {!PlatformCreateRequest}
   * @param {!PlatformCreateRequest} other Other struct
   * @returns {!PlatformCreateRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.SortOrder != null) {
      this.SortOrder = other.SortOrder
    } else {
      this.SortOrder = null
    }
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.CompanyName != null) {
      this.CompanyName = other.CompanyName
    } else {
      this.CompanyName = null
    }
    if (other.Address != null) {
      this.Address = other.Address
    } else {
      this.Address = null
    }
    if (other.TimezoneId != null) {
      this.TimezoneId = new UUID(other.TimezoneId)
    } else {
      this.TimezoneId = null
    }
    if (other.TimezoneName != null) {
      this.TimezoneName = other.TimezoneName
    } else {
      this.TimezoneName = null
    }
    if (other.RolloverTime != null) {
      this.RolloverTime = UInt64.fromNumber(other.RolloverTime)
    } else {
      this.RolloverTime = null
    }
    if (other.Deprecated1 != null) {
      this.Deprecated1 = UInt64.fromNumber(other.Deprecated1)
    } else {
      this.Deprecated1 = null
    }
    if (other.CompanyFullName != null) {
      this.CompanyFullName = other.CompanyFullName
    } else {
      this.CompanyFullName = null
    }
    if (other.CompanyEmail != null) {
      this.CompanyEmail = other.CompanyEmail
    } else {
      this.CompanyEmail = null
    }
    if (other.CompanyPhone != null) {
      this.CompanyPhone = other.CompanyPhone
    } else {
      this.CompanyPhone = null
    }
    if (other.CompanyAddress != null) {
      this.CompanyAddress = other.CompanyAddress
    } else {
      this.CompanyAddress = null
    }
    if (other.CompanyWebsite != null) {
      this.CompanyWebsite = other.CompanyWebsite
    } else {
      this.CompanyWebsite = null
    }
    if (other.CompanyDescription != null) {
      this.CompanyDescription = other.CompanyDescription
    } else {
      this.CompanyDescription = null
    }
    if (other.ManagerLoginRangeOffset != null) {
      this.ManagerLoginRangeOffset = UInt64.fromNumber(other.ManagerLoginRangeOffset)
    } else {
      this.ManagerLoginRangeOffset = null
    }
    if (other.ManagerLoginRangeSize != null) {
      this.ManagerLoginRangeSize = UInt64.fromNumber(other.ManagerLoginRangeSize)
    } else {
      this.ManagerLoginRangeSize = null
    }
    if (other.AccountLoginRangeOffset != null) {
      this.AccountLoginRangeOffset = UInt64.fromNumber(other.AccountLoginRangeOffset)
    } else {
      this.AccountLoginRangeOffset = null
    }
    if (other.AccountLoginRangeSize != null) {
      this.AccountLoginRangeSize = UInt64.fromNumber(other.AccountLoginRangeSize)
    } else {
      this.AccountLoginRangeSize = null
    }
    if (other.FeaturesToggle != null) {
      this.FeaturesToggle = FeaturesToggle.fromObject(other.FeaturesToggle)
    } else {
      this.FeaturesToggle = null
    }
    if (other.DailyReportTemplateId != null) {
      this.DailyReportTemplateId = new UUID(other.DailyReportTemplateId)
    } else {
      this.DailyReportTemplateId = null
    }
    if (other.DailyReportTemplateName != null) {
      this.DailyReportTemplateName = other.DailyReportTemplateName
    } else {
      this.DailyReportTemplateName = null
    }
    if (other.MonthlyReportTemplateId != null) {
      this.MonthlyReportTemplateId = new UUID(other.MonthlyReportTemplateId)
    } else {
      this.MonthlyReportTemplateId = null
    }
    if (other.MonthlyReportTemplateName != null) {
      this.MonthlyReportTemplateName = other.MonthlyReportTemplateName
    } else {
      this.MonthlyReportTemplateName = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!PlatformCreateRequest}
   * @returns {!PlatformCreateRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new PlatformCreateRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new PlatformCreateRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!PlatformCreateRequest}
   * @param {!PlatformCreateRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof PlatformCreateRequest)) {
      throw new TypeError('Instance of PlatformCreateRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!PlatformCreateRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      SortOrder: ((this.SortOrder != null) ? this.SortOrder : null),
      Name: ((this.Name != null) ? this.Name : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      Description: ((this.Description != null) ? this.Description : null),
      CompanyName: ((this.CompanyName != null) ? this.CompanyName : null),
      Address: ((this.Address != null) ? this.Address : null),
      TimezoneId: ((this.TimezoneId != null) ? this.TimezoneId.toString() : null),
      TimezoneName: ((this.TimezoneName != null) ? this.TimezoneName : null),
      RolloverTime: ((this.RolloverTime != null) ? this.RolloverTime.toNumber() : null),
      Deprecated1: ((this.Deprecated1 != null) ? this.Deprecated1.toNumber() : null),
      CompanyFullName: ((this.CompanyFullName != null) ? this.CompanyFullName : null),
      CompanyEmail: ((this.CompanyEmail != null) ? this.CompanyEmail : null),
      CompanyPhone: ((this.CompanyPhone != null) ? this.CompanyPhone : null),
      CompanyAddress: ((this.CompanyAddress != null) ? this.CompanyAddress : null),
      CompanyWebsite: ((this.CompanyWebsite != null) ? this.CompanyWebsite : null),
      CompanyDescription: ((this.CompanyDescription != null) ? this.CompanyDescription : null),
      ManagerLoginRangeOffset: ((this.ManagerLoginRangeOffset != null) ? this.ManagerLoginRangeOffset.toNumber() : null),
      ManagerLoginRangeSize: ((this.ManagerLoginRangeSize != null) ? this.ManagerLoginRangeSize.toNumber() : null),
      AccountLoginRangeOffset: ((this.AccountLoginRangeOffset != null) ? this.AccountLoginRangeOffset.toNumber() : null),
      AccountLoginRangeSize: ((this.AccountLoginRangeSize != null) ? this.AccountLoginRangeSize.toNumber() : null),
      FeaturesToggle: ((this.FeaturesToggle != null) ? this.FeaturesToggle : null),
      DailyReportTemplateId: ((this.DailyReportTemplateId != null) ? this.DailyReportTemplateId.toString() : null),
      DailyReportTemplateName: ((this.DailyReportTemplateName != null) ? this.DailyReportTemplateName : null),
      MonthlyReportTemplateId: ((this.MonthlyReportTemplateId != null) ? this.MonthlyReportTemplateId.toString() : null),
      MonthlyReportTemplateName: ((this.MonthlyReportTemplateName != null) ? this.MonthlyReportTemplateName : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return PlatformCreateRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!PlatformCreateRequest} other Object value
   * @returns {!PlatformCreateRequest} Created struct
   */
  static fromObject (other) {
    return new PlatformCreateRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!PlatformCreateRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return PlatformCreateRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!PlatformCreateRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6170
  }
}

export { PlatformCreateRequest };

/**
 * Fast Binary Encoding PlatformCreateRequest field model
 */
class FieldModelPlatformCreateRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.SortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.Name = new fbe.FieldModelString(buffer, this.SortOrder.fbeOffset + this.SortOrder.fbeSize)
    this.Comment = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.Description = new fbe.FieldModelString(buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.CompanyName = new fbe.FieldModelString(buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.Address = new fbe.FieldModelString(buffer, this.CompanyName.fbeOffset + this.CompanyName.fbeSize)
    this.TimezoneId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.Address.fbeOffset + this.Address.fbeSize), buffer, this.Address.fbeOffset + this.Address.fbeSize)
    this.TimezoneName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.TimezoneId.fbeOffset + this.TimezoneId.fbeSize), buffer, this.TimezoneId.fbeOffset + this.TimezoneId.fbeSize)
    this.RolloverTime = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.TimezoneName.fbeOffset + this.TimezoneName.fbeSize), buffer, this.TimezoneName.fbeOffset + this.TimezoneName.fbeSize)
    this.Deprecated1 = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.RolloverTime.fbeOffset + this.RolloverTime.fbeSize), buffer, this.RolloverTime.fbeOffset + this.RolloverTime.fbeSize)
    this.CompanyFullName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.Deprecated1.fbeOffset + this.Deprecated1.fbeSize), buffer, this.Deprecated1.fbeOffset + this.Deprecated1.fbeSize)
    this.CompanyEmail = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.CompanyFullName.fbeOffset + this.CompanyFullName.fbeSize), buffer, this.CompanyFullName.fbeOffset + this.CompanyFullName.fbeSize)
    this.CompanyPhone = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.CompanyEmail.fbeOffset + this.CompanyEmail.fbeSize), buffer, this.CompanyEmail.fbeOffset + this.CompanyEmail.fbeSize)
    this.CompanyAddress = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.CompanyPhone.fbeOffset + this.CompanyPhone.fbeSize), buffer, this.CompanyPhone.fbeOffset + this.CompanyPhone.fbeSize)
    this.CompanyWebsite = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.CompanyAddress.fbeOffset + this.CompanyAddress.fbeSize), buffer, this.CompanyAddress.fbeOffset + this.CompanyAddress.fbeSize)
    this.CompanyDescription = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.CompanyWebsite.fbeOffset + this.CompanyWebsite.fbeSize), buffer, this.CompanyWebsite.fbeOffset + this.CompanyWebsite.fbeSize)
    this.ManagerLoginRangeOffset = new fbe.FieldModelUInt64(buffer, this.CompanyDescription.fbeOffset + this.CompanyDescription.fbeSize)
    this.ManagerLoginRangeSize = new fbe.FieldModelUInt64(buffer, this.ManagerLoginRangeOffset.fbeOffset + this.ManagerLoginRangeOffset.fbeSize)
    this.AccountLoginRangeOffset = new fbe.FieldModelUInt64(buffer, this.ManagerLoginRangeSize.fbeOffset + this.ManagerLoginRangeSize.fbeSize)
    this.AccountLoginRangeSize = new fbe.FieldModelUInt64(buffer, this.AccountLoginRangeOffset.fbeOffset + this.AccountLoginRangeOffset.fbeSize)
    this.FeaturesToggle = new fbe.FieldModelOptional(new FieldModelFeaturesToggle(buffer, this.AccountLoginRangeSize.fbeOffset + this.AccountLoginRangeSize.fbeSize), buffer, this.AccountLoginRangeSize.fbeOffset + this.AccountLoginRangeSize.fbeSize)
    this.DailyReportTemplateId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.FeaturesToggle.fbeOffset + this.FeaturesToggle.fbeSize), buffer, this.FeaturesToggle.fbeOffset + this.FeaturesToggle.fbeSize)
    this.DailyReportTemplateName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.DailyReportTemplateId.fbeOffset + this.DailyReportTemplateId.fbeSize), buffer, this.DailyReportTemplateId.fbeOffset + this.DailyReportTemplateId.fbeSize)
    this.MonthlyReportTemplateId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.DailyReportTemplateName.fbeOffset + this.DailyReportTemplateName.fbeSize), buffer, this.DailyReportTemplateName.fbeOffset + this.DailyReportTemplateName.fbeSize)
    this.MonthlyReportTemplateName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.MonthlyReportTemplateId.fbeOffset + this.MonthlyReportTemplateId.fbeSize), buffer, this.MonthlyReportTemplateId.fbeOffset + this.MonthlyReportTemplateId.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelPlatformCreateRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelPlatformCreateRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelPlatformCreateRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.SortOrder.fbeSize + this.Name.fbeSize + this.Comment.fbeSize + this.Description.fbeSize + this.CompanyName.fbeSize + this.Address.fbeSize + this.TimezoneId.fbeSize + this.TimezoneName.fbeSize + this.RolloverTime.fbeSize + this.Deprecated1.fbeSize + this.CompanyFullName.fbeSize + this.CompanyEmail.fbeSize + this.CompanyPhone.fbeSize + this.CompanyAddress.fbeSize + this.CompanyWebsite.fbeSize + this.CompanyDescription.fbeSize + this.ManagerLoginRangeOffset.fbeSize + this.ManagerLoginRangeSize.fbeSize + this.AccountLoginRangeOffset.fbeSize + this.AccountLoginRangeSize.fbeSize + this.FeaturesToggle.fbeSize + this.DailyReportTemplateId.fbeSize + this.DailyReportTemplateName.fbeSize + this.MonthlyReportTemplateId.fbeSize + this.MonthlyReportTemplateName.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelPlatformCreateRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.SortOrder.fbeExtra + this.Name.fbeExtra + this.Comment.fbeExtra + this.Description.fbeExtra + this.CompanyName.fbeExtra + this.Address.fbeExtra + this.TimezoneId.fbeExtra + this.TimezoneName.fbeExtra + this.RolloverTime.fbeExtra + this.Deprecated1.fbeExtra + this.CompanyFullName.fbeExtra + this.CompanyEmail.fbeExtra + this.CompanyPhone.fbeExtra + this.CompanyAddress.fbeExtra + this.CompanyWebsite.fbeExtra + this.CompanyDescription.fbeExtra + this.ManagerLoginRangeOffset.fbeExtra + this.ManagerLoginRangeSize.fbeExtra + this.AccountLoginRangeOffset.fbeExtra + this.AccountLoginRangeSize.fbeExtra + this.FeaturesToggle.fbeExtra + this.DailyReportTemplateId.fbeExtra + this.DailyReportTemplateName.fbeExtra + this.MonthlyReportTemplateId.fbeExtra + this.MonthlyReportTemplateName.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelPlatformCreateRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelPlatformCreateRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelPlatformCreateRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6170
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelPlatformCreateRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelPlatformCreateRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.CompanyName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CompanyName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyName.fbeSize

    if ((fbeCurrentSize + this.Address.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Address.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Address.fbeSize

    if ((fbeCurrentSize + this.TimezoneId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TimezoneId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimezoneId.fbeSize

    if ((fbeCurrentSize + this.TimezoneName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TimezoneName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimezoneName.fbeSize

    if ((fbeCurrentSize + this.RolloverTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RolloverTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RolloverTime.fbeSize

    if ((fbeCurrentSize + this.Deprecated1.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated1.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated1.fbeSize

    if ((fbeCurrentSize + this.CompanyFullName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CompanyFullName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyFullName.fbeSize

    if ((fbeCurrentSize + this.CompanyEmail.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CompanyEmail.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyEmail.fbeSize

    if ((fbeCurrentSize + this.CompanyPhone.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CompanyPhone.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyPhone.fbeSize

    if ((fbeCurrentSize + this.CompanyAddress.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CompanyAddress.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyAddress.fbeSize

    if ((fbeCurrentSize + this.CompanyWebsite.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CompanyWebsite.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyWebsite.fbeSize

    if ((fbeCurrentSize + this.CompanyDescription.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CompanyDescription.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyDescription.fbeSize

    if ((fbeCurrentSize + this.ManagerLoginRangeOffset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ManagerLoginRangeOffset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerLoginRangeOffset.fbeSize

    if ((fbeCurrentSize + this.ManagerLoginRangeSize.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ManagerLoginRangeSize.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerLoginRangeSize.fbeSize

    if ((fbeCurrentSize + this.AccountLoginRangeOffset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountLoginRangeOffset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountLoginRangeOffset.fbeSize

    if ((fbeCurrentSize + this.AccountLoginRangeSize.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountLoginRangeSize.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountLoginRangeSize.fbeSize

    if ((fbeCurrentSize + this.FeaturesToggle.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.FeaturesToggle.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeaturesToggle.fbeSize

    if ((fbeCurrentSize + this.DailyReportTemplateId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DailyReportTemplateId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DailyReportTemplateId.fbeSize

    if ((fbeCurrentSize + this.DailyReportTemplateName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DailyReportTemplateName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DailyReportTemplateName.fbeSize

    if ((fbeCurrentSize + this.MonthlyReportTemplateId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MonthlyReportTemplateId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MonthlyReportTemplateId.fbeSize

    if ((fbeCurrentSize + this.MonthlyReportTemplateName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MonthlyReportTemplateName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MonthlyReportTemplateName.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelPlatformCreateRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelPlatformCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelPlatformCreateRequest}
   * @param {!PlatformCreateRequest} fbeValue Default value, defaults is new PlatformCreateRequest()
   * @returns {!PlatformCreateRequest} PlatformCreateRequest value
   */
  get (fbeValue = new PlatformCreateRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelPlatformCreateRequest}
   * @param {!PlatformCreateRequest} fbeValue PlatformCreateRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.SortOrder = this.SortOrder.get()
    } else {
      fbeValue.SortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.CompanyName.fbeSize) <= fbeStructSize) {
      fbeValue.CompanyName = this.CompanyName.get()
    } else {
      fbeValue.CompanyName = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyName.fbeSize

    if ((fbeCurrentSize + this.Address.fbeSize) <= fbeStructSize) {
      fbeValue.Address = this.Address.get()
    } else {
      fbeValue.Address = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Address.fbeSize

    if ((fbeCurrentSize + this.TimezoneId.fbeSize) <= fbeStructSize) {
      fbeValue.TimezoneId = this.TimezoneId.get()
    } else {
      fbeValue.TimezoneId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimezoneId.fbeSize

    if ((fbeCurrentSize + this.TimezoneName.fbeSize) <= fbeStructSize) {
      fbeValue.TimezoneName = this.TimezoneName.get()
    } else {
      fbeValue.TimezoneName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TimezoneName.fbeSize

    if ((fbeCurrentSize + this.RolloverTime.fbeSize) <= fbeStructSize) {
      fbeValue.RolloverTime = this.RolloverTime.get()
    } else {
      fbeValue.RolloverTime = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RolloverTime.fbeSize

    if ((fbeCurrentSize + this.Deprecated1.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated1 = this.Deprecated1.get()
    } else {
      fbeValue.Deprecated1 = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated1.fbeSize

    if ((fbeCurrentSize + this.CompanyFullName.fbeSize) <= fbeStructSize) {
      fbeValue.CompanyFullName = this.CompanyFullName.get()
    } else {
      fbeValue.CompanyFullName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyFullName.fbeSize

    if ((fbeCurrentSize + this.CompanyEmail.fbeSize) <= fbeStructSize) {
      fbeValue.CompanyEmail = this.CompanyEmail.get()
    } else {
      fbeValue.CompanyEmail = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyEmail.fbeSize

    if ((fbeCurrentSize + this.CompanyPhone.fbeSize) <= fbeStructSize) {
      fbeValue.CompanyPhone = this.CompanyPhone.get()
    } else {
      fbeValue.CompanyPhone = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyPhone.fbeSize

    if ((fbeCurrentSize + this.CompanyAddress.fbeSize) <= fbeStructSize) {
      fbeValue.CompanyAddress = this.CompanyAddress.get()
    } else {
      fbeValue.CompanyAddress = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyAddress.fbeSize

    if ((fbeCurrentSize + this.CompanyWebsite.fbeSize) <= fbeStructSize) {
      fbeValue.CompanyWebsite = this.CompanyWebsite.get()
    } else {
      fbeValue.CompanyWebsite = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyWebsite.fbeSize

    if ((fbeCurrentSize + this.CompanyDescription.fbeSize) <= fbeStructSize) {
      fbeValue.CompanyDescription = this.CompanyDescription.get()
    } else {
      fbeValue.CompanyDescription = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CompanyDescription.fbeSize

    if ((fbeCurrentSize + this.ManagerLoginRangeOffset.fbeSize) <= fbeStructSize) {
      fbeValue.ManagerLoginRangeOffset = this.ManagerLoginRangeOffset.get()
    } else {
      fbeValue.ManagerLoginRangeOffset = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerLoginRangeOffset.fbeSize

    if ((fbeCurrentSize + this.ManagerLoginRangeSize.fbeSize) <= fbeStructSize) {
      fbeValue.ManagerLoginRangeSize = this.ManagerLoginRangeSize.get()
    } else {
      fbeValue.ManagerLoginRangeSize = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerLoginRangeSize.fbeSize

    if ((fbeCurrentSize + this.AccountLoginRangeOffset.fbeSize) <= fbeStructSize) {
      fbeValue.AccountLoginRangeOffset = this.AccountLoginRangeOffset.get()
    } else {
      fbeValue.AccountLoginRangeOffset = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountLoginRangeOffset.fbeSize

    if ((fbeCurrentSize + this.AccountLoginRangeSize.fbeSize) <= fbeStructSize) {
      fbeValue.AccountLoginRangeSize = this.AccountLoginRangeSize.get()
    } else {
      fbeValue.AccountLoginRangeSize = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountLoginRangeSize.fbeSize

    if ((fbeCurrentSize + this.FeaturesToggle.fbeSize) <= fbeStructSize) {
      fbeValue.FeaturesToggle = this.FeaturesToggle.get()
    } else {
      fbeValue.FeaturesToggle = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.FeaturesToggle.fbeSize

    if ((fbeCurrentSize + this.DailyReportTemplateId.fbeSize) <= fbeStructSize) {
      fbeValue.DailyReportTemplateId = this.DailyReportTemplateId.get()
    } else {
      fbeValue.DailyReportTemplateId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DailyReportTemplateId.fbeSize

    if ((fbeCurrentSize + this.DailyReportTemplateName.fbeSize) <= fbeStructSize) {
      fbeValue.DailyReportTemplateName = this.DailyReportTemplateName.get()
    } else {
      fbeValue.DailyReportTemplateName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DailyReportTemplateName.fbeSize

    if ((fbeCurrentSize + this.MonthlyReportTemplateId.fbeSize) <= fbeStructSize) {
      fbeValue.MonthlyReportTemplateId = this.MonthlyReportTemplateId.get()
    } else {
      fbeValue.MonthlyReportTemplateId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MonthlyReportTemplateId.fbeSize

    if ((fbeCurrentSize + this.MonthlyReportTemplateName.fbeSize) <= fbeStructSize) {
      fbeValue.MonthlyReportTemplateName = this.MonthlyReportTemplateName.get()
    } else {
      fbeValue.MonthlyReportTemplateName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MonthlyReportTemplateName.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelPlatformCreateRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelPlatformCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelPlatformCreateRequest}
   * @param {!PlatformCreateRequest} fbeValue PlatformCreateRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelPlatformCreateRequest}
   * @param {PlatformCreateRequest} fbeValue PlatformCreateRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.SortOrder.set(fbeValue.SortOrder)
    this.Name.set(fbeValue.Name)
    this.Comment.set(fbeValue.Comment)
    this.Description.set(fbeValue.Description)
    this.CompanyName.set(fbeValue.CompanyName)
    this.Address.set(fbeValue.Address)
    this.TimezoneId.set(fbeValue.TimezoneId)
    this.TimezoneName.set(fbeValue.TimezoneName)
    this.RolloverTime.set(fbeValue.RolloverTime)
    this.Deprecated1.set(fbeValue.Deprecated1)
    this.CompanyFullName.set(fbeValue.CompanyFullName)
    this.CompanyEmail.set(fbeValue.CompanyEmail)
    this.CompanyPhone.set(fbeValue.CompanyPhone)
    this.CompanyAddress.set(fbeValue.CompanyAddress)
    this.CompanyWebsite.set(fbeValue.CompanyWebsite)
    this.CompanyDescription.set(fbeValue.CompanyDescription)
    this.ManagerLoginRangeOffset.set(fbeValue.ManagerLoginRangeOffset)
    this.ManagerLoginRangeSize.set(fbeValue.ManagerLoginRangeSize)
    this.AccountLoginRangeOffset.set(fbeValue.AccountLoginRangeOffset)
    this.AccountLoginRangeSize.set(fbeValue.AccountLoginRangeSize)
    this.FeaturesToggle.set(fbeValue.FeaturesToggle)
    this.DailyReportTemplateId.set(fbeValue.DailyReportTemplateId)
    this.DailyReportTemplateName.set(fbeValue.DailyReportTemplateName)
    this.MonthlyReportTemplateId.set(fbeValue.MonthlyReportTemplateId)
    this.MonthlyReportTemplateName.set(fbeValue.MonthlyReportTemplateName)
  }
}

export { FieldModelPlatformCreateRequest };

/**
 * Fast Binary Encoding PlatformCreateRequest model
 */
class PlatformCreateRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelPlatformCreateRequest(this.buffer, 4)
  }

  /**
   * Get the PlatformCreateRequest model
   * @this {!PlatformCreateRequestModel}
   * @returns {!FieldModelPlatformCreateRequest} model PlatformCreateRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!PlatformCreateRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!PlatformCreateRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return PlatformCreateRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!PlatformCreateRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelPlatformCreateRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!PlatformCreateRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!PlatformCreateRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!PlatformCreateRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!PlatformCreateRequestModel}
   * @param {!PlatformCreateRequest} value PlatformCreateRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!PlatformCreateRequestModel}
   * @param {!PlatformCreateRequest} value PlatformCreateRequest value, defaults is new PlatformCreateRequest()
   * @return {!object} Deserialized PlatformCreateRequest value and its size
   */
  deserialize (value = new PlatformCreateRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new PlatformCreateRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new PlatformCreateRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!PlatformCreateRequestModel}
   * @param {!number} prev Previous PlatformCreateRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { PlatformCreateRequestModel };
PlatformCreateRequest.__has_response = true;
PlatformCreateRequest.__response_class = PlatformCreateResponse;
