
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as feed from '../feed'
import * as history from '../history'
import * as storage from '../storage'
import * as client from '../client'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {PlatformModifyResponse} from './PlatformModifyResponse';
import {FeaturesToggle} from '../core/FeaturesToggle';
import {FieldModelFeaturesToggle} from '../core/FeaturesToggle';

/**
 * PlatformModifyRequest struct
 */
class PlatformModifyRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {UUID=} platformId
   * @param {string=} platformName
   * @param {number=} newSortOrder
   * @param {string=} newName
   * @param {string=} newComment
   * @param {string=} newDescription
   * @param {string=} newCompanyName
   * @param {string=} newAddress
   * @param {UUID=} newTimezoneId
   * @param {string=} newTimezoneName
   * @param {number=} newRolloverTime
   * @param {!boolean=} newRolloverTimeReset
   * @param {number=} deprecated1
   * @param {!boolean=} deprecated1Reset
   * @param {string=} newCompanyFullName
   * @param {string=} newCompanyEmail
   * @param {string=} newCompanyPhone
   * @param {string=} newCompanyAddress
   * @param {string=} newCompanyWebsite
   * @param {string=} newCompanyDescription
   * @param {number=} newManagerLoginRangeOffset
   * @param {number=} newManagerLoginRangeSize
   * @param {number=} newAccountLoginRangeOffset
   * @param {number=} newAccountLoginRangeSize
   * @param {FeaturesToggle=} newFeaturesToggle
   * @param {UUID=} newDailyReportTemplateId
   * @param {!boolean=} newDailyReportTemplateIdReset
   * @param {string=} newDailyReportTemplateName
   * @param {UUID=} newMonthlyReportTemplateId
   * @param {!boolean=} newMonthlyReportTemplateIdReset
   * @param {string=} newMonthlyReportTemplateName
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), platformId = null, platformName = null, newSortOrder = null, newName = null, newComment = null, newDescription = null, newCompanyName = null, newAddress = null, newTimezoneId = null, newTimezoneName = null, newRolloverTime = null, newRolloverTimeReset = false, deprecated1 = null, deprecated1Reset = false, newCompanyFullName = null, newCompanyEmail = null, newCompanyPhone = null, newCompanyAddress = null, newCompanyWebsite = null, newCompanyDescription = null, newManagerLoginRangeOffset = null, newManagerLoginRangeSize = null, newAccountLoginRangeOffset = null, newAccountLoginRangeSize = null, newFeaturesToggle = null, newDailyReportTemplateId = null, newDailyReportTemplateIdReset = false, newDailyReportTemplateName = null, newMonthlyReportTemplateId = null, newMonthlyReportTemplateIdReset = false, newMonthlyReportTemplateName = null) {
    super()
    super.copy(parent)
    this.PlatformId = platformId
    this.PlatformName = platformName
    this.NewSortOrder = newSortOrder
    this.NewName = newName
    this.NewComment = newComment
    this.NewDescription = newDescription
    this.NewCompanyName = newCompanyName
    this.NewAddress = newAddress
    this.NewTimezoneId = newTimezoneId
    this.NewTimezoneName = newTimezoneName
    this.NewRolloverTime = newRolloverTime
    this.NewRolloverTimeReset = newRolloverTimeReset
    this.Deprecated1 = deprecated1
    this.Deprecated1Reset = deprecated1Reset
    this.NewCompanyFullName = newCompanyFullName
    this.NewCompanyEmail = newCompanyEmail
    this.NewCompanyPhone = newCompanyPhone
    this.NewCompanyAddress = newCompanyAddress
    this.NewCompanyWebsite = newCompanyWebsite
    this.NewCompanyDescription = newCompanyDescription
    this.NewManagerLoginRangeOffset = newManagerLoginRangeOffset
    this.NewManagerLoginRangeSize = newManagerLoginRangeSize
    this.NewAccountLoginRangeOffset = newAccountLoginRangeOffset
    this.NewAccountLoginRangeSize = newAccountLoginRangeSize
    this.NewFeaturesToggle = newFeaturesToggle
    this.NewDailyReportTemplateId = newDailyReportTemplateId
    this.NewDailyReportTemplateIdReset = newDailyReportTemplateIdReset
    this.NewDailyReportTemplateName = newDailyReportTemplateName
    this.NewMonthlyReportTemplateId = newMonthlyReportTemplateId
    this.NewMonthlyReportTemplateIdReset = newMonthlyReportTemplateIdReset
    this.NewMonthlyReportTemplateName = newMonthlyReportTemplateName
  }

  /**
   * Copy struct (shallow copy)
   * @this {!PlatformModifyRequest}
   * @param {!PlatformModifyRequest} other Other struct
   * @returns {!PlatformModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.PlatformId != null) {
      this.PlatformId = new UUID(other.PlatformId)
    } else {
      this.PlatformId = null
    }
    if (other.PlatformName != null) {
      this.PlatformName = other.PlatformName
    } else {
      this.PlatformName = null
    }
    if (other.NewSortOrder != null) {
      this.NewSortOrder = other.NewSortOrder
    } else {
      this.NewSortOrder = null
    }
    if (other.NewName != null) {
      this.NewName = other.NewName
    } else {
      this.NewName = null
    }
    if (other.NewComment != null) {
      this.NewComment = other.NewComment
    } else {
      this.NewComment = null
    }
    if (other.NewDescription != null) {
      this.NewDescription = other.NewDescription
    } else {
      this.NewDescription = null
    }
    if (other.NewCompanyName != null) {
      this.NewCompanyName = other.NewCompanyName
    } else {
      this.NewCompanyName = null
    }
    if (other.NewAddress != null) {
      this.NewAddress = other.NewAddress
    } else {
      this.NewAddress = null
    }
    if (other.NewTimezoneId != null) {
      this.NewTimezoneId = new UUID(other.NewTimezoneId)
    } else {
      this.NewTimezoneId = null
    }
    if (other.NewTimezoneName != null) {
      this.NewTimezoneName = other.NewTimezoneName
    } else {
      this.NewTimezoneName = null
    }
    if (other.NewRolloverTime != null) {
      this.NewRolloverTime = UInt64.fromNumber(other.NewRolloverTime)
    } else {
      this.NewRolloverTime = null
    }
    if (other.NewRolloverTimeReset != null) {
      this.NewRolloverTimeReset = other.NewRolloverTimeReset
    } else {
      this.NewRolloverTimeReset = null
    }
    if (other.Deprecated1 != null) {
      this.Deprecated1 = UInt64.fromNumber(other.Deprecated1)
    } else {
      this.Deprecated1 = null
    }
    if (other.Deprecated1Reset != null) {
      this.Deprecated1Reset = other.Deprecated1Reset
    } else {
      this.Deprecated1Reset = null
    }
    if (other.NewCompanyFullName != null) {
      this.NewCompanyFullName = other.NewCompanyFullName
    } else {
      this.NewCompanyFullName = null
    }
    if (other.NewCompanyEmail != null) {
      this.NewCompanyEmail = other.NewCompanyEmail
    } else {
      this.NewCompanyEmail = null
    }
    if (other.NewCompanyPhone != null) {
      this.NewCompanyPhone = other.NewCompanyPhone
    } else {
      this.NewCompanyPhone = null
    }
    if (other.NewCompanyAddress != null) {
      this.NewCompanyAddress = other.NewCompanyAddress
    } else {
      this.NewCompanyAddress = null
    }
    if (other.NewCompanyWebsite != null) {
      this.NewCompanyWebsite = other.NewCompanyWebsite
    } else {
      this.NewCompanyWebsite = null
    }
    if (other.NewCompanyDescription != null) {
      this.NewCompanyDescription = other.NewCompanyDescription
    } else {
      this.NewCompanyDescription = null
    }
    if (other.NewManagerLoginRangeOffset != null) {
      this.NewManagerLoginRangeOffset = UInt64.fromNumber(other.NewManagerLoginRangeOffset)
    } else {
      this.NewManagerLoginRangeOffset = null
    }
    if (other.NewManagerLoginRangeSize != null) {
      this.NewManagerLoginRangeSize = UInt64.fromNumber(other.NewManagerLoginRangeSize)
    } else {
      this.NewManagerLoginRangeSize = null
    }
    if (other.NewAccountLoginRangeOffset != null) {
      this.NewAccountLoginRangeOffset = UInt64.fromNumber(other.NewAccountLoginRangeOffset)
    } else {
      this.NewAccountLoginRangeOffset = null
    }
    if (other.NewAccountLoginRangeSize != null) {
      this.NewAccountLoginRangeSize = UInt64.fromNumber(other.NewAccountLoginRangeSize)
    } else {
      this.NewAccountLoginRangeSize = null
    }
    if (other.NewFeaturesToggle != null) {
      this.NewFeaturesToggle = FeaturesToggle.fromObject(other.NewFeaturesToggle)
    } else {
      this.NewFeaturesToggle = null
    }
    if (other.NewDailyReportTemplateId != null) {
      this.NewDailyReportTemplateId = new UUID(other.NewDailyReportTemplateId)
    } else {
      this.NewDailyReportTemplateId = null
    }
    if (other.NewDailyReportTemplateIdReset != null) {
      this.NewDailyReportTemplateIdReset = other.NewDailyReportTemplateIdReset
    } else {
      this.NewDailyReportTemplateIdReset = null
    }
    if (other.NewDailyReportTemplateName != null) {
      this.NewDailyReportTemplateName = other.NewDailyReportTemplateName
    } else {
      this.NewDailyReportTemplateName = null
    }
    if (other.NewMonthlyReportTemplateId != null) {
      this.NewMonthlyReportTemplateId = new UUID(other.NewMonthlyReportTemplateId)
    } else {
      this.NewMonthlyReportTemplateId = null
    }
    if (other.NewMonthlyReportTemplateIdReset != null) {
      this.NewMonthlyReportTemplateIdReset = other.NewMonthlyReportTemplateIdReset
    } else {
      this.NewMonthlyReportTemplateIdReset = null
    }
    if (other.NewMonthlyReportTemplateName != null) {
      this.NewMonthlyReportTemplateName = other.NewMonthlyReportTemplateName
    } else {
      this.NewMonthlyReportTemplateName = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!PlatformModifyRequest}
   * @returns {!PlatformModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new PlatformModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new PlatformModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!PlatformModifyRequest}
   * @param {!PlatformModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof PlatformModifyRequest)) {
      throw new TypeError('Instance of PlatformModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!PlatformModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      PlatformId: ((this.PlatformId != null) ? this.PlatformId.toString() : null),
      PlatformName: ((this.PlatformName != null) ? this.PlatformName : null),
      NewSortOrder: ((this.NewSortOrder != null) ? this.NewSortOrder : null),
      NewName: ((this.NewName != null) ? this.NewName : null),
      NewComment: ((this.NewComment != null) ? this.NewComment : null),
      NewDescription: ((this.NewDescription != null) ? this.NewDescription : null),
      NewCompanyName: ((this.NewCompanyName != null) ? this.NewCompanyName : null),
      NewAddress: ((this.NewAddress != null) ? this.NewAddress : null),
      NewTimezoneId: ((this.NewTimezoneId != null) ? this.NewTimezoneId.toString() : null),
      NewTimezoneName: ((this.NewTimezoneName != null) ? this.NewTimezoneName : null),
      NewRolloverTime: ((this.NewRolloverTime != null) ? this.NewRolloverTime.toNumber() : null),
      NewRolloverTimeReset: ((this.NewRolloverTimeReset != null) ? this.NewRolloverTimeReset : null),
      Deprecated1: ((this.Deprecated1 != null) ? this.Deprecated1.toNumber() : null),
      Deprecated1Reset: ((this.Deprecated1Reset != null) ? this.Deprecated1Reset : null),
      NewCompanyFullName: ((this.NewCompanyFullName != null) ? this.NewCompanyFullName : null),
      NewCompanyEmail: ((this.NewCompanyEmail != null) ? this.NewCompanyEmail : null),
      NewCompanyPhone: ((this.NewCompanyPhone != null) ? this.NewCompanyPhone : null),
      NewCompanyAddress: ((this.NewCompanyAddress != null) ? this.NewCompanyAddress : null),
      NewCompanyWebsite: ((this.NewCompanyWebsite != null) ? this.NewCompanyWebsite : null),
      NewCompanyDescription: ((this.NewCompanyDescription != null) ? this.NewCompanyDescription : null),
      NewManagerLoginRangeOffset: ((this.NewManagerLoginRangeOffset != null) ? this.NewManagerLoginRangeOffset.toNumber() : null),
      NewManagerLoginRangeSize: ((this.NewManagerLoginRangeSize != null) ? this.NewManagerLoginRangeSize.toNumber() : null),
      NewAccountLoginRangeOffset: ((this.NewAccountLoginRangeOffset != null) ? this.NewAccountLoginRangeOffset.toNumber() : null),
      NewAccountLoginRangeSize: ((this.NewAccountLoginRangeSize != null) ? this.NewAccountLoginRangeSize.toNumber() : null),
      NewFeaturesToggle: ((this.NewFeaturesToggle != null) ? this.NewFeaturesToggle : null),
      NewDailyReportTemplateId: ((this.NewDailyReportTemplateId != null) ? this.NewDailyReportTemplateId.toString() : null),
      NewDailyReportTemplateIdReset: ((this.NewDailyReportTemplateIdReset != null) ? this.NewDailyReportTemplateIdReset : null),
      NewDailyReportTemplateName: ((this.NewDailyReportTemplateName != null) ? this.NewDailyReportTemplateName : null),
      NewMonthlyReportTemplateId: ((this.NewMonthlyReportTemplateId != null) ? this.NewMonthlyReportTemplateId.toString() : null),
      NewMonthlyReportTemplateIdReset: ((this.NewMonthlyReportTemplateIdReset != null) ? this.NewMonthlyReportTemplateIdReset : null),
      NewMonthlyReportTemplateName: ((this.NewMonthlyReportTemplateName != null) ? this.NewMonthlyReportTemplateName : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return PlatformModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!PlatformModifyRequest} other Object value
   * @returns {!PlatformModifyRequest} Created struct
   */
  static fromObject (other) {
    return new PlatformModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!PlatformModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return PlatformModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!PlatformModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6172
  }
}

export { PlatformModifyRequest };

/**
 * Fast Binary Encoding PlatformModifyRequest field model
 */
class FieldModelPlatformModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.PlatformId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.PlatformName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.PlatformId.fbeOffset + this.PlatformId.fbeSize), buffer, this.PlatformId.fbeOffset + this.PlatformId.fbeSize)
    this.NewSortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.PlatformName.fbeOffset + this.PlatformName.fbeSize), buffer, this.PlatformName.fbeOffset + this.PlatformName.fbeSize)
    this.NewName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize), buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize)
    this.NewComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewName.fbeOffset + this.NewName.fbeSize), buffer, this.NewName.fbeOffset + this.NewName.fbeSize)
    this.NewDescription = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize), buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize)
    this.NewCompanyName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize), buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize)
    this.NewAddress = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewCompanyName.fbeOffset + this.NewCompanyName.fbeSize), buffer, this.NewCompanyName.fbeOffset + this.NewCompanyName.fbeSize)
    this.NewTimezoneId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.NewAddress.fbeOffset + this.NewAddress.fbeSize), buffer, this.NewAddress.fbeOffset + this.NewAddress.fbeSize)
    this.NewTimezoneName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewTimezoneId.fbeOffset + this.NewTimezoneId.fbeSize), buffer, this.NewTimezoneId.fbeOffset + this.NewTimezoneId.fbeSize)
    this.NewRolloverTime = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewTimezoneName.fbeOffset + this.NewTimezoneName.fbeSize), buffer, this.NewTimezoneName.fbeOffset + this.NewTimezoneName.fbeSize)
    this.NewRolloverTimeReset = new fbe.FieldModelBool(buffer, this.NewRolloverTime.fbeOffset + this.NewRolloverTime.fbeSize)
    this.Deprecated1 = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewRolloverTimeReset.fbeOffset + this.NewRolloverTimeReset.fbeSize), buffer, this.NewRolloverTimeReset.fbeOffset + this.NewRolloverTimeReset.fbeSize)
    this.Deprecated1Reset = new fbe.FieldModelBool(buffer, this.Deprecated1.fbeOffset + this.Deprecated1.fbeSize)
    this.NewCompanyFullName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.Deprecated1Reset.fbeOffset + this.Deprecated1Reset.fbeSize), buffer, this.Deprecated1Reset.fbeOffset + this.Deprecated1Reset.fbeSize)
    this.NewCompanyEmail = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewCompanyFullName.fbeOffset + this.NewCompanyFullName.fbeSize), buffer, this.NewCompanyFullName.fbeOffset + this.NewCompanyFullName.fbeSize)
    this.NewCompanyPhone = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewCompanyEmail.fbeOffset + this.NewCompanyEmail.fbeSize), buffer, this.NewCompanyEmail.fbeOffset + this.NewCompanyEmail.fbeSize)
    this.NewCompanyAddress = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewCompanyPhone.fbeOffset + this.NewCompanyPhone.fbeSize), buffer, this.NewCompanyPhone.fbeOffset + this.NewCompanyPhone.fbeSize)
    this.NewCompanyWebsite = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewCompanyAddress.fbeOffset + this.NewCompanyAddress.fbeSize), buffer, this.NewCompanyAddress.fbeOffset + this.NewCompanyAddress.fbeSize)
    this.NewCompanyDescription = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewCompanyWebsite.fbeOffset + this.NewCompanyWebsite.fbeSize), buffer, this.NewCompanyWebsite.fbeOffset + this.NewCompanyWebsite.fbeSize)
    this.NewManagerLoginRangeOffset = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewCompanyDescription.fbeOffset + this.NewCompanyDescription.fbeSize), buffer, this.NewCompanyDescription.fbeOffset + this.NewCompanyDescription.fbeSize)
    this.NewManagerLoginRangeSize = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewManagerLoginRangeOffset.fbeOffset + this.NewManagerLoginRangeOffset.fbeSize), buffer, this.NewManagerLoginRangeOffset.fbeOffset + this.NewManagerLoginRangeOffset.fbeSize)
    this.NewAccountLoginRangeOffset = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewManagerLoginRangeSize.fbeOffset + this.NewManagerLoginRangeSize.fbeSize), buffer, this.NewManagerLoginRangeSize.fbeOffset + this.NewManagerLoginRangeSize.fbeSize)
    this.NewAccountLoginRangeSize = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewAccountLoginRangeOffset.fbeOffset + this.NewAccountLoginRangeOffset.fbeSize), buffer, this.NewAccountLoginRangeOffset.fbeOffset + this.NewAccountLoginRangeOffset.fbeSize)
    this.NewFeaturesToggle = new fbe.FieldModelOptional(new FieldModelFeaturesToggle(buffer, this.NewAccountLoginRangeSize.fbeOffset + this.NewAccountLoginRangeSize.fbeSize), buffer, this.NewAccountLoginRangeSize.fbeOffset + this.NewAccountLoginRangeSize.fbeSize)
    this.NewDailyReportTemplateId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.NewFeaturesToggle.fbeOffset + this.NewFeaturesToggle.fbeSize), buffer, this.NewFeaturesToggle.fbeOffset + this.NewFeaturesToggle.fbeSize)
    this.NewDailyReportTemplateIdReset = new fbe.FieldModelBool(buffer, this.NewDailyReportTemplateId.fbeOffset + this.NewDailyReportTemplateId.fbeSize)
    this.NewDailyReportTemplateName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewDailyReportTemplateIdReset.fbeOffset + this.NewDailyReportTemplateIdReset.fbeSize), buffer, this.NewDailyReportTemplateIdReset.fbeOffset + this.NewDailyReportTemplateIdReset.fbeSize)
    this.NewMonthlyReportTemplateId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.NewDailyReportTemplateName.fbeOffset + this.NewDailyReportTemplateName.fbeSize), buffer, this.NewDailyReportTemplateName.fbeOffset + this.NewDailyReportTemplateName.fbeSize)
    this.NewMonthlyReportTemplateIdReset = new fbe.FieldModelBool(buffer, this.NewMonthlyReportTemplateId.fbeOffset + this.NewMonthlyReportTemplateId.fbeSize)
    this.NewMonthlyReportTemplateName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewMonthlyReportTemplateIdReset.fbeOffset + this.NewMonthlyReportTemplateIdReset.fbeSize), buffer, this.NewMonthlyReportTemplateIdReset.fbeOffset + this.NewMonthlyReportTemplateIdReset.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelPlatformModifyRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelPlatformModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelPlatformModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.PlatformId.fbeSize + this.PlatformName.fbeSize + this.NewSortOrder.fbeSize + this.NewName.fbeSize + this.NewComment.fbeSize + this.NewDescription.fbeSize + this.NewCompanyName.fbeSize + this.NewAddress.fbeSize + this.NewTimezoneId.fbeSize + this.NewTimezoneName.fbeSize + this.NewRolloverTime.fbeSize + this.NewRolloverTimeReset.fbeSize + this.Deprecated1.fbeSize + this.Deprecated1Reset.fbeSize + this.NewCompanyFullName.fbeSize + this.NewCompanyEmail.fbeSize + this.NewCompanyPhone.fbeSize + this.NewCompanyAddress.fbeSize + this.NewCompanyWebsite.fbeSize + this.NewCompanyDescription.fbeSize + this.NewManagerLoginRangeOffset.fbeSize + this.NewManagerLoginRangeSize.fbeSize + this.NewAccountLoginRangeOffset.fbeSize + this.NewAccountLoginRangeSize.fbeSize + this.NewFeaturesToggle.fbeSize + this.NewDailyReportTemplateId.fbeSize + this.NewDailyReportTemplateIdReset.fbeSize + this.NewDailyReportTemplateName.fbeSize + this.NewMonthlyReportTemplateId.fbeSize + this.NewMonthlyReportTemplateIdReset.fbeSize + this.NewMonthlyReportTemplateName.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelPlatformModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.PlatformId.fbeExtra + this.PlatformName.fbeExtra + this.NewSortOrder.fbeExtra + this.NewName.fbeExtra + this.NewComment.fbeExtra + this.NewDescription.fbeExtra + this.NewCompanyName.fbeExtra + this.NewAddress.fbeExtra + this.NewTimezoneId.fbeExtra + this.NewTimezoneName.fbeExtra + this.NewRolloverTime.fbeExtra + this.NewRolloverTimeReset.fbeExtra + this.Deprecated1.fbeExtra + this.Deprecated1Reset.fbeExtra + this.NewCompanyFullName.fbeExtra + this.NewCompanyEmail.fbeExtra + this.NewCompanyPhone.fbeExtra + this.NewCompanyAddress.fbeExtra + this.NewCompanyWebsite.fbeExtra + this.NewCompanyDescription.fbeExtra + this.NewManagerLoginRangeOffset.fbeExtra + this.NewManagerLoginRangeSize.fbeExtra + this.NewAccountLoginRangeOffset.fbeExtra + this.NewAccountLoginRangeSize.fbeExtra + this.NewFeaturesToggle.fbeExtra + this.NewDailyReportTemplateId.fbeExtra + this.NewDailyReportTemplateIdReset.fbeExtra + this.NewDailyReportTemplateName.fbeExtra + this.NewMonthlyReportTemplateId.fbeExtra + this.NewMonthlyReportTemplateIdReset.fbeExtra + this.NewMonthlyReportTemplateName.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelPlatformModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelPlatformModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelPlatformModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6172
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelPlatformModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelPlatformModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.PlatformId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PlatformId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformId.fbeSize

    if ((fbeCurrentSize + this.PlatformName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PlatformName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformName.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDescription.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewCompanyName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewCompanyName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCompanyName.fbeSize

    if ((fbeCurrentSize + this.NewAddress.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAddress.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAddress.fbeSize

    if ((fbeCurrentSize + this.NewTimezoneId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewTimezoneId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTimezoneId.fbeSize

    if ((fbeCurrentSize + this.NewTimezoneName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewTimezoneName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTimezoneName.fbeSize

    if ((fbeCurrentSize + this.NewRolloverTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewRolloverTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewRolloverTime.fbeSize

    if ((fbeCurrentSize + this.NewRolloverTimeReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewRolloverTimeReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewRolloverTimeReset.fbeSize

    if ((fbeCurrentSize + this.Deprecated1.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated1.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated1.fbeSize

    if ((fbeCurrentSize + this.Deprecated1Reset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deprecated1Reset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated1Reset.fbeSize

    if ((fbeCurrentSize + this.NewCompanyFullName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewCompanyFullName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCompanyFullName.fbeSize

    if ((fbeCurrentSize + this.NewCompanyEmail.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewCompanyEmail.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCompanyEmail.fbeSize

    if ((fbeCurrentSize + this.NewCompanyPhone.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewCompanyPhone.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCompanyPhone.fbeSize

    if ((fbeCurrentSize + this.NewCompanyAddress.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewCompanyAddress.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCompanyAddress.fbeSize

    if ((fbeCurrentSize + this.NewCompanyWebsite.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewCompanyWebsite.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCompanyWebsite.fbeSize

    if ((fbeCurrentSize + this.NewCompanyDescription.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewCompanyDescription.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCompanyDescription.fbeSize

    if ((fbeCurrentSize + this.NewManagerLoginRangeOffset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewManagerLoginRangeOffset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewManagerLoginRangeOffset.fbeSize

    if ((fbeCurrentSize + this.NewManagerLoginRangeSize.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewManagerLoginRangeSize.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewManagerLoginRangeSize.fbeSize

    if ((fbeCurrentSize + this.NewAccountLoginRangeOffset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAccountLoginRangeOffset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccountLoginRangeOffset.fbeSize

    if ((fbeCurrentSize + this.NewAccountLoginRangeSize.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAccountLoginRangeSize.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccountLoginRangeSize.fbeSize

    if ((fbeCurrentSize + this.NewFeaturesToggle.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewFeaturesToggle.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewFeaturesToggle.fbeSize

    if ((fbeCurrentSize + this.NewDailyReportTemplateId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDailyReportTemplateId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDailyReportTemplateId.fbeSize

    if ((fbeCurrentSize + this.NewDailyReportTemplateIdReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDailyReportTemplateIdReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDailyReportTemplateIdReset.fbeSize

    if ((fbeCurrentSize + this.NewDailyReportTemplateName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDailyReportTemplateName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDailyReportTemplateName.fbeSize

    if ((fbeCurrentSize + this.NewMonthlyReportTemplateId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMonthlyReportTemplateId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMonthlyReportTemplateId.fbeSize

    if ((fbeCurrentSize + this.NewMonthlyReportTemplateIdReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMonthlyReportTemplateIdReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMonthlyReportTemplateIdReset.fbeSize

    if ((fbeCurrentSize + this.NewMonthlyReportTemplateName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMonthlyReportTemplateName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMonthlyReportTemplateName.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelPlatformModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelPlatformModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelPlatformModifyRequest}
   * @param {!PlatformModifyRequest} fbeValue Default value, defaults is new PlatformModifyRequest()
   * @returns {!PlatformModifyRequest} PlatformModifyRequest value
   */
  get (fbeValue = new PlatformModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelPlatformModifyRequest}
   * @param {!PlatformModifyRequest} fbeValue PlatformModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.PlatformId.fbeSize) <= fbeStructSize) {
      fbeValue.PlatformId = this.PlatformId.get()
    } else {
      fbeValue.PlatformId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformId.fbeSize

    if ((fbeCurrentSize + this.PlatformName.fbeSize) <= fbeStructSize) {
      fbeValue.PlatformName = this.PlatformName.get()
    } else {
      fbeValue.PlatformName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformName.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.NewSortOrder = this.NewSortOrder.get()
    } else {
      fbeValue.NewSortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) <= fbeStructSize) {
      fbeValue.NewName = this.NewName.get()
    } else {
      fbeValue.NewName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewComment = this.NewComment.get()
    } else {
      fbeValue.NewComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) <= fbeStructSize) {
      fbeValue.NewDescription = this.NewDescription.get()
    } else {
      fbeValue.NewDescription = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewCompanyName.fbeSize) <= fbeStructSize) {
      fbeValue.NewCompanyName = this.NewCompanyName.get()
    } else {
      fbeValue.NewCompanyName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCompanyName.fbeSize

    if ((fbeCurrentSize + this.NewAddress.fbeSize) <= fbeStructSize) {
      fbeValue.NewAddress = this.NewAddress.get()
    } else {
      fbeValue.NewAddress = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAddress.fbeSize

    if ((fbeCurrentSize + this.NewTimezoneId.fbeSize) <= fbeStructSize) {
      fbeValue.NewTimezoneId = this.NewTimezoneId.get()
    } else {
      fbeValue.NewTimezoneId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTimezoneId.fbeSize

    if ((fbeCurrentSize + this.NewTimezoneName.fbeSize) <= fbeStructSize) {
      fbeValue.NewTimezoneName = this.NewTimezoneName.get()
    } else {
      fbeValue.NewTimezoneName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTimezoneName.fbeSize

    if ((fbeCurrentSize + this.NewRolloverTime.fbeSize) <= fbeStructSize) {
      fbeValue.NewRolloverTime = this.NewRolloverTime.get()
    } else {
      fbeValue.NewRolloverTime = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewRolloverTime.fbeSize

    if ((fbeCurrentSize + this.NewRolloverTimeReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewRolloverTimeReset = this.NewRolloverTimeReset.get()
    } else {
      fbeValue.NewRolloverTimeReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewRolloverTimeReset.fbeSize

    if ((fbeCurrentSize + this.Deprecated1.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated1 = this.Deprecated1.get()
    } else {
      fbeValue.Deprecated1 = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated1.fbeSize

    if ((fbeCurrentSize + this.Deprecated1Reset.fbeSize) <= fbeStructSize) {
      fbeValue.Deprecated1Reset = this.Deprecated1Reset.get()
    } else {
      fbeValue.Deprecated1Reset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deprecated1Reset.fbeSize

    if ((fbeCurrentSize + this.NewCompanyFullName.fbeSize) <= fbeStructSize) {
      fbeValue.NewCompanyFullName = this.NewCompanyFullName.get()
    } else {
      fbeValue.NewCompanyFullName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCompanyFullName.fbeSize

    if ((fbeCurrentSize + this.NewCompanyEmail.fbeSize) <= fbeStructSize) {
      fbeValue.NewCompanyEmail = this.NewCompanyEmail.get()
    } else {
      fbeValue.NewCompanyEmail = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCompanyEmail.fbeSize

    if ((fbeCurrentSize + this.NewCompanyPhone.fbeSize) <= fbeStructSize) {
      fbeValue.NewCompanyPhone = this.NewCompanyPhone.get()
    } else {
      fbeValue.NewCompanyPhone = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCompanyPhone.fbeSize

    if ((fbeCurrentSize + this.NewCompanyAddress.fbeSize) <= fbeStructSize) {
      fbeValue.NewCompanyAddress = this.NewCompanyAddress.get()
    } else {
      fbeValue.NewCompanyAddress = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCompanyAddress.fbeSize

    if ((fbeCurrentSize + this.NewCompanyWebsite.fbeSize) <= fbeStructSize) {
      fbeValue.NewCompanyWebsite = this.NewCompanyWebsite.get()
    } else {
      fbeValue.NewCompanyWebsite = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCompanyWebsite.fbeSize

    if ((fbeCurrentSize + this.NewCompanyDescription.fbeSize) <= fbeStructSize) {
      fbeValue.NewCompanyDescription = this.NewCompanyDescription.get()
    } else {
      fbeValue.NewCompanyDescription = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewCompanyDescription.fbeSize

    if ((fbeCurrentSize + this.NewManagerLoginRangeOffset.fbeSize) <= fbeStructSize) {
      fbeValue.NewManagerLoginRangeOffset = this.NewManagerLoginRangeOffset.get()
    } else {
      fbeValue.NewManagerLoginRangeOffset = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewManagerLoginRangeOffset.fbeSize

    if ((fbeCurrentSize + this.NewManagerLoginRangeSize.fbeSize) <= fbeStructSize) {
      fbeValue.NewManagerLoginRangeSize = this.NewManagerLoginRangeSize.get()
    } else {
      fbeValue.NewManagerLoginRangeSize = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewManagerLoginRangeSize.fbeSize

    if ((fbeCurrentSize + this.NewAccountLoginRangeOffset.fbeSize) <= fbeStructSize) {
      fbeValue.NewAccountLoginRangeOffset = this.NewAccountLoginRangeOffset.get()
    } else {
      fbeValue.NewAccountLoginRangeOffset = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccountLoginRangeOffset.fbeSize

    if ((fbeCurrentSize + this.NewAccountLoginRangeSize.fbeSize) <= fbeStructSize) {
      fbeValue.NewAccountLoginRangeSize = this.NewAccountLoginRangeSize.get()
    } else {
      fbeValue.NewAccountLoginRangeSize = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAccountLoginRangeSize.fbeSize

    if ((fbeCurrentSize + this.NewFeaturesToggle.fbeSize) <= fbeStructSize) {
      fbeValue.NewFeaturesToggle = this.NewFeaturesToggle.get()
    } else {
      fbeValue.NewFeaturesToggle = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewFeaturesToggle.fbeSize

    if ((fbeCurrentSize + this.NewDailyReportTemplateId.fbeSize) <= fbeStructSize) {
      fbeValue.NewDailyReportTemplateId = this.NewDailyReportTemplateId.get()
    } else {
      fbeValue.NewDailyReportTemplateId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDailyReportTemplateId.fbeSize

    if ((fbeCurrentSize + this.NewDailyReportTemplateIdReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewDailyReportTemplateIdReset = this.NewDailyReportTemplateIdReset.get()
    } else {
      fbeValue.NewDailyReportTemplateIdReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDailyReportTemplateIdReset.fbeSize

    if ((fbeCurrentSize + this.NewDailyReportTemplateName.fbeSize) <= fbeStructSize) {
      fbeValue.NewDailyReportTemplateName = this.NewDailyReportTemplateName.get()
    } else {
      fbeValue.NewDailyReportTemplateName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDailyReportTemplateName.fbeSize

    if ((fbeCurrentSize + this.NewMonthlyReportTemplateId.fbeSize) <= fbeStructSize) {
      fbeValue.NewMonthlyReportTemplateId = this.NewMonthlyReportTemplateId.get()
    } else {
      fbeValue.NewMonthlyReportTemplateId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMonthlyReportTemplateId.fbeSize

    if ((fbeCurrentSize + this.NewMonthlyReportTemplateIdReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewMonthlyReportTemplateIdReset = this.NewMonthlyReportTemplateIdReset.get()
    } else {
      fbeValue.NewMonthlyReportTemplateIdReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMonthlyReportTemplateIdReset.fbeSize

    if ((fbeCurrentSize + this.NewMonthlyReportTemplateName.fbeSize) <= fbeStructSize) {
      fbeValue.NewMonthlyReportTemplateName = this.NewMonthlyReportTemplateName.get()
    } else {
      fbeValue.NewMonthlyReportTemplateName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMonthlyReportTemplateName.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelPlatformModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelPlatformModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelPlatformModifyRequest}
   * @param {!PlatformModifyRequest} fbeValue PlatformModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelPlatformModifyRequest}
   * @param {PlatformModifyRequest} fbeValue PlatformModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.PlatformId.set(fbeValue.PlatformId)
    this.PlatformName.set(fbeValue.PlatformName)
    this.NewSortOrder.set(fbeValue.NewSortOrder)
    this.NewName.set(fbeValue.NewName)
    this.NewComment.set(fbeValue.NewComment)
    this.NewDescription.set(fbeValue.NewDescription)
    this.NewCompanyName.set(fbeValue.NewCompanyName)
    this.NewAddress.set(fbeValue.NewAddress)
    this.NewTimezoneId.set(fbeValue.NewTimezoneId)
    this.NewTimezoneName.set(fbeValue.NewTimezoneName)
    this.NewRolloverTime.set(fbeValue.NewRolloverTime)
    this.NewRolloverTimeReset.set(fbeValue.NewRolloverTimeReset)
    this.Deprecated1.set(fbeValue.Deprecated1)
    this.Deprecated1Reset.set(fbeValue.Deprecated1Reset)
    this.NewCompanyFullName.set(fbeValue.NewCompanyFullName)
    this.NewCompanyEmail.set(fbeValue.NewCompanyEmail)
    this.NewCompanyPhone.set(fbeValue.NewCompanyPhone)
    this.NewCompanyAddress.set(fbeValue.NewCompanyAddress)
    this.NewCompanyWebsite.set(fbeValue.NewCompanyWebsite)
    this.NewCompanyDescription.set(fbeValue.NewCompanyDescription)
    this.NewManagerLoginRangeOffset.set(fbeValue.NewManagerLoginRangeOffset)
    this.NewManagerLoginRangeSize.set(fbeValue.NewManagerLoginRangeSize)
    this.NewAccountLoginRangeOffset.set(fbeValue.NewAccountLoginRangeOffset)
    this.NewAccountLoginRangeSize.set(fbeValue.NewAccountLoginRangeSize)
    this.NewFeaturesToggle.set(fbeValue.NewFeaturesToggle)
    this.NewDailyReportTemplateId.set(fbeValue.NewDailyReportTemplateId)
    this.NewDailyReportTemplateIdReset.set(fbeValue.NewDailyReportTemplateIdReset)
    this.NewDailyReportTemplateName.set(fbeValue.NewDailyReportTemplateName)
    this.NewMonthlyReportTemplateId.set(fbeValue.NewMonthlyReportTemplateId)
    this.NewMonthlyReportTemplateIdReset.set(fbeValue.NewMonthlyReportTemplateIdReset)
    this.NewMonthlyReportTemplateName.set(fbeValue.NewMonthlyReportTemplateName)
  }
}

export { FieldModelPlatformModifyRequest };

/**
 * Fast Binary Encoding PlatformModifyRequest model
 */
class PlatformModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelPlatformModifyRequest(this.buffer, 4)
  }

  /**
   * Get the PlatformModifyRequest model
   * @this {!PlatformModifyRequestModel}
   * @returns {!FieldModelPlatformModifyRequest} model PlatformModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!PlatformModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!PlatformModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return PlatformModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!PlatformModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelPlatformModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!PlatformModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!PlatformModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!PlatformModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!PlatformModifyRequestModel}
   * @param {!PlatformModifyRequest} value PlatformModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!PlatformModifyRequestModel}
   * @param {!PlatformModifyRequest} value PlatformModifyRequest value, defaults is new PlatformModifyRequest()
   * @return {!object} Deserialized PlatformModifyRequest value and its size
   */
  deserialize (value = new PlatformModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new PlatformModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new PlatformModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!PlatformModifyRequestModel}
   * @param {!number} prev Previous PlatformModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { PlatformModifyRequestModel };
PlatformModifyRequest.__has_response = true;
PlatformModifyRequest.__response_class = PlatformModifyResponse;
