import { AccountingType } from '@api/fbe/core/AccountingType';
import { AuthenticationType } from '@api/fbe/core/AuthenticationType';
import { BinaryOperatorType } from '@api/fbe/core/BinaryOperatorType';
import { ChartBarPeriodType } from '@api/fbe/core/ChartBarPeriodType';
import { ChartBarsImportServerType } from '@api/fbe/core/ChartBarsImportServerType';
import { ClientReportType } from '@api/fbe/core/ClientReportType';
import { CommissionMode } from '@api/fbe/core/CommissionMode';
import { CommissionType } from '@api/fbe/core/CommissionType';
import { DataFeedStatus } from '@api/fbe/core/DataFeedStatus';
import { EventType } from '@api/fbe/core/EventType';
import { ExportDatabaseType } from '@api/fbe/core/ExportDatabaseType';
import { FeatureToggleForce } from '@api/fbe/core/FeatureToggleForce';
import { LevelType } from '@api/fbe/core/LevelType';
import { LeverageProfileLeverageMode } from '@api/fbe/core/LeverageProfileLeverageMode';
import { LogLevelType } from '@api/fbe/core/LogLevelType';
import { MarginCalculationType } from '@api/fbe/core/MarginCalculationType';
import { MarginRateProfilePositionMode } from '@api/fbe/core/MarginRateProfilePositionMode';
import { MarginRateProfileStatus } from '@api/fbe/core/MarginRateProfileStatus';
import { ModuleType } from '@api/fbe/core/ModuleType';
import { OffTimeType } from '@api/fbe/core/OffTimeType';
import { OrderRequestSourceType } from '@api/fbe/core/OrderRequestSourceType';
import { OrderSide } from '@api/fbe/core/OrderSide';
import { OrderStatus } from '@api/fbe/core/OrderStatus';
import { OrderTimeInForce } from '@api/fbe/core/OrderTimeInForce';
import { OrderType } from '@api/fbe/core/OrderType';
import { ProfitCalculationType } from '@api/fbe/core/ProfitCalculationType';
import { ProtocolType } from '@api/fbe/core/ProtocolType';
import { SocialTradingAccountMode } from '@api/fbe/core/SocialTradingAccountMode';
import { SocialTradingAccountType } from '@api/fbe/core/SocialTradingAccountType';
import { SocialTradingAllocationMethod } from '@api/fbe/core/SocialTradingAllocationMethod';
import { SocialTradingOrderType } from '@api/fbe/core/SocialTradingOrderType';
import { StopOutType } from '@api/fbe/core/StopOutType';
import { StorageDatabaseType } from '@api/fbe/core/StorageDatabaseType';
import { SwapCalculationType } from '@api/fbe/core/SwapCalculationType';
import { TradingMode } from '@api/fbe/core/TradingMode';
import { TradingRouteActionType } from '@api/fbe/core/TradingRouteActionType';
import { TradingRouteConditionType } from '@api/fbe/core/TradingRouteConditionType';
import { TradingRouteRequestType } from '@api/fbe/core/TradingRouteRequestType';
import { TransportType } from '@api/fbe/core/TransportType';
import { getClassStaticFields } from 'utils';

export enum MONEY_MANAGEMENT_TYPES {
    DEPOSIT = 2,
    CREDIT = 3,
    TRANSFER = 4,
}

export const TRADING_ROUTES_TYPES = {
    [TradingRouteRequestType.OPENING_MARKET_ORDER.valueOf()]: 'Opening Market Order',
    [TradingRouteRequestType.CLOSING_MARKET_ORDER.valueOf()]: 'Closing Market Order',
    [TradingRouteRequestType.PLACING_PENDING_ORDER.valueOf()]: 'Placing Pending Order',
    [TradingRouteRequestType.ACTIVATING_PENDING_ORDER.valueOf()]: 'Activating Pending Order',
    [TradingRouteRequestType.MODIFYING_PENDING_ORDER.valueOf()]: 'Modifying Pending Order',
    [TradingRouteRequestType.CANCELLING_PENDING_ORDER.valueOf()]: 'Cancelling Pending Order',
    [TradingRouteRequestType.EXPIRING_PENDING_ORDER.valueOf()]: 'Expiring Pending Order',
    [TradingRouteRequestType.ACTIVATING_SL.valueOf()]: 'Activating SL',
    [TradingRouteRequestType.ACTIVATING_TP.valueOf()]: 'Activating TP',
    [TradingRouteRequestType.ACTIVATING_STOP_OUT.valueOf()]: 'Activating Stop Out',
    [TradingRouteRequestType.ALL.valueOf()]: 'All',
};

export const TRADING_ROUTE_ACTION_TYPE = {
    [TradingRouteActionType.FORWARD_TO_GATEWAY.valueOf()]: 'Forward to Gateway',
    [TradingRouteActionType.CONFIRM.valueOf()]: 'Confirm',
    [TradingRouteActionType.REJECT.valueOf()]: 'Reject',
    [TradingRouteActionType.CANCEL.valueOf()]: 'Cancel',
};

export const TRADING_ROUTES_ORDER_SIDE = {
    // RENAME TO ORDER SIDE
    [OrderSide.BUY.valueOf()]: 'Buy',
    [OrderSide.SELL.valueOf()]: 'Sell',
};

export const CUSTOM_TRADE_HISTORY_ORDER_SIDE = {
    [OrderSide.BUY.valueOf()]: 'Buy',
    [OrderSide.SELL.valueOf()]: 'Sell',
    [MONEY_MANAGEMENT_TYPES.DEPOSIT]: 'Balance',
    [MONEY_MANAGEMENT_TYPES.CREDIT]: 'Credit',
    [MONEY_MANAGEMENT_TYPES.TRANSFER]: 'Transfer',
};

export const TRADING_ROUTES_CONDITION_TYPES = {
    [TradingRouteConditionType.ORDER_SIDE.valueOf()]: 'Order Side',
    [TradingRouteConditionType.ORDER_VOLUME.valueOf()]: 'Order Volume',
    [TradingRouteConditionType.SYMBOL.valueOf()]: 'Symbol',
    [TradingRouteConditionType.SYMBOL_GROUP.valueOf()]: 'Symbol Group',
    [TradingRouteConditionType.ACCOUNT_GROUP.valueOf()]: 'Account Group',
    [TradingRouteConditionType.ACCOUNT_LOGIN.valueOf()]: 'Account Login',
};

export const TRADING_ROUTES_OPERATOR_TYPES = {
    [BinaryOperatorType.EQUAL.valueOf()]: 'Equal',
    [BinaryOperatorType.NOT_EQUAL.valueOf()]: 'Not equal',
    [BinaryOperatorType.LESS.valueOf()]: 'Less',
    [BinaryOperatorType.LESS_OR_EQUAL.valueOf()]: 'Less or equal',
    [BinaryOperatorType.GREATER.valueOf()]: 'Greater',
    [BinaryOperatorType.GREATER_OR_EQUAL.valueOf()]: 'Greater or equal',
};

export const ORDER_TYPES = {
    [OrderType.MARKET.valueOf()]: 'Market',
    [OrderType.LIMIT.valueOf()]: 'Limit',
    [OrderType.STOP.valueOf()]: 'Stop',
};

export const ORDER_TIME_IN_FORCES = {
    [OrderTimeInForce.GTC.valueOf()]: 'GTC',
    [OrderTimeInForce.IOC.valueOf()]: 'IOC',
    [OrderTimeInForce.FOK.valueOf()]: 'FOK',
    [OrderTimeInForce.DAY.valueOf()]: 'DAY',
};

export const ORDER_STATUSES = {
    [OrderStatus.INVALID.valueOf()]: 'Invalid',
    [OrderStatus.CREATED.valueOf()]: 'Created',
    [OrderStatus.PENDING.valueOf()]: 'Pending',
    [OrderStatus.CANCELED.valueOf()]: 'Canceled',
    [OrderStatus.EXPIRED.valueOf()]: 'Expired',
    [OrderStatus.FILLED.valueOf()]: 'Filled',
    [OrderStatus.FILLED_PARTIALLY.valueOf()]: 'Filled partially',
    [OrderStatus.REJECTED.valueOf()]: 'Rejected',
};

export const CALENDARS_OFFTIME_TYPES = {
    [OffTimeType.ONCE.valueOf()]: 'Once',
    [OffTimeType.WEEKLY.valueOf()]: 'Weekly',
    [OffTimeType.YEARLY.valueOf()]: 'Yearly',
};

export const MODULE_TYPES = {
    [ModuleType.AccessServer.valueOf()]: 'Access Server',
    [ModuleType.ConfigServer.valueOf()]: 'Config Server',
    [ModuleType.FeedServer.valueOf()]: 'Feed Server',
    [ModuleType.HistoryServer.valueOf()]: 'History Server',
    [ModuleType.StorageServer.valueOf()]: 'Storage Server',
    [ModuleType.TradeServer.valueOf()]: 'Trade Server',
    [ModuleType.ExecutionServer.valueOf()]: 'Execution Server',
    [ModuleType.BackupServer.valueOf()]: 'Backup Server',
    [ModuleType.ExportServer.valueOf()]: 'Export Server',
    [ModuleType.DrawingStorageServer.valueOf()]: 'Drawing Storage Server',
};

export const LOG_LEVEL_TYPES = new Map([
    [LogLevelType.ALL.valueOf(), 'All'],
    [LogLevelType.DEBUG.valueOf(), 'Debug'],
    [LogLevelType.INFO.valueOf(), 'Info'],
    [LogLevelType.WARN.valueOf(), 'Warn'],
    [LogLevelType.ERROR.valueOf(), 'Error'],
    [LogLevelType.FATAL.valueOf(), 'Fatal'],
    [LogLevelType.NONE.valueOf(), 'None'],
    [LogLevelType.TRACE.valueOf(), 'Trace'],
]);

export const STORAGE_DATABASE_TYPES = {
    [StorageDatabaseType.MEMORY.valueOf()]: 'Memory',
    [StorageDatabaseType.SQLITE.valueOf()]: 'SQLite',
};

export const EXPORT_DATABASE_TYPES = {
    [ExportDatabaseType.MYSQL.valueOf()]: 'MySQL',
};

export const TRADING_MODES = {
    [TradingMode.NORMAL.valueOf()]: 'Full',
    [TradingMode.LONG_ONLY.valueOf()]: 'Long only',
    [TradingMode.SHORT_ONLY.valueOf()]: 'Short only',
    [TradingMode.READ_ONLY.valueOf()]: 'Read only',
    [TradingMode.CLOSE_ONLY.valueOf()]: 'Close only',
};

export const STOPOUT_TYPES = {
    [StopOutType.CLOSE_ALL.valueOf()]: 'Close All',
    [StopOutType.CLOSE_MOST_LOSS.valueOf()]: 'Close Most Loss',
};

export const ACCOUNTING_TYPES = {
    [AccountingType.GROSS.valueOf()]: 'GROSS',
    [AccountingType.NET.valueOf()]: 'NET',
    [AccountingType.EXCHANGE.valueOf()]: 'EXCHANGE',
    
};

export const LEVEL_TYPES = {
    [LevelType.NONE.valueOf()]: 'None',
    [LevelType.MARGIN_CALL.valueOf()]: 'Margin Call',
    [LevelType.STOP_OUT.valueOf()]: 'Stop Out',
};

export const DATA_FEED_STATUSES = {
    [DataFeedStatus.OFFLINE.valueOf()]: 'Offline',
    [DataFeedStatus.LOCAL_ONLINE.valueOf()]: 'Local Online',
    [DataFeedStatus.REMOTE_ONLINE.valueOf()]: 'Remote Online',
};

export const PROTOCOL_TYPES = {
    [ProtocolType.CLIENT.valueOf()]: 'Client',
    [ProtocolType.MANAGER.valueOf()]: 'Manager',
    [ProtocolType.PUBLIC.valueOf()]: 'Public',
    [ProtocolType.UNKNOWN.valueOf()]: 'Unknown',
};

export const MARGIN_CALCULATION_TYPES = {
    [MarginCalculationType.SimpleMidPrice.valueOf()]: 'Forex',
    [MarginCalculationType.CFD.valueOf()]: 'CFD',
};

export const PROFIT_CALCULATION_TYPES = {
    [ProfitCalculationType.SimpleMarketPrice.valueOf()]: 'Forex',
    [ProfitCalculationType.CFD.valueOf()]: 'CFD',
};

export const TRANSPORT_TYPES = getClassStaticFields(TransportType);

export const AUTHENTICATION_TYPES = getClassStaticFields(AuthenticationType);

export const LEVERAGE_PROFILE_MODES = {
    [LeverageProfileLeverageMode.EQUITY.valueOf()]: 'Equity',
    [LeverageProfileLeverageMode.BALANCE.valueOf()]: 'Balance',
};

export const MARGIN_RATE_PROFILE_STATUSES = {
    [MarginRateProfileStatus.ACTIVE.valueOf()]: 'Active',
    [MarginRateProfileStatus.INACTIVE.valueOf()]: 'Inactive',
};

export const MARGIN_RATE_PROFILE_POSITION_MODES = {
    [MarginRateProfilePositionMode.NEW_POSITIONS.valueOf()]: 'New Positions',
    [MarginRateProfilePositionMode.ALL_POSITIONS.valueOf()]: 'All Positions',
};

export const LEVERAGE_MODES = {
    [LeverageProfileLeverageMode.BALANCE.valueOf()]: 'Balance',
    [LeverageProfileLeverageMode.EQUITY.valueOf()]: 'Equity',
};

export const SWAP_CALCULATION_TYPES = {
    [SwapCalculationType.BY_POINTS.valueOf()]: 'By Points',
    [SwapCalculationType.BY_MONEY_BASE_CURRENCY.valueOf()]: 'By money base currency',
    [SwapCalculationType.BY_MONEY_MARGIN_CURRENCY.valueOf()]: 'By money margin currency',
    [SwapCalculationType.BY_MONEY_PROFIT_CURRENCY.valueOf()]: 'By money profit currency',
    [SwapCalculationType.BY_MONEY_ACCOUNT_CURRENCY.valueOf()]: 'By money account currency',
    [SwapCalculationType.BY_PERCENTAGE_CURRENT_PRICE.valueOf()]: 'By percentage current price',
};

export const MAIL_EVENT_TYPES = {
    // [EventType.NONE.valueOf()]: 'None',
    [EventType.RESET_MANAGER_PASSWORD.valueOf()]: 'Reset Manager Password',
    [EventType.DEPRECATED_SEND_CLIENT_REPORT.valueOf()]: 'Deprecated Send Client Report',
    [EventType.LEVERAGE_CHANGE.valueOf()]: 'Leverage Change',
    [EventType.DAILY_CLIENT_REPORT.valueOf()]: 'Daily Client Report',
    [EventType.MONTHLY_CLIENT_REPORT.valueOf()]: 'Monthly Client Report',
};

export const CLIENT_REPORT_TYPES = {
    // [ClientReportType.UNKNOWN.valueOf()]: 'Unknown',
    [ClientReportType.DAILY_CONFIRMATION.valueOf()]: 'Daily Statement',
    [ClientReportType.MONTHLY_STATEMENT.valueOf()]: 'Monthly Statement',
};

export const PROFILES_COMMISSION_MODES = {
    [CommissionMode.PERCENTS.valueOf()]: 'Percents',
    [CommissionMode.MONEY_USD.valueOf()]: 'Money',
    [CommissionMode.PRIMARY_CURRENCY.valueOf()]: 'Primary CCY',
    [CommissionMode.POINTS.valueOf()]: 'Points',
};

export const PROFILES_COMMISSION_TYPES = {
    [CommissionType.PER_LOT.valueOf()]: 'Per Lot',
    [CommissionType.PER_TRADE.valueOf()]: 'Per Trade',
};

export const CHART_BARS_IMPORT_SERVER_TYPES = {
    [ChartBarsImportServerType.TP.valueOf()]: 'TP',
    [ChartBarsImportServerType.MT4.valueOf()]: 'MT4',
    [ChartBarsImportServerType.MT5.valueOf()]: 'MT5',
};

export const CHART_BAR_PERIOD_TYPES = new Map([
    [ChartBarPeriodType.M1.valueOf(), 'M1'],
    [ChartBarPeriodType.M5.valueOf(), 'M5'],
    [ChartBarPeriodType.M15.valueOf(), 'M15'],
    [ChartBarPeriodType.M30.valueOf(), 'M30'],
    [ChartBarPeriodType.H1.valueOf(), 'H1'],
    [ChartBarPeriodType.H4.valueOf(), 'H4'],
    [ChartBarPeriodType.D1.valueOf(), 'D1'],
    [ChartBarPeriodType.W1.valueOf(), 'W1'],
    [ChartBarPeriodType.MN.valueOf(), 'MN'],
]);

export const ORDER_REQUEST_SOURCE_TYPES = {
    [OrderRequestSourceType.UNKNOWN.valueOf()]: '',
    [OrderRequestSourceType.MANAGER.valueOf()]: 'Manager',
    [OrderRequestSourceType.MOBILE_APP.valueOf()]: 'Mobile App',
    [OrderRequestSourceType.WEB_TRADER.valueOf()]: 'Web Trader',
};

export const FEATURE_TOGGLE_FORCE_TYPES = {
    [FeatureToggleForce.FORCE_ENABLE.valueOf()]: 'Force Enabled',
    [FeatureToggleForce.FORCE_DISABLE.valueOf()]: 'Force Disabled',
    [FeatureToggleForce.NONE.valueOf()]: 'By Platform',
}

export const SOCIAL_TRADING_MODEL_TYPES = {
    [SocialTradingAccountType.REGULAR.valueOf()]: 'Regular',
    [SocialTradingAccountType.MASTER.valueOf()]: 'Master',
    [SocialTradingAccountType.INVESTOR.valueOf()]: 'Follower',
};

export const SOCIAL_TRADING_CONVERT_MODEL_TYPES = {
    [SocialTradingAccountType.MASTER.valueOf()]: 'Master',
    [SocialTradingAccountType.INVESTOR.valueOf()]: 'Follower',
};

export const SOCIAL_TRADING_ALLOCATION_METHOD_TYPES = {
    [SocialTradingAllocationMethod.EQUITY.valueOf()]: 'Equity',
    [SocialTradingAllocationMethod.BALANCE.valueOf()]: 'Balance',
    [SocialTradingAllocationMethod.LOT.valueOf()]: 'Lot',
};

export const SOCIAL_TRADING_ACCOUNT_MODE_TYPES = {
    [SocialTradingAccountMode.MAM.valueOf()]: 'MAM',
    [SocialTradingAccountMode.CT.valueOf()]: 'CT',
};

export const SOCIAL_TRADING_ORDER_TYPES = {
    [SocialTradingOrderType.REGULAR.valueOf()]: 'Regular',
    [SocialTradingOrderType.MASTER_ORDER.valueOf()]: 'Master Order',
    [SocialTradingOrderType.INVESTOR_ORDER.valueOf()]: 'Follower Order',
}