
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Order} from '../core/Order';
import {FieldModelOrder} from '../core/Order';
import {AccountMarginStable} from './AccountMarginStable';
import {FieldModelAccountMarginStable} from './AccountMarginStable';
import {TradeHistoryRecord} from '../core/TradeHistoryRecord';
import {FieldModelTradeHistoryRecord} from '../core/TradeHistoryRecord';
import {TradeEvent} from './TradeEvent';
import {FieldModelTradeEvent} from './TradeEvent';

/**
 * Order2ResponseParams struct
 */
class Order2ResponseParams {
  /**
   * Initialize struct
   * @param {Order=} orderPrevious
   * @param {Order=} orderCurrent
   * @param {AccountMarginStable=} accountMarginPrevious
   * @param {AccountMarginStable=} accountMarginCurrent
   * @param {!TradeHistoryRecord=} tradeHistoryRecord
   * @param {!Array=} tradeEvents
   * @constructor
   */
  constructor (orderPrevious = null, orderCurrent = null, accountMarginPrevious = null, accountMarginCurrent = null, tradeHistoryRecord = new TradeHistoryRecord(), tradeEvents = []) {
    this.OrderPrevious = orderPrevious
    this.OrderCurrent = orderCurrent
    this.AccountMarginPrevious = accountMarginPrevious
    this.AccountMarginCurrent = accountMarginCurrent
    this.TradeHistoryRecord = tradeHistoryRecord
    this.TradeEvents = tradeEvents
  }

  /**
   * Copy struct (shallow copy)
   * @this {!Order2ResponseParams}
   * @param {!Order2ResponseParams} other Other struct
   * @returns {!Order2ResponseParams} This struct
   */
  copy (other) {
    if (other.OrderPrevious != null) {
      this.OrderPrevious = Order.fromObject(other.OrderPrevious)
    } else {
      this.OrderPrevious = null
    }
    if (other.OrderCurrent != null) {
      this.OrderCurrent = Order.fromObject(other.OrderCurrent)
    } else {
      this.OrderCurrent = null
    }
    if (other.AccountMarginPrevious != null) {
      this.AccountMarginPrevious = AccountMarginStable.fromObject(other.AccountMarginPrevious)
    } else {
      this.AccountMarginPrevious = null
    }
    if (other.AccountMarginCurrent != null) {
      this.AccountMarginCurrent = AccountMarginStable.fromObject(other.AccountMarginCurrent)
    } else {
      this.AccountMarginCurrent = null
    }
    if (other.TradeHistoryRecord != null) {
      this.TradeHistoryRecord = TradeHistoryRecord.fromObject(other.TradeHistoryRecord)
    } else {
      this.TradeHistoryRecord = null
    }
    if (other.TradeEvents != null) {
      this.TradeEvents = []
      for (let item of other.TradeEvents) {
        if (item != null) {
          let tempItem
          tempItem = TradeEvent.fromObject(item)
          this.TradeEvents.push(tempItem)
        } else {
          this.TradeEvents.push(null)
        }
      }
    } else {
      this.TradeEvents = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!Order2ResponseParams}
   * @returns {!Order2ResponseParams} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new Order2ResponseParamsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new Order2ResponseParamsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!Order2ResponseParams}
   * @param {!Order2ResponseParams} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof Order2ResponseParams)) {
      throw new TypeError('Instance of Order2ResponseParams is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!Order2ResponseParams}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      OrderPrevious: ((this.OrderPrevious != null) ? this.OrderPrevious : null),
      OrderCurrent: ((this.OrderCurrent != null) ? this.OrderCurrent : null),
      AccountMarginPrevious: ((this.AccountMarginPrevious != null) ? this.AccountMarginPrevious : null),
      AccountMarginCurrent: ((this.AccountMarginCurrent != null) ? this.AccountMarginCurrent : null),
      TradeHistoryRecord: ((this.TradeHistoryRecord != null) ? this.TradeHistoryRecord : null),
      TradeEvents: ((this.TradeEvents != null) ? Array.from(this.TradeEvents, item => ((item != null) ? item : null)) : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return Order2ResponseParams.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!Order2ResponseParams} other Object value
   * @returns {!Order2ResponseParams} Created struct
   */
  static fromObject (other) {
    return new Order2ResponseParams().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!Order2ResponseParams}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return Order2ResponseParams.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!Order2ResponseParams}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 4152
  }
}

export { Order2ResponseParams };

/**
 * Fast Binary Encoding Order2ResponseParams field model
 */
class FieldModelOrder2ResponseParams extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.OrderPrevious = new fbe.FieldModelOptional(new FieldModelOrder(buffer, 4 + 4), buffer, 4 + 4)
    this.OrderCurrent = new fbe.FieldModelOptional(new FieldModelOrder(buffer, this.OrderPrevious.fbeOffset + this.OrderPrevious.fbeSize), buffer, this.OrderPrevious.fbeOffset + this.OrderPrevious.fbeSize)
    this.AccountMarginPrevious = new fbe.FieldModelOptional(new FieldModelAccountMarginStable(buffer, this.OrderCurrent.fbeOffset + this.OrderCurrent.fbeSize), buffer, this.OrderCurrent.fbeOffset + this.OrderCurrent.fbeSize)
    this.AccountMarginCurrent = new fbe.FieldModelOptional(new FieldModelAccountMarginStable(buffer, this.AccountMarginPrevious.fbeOffset + this.AccountMarginPrevious.fbeSize), buffer, this.AccountMarginPrevious.fbeOffset + this.AccountMarginPrevious.fbeSize)
    this.TradeHistoryRecord = new FieldModelTradeHistoryRecord(buffer, this.AccountMarginCurrent.fbeOffset + this.AccountMarginCurrent.fbeSize)
    this.TradeEvents = new fbe.FieldModelVector(new FieldModelTradeEvent(buffer, this.TradeHistoryRecord.fbeOffset + this.TradeHistoryRecord.fbeSize), buffer, this.TradeHistoryRecord.fbeOffset + this.TradeHistoryRecord.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelOrder2ResponseParams}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrder2ResponseParams}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.OrderPrevious.fbeSize + this.OrderCurrent.fbeSize + this.AccountMarginPrevious.fbeSize + this.AccountMarginCurrent.fbeSize + this.TradeHistoryRecord.fbeSize + this.TradeEvents.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrder2ResponseParams}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.OrderPrevious.fbeExtra + this.OrderCurrent.fbeExtra + this.AccountMarginPrevious.fbeExtra + this.AccountMarginCurrent.fbeExtra + this.TradeHistoryRecord.fbeExtra + this.TradeEvents.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrder2ResponseParams}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrder2ResponseParams.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrder2ResponseParams}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 4152
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrder2ResponseParams}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrder2ResponseParams}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.OrderPrevious.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderPrevious.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderPrevious.fbeSize

    if ((fbeCurrentSize + this.OrderCurrent.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OrderCurrent.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderCurrent.fbeSize

    if ((fbeCurrentSize + this.AccountMarginPrevious.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountMarginPrevious.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginPrevious.fbeSize

    if ((fbeCurrentSize + this.AccountMarginCurrent.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountMarginCurrent.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginCurrent.fbeSize

    if ((fbeCurrentSize + this.TradeHistoryRecord.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradeHistoryRecord.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeHistoryRecord.fbeSize

    if ((fbeCurrentSize + this.TradeEvents.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradeEvents.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeEvents.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrder2ResponseParams}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrder2ResponseParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrder2ResponseParams}
   * @param {!Order2ResponseParams} fbeValue Default value, defaults is new Order2ResponseParams()
   * @returns {!Order2ResponseParams} Order2ResponseParams value
   */
  get (fbeValue = new Order2ResponseParams()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrder2ResponseParams}
   * @param {!Order2ResponseParams} fbeValue Order2ResponseParams value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.OrderPrevious.fbeSize) <= fbeStructSize) {
      fbeValue.OrderPrevious = this.OrderPrevious.get()
    } else {
      fbeValue.OrderPrevious = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderPrevious.fbeSize

    if ((fbeCurrentSize + this.OrderCurrent.fbeSize) <= fbeStructSize) {
      fbeValue.OrderCurrent = this.OrderCurrent.get()
    } else {
      fbeValue.OrderCurrent = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OrderCurrent.fbeSize

    if ((fbeCurrentSize + this.AccountMarginPrevious.fbeSize) <= fbeStructSize) {
      fbeValue.AccountMarginPrevious = this.AccountMarginPrevious.get()
    } else {
      fbeValue.AccountMarginPrevious = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginPrevious.fbeSize

    if ((fbeCurrentSize + this.AccountMarginCurrent.fbeSize) <= fbeStructSize) {
      fbeValue.AccountMarginCurrent = this.AccountMarginCurrent.get()
    } else {
      fbeValue.AccountMarginCurrent = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountMarginCurrent.fbeSize

    if ((fbeCurrentSize + this.TradeHistoryRecord.fbeSize) <= fbeStructSize) {
      fbeValue.TradeHistoryRecord = this.TradeHistoryRecord.get()
    } else {
      fbeValue.TradeHistoryRecord = new TradeHistoryRecord()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeHistoryRecord.fbeSize

    if ((fbeCurrentSize + this.TradeEvents.fbeSize) <= fbeStructSize) {
      this.TradeEvents.get(fbeValue.TradeEvents)
    } else {
      fbeValue.TradeEvents.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradeEvents.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrder2ResponseParams}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrder2ResponseParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrder2ResponseParams}
   * @param {!Order2ResponseParams} fbeValue Order2ResponseParams value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrder2ResponseParams}
   * @param {Order2ResponseParams} fbeValue Order2ResponseParams value
   */
  setFields (fbeValue) {
    this.OrderPrevious.set(fbeValue.OrderPrevious)
    this.OrderCurrent.set(fbeValue.OrderCurrent)
    this.AccountMarginPrevious.set(fbeValue.AccountMarginPrevious)
    this.AccountMarginCurrent.set(fbeValue.AccountMarginCurrent)
    this.TradeHistoryRecord.set(fbeValue.TradeHistoryRecord)
    this.TradeEvents.set(fbeValue.TradeEvents)
  }
}

export { FieldModelOrder2ResponseParams };

/**
 * Fast Binary Encoding Order2ResponseParams model
 */
class Order2ResponseParamsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrder2ResponseParams(this.buffer, 4)
  }

  /**
   * Get the Order2ResponseParams model
   * @this {!Order2ResponseParamsModel}
   * @returns {!FieldModelOrder2ResponseParams} model Order2ResponseParams model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!Order2ResponseParamsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!Order2ResponseParamsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return Order2ResponseParamsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!Order2ResponseParamsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrder2ResponseParams.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!Order2ResponseParamsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!Order2ResponseParamsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!Order2ResponseParamsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!Order2ResponseParamsModel}
   * @param {!Order2ResponseParams} value Order2ResponseParams value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!Order2ResponseParamsModel}
   * @param {!Order2ResponseParams} value Order2ResponseParams value, defaults is new Order2ResponseParams()
   * @return {!object} Deserialized Order2ResponseParams value and its size
   */
  deserialize (value = new Order2ResponseParams()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new Order2ResponseParams(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new Order2ResponseParams(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!Order2ResponseParamsModel}
   * @param {!number} prev Previous Order2ResponseParams model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { Order2ResponseParamsModel };
