import { makeAutoObservable } from 'mobx';
import { SocketClient } from '@api/SocketClient';
import { UUID } from '@api/fbe/uuid';
import { ErrorsTracker } from '@api/errorsTracker';
import { adaptApi } from '@utils/models';
import { MarginCalculationType } from '@api/fbe/core/MarginCalculationType';
import { ProfitCalculationType } from '@api/fbe/core/ProfitCalculationType';
import { TradingMode } from '@api/fbe/core/TradingMode';
import { ConvertedSymbol } from '@models/Symbols';
import { UInt64 } from '@api/fbe/int64';
import { baseSorter } from '@components/TableExt/RowFilters/utils';
import { SymbolsGetAllRequest } from '@api/fbe/manager/SymbolsGetAllRequest';
import { SymbolCreateRequest } from '@api/fbe/manager/SymbolCreateRequest';
import { SymbolDeleteRequest } from '@api/fbe/manager/SymbolDeleteRequest';
import { SymbolModifyRequest } from '@api/fbe/manager/SymbolModifyRequest';
import { SwapCalculationType } from '@api/fbe/core/SwapCalculationType';
import { DayOfWeek } from '@api/fbe/core/DayOfWeek';
import { SwapImportSettings } from '@api/fbe/core/SwapImportSettings';
import { SwapsImportResponse } from '@api/fbe/manager/SwapsImportResponse';
import { SwapsImportRequest } from '@api/fbe/manager/SwapsImportRequest';

export class SymbolsStore {
    errorTracker = new ErrorsTracker({ title: 'Symbols' });

    constructor() {
        makeAutoObservable(this);
    }

    data: ConvertedSymbol[] = [];

    isLoading: boolean = false;

    setData(value: ConvertedSymbol[]) {
        this.data = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @ErrorsTracker.wrapApi()
    async get() {
        const request = new SymbolsGetAllRequest();
        this.setIsLoading(true);
        const data = await SocketClient.instance.request(request, this.errorTracker);
        this.setData(
            data.Symbols.map(
                (symbol): ConvertedSymbol => ({
                    ...adaptApi(symbol),
                    MarginCalculationType: symbol.MarginCalculationType.valueOf(),
                    ProfitCalculationType: symbol.ProfitCalculationType.valueOf(),

                    IsSwapEnabled: symbol.IsSwapEnabled,
                    SwapType: symbol.SwapSettings ? symbol.SwapSettings.Type.valueOf() : null,
                    SwapLong: symbol.SwapSettings ? symbol.SwapSettings.Long : null,
                    SwapShort: symbol.SwapSettings ? symbol.SwapSettings.Short : null,
                    SwapTripleDay: symbol.SwapSettings ? symbol.SwapSettings.TripleDay.valueOf() : null,
                    SwapDaysInYear: symbol.SwapSettings ? +symbol.SwapSettings.DaysInYear.toNumber() : null,
                    TradingMode: symbol.TradingMode?.valueOf(),
                    StopsLevel: Number(symbol.StopsLevel?.toString()),
                    ProfitCurrencyPrecision: symbol.ProfitCurrencyPrecision ?? null,
                    MarginCurrencyPrecision: symbol.MarginCurrencyPrecision ?? null,
                    IsTradeAllowed: symbol.IsTradeAllowed ?? null,
                }),
            ),
        );
        this.setIsLoading(false);
    }

    get getWatchListSettingsSymbols() {
        return this.data
            .filter((item) => item.IsFavorite)
            .sort((a, b) => baseSorter(a.SortOrderFavorite, b.SortOrderFavorite));
    }

    @ErrorsTracker.wrapApi()
    async create(data: ConvertedSymbol) {
        const request = new SymbolCreateRequest();

        Object.keys(data).forEach((key) => {
            if (data[key] !== undefined) {
                if (key === 'SymbolGroupId') {
                    request.SymbolGroupId = UUID.fromString(data.SymbolGroupId!);
                } else if (key === 'ProfitCalculationType') {
                    request.ProfitCalculationType = new ProfitCalculationType(data.ProfitCalculationType);
                } else if (key === 'MarginCalculationType') {
                    request.MarginCalculationType = new MarginCalculationType(data.MarginCalculationType);
                } else if (key === 'MarginCurrencyId') {
                    request.MarginCurrencyId = data.MarginCurrencyId ? UUID.fromString(data.MarginCurrencyId) : null;
                } else if (key === 'ProfitCurrencyId') {
                    request.ProfitCurrencyId = data.ProfitCurrencyId ? UUID.fromString(data.ProfitCurrencyId) : null;
                } else if (key === 'TradingMode') {
                    request.TradingMode = data.TradingMode ? new TradingMode(data.TradingMode) : null;
                } else if (key === 'StopsLevel') {
                    request.StopsLevel = UInt64.fromNumber(data.StopsLevel ?? 0);
                } else if (key === 'SwapDaysInYear') {
                    request.SwapDaysInYear =
                        typeof data.SwapDaysInYear === 'number' ? UInt64.fromNumber(data.SwapDaysInYear) : null;
                } else {
                    request[key] = data[key];
                }
            }
        });

        request.IsSwapEnabled = data.IsSwapEnabled;
        request.SwapType = data.SwapType !== null ? new SwapCalculationType(data.SwapType) : null;
        request.SwapLong = data.SwapLong;
        request.SwapShort = data.SwapShort;
        request.SwapDaysInYear =
            data.SwapType === SwapCalculationType.BY_PERCENTAGE_CURRENT_PRICE.valueOf() &&
            typeof data.SwapDaysInYear === 'number'
                ? UInt64.fromNumber(data.SwapDaysInYear)
                : null;
        request.SwapTripleDay = data.SwapTripleDay !== null ? new DayOfWeek(data.SwapTripleDay) : null;
        
        if (data.ProfitCurrencyPrecision !== null) {
            request.ProfitCurrencyPrecision = data.ProfitCurrencyPrecision;
        }
        if (data.MarginCurrencyPrecision !== null) {
            request.MarginCurrencyPrecision = data.MarginCurrencyPrecision;
        }

        const res = await SocketClient.instance.request(request, this.errorTracker);
        await this.get();

        return adaptApi(res.Symbol);
    }

    @ErrorsTracker.wrapApi()
    async delete(id: string | UUID) {
        const request = new SymbolDeleteRequest();

        request.SymbolId = new UUID(id);

        await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
    }

    @ErrorsTracker.wrapApi()
    async update(newData: Partial<ConvertedSymbol>) {
        const request = new SymbolModifyRequest();

        request.SymbolId = UUID.fromString(newData.id!);
        Object.keys(newData).forEach((key) => {
            if (newData[key] !== undefined) {
                if (key === 'SymbolGroupId') {
                    request.NewSymbolGroupId = UUID.fromString(newData.SymbolGroupId!);
                } else if (key === 'ProfitCalculationType') {
                    request.NewProfitCalculationType = new ProfitCalculationType(newData.ProfitCalculationType);
                } else if (key === 'MarginCalculationType') {
                    request.NewMarginCalculationType = new MarginCalculationType(newData.MarginCalculationType);
                } else if (key === 'MarginCurrencyId') {
                    request.NewMarginCurrencyId = newData[key] ? UUID.fromString(newData[key]!) : null;
                } else if (key === 'ProfitCurrencyId') {
                    request.NewProfitCurrencyId = newData[key] ? UUID.fromString(newData[key]!) : null;
                } else if (key === 'TradingMode') {
                    request.NewTradingMode = newData.TradingMode ? new TradingMode(newData.TradingMode) : null;
                    request.NewTradingModeReset = true;
                } else if (key === 'StopsLevel') {
                    request.NewStopsLevel =
                        typeof newData.StopsLevel === 'number' ? UInt64.fromNumber(newData.StopsLevel) : null;
                } else if (key === 'SwapDaysInYear') {
                    request.NewSwapDaysInYear =
                        typeof newData.SwapDaysInYear === 'number' ? UInt64.fromNumber(newData.SwapDaysInYear) : null;
                } else {
                    const k = `New${key}` as `New${typeof key}`;
                    request[k] = newData[key];
                }
            }
        });

        request.NewIsTradeAllowed = newData.IsTradeAllowed;
        if (newData.IsTradeAllowed === null) request.NewIsTradeAllowedReset = true;
        request.NewIsSwapEnabled = newData.IsSwapEnabled;
        request.NewSwapType = newData.SwapType !== null ? new SwapCalculationType(newData.SwapType) : null;
        request.NewSwapLong = newData.SwapLong ?? null;
        request.NewSwapShort = newData.SwapShort ?? null;
        request.NewSwapDaysInYear =
            newData.SwapType === SwapCalculationType.BY_PERCENTAGE_CURRENT_PRICE.valueOf() &&
            typeof newData.SwapDaysInYear === 'number'
                ? UInt64.fromNumber(newData.SwapDaysInYear)
                : null;
        request.NewSwapTripleDay = newData.SwapTripleDay !== null ? new DayOfWeek(newData.SwapTripleDay) : null;

        request.NewProfitCurrencyPrecision = newData.ProfitCurrencyPrecision !== null ? newData.ProfitCurrencyPrecision : null;
        if (typeof newData.ProfitCurrencyPrecision !== 'number') {
            request.NewProfitCurrencyPrecisionReset = true;
        }
        request.NewMarginCurrencyPrecision = newData.MarginCurrencyPrecision !== null ? newData.MarginCurrencyPrecision : null;
        if (typeof newData.MarginCurrencyPrecision !== 'number') {
            request.NewMarginCurrencyPrecisionReset = true;
        }
        if (!newData.IsFavorite) {
            request.NewSortOrderFavorite = null;
        }
        const data = await SocketClient.instance.request(request, this.errorTracker);
        await this.get();
        return adaptApi(data.Symbol);
    }

    @ErrorsTracker.wrapApi()
    async updateItemSortOrder(id: string, newIndex: number) {
        const targetItem = this.data.find((item) => item.id === id);
        if (targetItem) {
            const request = new SymbolModifyRequest();
            request.SymbolId = UUID.fromString(targetItem.id);
            request.NewSortOrder = newIndex;
            await SocketClient.instance.request(request, this.errorTracker);
            this.get();
        }
    }

    @ErrorsTracker.wrapApi()
    async updateItemSortOrderFavorite(id: string, newIndex: number) {
        const targetItem = this.data.find((item) => item.id === id);
        if (targetItem) {
            const request = new SymbolModifyRequest();
            request.SymbolId = UUID.fromString(targetItem.id);
            request.NewSortOrderFavorite = newIndex;
            await SocketClient.instance.request(request, this.errorTracker);
            this.get();
        }
    }

    @ErrorsTracker.wrapApi()
    async importSwaps(swaps: SwapImportSettings[]): Promise<SwapsImportResponse> {
        const request = new SwapsImportRequest();

        request.SwapImportSettings = swaps;

        const response = await SocketClient.instance.request(request, this.errorTracker);
        return response;
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const symbolsStore = new SymbolsStore();
export { symbolsStore };
