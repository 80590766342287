
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * TransactionDuration struct
 */
class TransactionDuration {
  /**
   * Initialize struct
   * @param {!Date=} startTime
   * @param {!Date=} endTime
   * @param {!number=} durationInNanoseconds
   * @constructor
   */
  constructor (startTime = new Date(0), endTime = new Date(0), durationInNanoseconds = new UInt64(0, 0)) {
    this.StartTime = startTime
    this.EndTime = endTime
    this.DurationInNanoseconds = durationInNanoseconds
  }

  /**
   * Copy struct (shallow copy)
   * @this {!TransactionDuration}
   * @param {!TransactionDuration} other Other struct
   * @returns {!TransactionDuration} This struct
   */
  copy (other) {
    if (other.StartTime != null) {
      if (other.StartTime instanceof Date) {
        this.StartTime = new Date(other.StartTime.getTime())
      } else {
        this.StartTime = new Date(Math.round(other.StartTime / 1000000))
      }
    } else {
      this.StartTime = null
    }
    if (other.EndTime != null) {
      if (other.EndTime instanceof Date) {
        this.EndTime = new Date(other.EndTime.getTime())
      } else {
        this.EndTime = new Date(Math.round(other.EndTime / 1000000))
      }
    } else {
      this.EndTime = null
    }
    if (other.DurationInNanoseconds != null) {
      this.DurationInNanoseconds = UInt64.fromNumber(other.DurationInNanoseconds)
    } else {
      this.DurationInNanoseconds = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!TransactionDuration}
   * @returns {!TransactionDuration} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new TransactionDurationModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new TransactionDurationModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!TransactionDuration}
   * @param {!TransactionDuration} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TransactionDuration)) {
      throw new TypeError('Instance of TransactionDuration is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!TransactionDuration}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      StartTime: ((this.StartTime != null) ? (this.StartTime.getTime() * 1000000) : null),
      EndTime: ((this.EndTime != null) ? (this.EndTime.getTime() * 1000000) : null),
      DurationInNanoseconds: ((this.DurationInNanoseconds != null) ? this.DurationInNanoseconds.toNumber() : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return TransactionDuration.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!TransactionDuration} other Object value
   * @returns {!TransactionDuration} Created struct
   */
  static fromObject (other) {
    return new TransactionDuration().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!TransactionDuration}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return TransactionDuration.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!TransactionDuration}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 4097
  }
}

export { TransactionDuration };

/**
 * Fast Binary Encoding TransactionDuration field model
 */
class FieldModelTransactionDuration extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.StartTime = new fbe.FieldModelTimestamp(buffer, 4 + 4)
    this.EndTime = new fbe.FieldModelTimestamp(buffer, this.StartTime.fbeOffset + this.StartTime.fbeSize)
    this.DurationInNanoseconds = new fbe.FieldModelUInt64(buffer, this.EndTime.fbeOffset + this.EndTime.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelTransactionDuration}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelTransactionDuration}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.StartTime.fbeSize + this.EndTime.fbeSize + this.DurationInNanoseconds.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelTransactionDuration}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.StartTime.fbeExtra + this.EndTime.fbeExtra + this.DurationInNanoseconds.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelTransactionDuration}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelTransactionDuration.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelTransactionDuration}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 4097
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelTransactionDuration}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelTransactionDuration}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.StartTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.StartTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StartTime.fbeSize

    if ((fbeCurrentSize + this.EndTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.EndTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.EndTime.fbeSize

    if ((fbeCurrentSize + this.DurationInNanoseconds.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DurationInNanoseconds.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DurationInNanoseconds.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelTransactionDuration}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelTransactionDuration}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelTransactionDuration}
   * @param {!TransactionDuration} fbeValue Default value, defaults is new TransactionDuration()
   * @returns {!TransactionDuration} TransactionDuration value
   */
  get (fbeValue = new TransactionDuration()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelTransactionDuration}
   * @param {!TransactionDuration} fbeValue TransactionDuration value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.StartTime.fbeSize) <= fbeStructSize) {
      fbeValue.StartTime = this.StartTime.get()
    } else {
      fbeValue.StartTime = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.StartTime.fbeSize

    if ((fbeCurrentSize + this.EndTime.fbeSize) <= fbeStructSize) {
      fbeValue.EndTime = this.EndTime.get()
    } else {
      fbeValue.EndTime = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.EndTime.fbeSize

    if ((fbeCurrentSize + this.DurationInNanoseconds.fbeSize) <= fbeStructSize) {
      fbeValue.DurationInNanoseconds = this.DurationInNanoseconds.get()
    } else {
      fbeValue.DurationInNanoseconds = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DurationInNanoseconds.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelTransactionDuration}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelTransactionDuration}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelTransactionDuration}
   * @param {!TransactionDuration} fbeValue TransactionDuration value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelTransactionDuration}
   * @param {TransactionDuration} fbeValue TransactionDuration value
   */
  setFields (fbeValue) {
    this.StartTime.set(fbeValue.StartTime)
    this.EndTime.set(fbeValue.EndTime)
    this.DurationInNanoseconds.set(fbeValue.DurationInNanoseconds)
  }
}

export { FieldModelTransactionDuration };

/**
 * Fast Binary Encoding TransactionDuration model
 */
class TransactionDurationModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelTransactionDuration(this.buffer, 4)
  }

  /**
   * Get the TransactionDuration model
   * @this {!TransactionDurationModel}
   * @returns {!FieldModelTransactionDuration} model TransactionDuration model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!TransactionDurationModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!TransactionDurationModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return TransactionDurationModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!TransactionDurationModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelTransactionDuration.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!TransactionDurationModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!TransactionDurationModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!TransactionDurationModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!TransactionDurationModel}
   * @param {!TransactionDuration} value TransactionDuration value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!TransactionDurationModel}
   * @param {!TransactionDuration} value TransactionDuration value, defaults is new TransactionDuration()
   * @return {!object} Deserialized TransactionDuration value and its size
   */
  deserialize (value = new TransactionDuration()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new TransactionDuration(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new TransactionDuration(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!TransactionDurationModel}
   * @param {!number} prev Previous TransactionDuration model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { TransactionDurationModel };
