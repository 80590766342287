
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Response} from '../core/Response';
import {FieldModelResponse} from '../core/Response';
import {Account} from '../core/Account';
import {FieldModelAccount} from '../core/Account';
import {AccountMargin} from '../core/AccountMargin';
import {FieldModelAccountMargin} from '../core/AccountMargin';

/**
 * DemoAccountCreateResponse struct
 */
class DemoAccountCreateResponse extends Response {
  /**
   * Initialize struct
   * @param {!Response=} parent
   * @param {!Account=} account
   * @param {AccountMargin=} margin
   * @param {!string=} password
   * @param {!string=} investorPassword
   * @param {!string=} phonePassword
   * @param {string=} oTPSecret
   * @constructor
   */
  constructor (parent = new Response(), account = new Account(), margin = null, password = '', investorPassword = '', phonePassword = '', oTPSecret = null) {
    super()
    super.copy(parent)
    this.Account = account
    this.Margin = margin
    this.Password = password
    this.InvestorPassword = investorPassword
    this.PhonePassword = phonePassword
    this.OTPSecret = oTPSecret
  }

  /**
   * Copy struct (shallow copy)
   * @this {!DemoAccountCreateResponse}
   * @param {!DemoAccountCreateResponse} other Other struct
   * @returns {!DemoAccountCreateResponse} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Account != null) {
      this.Account = Account.fromObject(other.Account)
    } else {
      this.Account = null
    }
    if (other.Margin != null) {
      this.Margin = AccountMargin.fromObject(other.Margin)
    } else {
      this.Margin = null
    }
    if (other.Password != null) {
      this.Password = other.Password
    } else {
      this.Password = null
    }
    if (other.InvestorPassword != null) {
      this.InvestorPassword = other.InvestorPassword
    } else {
      this.InvestorPassword = null
    }
    if (other.PhonePassword != null) {
      this.PhonePassword = other.PhonePassword
    } else {
      this.PhonePassword = null
    }
    if (other.OTPSecret != null) {
      this.OTPSecret = other.OTPSecret
    } else {
      this.OTPSecret = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!DemoAccountCreateResponse}
   * @returns {!DemoAccountCreateResponse} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new DemoAccountCreateResponseModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new DemoAccountCreateResponseModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!DemoAccountCreateResponse}
   * @param {!DemoAccountCreateResponse} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof DemoAccountCreateResponse)) {
      throw new TypeError('Instance of DemoAccountCreateResponse is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!DemoAccountCreateResponse}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Account: ((this.Account != null) ? this.Account : null),
      Margin: ((this.Margin != null) ? this.Margin : null),
      Password: ((this.Password != null) ? this.Password : null),
      InvestorPassword: ((this.InvestorPassword != null) ? this.InvestorPassword : null),
      PhonePassword: ((this.PhonePassword != null) ? this.PhonePassword : null),
      OTPSecret: ((this.OTPSecret != null) ? this.OTPSecret : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return DemoAccountCreateResponse.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!DemoAccountCreateResponse} other Object value
   * @returns {!DemoAccountCreateResponse} Created struct
   */
  static fromObject (other) {
    return new DemoAccountCreateResponse().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!DemoAccountCreateResponse}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return DemoAccountCreateResponse.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!DemoAccountCreateResponse}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 7006
  }
}

export { DemoAccountCreateResponse };

/**
 * Fast Binary Encoding DemoAccountCreateResponse field model
 */
class FieldModelDemoAccountCreateResponse extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelResponse(buffer, 4 + 4)
    this.Account = new FieldModelAccount(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.Margin = new fbe.FieldModelOptional(new FieldModelAccountMargin(buffer, this.Account.fbeOffset + this.Account.fbeSize), buffer, this.Account.fbeOffset + this.Account.fbeSize)
    this.Password = new fbe.FieldModelString(buffer, this.Margin.fbeOffset + this.Margin.fbeSize)
    this.InvestorPassword = new fbe.FieldModelString(buffer, this.Password.fbeOffset + this.Password.fbeSize)
    this.PhonePassword = new fbe.FieldModelString(buffer, this.InvestorPassword.fbeOffset + this.InvestorPassword.fbeSize)
    this.OTPSecret = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.PhonePassword.fbeOffset + this.PhonePassword.fbeSize), buffer, this.PhonePassword.fbeOffset + this.PhonePassword.fbeSize)
  }

  /**
   * Get the core.Response field model
   * @this {!FieldModelDemoAccountCreateResponse}
   * @returns {!FieldModelResponse} core.Response field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelDemoAccountCreateResponse}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelDemoAccountCreateResponse}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Account.fbeSize + this.Margin.fbeSize + this.Password.fbeSize + this.InvestorPassword.fbeSize + this.PhonePassword.fbeSize + this.OTPSecret.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelDemoAccountCreateResponse}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Account.fbeExtra + this.Margin.fbeExtra + this.Password.fbeExtra + this.InvestorPassword.fbeExtra + this.PhonePassword.fbeExtra + this.OTPSecret.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelDemoAccountCreateResponse}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelDemoAccountCreateResponse.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelDemoAccountCreateResponse}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 7006
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelDemoAccountCreateResponse}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelDemoAccountCreateResponse}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Account.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Account.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Account.fbeSize

    if ((fbeCurrentSize + this.Margin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Margin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Margin.fbeSize

    if ((fbeCurrentSize + this.Password.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Password.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Password.fbeSize

    if ((fbeCurrentSize + this.InvestorPassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.InvestorPassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.InvestorPassword.fbeSize

    if ((fbeCurrentSize + this.PhonePassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PhonePassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PhonePassword.fbeSize

    if ((fbeCurrentSize + this.OTPSecret.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OTPSecret.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OTPSecret.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelDemoAccountCreateResponse}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelDemoAccountCreateResponse}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelDemoAccountCreateResponse}
   * @param {!DemoAccountCreateResponse} fbeValue Default value, defaults is new DemoAccountCreateResponse()
   * @returns {!DemoAccountCreateResponse} DemoAccountCreateResponse value
   */
  get (fbeValue = new DemoAccountCreateResponse()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelDemoAccountCreateResponse}
   * @param {!DemoAccountCreateResponse} fbeValue DemoAccountCreateResponse value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Account.fbeSize) <= fbeStructSize) {
      fbeValue.Account = this.Account.get()
    } else {
      fbeValue.Account = new Account()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Account.fbeSize

    if ((fbeCurrentSize + this.Margin.fbeSize) <= fbeStructSize) {
      fbeValue.Margin = this.Margin.get()
    } else {
      fbeValue.Margin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Margin.fbeSize

    if ((fbeCurrentSize + this.Password.fbeSize) <= fbeStructSize) {
      fbeValue.Password = this.Password.get()
    } else {
      fbeValue.Password = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Password.fbeSize

    if ((fbeCurrentSize + this.InvestorPassword.fbeSize) <= fbeStructSize) {
      fbeValue.InvestorPassword = this.InvestorPassword.get()
    } else {
      fbeValue.InvestorPassword = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.InvestorPassword.fbeSize

    if ((fbeCurrentSize + this.PhonePassword.fbeSize) <= fbeStructSize) {
      fbeValue.PhonePassword = this.PhonePassword.get()
    } else {
      fbeValue.PhonePassword = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PhonePassword.fbeSize

    if ((fbeCurrentSize + this.OTPSecret.fbeSize) <= fbeStructSize) {
      fbeValue.OTPSecret = this.OTPSecret.get()
    } else {
      fbeValue.OTPSecret = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OTPSecret.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelDemoAccountCreateResponse}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelDemoAccountCreateResponse}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelDemoAccountCreateResponse}
   * @param {!DemoAccountCreateResponse} fbeValue DemoAccountCreateResponse value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelDemoAccountCreateResponse}
   * @param {DemoAccountCreateResponse} fbeValue DemoAccountCreateResponse value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Account.set(fbeValue.Account)
    this.Margin.set(fbeValue.Margin)
    this.Password.set(fbeValue.Password)
    this.InvestorPassword.set(fbeValue.InvestorPassword)
    this.PhonePassword.set(fbeValue.PhonePassword)
    this.OTPSecret.set(fbeValue.OTPSecret)
  }
}

export { FieldModelDemoAccountCreateResponse };

/**
 * Fast Binary Encoding DemoAccountCreateResponse model
 */
class DemoAccountCreateResponseModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelDemoAccountCreateResponse(this.buffer, 4)
  }

  /**
   * Get the DemoAccountCreateResponse model
   * @this {!DemoAccountCreateResponseModel}
   * @returns {!FieldModelDemoAccountCreateResponse} model DemoAccountCreateResponse model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!DemoAccountCreateResponseModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!DemoAccountCreateResponseModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return DemoAccountCreateResponseModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!DemoAccountCreateResponseModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelDemoAccountCreateResponse.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!DemoAccountCreateResponseModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!DemoAccountCreateResponseModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!DemoAccountCreateResponseModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!DemoAccountCreateResponseModel}
   * @param {!DemoAccountCreateResponse} value DemoAccountCreateResponse value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!DemoAccountCreateResponseModel}
   * @param {!DemoAccountCreateResponse} value DemoAccountCreateResponse value, defaults is new DemoAccountCreateResponse()
   * @return {!object} Deserialized DemoAccountCreateResponse value and its size
   */
  deserialize (value = new DemoAccountCreateResponse()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new DemoAccountCreateResponse(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new DemoAccountCreateResponse(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!DemoAccountCreateResponseModel}
   * @param {!number} prev Previous DemoAccountCreateResponse model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { DemoAccountCreateResponseModel };
