
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as feed from '../feed'
import * as history from '../history'
import * as storage from '../storage'
import * as client from '../client'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {LeverageProfileModifyResponse} from './LeverageProfileModifyResponse';
import {LeverageProfileLeverageMode} from '../core/LeverageProfileLeverageMode';
import {FieldModelLeverageProfileLeverageMode} from '../core/LeverageProfileLeverageMode';
import {LeverageProfileValue} from '../core/LeverageProfileValue';
import {FieldModelLeverageProfileValue} from '../core/LeverageProfileValue';

/**
 * LeverageProfileModifyRequest struct
 */
class LeverageProfileModifyRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {UUID=} leverageProfileId
   * @param {string=} leverageProfileName
   * @param {string=} newName
   * @param {string=} newComment
   * @param {string=} newDescription
   * @param {LeverageProfileLeverageMode=} newLeverageMode
   * @param {number=} newLeverageThreshold
   * @param {number=} newRefreshTime
   * @param {!Array=} newValues
   * @param {!boolean=} valuesReset
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), leverageProfileId = null, leverageProfileName = null, newName = null, newComment = null, newDescription = null, newLeverageMode = null, newLeverageThreshold = null, newRefreshTime = null, newValues = [], valuesReset = false) {
    super()
    super.copy(parent)
    this.LeverageProfileId = leverageProfileId
    this.LeverageProfileName = leverageProfileName
    this.NewName = newName
    this.NewComment = newComment
    this.NewDescription = newDescription
    this.NewLeverageMode = newLeverageMode
    this.NewLeverageThreshold = newLeverageThreshold
    this.NewRefreshTime = newRefreshTime
    this.NewValues = newValues
    this.ValuesReset = valuesReset
  }

  /**
   * Copy struct (shallow copy)
   * @this {!LeverageProfileModifyRequest}
   * @param {!LeverageProfileModifyRequest} other Other struct
   * @returns {!LeverageProfileModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.LeverageProfileId != null) {
      this.LeverageProfileId = new UUID(other.LeverageProfileId)
    } else {
      this.LeverageProfileId = null
    }
    if (other.LeverageProfileName != null) {
      this.LeverageProfileName = other.LeverageProfileName
    } else {
      this.LeverageProfileName = null
    }
    if (other.NewName != null) {
      this.NewName = other.NewName
    } else {
      this.NewName = null
    }
    if (other.NewComment != null) {
      this.NewComment = other.NewComment
    } else {
      this.NewComment = null
    }
    if (other.NewDescription != null) {
      this.NewDescription = other.NewDescription
    } else {
      this.NewDescription = null
    }
    if (other.NewLeverageMode != null) {
      this.NewLeverageMode = LeverageProfileLeverageMode.fromObject(other.NewLeverageMode)
    } else {
      this.NewLeverageMode = null
    }
    if (other.NewLeverageThreshold != null) {
      this.NewLeverageThreshold = other.NewLeverageThreshold
    } else {
      this.NewLeverageThreshold = null
    }
    if (other.NewRefreshTime != null) {
      this.NewRefreshTime = other.NewRefreshTime
    } else {
      this.NewRefreshTime = null
    }
    if (other.NewValues != null) {
      this.NewValues = []
      for (let item of other.NewValues) {
        if (item != null) {
          let tempItem
          tempItem = LeverageProfileValue.fromObject(item)
          this.NewValues.push(tempItem)
        } else {
          this.NewValues.push(null)
        }
      }
    } else {
      this.NewValues = null
    }
    if (other.ValuesReset != null) {
      this.ValuesReset = other.ValuesReset
    } else {
      this.ValuesReset = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!LeverageProfileModifyRequest}
   * @returns {!LeverageProfileModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new LeverageProfileModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new LeverageProfileModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!LeverageProfileModifyRequest}
   * @param {!LeverageProfileModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof LeverageProfileModifyRequest)) {
      throw new TypeError('Instance of LeverageProfileModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!LeverageProfileModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      LeverageProfileId: ((this.LeverageProfileId != null) ? this.LeverageProfileId.toString() : null),
      LeverageProfileName: ((this.LeverageProfileName != null) ? this.LeverageProfileName : null),
      NewName: ((this.NewName != null) ? this.NewName : null),
      NewComment: ((this.NewComment != null) ? this.NewComment : null),
      NewDescription: ((this.NewDescription != null) ? this.NewDescription : null),
      NewLeverageMode: ((this.NewLeverageMode != null) ? this.NewLeverageMode : null),
      NewLeverageThreshold: ((this.NewLeverageThreshold != null) ? this.NewLeverageThreshold : null),
      NewRefreshTime: ((this.NewRefreshTime != null) ? this.NewRefreshTime : null),
      NewValues: ((this.NewValues != null) ? Array.from(this.NewValues, item => ((item != null) ? item : null)) : null),
      ValuesReset: ((this.ValuesReset != null) ? this.ValuesReset : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return LeverageProfileModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!LeverageProfileModifyRequest} other Object value
   * @returns {!LeverageProfileModifyRequest} Created struct
   */
  static fromObject (other) {
    return new LeverageProfileModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!LeverageProfileModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return LeverageProfileModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!LeverageProfileModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6379
  }
}

export { LeverageProfileModifyRequest };

/**
 * Fast Binary Encoding LeverageProfileModifyRequest field model
 */
class FieldModelLeverageProfileModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.LeverageProfileId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.LeverageProfileName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.LeverageProfileId.fbeOffset + this.LeverageProfileId.fbeSize), buffer, this.LeverageProfileId.fbeOffset + this.LeverageProfileId.fbeSize)
    this.NewName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.LeverageProfileName.fbeOffset + this.LeverageProfileName.fbeSize), buffer, this.LeverageProfileName.fbeOffset + this.LeverageProfileName.fbeSize)
    this.NewComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewName.fbeOffset + this.NewName.fbeSize), buffer, this.NewName.fbeOffset + this.NewName.fbeSize)
    this.NewDescription = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize), buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize)
    this.NewLeverageMode = new fbe.FieldModelOptional(new FieldModelLeverageProfileLeverageMode(buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize), buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize)
    this.NewLeverageThreshold = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.NewLeverageMode.fbeOffset + this.NewLeverageMode.fbeSize), buffer, this.NewLeverageMode.fbeOffset + this.NewLeverageMode.fbeSize)
    this.NewRefreshTime = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.NewLeverageThreshold.fbeOffset + this.NewLeverageThreshold.fbeSize), buffer, this.NewLeverageThreshold.fbeOffset + this.NewLeverageThreshold.fbeSize)
    this.NewValues = new fbe.FieldModelVector(new FieldModelLeverageProfileValue(buffer, this.NewRefreshTime.fbeOffset + this.NewRefreshTime.fbeSize), buffer, this.NewRefreshTime.fbeOffset + this.NewRefreshTime.fbeSize)
    this.ValuesReset = new fbe.FieldModelBool(buffer, this.NewValues.fbeOffset + this.NewValues.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelLeverageProfileModifyRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelLeverageProfileModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelLeverageProfileModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.LeverageProfileId.fbeSize + this.LeverageProfileName.fbeSize + this.NewName.fbeSize + this.NewComment.fbeSize + this.NewDescription.fbeSize + this.NewLeverageMode.fbeSize + this.NewLeverageThreshold.fbeSize + this.NewRefreshTime.fbeSize + this.NewValues.fbeSize + this.ValuesReset.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelLeverageProfileModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.LeverageProfileId.fbeExtra + this.LeverageProfileName.fbeExtra + this.NewName.fbeExtra + this.NewComment.fbeExtra + this.NewDescription.fbeExtra + this.NewLeverageMode.fbeExtra + this.NewLeverageThreshold.fbeExtra + this.NewRefreshTime.fbeExtra + this.NewValues.fbeExtra + this.ValuesReset.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelLeverageProfileModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelLeverageProfileModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelLeverageProfileModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6379
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelLeverageProfileModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelLeverageProfileModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.LeverageProfileId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LeverageProfileId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LeverageProfileId.fbeSize

    if ((fbeCurrentSize + this.LeverageProfileName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LeverageProfileName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LeverageProfileName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDescription.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewLeverageMode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewLeverageMode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLeverageMode.fbeSize

    if ((fbeCurrentSize + this.NewLeverageThreshold.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewLeverageThreshold.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLeverageThreshold.fbeSize

    if ((fbeCurrentSize + this.NewRefreshTime.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewRefreshTime.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewRefreshTime.fbeSize

    if ((fbeCurrentSize + this.NewValues.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewValues.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewValues.fbeSize

    if ((fbeCurrentSize + this.ValuesReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ValuesReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ValuesReset.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelLeverageProfileModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelLeverageProfileModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelLeverageProfileModifyRequest}
   * @param {!LeverageProfileModifyRequest} fbeValue Default value, defaults is new LeverageProfileModifyRequest()
   * @returns {!LeverageProfileModifyRequest} LeverageProfileModifyRequest value
   */
  get (fbeValue = new LeverageProfileModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelLeverageProfileModifyRequest}
   * @param {!LeverageProfileModifyRequest} fbeValue LeverageProfileModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.LeverageProfileId.fbeSize) <= fbeStructSize) {
      fbeValue.LeverageProfileId = this.LeverageProfileId.get()
    } else {
      fbeValue.LeverageProfileId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LeverageProfileId.fbeSize

    if ((fbeCurrentSize + this.LeverageProfileName.fbeSize) <= fbeStructSize) {
      fbeValue.LeverageProfileName = this.LeverageProfileName.get()
    } else {
      fbeValue.LeverageProfileName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LeverageProfileName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) <= fbeStructSize) {
      fbeValue.NewName = this.NewName.get()
    } else {
      fbeValue.NewName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewComment = this.NewComment.get()
    } else {
      fbeValue.NewComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) <= fbeStructSize) {
      fbeValue.NewDescription = this.NewDescription.get()
    } else {
      fbeValue.NewDescription = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewLeverageMode.fbeSize) <= fbeStructSize) {
      fbeValue.NewLeverageMode = this.NewLeverageMode.get()
    } else {
      fbeValue.NewLeverageMode = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLeverageMode.fbeSize

    if ((fbeCurrentSize + this.NewLeverageThreshold.fbeSize) <= fbeStructSize) {
      fbeValue.NewLeverageThreshold = this.NewLeverageThreshold.get()
    } else {
      fbeValue.NewLeverageThreshold = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLeverageThreshold.fbeSize

    if ((fbeCurrentSize + this.NewRefreshTime.fbeSize) <= fbeStructSize) {
      fbeValue.NewRefreshTime = this.NewRefreshTime.get()
    } else {
      fbeValue.NewRefreshTime = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewRefreshTime.fbeSize

    if ((fbeCurrentSize + this.NewValues.fbeSize) <= fbeStructSize) {
      this.NewValues.get(fbeValue.NewValues)
    } else {
      fbeValue.NewValues.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewValues.fbeSize

    if ((fbeCurrentSize + this.ValuesReset.fbeSize) <= fbeStructSize) {
      fbeValue.ValuesReset = this.ValuesReset.get()
    } else {
      fbeValue.ValuesReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ValuesReset.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelLeverageProfileModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelLeverageProfileModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelLeverageProfileModifyRequest}
   * @param {!LeverageProfileModifyRequest} fbeValue LeverageProfileModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelLeverageProfileModifyRequest}
   * @param {LeverageProfileModifyRequest} fbeValue LeverageProfileModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.LeverageProfileId.set(fbeValue.LeverageProfileId)
    this.LeverageProfileName.set(fbeValue.LeverageProfileName)
    this.NewName.set(fbeValue.NewName)
    this.NewComment.set(fbeValue.NewComment)
    this.NewDescription.set(fbeValue.NewDescription)
    this.NewLeverageMode.set(fbeValue.NewLeverageMode)
    this.NewLeverageThreshold.set(fbeValue.NewLeverageThreshold)
    this.NewRefreshTime.set(fbeValue.NewRefreshTime)
    this.NewValues.set(fbeValue.NewValues)
    this.ValuesReset.set(fbeValue.ValuesReset)
  }
}

export { FieldModelLeverageProfileModifyRequest };

/**
 * Fast Binary Encoding LeverageProfileModifyRequest model
 */
class LeverageProfileModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelLeverageProfileModifyRequest(this.buffer, 4)
  }

  /**
   * Get the LeverageProfileModifyRequest model
   * @this {!LeverageProfileModifyRequestModel}
   * @returns {!FieldModelLeverageProfileModifyRequest} model LeverageProfileModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!LeverageProfileModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!LeverageProfileModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return LeverageProfileModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!LeverageProfileModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelLeverageProfileModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!LeverageProfileModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!LeverageProfileModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!LeverageProfileModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!LeverageProfileModifyRequestModel}
   * @param {!LeverageProfileModifyRequest} value LeverageProfileModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!LeverageProfileModifyRequestModel}
   * @param {!LeverageProfileModifyRequest} value LeverageProfileModifyRequest value, defaults is new LeverageProfileModifyRequest()
   * @return {!object} Deserialized LeverageProfileModifyRequest value and its size
   */
  deserialize (value = new LeverageProfileModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new LeverageProfileModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new LeverageProfileModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!LeverageProfileModifyRequestModel}
   * @param {!number} prev Previous LeverageProfileModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { LeverageProfileModifyRequestModel };
LeverageProfileModifyRequest.__has_response = true;
LeverageProfileModifyRequest.__response_class = LeverageProfileModifyResponse;
