
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as feed from '../feed'
import * as history from '../history'
import * as storage from '../storage'
import * as client from '../client'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {FilledOrdersSummaryInfoSubscribeResponse} from './FilledOrdersSummaryInfoSubscribeResponse';

/**
 * FilledOrdersSummaryInfoUpdateSubscriptionRequest struct
 */
class FilledOrdersSummaryInfoUpdateSubscriptionRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {string=} newProfitCurrency
   * @param {boolean=} newIsCommissionAndSwapsIncluded
   * @constructor
   */
  constructor (parent = new Request(), newProfitCurrency = null, newIsCommissionAndSwapsIncluded = null) {
    super()
    super.copy(parent)
    this.NewProfitCurrency = newProfitCurrency
    this.NewIsCommissionAndSwapsIncluded = newIsCommissionAndSwapsIncluded
  }

  /**
   * Copy struct (shallow copy)
   * @this {!FilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @param {!FilledOrdersSummaryInfoUpdateSubscriptionRequest} other Other struct
   * @returns {!FilledOrdersSummaryInfoUpdateSubscriptionRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.NewProfitCurrency != null) {
      this.NewProfitCurrency = other.NewProfitCurrency
    } else {
      this.NewProfitCurrency = null
    }
    if (other.NewIsCommissionAndSwapsIncluded != null) {
      this.NewIsCommissionAndSwapsIncluded = other.NewIsCommissionAndSwapsIncluded
    } else {
      this.NewIsCommissionAndSwapsIncluded = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!FilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @returns {!FilledOrdersSummaryInfoUpdateSubscriptionRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new FilledOrdersSummaryInfoUpdateSubscriptionRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new FilledOrdersSummaryInfoUpdateSubscriptionRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!FilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @param {!FilledOrdersSummaryInfoUpdateSubscriptionRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof FilledOrdersSummaryInfoUpdateSubscriptionRequest)) {
      throw new TypeError('Instance of FilledOrdersSummaryInfoUpdateSubscriptionRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!FilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      NewProfitCurrency: ((this.NewProfitCurrency != null) ? this.NewProfitCurrency : null),
      NewIsCommissionAndSwapsIncluded: ((this.NewIsCommissionAndSwapsIncluded != null) ? this.NewIsCommissionAndSwapsIncluded : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return FilledOrdersSummaryInfoUpdateSubscriptionRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!FilledOrdersSummaryInfoUpdateSubscriptionRequest} other Object value
   * @returns {!FilledOrdersSummaryInfoUpdateSubscriptionRequest} Created struct
   */
  static fromObject (other) {
    return new FilledOrdersSummaryInfoUpdateSubscriptionRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!FilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return FilledOrdersSummaryInfoUpdateSubscriptionRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!FilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6442
  }
}

export { FilledOrdersSummaryInfoUpdateSubscriptionRequest };

/**
 * Fast Binary Encoding FilledOrdersSummaryInfoUpdateSubscriptionRequest field model
 */
class FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.NewProfitCurrency = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.NewIsCommissionAndSwapsIncluded = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewProfitCurrency.fbeOffset + this.NewProfitCurrency.fbeSize), buffer, this.NewProfitCurrency.fbeOffset + this.NewProfitCurrency.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.NewProfitCurrency.fbeSize + this.NewIsCommissionAndSwapsIncluded.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.NewProfitCurrency.fbeExtra + this.NewIsCommissionAndSwapsIncluded.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6442
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.NewProfitCurrency.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewProfitCurrency.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewProfitCurrency.fbeSize

    if ((fbeCurrentSize + this.NewIsCommissionAndSwapsIncluded.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsCommissionAndSwapsIncluded.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsCommissionAndSwapsIncluded.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @param {!FilledOrdersSummaryInfoUpdateSubscriptionRequest} fbeValue Default value, defaults is new FilledOrdersSummaryInfoUpdateSubscriptionRequest()
   * @returns {!FilledOrdersSummaryInfoUpdateSubscriptionRequest} FilledOrdersSummaryInfoUpdateSubscriptionRequest value
   */
  get (fbeValue = new FilledOrdersSummaryInfoUpdateSubscriptionRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @param {!FilledOrdersSummaryInfoUpdateSubscriptionRequest} fbeValue FilledOrdersSummaryInfoUpdateSubscriptionRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.NewProfitCurrency.fbeSize) <= fbeStructSize) {
      fbeValue.NewProfitCurrency = this.NewProfitCurrency.get()
    } else {
      fbeValue.NewProfitCurrency = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewProfitCurrency.fbeSize

    if ((fbeCurrentSize + this.NewIsCommissionAndSwapsIncluded.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsCommissionAndSwapsIncluded = this.NewIsCommissionAndSwapsIncluded.get()
    } else {
      fbeValue.NewIsCommissionAndSwapsIncluded = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsCommissionAndSwapsIncluded.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @param {!FilledOrdersSummaryInfoUpdateSubscriptionRequest} fbeValue FilledOrdersSummaryInfoUpdateSubscriptionRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest}
   * @param {FilledOrdersSummaryInfoUpdateSubscriptionRequest} fbeValue FilledOrdersSummaryInfoUpdateSubscriptionRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.NewProfitCurrency.set(fbeValue.NewProfitCurrency)
    this.NewIsCommissionAndSwapsIncluded.set(fbeValue.NewIsCommissionAndSwapsIncluded)
  }
}

export { FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest };

/**
 * Fast Binary Encoding FilledOrdersSummaryInfoUpdateSubscriptionRequest model
 */
class FilledOrdersSummaryInfoUpdateSubscriptionRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest(this.buffer, 4)
  }

  /**
   * Get the FilledOrdersSummaryInfoUpdateSubscriptionRequest model
   * @this {!FilledOrdersSummaryInfoUpdateSubscriptionRequestModel}
   * @returns {!FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest} model FilledOrdersSummaryInfoUpdateSubscriptionRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!FilledOrdersSummaryInfoUpdateSubscriptionRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!FilledOrdersSummaryInfoUpdateSubscriptionRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return FilledOrdersSummaryInfoUpdateSubscriptionRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!FilledOrdersSummaryInfoUpdateSubscriptionRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelFilledOrdersSummaryInfoUpdateSubscriptionRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!FilledOrdersSummaryInfoUpdateSubscriptionRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!FilledOrdersSummaryInfoUpdateSubscriptionRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!FilledOrdersSummaryInfoUpdateSubscriptionRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!FilledOrdersSummaryInfoUpdateSubscriptionRequestModel}
   * @param {!FilledOrdersSummaryInfoUpdateSubscriptionRequest} value FilledOrdersSummaryInfoUpdateSubscriptionRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!FilledOrdersSummaryInfoUpdateSubscriptionRequestModel}
   * @param {!FilledOrdersSummaryInfoUpdateSubscriptionRequest} value FilledOrdersSummaryInfoUpdateSubscriptionRequest value, defaults is new FilledOrdersSummaryInfoUpdateSubscriptionRequest()
   * @return {!object} Deserialized FilledOrdersSummaryInfoUpdateSubscriptionRequest value and its size
   */
  deserialize (value = new FilledOrdersSummaryInfoUpdateSubscriptionRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new FilledOrdersSummaryInfoUpdateSubscriptionRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new FilledOrdersSummaryInfoUpdateSubscriptionRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!FilledOrdersSummaryInfoUpdateSubscriptionRequestModel}
   * @param {!number} prev Previous FilledOrdersSummaryInfoUpdateSubscriptionRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { FilledOrdersSummaryInfoUpdateSubscriptionRequestModel };
FilledOrdersSummaryInfoUpdateSubscriptionRequest.__has_response = true;
FilledOrdersSummaryInfoUpdateSubscriptionRequest.__response_class = FilledOrdersSummaryInfoSubscribeResponse;
