
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as feed from '../feed'
import * as history from '../history'
import * as storage from '../storage'
import * as client from '../client'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {ManagerCreateResponse} from './ManagerCreateResponse';
import {RightsMode} from '../core/RightsMode';
import {FieldModelRightsMode} from '../core/RightsMode';
import {ManagerRight} from '../core/ManagerRight';
import {FieldModelManagerRight} from '../core/ManagerRight';

/**
 * ManagerCreateRequest struct
 */
class ManagerCreateRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {number=} login
   * @param {number=} sortOrder
   * @param {UUID=} managerGroupId
   * @param {string=} managerGroupName
   * @param {!string=} name
   * @param {!string=} comment
   * @param {!string=} description
   * @param {!string=} email
   * @param {boolean=} isEnabled
   * @param {boolean=} isOTPEnabled
   * @param {!boolean=} isManagerRightsSet
   * @param {!Array=} accessibleAccountGroups
   * @param {!Array=} accessibleSymbolGroups
   * @param {RightsMode=} accountsRights
   * @param {RightsMode=} symbolsRights
   * @param {RightsMode=} systemRights
   * @param {RightsMode=} modulesRights
   * @param {RightsMode=} platformsRights
   * @param {RightsMode=} managersRights
   * @param {RightsMode=} currenciesRights
   * @param {RightsMode=} calendarsRights
   * @param {RightsMode=} profilesRights
   * @param {RightsMode=} dataFeedsRights
   * @param {RightsMode=} gatewaysRights
   * @param {RightsMode=} tradingRoutesRights
   * @param {RightsMode=} quotesHistoryRights
   * @param {RightsMode=} reportsRights
   * @param {RightsMode=} monitoringRights
   * @param {!Set=} rights
   * @param {string=} password
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), login = null, sortOrder = null, managerGroupId = null, managerGroupName = null, name = '', comment = '', description = '', email = '', isEnabled = null, isOTPEnabled = null, isManagerRightsSet = false, accessibleAccountGroups = [], accessibleSymbolGroups = [], accountsRights = null, symbolsRights = null, systemRights = null, modulesRights = null, platformsRights = null, managersRights = null, currenciesRights = null, calendarsRights = null, profilesRights = null, dataFeedsRights = null, gatewaysRights = null, tradingRoutesRights = null, quotesHistoryRights = null, reportsRights = null, monitoringRights = null, rights = new Set(), password = null) {
    super()
    super.copy(parent)
    this.Login = login
    this.SortOrder = sortOrder
    this.ManagerGroupId = managerGroupId
    this.ManagerGroupName = managerGroupName
    this.Name = name
    this.Comment = comment
    this.Description = description
    this.Email = email
    this.IsEnabled = isEnabled
    this.IsOTPEnabled = isOTPEnabled
    this.IsManagerRightsSet = isManagerRightsSet
    this.AccessibleAccountGroups = accessibleAccountGroups
    this.AccessibleSymbolGroups = accessibleSymbolGroups
    this.AccountsRights = accountsRights
    this.SymbolsRights = symbolsRights
    this.SystemRights = systemRights
    this.ModulesRights = modulesRights
    this.PlatformsRights = platformsRights
    this.ManagersRights = managersRights
    this.CurrenciesRights = currenciesRights
    this.CalendarsRights = calendarsRights
    this.ProfilesRights = profilesRights
    this.DataFeedsRights = dataFeedsRights
    this.GatewaysRights = gatewaysRights
    this.TradingRoutesRights = tradingRoutesRights
    this.QuotesHistoryRights = quotesHistoryRights
    this.ReportsRights = reportsRights
    this.MonitoringRights = monitoringRights
    this.Rights = rights
    this.Password = password
  }

  /**
   * Copy struct (shallow copy)
   * @this {!ManagerCreateRequest}
   * @param {!ManagerCreateRequest} other Other struct
   * @returns {!ManagerCreateRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.SortOrder != null) {
      this.SortOrder = other.SortOrder
    } else {
      this.SortOrder = null
    }
    if (other.ManagerGroupId != null) {
      this.ManagerGroupId = new UUID(other.ManagerGroupId)
    } else {
      this.ManagerGroupId = null
    }
    if (other.ManagerGroupName != null) {
      this.ManagerGroupName = other.ManagerGroupName
    } else {
      this.ManagerGroupName = null
    }
    if (other.Name != null) {
      this.Name = other.Name
    } else {
      this.Name = null
    }
    if (other.Comment != null) {
      this.Comment = other.Comment
    } else {
      this.Comment = null
    }
    if (other.Description != null) {
      this.Description = other.Description
    } else {
      this.Description = null
    }
    if (other.Email != null) {
      this.Email = other.Email
    } else {
      this.Email = null
    }
    if (other.IsEnabled != null) {
      this.IsEnabled = other.IsEnabled
    } else {
      this.IsEnabled = null
    }
    if (other.IsOTPEnabled != null) {
      this.IsOTPEnabled = other.IsOTPEnabled
    } else {
      this.IsOTPEnabled = null
    }
    if (other.IsManagerRightsSet != null) {
      this.IsManagerRightsSet = other.IsManagerRightsSet
    } else {
      this.IsManagerRightsSet = null
    }
    if (other.AccessibleAccountGroups != null) {
      this.AccessibleAccountGroups = []
      for (let item of other.AccessibleAccountGroups) {
        if (item != null) {
          let tempItem
          tempItem = item
          this.AccessibleAccountGroups.push(tempItem)
        } else {
          this.AccessibleAccountGroups.push(null)
        }
      }
    } else {
      this.AccessibleAccountGroups = null
    }
    if (other.AccessibleSymbolGroups != null) {
      this.AccessibleSymbolGroups = []
      for (let item of other.AccessibleSymbolGroups) {
        if (item != null) {
          let tempItem
          tempItem = item
          this.AccessibleSymbolGroups.push(tempItem)
        } else {
          this.AccessibleSymbolGroups.push(null)
        }
      }
    } else {
      this.AccessibleSymbolGroups = null
    }
    if (other.AccountsRights != null) {
      this.AccountsRights = RightsMode.fromObject(other.AccountsRights)
    } else {
      this.AccountsRights = null
    }
    if (other.SymbolsRights != null) {
      this.SymbolsRights = RightsMode.fromObject(other.SymbolsRights)
    } else {
      this.SymbolsRights = null
    }
    if (other.SystemRights != null) {
      this.SystemRights = RightsMode.fromObject(other.SystemRights)
    } else {
      this.SystemRights = null
    }
    if (other.ModulesRights != null) {
      this.ModulesRights = RightsMode.fromObject(other.ModulesRights)
    } else {
      this.ModulesRights = null
    }
    if (other.PlatformsRights != null) {
      this.PlatformsRights = RightsMode.fromObject(other.PlatformsRights)
    } else {
      this.PlatformsRights = null
    }
    if (other.ManagersRights != null) {
      this.ManagersRights = RightsMode.fromObject(other.ManagersRights)
    } else {
      this.ManagersRights = null
    }
    if (other.CurrenciesRights != null) {
      this.CurrenciesRights = RightsMode.fromObject(other.CurrenciesRights)
    } else {
      this.CurrenciesRights = null
    }
    if (other.CalendarsRights != null) {
      this.CalendarsRights = RightsMode.fromObject(other.CalendarsRights)
    } else {
      this.CalendarsRights = null
    }
    if (other.ProfilesRights != null) {
      this.ProfilesRights = RightsMode.fromObject(other.ProfilesRights)
    } else {
      this.ProfilesRights = null
    }
    if (other.DataFeedsRights != null) {
      this.DataFeedsRights = RightsMode.fromObject(other.DataFeedsRights)
    } else {
      this.DataFeedsRights = null
    }
    if (other.GatewaysRights != null) {
      this.GatewaysRights = RightsMode.fromObject(other.GatewaysRights)
    } else {
      this.GatewaysRights = null
    }
    if (other.TradingRoutesRights != null) {
      this.TradingRoutesRights = RightsMode.fromObject(other.TradingRoutesRights)
    } else {
      this.TradingRoutesRights = null
    }
    if (other.QuotesHistoryRights != null) {
      this.QuotesHistoryRights = RightsMode.fromObject(other.QuotesHistoryRights)
    } else {
      this.QuotesHistoryRights = null
    }
    if (other.ReportsRights != null) {
      this.ReportsRights = RightsMode.fromObject(other.ReportsRights)
    } else {
      this.ReportsRights = null
    }
    if (other.MonitoringRights != null) {
      this.MonitoringRights = RightsMode.fromObject(other.MonitoringRights)
    } else {
      this.MonitoringRights = null
    }
    if (other.Rights != null) {
      this.Rights = new Set()
      for (let item of other.Rights) {
        if (item != null) {
          let tempItem
          tempItem = ManagerRight.fromObject(item)
          this.Rights.add(tempItem)
        } else {
          this.Rights.add(null)
        }
      }
    } else {
      this.Rights = null
    }
    if (other.Password != null) {
      this.Password = other.Password
    } else {
      this.Password = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!ManagerCreateRequest}
   * @returns {!ManagerCreateRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ManagerCreateRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ManagerCreateRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!ManagerCreateRequest}
   * @param {!ManagerCreateRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ManagerCreateRequest)) {
      throw new TypeError('Instance of ManagerCreateRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!ManagerCreateRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      SortOrder: ((this.SortOrder != null) ? this.SortOrder : null),
      ManagerGroupId: ((this.ManagerGroupId != null) ? this.ManagerGroupId.toString() : null),
      ManagerGroupName: ((this.ManagerGroupName != null) ? this.ManagerGroupName : null),
      Name: ((this.Name != null) ? this.Name : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      Description: ((this.Description != null) ? this.Description : null),
      Email: ((this.Email != null) ? this.Email : null),
      IsEnabled: ((this.IsEnabled != null) ? this.IsEnabled : null),
      IsOTPEnabled: ((this.IsOTPEnabled != null) ? this.IsOTPEnabled : null),
      IsManagerRightsSet: ((this.IsManagerRightsSet != null) ? this.IsManagerRightsSet : null),
      AccessibleAccountGroups: ((this.AccessibleAccountGroups != null) ? Array.from(this.AccessibleAccountGroups, item => ((item != null) ? item : null)) : null),
      AccessibleSymbolGroups: ((this.AccessibleSymbolGroups != null) ? Array.from(this.AccessibleSymbolGroups, item => ((item != null) ? item : null)) : null),
      AccountsRights: ((this.AccountsRights != null) ? this.AccountsRights : null),
      SymbolsRights: ((this.SymbolsRights != null) ? this.SymbolsRights : null),
      SystemRights: ((this.SystemRights != null) ? this.SystemRights : null),
      ModulesRights: ((this.ModulesRights != null) ? this.ModulesRights : null),
      PlatformsRights: ((this.PlatformsRights != null) ? this.PlatformsRights : null),
      ManagersRights: ((this.ManagersRights != null) ? this.ManagersRights : null),
      CurrenciesRights: ((this.CurrenciesRights != null) ? this.CurrenciesRights : null),
      CalendarsRights: ((this.CalendarsRights != null) ? this.CalendarsRights : null),
      ProfilesRights: ((this.ProfilesRights != null) ? this.ProfilesRights : null),
      DataFeedsRights: ((this.DataFeedsRights != null) ? this.DataFeedsRights : null),
      GatewaysRights: ((this.GatewaysRights != null) ? this.GatewaysRights : null),
      TradingRoutesRights: ((this.TradingRoutesRights != null) ? this.TradingRoutesRights : null),
      QuotesHistoryRights: ((this.QuotesHistoryRights != null) ? this.QuotesHistoryRights : null),
      ReportsRights: ((this.ReportsRights != null) ? this.ReportsRights : null),
      MonitoringRights: ((this.MonitoringRights != null) ? this.MonitoringRights : null),
      Rights: ((this.Rights != null) ? Array.from(this.Rights, item => ((item != null) ? item : null)) : null),
      Password: ((this.Password != null) ? this.Password : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return ManagerCreateRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!ManagerCreateRequest} other Object value
   * @returns {!ManagerCreateRequest} Created struct
   */
  static fromObject (other) {
    return new ManagerCreateRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!ManagerCreateRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return ManagerCreateRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!ManagerCreateRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6159
  }
}

export { ManagerCreateRequest };

/**
 * Fast Binary Encoding ManagerCreateRequest field model
 */
class FieldModelManagerCreateRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.Login = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.SortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.Login.fbeOffset + this.Login.fbeSize), buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.ManagerGroupId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.SortOrder.fbeOffset + this.SortOrder.fbeSize), buffer, this.SortOrder.fbeOffset + this.SortOrder.fbeSize)
    this.ManagerGroupName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.ManagerGroupId.fbeOffset + this.ManagerGroupId.fbeSize), buffer, this.ManagerGroupId.fbeOffset + this.ManagerGroupId.fbeSize)
    this.Name = new fbe.FieldModelString(buffer, this.ManagerGroupName.fbeOffset + this.ManagerGroupName.fbeSize)
    this.Comment = new fbe.FieldModelString(buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.Description = new fbe.FieldModelString(buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.Email = new fbe.FieldModelString(buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.IsEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.Email.fbeOffset + this.Email.fbeSize), buffer, this.Email.fbeOffset + this.Email.fbeSize)
    this.IsOTPEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize), buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize)
    this.IsManagerRightsSet = new fbe.FieldModelBool(buffer, this.IsOTPEnabled.fbeOffset + this.IsOTPEnabled.fbeSize)
    this.AccessibleAccountGroups = new fbe.FieldModelVector(new fbe.FieldModelString(buffer, this.IsManagerRightsSet.fbeOffset + this.IsManagerRightsSet.fbeSize), buffer, this.IsManagerRightsSet.fbeOffset + this.IsManagerRightsSet.fbeSize)
    this.AccessibleSymbolGroups = new fbe.FieldModelVector(new fbe.FieldModelString(buffer, this.AccessibleAccountGroups.fbeOffset + this.AccessibleAccountGroups.fbeSize), buffer, this.AccessibleAccountGroups.fbeOffset + this.AccessibleAccountGroups.fbeSize)
    this.AccountsRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.AccessibleSymbolGroups.fbeOffset + this.AccessibleSymbolGroups.fbeSize), buffer, this.AccessibleSymbolGroups.fbeOffset + this.AccessibleSymbolGroups.fbeSize)
    this.SymbolsRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.AccountsRights.fbeOffset + this.AccountsRights.fbeSize), buffer, this.AccountsRights.fbeOffset + this.AccountsRights.fbeSize)
    this.SystemRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.SymbolsRights.fbeOffset + this.SymbolsRights.fbeSize), buffer, this.SymbolsRights.fbeOffset + this.SymbolsRights.fbeSize)
    this.ModulesRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.SystemRights.fbeOffset + this.SystemRights.fbeSize), buffer, this.SystemRights.fbeOffset + this.SystemRights.fbeSize)
    this.PlatformsRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.ModulesRights.fbeOffset + this.ModulesRights.fbeSize), buffer, this.ModulesRights.fbeOffset + this.ModulesRights.fbeSize)
    this.ManagersRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.PlatformsRights.fbeOffset + this.PlatformsRights.fbeSize), buffer, this.PlatformsRights.fbeOffset + this.PlatformsRights.fbeSize)
    this.CurrenciesRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.ManagersRights.fbeOffset + this.ManagersRights.fbeSize), buffer, this.ManagersRights.fbeOffset + this.ManagersRights.fbeSize)
    this.CalendarsRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.CurrenciesRights.fbeOffset + this.CurrenciesRights.fbeSize), buffer, this.CurrenciesRights.fbeOffset + this.CurrenciesRights.fbeSize)
    this.ProfilesRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.CalendarsRights.fbeOffset + this.CalendarsRights.fbeSize), buffer, this.CalendarsRights.fbeOffset + this.CalendarsRights.fbeSize)
    this.DataFeedsRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.ProfilesRights.fbeOffset + this.ProfilesRights.fbeSize), buffer, this.ProfilesRights.fbeOffset + this.ProfilesRights.fbeSize)
    this.GatewaysRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.DataFeedsRights.fbeOffset + this.DataFeedsRights.fbeSize), buffer, this.DataFeedsRights.fbeOffset + this.DataFeedsRights.fbeSize)
    this.TradingRoutesRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.GatewaysRights.fbeOffset + this.GatewaysRights.fbeSize), buffer, this.GatewaysRights.fbeOffset + this.GatewaysRights.fbeSize)
    this.QuotesHistoryRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.TradingRoutesRights.fbeOffset + this.TradingRoutesRights.fbeSize), buffer, this.TradingRoutesRights.fbeOffset + this.TradingRoutesRights.fbeSize)
    this.ReportsRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.QuotesHistoryRights.fbeOffset + this.QuotesHistoryRights.fbeSize), buffer, this.QuotesHistoryRights.fbeOffset + this.QuotesHistoryRights.fbeSize)
    this.MonitoringRights = new fbe.FieldModelOptional(new FieldModelRightsMode(buffer, this.ReportsRights.fbeOffset + this.ReportsRights.fbeSize), buffer, this.ReportsRights.fbeOffset + this.ReportsRights.fbeSize)
    this.Rights = new fbe.FieldModelSet(new FieldModelManagerRight(buffer, this.MonitoringRights.fbeOffset + this.MonitoringRights.fbeSize), buffer, this.MonitoringRights.fbeOffset + this.MonitoringRights.fbeSize)
    this.Password = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.Rights.fbeOffset + this.Rights.fbeSize), buffer, this.Rights.fbeOffset + this.Rights.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelManagerCreateRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelManagerCreateRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelManagerCreateRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Login.fbeSize + this.SortOrder.fbeSize + this.ManagerGroupId.fbeSize + this.ManagerGroupName.fbeSize + this.Name.fbeSize + this.Comment.fbeSize + this.Description.fbeSize + this.Email.fbeSize + this.IsEnabled.fbeSize + this.IsOTPEnabled.fbeSize + this.IsManagerRightsSet.fbeSize + this.AccessibleAccountGroups.fbeSize + this.AccessibleSymbolGroups.fbeSize + this.AccountsRights.fbeSize + this.SymbolsRights.fbeSize + this.SystemRights.fbeSize + this.ModulesRights.fbeSize + this.PlatformsRights.fbeSize + this.ManagersRights.fbeSize + this.CurrenciesRights.fbeSize + this.CalendarsRights.fbeSize + this.ProfilesRights.fbeSize + this.DataFeedsRights.fbeSize + this.GatewaysRights.fbeSize + this.TradingRoutesRights.fbeSize + this.QuotesHistoryRights.fbeSize + this.ReportsRights.fbeSize + this.MonitoringRights.fbeSize + this.Rights.fbeSize + this.Password.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelManagerCreateRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Login.fbeExtra + this.SortOrder.fbeExtra + this.ManagerGroupId.fbeExtra + this.ManagerGroupName.fbeExtra + this.Name.fbeExtra + this.Comment.fbeExtra + this.Description.fbeExtra + this.Email.fbeExtra + this.IsEnabled.fbeExtra + this.IsOTPEnabled.fbeExtra + this.IsManagerRightsSet.fbeExtra + this.AccessibleAccountGroups.fbeExtra + this.AccessibleSymbolGroups.fbeExtra + this.AccountsRights.fbeExtra + this.SymbolsRights.fbeExtra + this.SystemRights.fbeExtra + this.ModulesRights.fbeExtra + this.PlatformsRights.fbeExtra + this.ManagersRights.fbeExtra + this.CurrenciesRights.fbeExtra + this.CalendarsRights.fbeExtra + this.ProfilesRights.fbeExtra + this.DataFeedsRights.fbeExtra + this.GatewaysRights.fbeExtra + this.TradingRoutesRights.fbeExtra + this.QuotesHistoryRights.fbeExtra + this.ReportsRights.fbeExtra + this.MonitoringRights.fbeExtra + this.Rights.fbeExtra + this.Password.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelManagerCreateRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelManagerCreateRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelManagerCreateRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6159
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelManagerCreateRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelManagerCreateRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.SortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.ManagerGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ManagerGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerGroupId.fbeSize

    if ((fbeCurrentSize + this.ManagerGroupName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ManagerGroupName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerGroupName.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.Email.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Email.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Email.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.IsOTPEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsOTPEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.IsManagerRightsSet.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsManagerRightsSet.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsManagerRightsSet.fbeSize

    if ((fbeCurrentSize + this.AccessibleAccountGroups.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccessibleAccountGroups.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccessibleAccountGroups.fbeSize

    if ((fbeCurrentSize + this.AccessibleSymbolGroups.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccessibleSymbolGroups.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccessibleSymbolGroups.fbeSize

    if ((fbeCurrentSize + this.AccountsRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountsRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountsRights.fbeSize

    if ((fbeCurrentSize + this.SymbolsRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolsRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolsRights.fbeSize

    if ((fbeCurrentSize + this.SystemRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SystemRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SystemRights.fbeSize

    if ((fbeCurrentSize + this.ModulesRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ModulesRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModulesRights.fbeSize

    if ((fbeCurrentSize + this.PlatformsRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PlatformsRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformsRights.fbeSize

    if ((fbeCurrentSize + this.ManagersRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ManagersRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagersRights.fbeSize

    if ((fbeCurrentSize + this.CurrenciesRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CurrenciesRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CurrenciesRights.fbeSize

    if ((fbeCurrentSize + this.CalendarsRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CalendarsRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CalendarsRights.fbeSize

    if ((fbeCurrentSize + this.ProfilesRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProfilesRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfilesRights.fbeSize

    if ((fbeCurrentSize + this.DataFeedsRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.DataFeedsRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DataFeedsRights.fbeSize

    if ((fbeCurrentSize + this.GatewaysRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.GatewaysRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewaysRights.fbeSize

    if ((fbeCurrentSize + this.TradingRoutesRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TradingRoutesRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradingRoutesRights.fbeSize

    if ((fbeCurrentSize + this.QuotesHistoryRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.QuotesHistoryRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.QuotesHistoryRights.fbeSize

    if ((fbeCurrentSize + this.ReportsRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ReportsRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ReportsRights.fbeSize

    if ((fbeCurrentSize + this.MonitoringRights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MonitoringRights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MonitoringRights.fbeSize

    if ((fbeCurrentSize + this.Rights.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Rights.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Rights.fbeSize

    if ((fbeCurrentSize + this.Password.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Password.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Password.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelManagerCreateRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelManagerCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelManagerCreateRequest}
   * @param {!ManagerCreateRequest} fbeValue Default value, defaults is new ManagerCreateRequest()
   * @returns {!ManagerCreateRequest} ManagerCreateRequest value
   */
  get (fbeValue = new ManagerCreateRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelManagerCreateRequest}
   * @param {!ManagerCreateRequest} fbeValue ManagerCreateRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.SortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.SortOrder = this.SortOrder.get()
    } else {
      fbeValue.SortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SortOrder.fbeSize

    if ((fbeCurrentSize + this.ManagerGroupId.fbeSize) <= fbeStructSize) {
      fbeValue.ManagerGroupId = this.ManagerGroupId.get()
    } else {
      fbeValue.ManagerGroupId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerGroupId.fbeSize

    if ((fbeCurrentSize + this.ManagerGroupName.fbeSize) <= fbeStructSize) {
      fbeValue.ManagerGroupName = this.ManagerGroupName.get()
    } else {
      fbeValue.ManagerGroupName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerGroupName.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.Email.fbeSize) <= fbeStructSize) {
      fbeValue.Email = this.Email.get()
    } else {
      fbeValue.Email = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Email.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsEnabled = this.IsEnabled.get()
    } else {
      fbeValue.IsEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.IsOTPEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsOTPEnabled = this.IsOTPEnabled.get()
    } else {
      fbeValue.IsOTPEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.IsManagerRightsSet.fbeSize) <= fbeStructSize) {
      fbeValue.IsManagerRightsSet = this.IsManagerRightsSet.get()
    } else {
      fbeValue.IsManagerRightsSet = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsManagerRightsSet.fbeSize

    if ((fbeCurrentSize + this.AccessibleAccountGroups.fbeSize) <= fbeStructSize) {
      this.AccessibleAccountGroups.get(fbeValue.AccessibleAccountGroups)
    } else {
      fbeValue.AccessibleAccountGroups.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccessibleAccountGroups.fbeSize

    if ((fbeCurrentSize + this.AccessibleSymbolGroups.fbeSize) <= fbeStructSize) {
      this.AccessibleSymbolGroups.get(fbeValue.AccessibleSymbolGroups)
    } else {
      fbeValue.AccessibleSymbolGroups.length = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccessibleSymbolGroups.fbeSize

    if ((fbeCurrentSize + this.AccountsRights.fbeSize) <= fbeStructSize) {
      fbeValue.AccountsRights = this.AccountsRights.get()
    } else {
      fbeValue.AccountsRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountsRights.fbeSize

    if ((fbeCurrentSize + this.SymbolsRights.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolsRights = this.SymbolsRights.get()
    } else {
      fbeValue.SymbolsRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolsRights.fbeSize

    if ((fbeCurrentSize + this.SystemRights.fbeSize) <= fbeStructSize) {
      fbeValue.SystemRights = this.SystemRights.get()
    } else {
      fbeValue.SystemRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SystemRights.fbeSize

    if ((fbeCurrentSize + this.ModulesRights.fbeSize) <= fbeStructSize) {
      fbeValue.ModulesRights = this.ModulesRights.get()
    } else {
      fbeValue.ModulesRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ModulesRights.fbeSize

    if ((fbeCurrentSize + this.PlatformsRights.fbeSize) <= fbeStructSize) {
      fbeValue.PlatformsRights = this.PlatformsRights.get()
    } else {
      fbeValue.PlatformsRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PlatformsRights.fbeSize

    if ((fbeCurrentSize + this.ManagersRights.fbeSize) <= fbeStructSize) {
      fbeValue.ManagersRights = this.ManagersRights.get()
    } else {
      fbeValue.ManagersRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagersRights.fbeSize

    if ((fbeCurrentSize + this.CurrenciesRights.fbeSize) <= fbeStructSize) {
      fbeValue.CurrenciesRights = this.CurrenciesRights.get()
    } else {
      fbeValue.CurrenciesRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CurrenciesRights.fbeSize

    if ((fbeCurrentSize + this.CalendarsRights.fbeSize) <= fbeStructSize) {
      fbeValue.CalendarsRights = this.CalendarsRights.get()
    } else {
      fbeValue.CalendarsRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CalendarsRights.fbeSize

    if ((fbeCurrentSize + this.ProfilesRights.fbeSize) <= fbeStructSize) {
      fbeValue.ProfilesRights = this.ProfilesRights.get()
    } else {
      fbeValue.ProfilesRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfilesRights.fbeSize

    if ((fbeCurrentSize + this.DataFeedsRights.fbeSize) <= fbeStructSize) {
      fbeValue.DataFeedsRights = this.DataFeedsRights.get()
    } else {
      fbeValue.DataFeedsRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.DataFeedsRights.fbeSize

    if ((fbeCurrentSize + this.GatewaysRights.fbeSize) <= fbeStructSize) {
      fbeValue.GatewaysRights = this.GatewaysRights.get()
    } else {
      fbeValue.GatewaysRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.GatewaysRights.fbeSize

    if ((fbeCurrentSize + this.TradingRoutesRights.fbeSize) <= fbeStructSize) {
      fbeValue.TradingRoutesRights = this.TradingRoutesRights.get()
    } else {
      fbeValue.TradingRoutesRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TradingRoutesRights.fbeSize

    if ((fbeCurrentSize + this.QuotesHistoryRights.fbeSize) <= fbeStructSize) {
      fbeValue.QuotesHistoryRights = this.QuotesHistoryRights.get()
    } else {
      fbeValue.QuotesHistoryRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.QuotesHistoryRights.fbeSize

    if ((fbeCurrentSize + this.ReportsRights.fbeSize) <= fbeStructSize) {
      fbeValue.ReportsRights = this.ReportsRights.get()
    } else {
      fbeValue.ReportsRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ReportsRights.fbeSize

    if ((fbeCurrentSize + this.MonitoringRights.fbeSize) <= fbeStructSize) {
      fbeValue.MonitoringRights = this.MonitoringRights.get()
    } else {
      fbeValue.MonitoringRights = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MonitoringRights.fbeSize

    if ((fbeCurrentSize + this.Rights.fbeSize) <= fbeStructSize) {
      this.Rights.get(fbeValue.Rights)
    } else {
      fbeValue.Rights.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Rights.fbeSize

    if ((fbeCurrentSize + this.Password.fbeSize) <= fbeStructSize) {
      fbeValue.Password = this.Password.get()
    } else {
      fbeValue.Password = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Password.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelManagerCreateRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelManagerCreateRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelManagerCreateRequest}
   * @param {!ManagerCreateRequest} fbeValue ManagerCreateRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelManagerCreateRequest}
   * @param {ManagerCreateRequest} fbeValue ManagerCreateRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Login.set(fbeValue.Login)
    this.SortOrder.set(fbeValue.SortOrder)
    this.ManagerGroupId.set(fbeValue.ManagerGroupId)
    this.ManagerGroupName.set(fbeValue.ManagerGroupName)
    this.Name.set(fbeValue.Name)
    this.Comment.set(fbeValue.Comment)
    this.Description.set(fbeValue.Description)
    this.Email.set(fbeValue.Email)
    this.IsEnabled.set(fbeValue.IsEnabled)
    this.IsOTPEnabled.set(fbeValue.IsOTPEnabled)
    this.IsManagerRightsSet.set(fbeValue.IsManagerRightsSet)
    this.AccessibleAccountGroups.set(fbeValue.AccessibleAccountGroups)
    this.AccessibleSymbolGroups.set(fbeValue.AccessibleSymbolGroups)
    this.AccountsRights.set(fbeValue.AccountsRights)
    this.SymbolsRights.set(fbeValue.SymbolsRights)
    this.SystemRights.set(fbeValue.SystemRights)
    this.ModulesRights.set(fbeValue.ModulesRights)
    this.PlatformsRights.set(fbeValue.PlatformsRights)
    this.ManagersRights.set(fbeValue.ManagersRights)
    this.CurrenciesRights.set(fbeValue.CurrenciesRights)
    this.CalendarsRights.set(fbeValue.CalendarsRights)
    this.ProfilesRights.set(fbeValue.ProfilesRights)
    this.DataFeedsRights.set(fbeValue.DataFeedsRights)
    this.GatewaysRights.set(fbeValue.GatewaysRights)
    this.TradingRoutesRights.set(fbeValue.TradingRoutesRights)
    this.QuotesHistoryRights.set(fbeValue.QuotesHistoryRights)
    this.ReportsRights.set(fbeValue.ReportsRights)
    this.MonitoringRights.set(fbeValue.MonitoringRights)
    this.Rights.set(fbeValue.Rights)
    this.Password.set(fbeValue.Password)
  }
}

export { FieldModelManagerCreateRequest };

/**
 * Fast Binary Encoding ManagerCreateRequest model
 */
class ManagerCreateRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelManagerCreateRequest(this.buffer, 4)
  }

  /**
   * Get the ManagerCreateRequest model
   * @this {!ManagerCreateRequestModel}
   * @returns {!FieldModelManagerCreateRequest} model ManagerCreateRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ManagerCreateRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ManagerCreateRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ManagerCreateRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ManagerCreateRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelManagerCreateRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ManagerCreateRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ManagerCreateRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ManagerCreateRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ManagerCreateRequestModel}
   * @param {!ManagerCreateRequest} value ManagerCreateRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ManagerCreateRequestModel}
   * @param {!ManagerCreateRequest} value ManagerCreateRequest value, defaults is new ManagerCreateRequest()
   * @return {!object} Deserialized ManagerCreateRequest value and its size
   */
  deserialize (value = new ManagerCreateRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new ManagerCreateRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new ManagerCreateRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ManagerCreateRequestModel}
   * @param {!number} prev Previous ManagerCreateRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ManagerCreateRequestModel };
ManagerCreateRequest.__has_response = true;
ManagerCreateRequest.__response_class = ManagerCreateResponse;
