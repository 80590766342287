
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as feed from '../feed'
import * as history from '../history'
import * as storage from '../storage'
import * as client from '../client'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line

/**
 * FilledOrdersSummaryInfoBySymbol struct
 */
class FilledOrdersSummaryInfoBySymbol {
  /**
   * Initialize struct
   * @param {!string=} symbol
   * @param {!number=} totalOrders
   * @param {!number=} totalOrdersHedged
   * @param {!number=} buyVolume
   * @param {!number=} buyVolumeHedged
   * @param {!number=} buyOpenPriceAverage
   * @param {!number=} buyOpenPriceAverageHedged
   * @param {!number=} sellVolume
   * @param {!number=} sellVolumeHedged
   * @param {!number=} sellOpenPriceAverage
   * @param {!number=} sellOpenPriceAverageHedged
   * @param {!number=} netVolume
   * @param {!boolean=} isProfitCalculated
   * @param {!number=} totalProfit
   * @param {!number=} totalProfitHedged
   * @param {!number=} profitUncovered
   * @constructor
   */
  constructor (symbol = '', totalOrders = new UInt64(0, 0), totalOrdersHedged = new UInt64(0, 0), buyVolume = 0.0, buyVolumeHedged = 0.0, buyOpenPriceAverage = 0.0, buyOpenPriceAverageHedged = 0.0, sellVolume = 0.0, sellVolumeHedged = 0.0, sellOpenPriceAverage = 0.0, sellOpenPriceAverageHedged = 0.0, netVolume = 0.0, isProfitCalculated = false, totalProfit = 0.0, totalProfitHedged = 0.0, profitUncovered = 0.0) {
    this.Symbol = symbol
    this.TotalOrders = totalOrders
    this.TotalOrdersHedged = totalOrdersHedged
    this.BuyVolume = buyVolume
    this.BuyVolumeHedged = buyVolumeHedged
    this.BuyOpenPriceAverage = buyOpenPriceAverage
    this.BuyOpenPriceAverageHedged = buyOpenPriceAverageHedged
    this.SellVolume = sellVolume
    this.SellVolumeHedged = sellVolumeHedged
    this.SellOpenPriceAverage = sellOpenPriceAverage
    this.SellOpenPriceAverageHedged = sellOpenPriceAverageHedged
    this.NetVolume = netVolume
    this.IsProfitCalculated = isProfitCalculated
    this.TotalProfit = totalProfit
    this.TotalProfitHedged = totalProfitHedged
    this.ProfitUncovered = profitUncovered
  }

  /**
   * Copy struct (shallow copy)
   * @this {!FilledOrdersSummaryInfoBySymbol}
   * @param {!FilledOrdersSummaryInfoBySymbol} other Other struct
   * @returns {!FilledOrdersSummaryInfoBySymbol} This struct
   */
  copy (other) {
    if (other.Symbol != null) {
      this.Symbol = other.Symbol
    } else {
      this.Symbol = null
    }
    if (other.TotalOrders != null) {
      this.TotalOrders = UInt64.fromNumber(other.TotalOrders)
    } else {
      this.TotalOrders = null
    }
    if (other.TotalOrdersHedged != null) {
      this.TotalOrdersHedged = UInt64.fromNumber(other.TotalOrdersHedged)
    } else {
      this.TotalOrdersHedged = null
    }
    if (other.BuyVolume != null) {
      this.BuyVolume = other.BuyVolume
    } else {
      this.BuyVolume = null
    }
    if (other.BuyVolumeHedged != null) {
      this.BuyVolumeHedged = other.BuyVolumeHedged
    } else {
      this.BuyVolumeHedged = null
    }
    if (other.BuyOpenPriceAverage != null) {
      this.BuyOpenPriceAverage = other.BuyOpenPriceAverage
    } else {
      this.BuyOpenPriceAverage = null
    }
    if (other.BuyOpenPriceAverageHedged != null) {
      this.BuyOpenPriceAverageHedged = other.BuyOpenPriceAverageHedged
    } else {
      this.BuyOpenPriceAverageHedged = null
    }
    if (other.SellVolume != null) {
      this.SellVolume = other.SellVolume
    } else {
      this.SellVolume = null
    }
    if (other.SellVolumeHedged != null) {
      this.SellVolumeHedged = other.SellVolumeHedged
    } else {
      this.SellVolumeHedged = null
    }
    if (other.SellOpenPriceAverage != null) {
      this.SellOpenPriceAverage = other.SellOpenPriceAverage
    } else {
      this.SellOpenPriceAverage = null
    }
    if (other.SellOpenPriceAverageHedged != null) {
      this.SellOpenPriceAverageHedged = other.SellOpenPriceAverageHedged
    } else {
      this.SellOpenPriceAverageHedged = null
    }
    if (other.NetVolume != null) {
      this.NetVolume = other.NetVolume
    } else {
      this.NetVolume = null
    }
    if (other.IsProfitCalculated != null) {
      this.IsProfitCalculated = other.IsProfitCalculated
    } else {
      this.IsProfitCalculated = null
    }
    if (other.TotalProfit != null) {
      this.TotalProfit = other.TotalProfit
    } else {
      this.TotalProfit = null
    }
    if (other.TotalProfitHedged != null) {
      this.TotalProfitHedged = other.TotalProfitHedged
    } else {
      this.TotalProfitHedged = null
    }
    if (other.ProfitUncovered != null) {
      this.ProfitUncovered = other.ProfitUncovered
    } else {
      this.ProfitUncovered = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!FilledOrdersSummaryInfoBySymbol}
   * @returns {!FilledOrdersSummaryInfoBySymbol} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new FilledOrdersSummaryInfoBySymbolModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new FilledOrdersSummaryInfoBySymbolModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!FilledOrdersSummaryInfoBySymbol}
   * @param {!FilledOrdersSummaryInfoBySymbol} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof FilledOrdersSummaryInfoBySymbol)) {
      throw new TypeError('Instance of FilledOrdersSummaryInfoBySymbol is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!FilledOrdersSummaryInfoBySymbol}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Symbol: ((this.Symbol != null) ? this.Symbol : null),
      TotalOrders: ((this.TotalOrders != null) ? this.TotalOrders.toNumber() : null),
      TotalOrdersHedged: ((this.TotalOrdersHedged != null) ? this.TotalOrdersHedged.toNumber() : null),
      BuyVolume: ((this.BuyVolume != null) ? this.BuyVolume : null),
      BuyVolumeHedged: ((this.BuyVolumeHedged != null) ? this.BuyVolumeHedged : null),
      BuyOpenPriceAverage: ((this.BuyOpenPriceAverage != null) ? this.BuyOpenPriceAverage : null),
      BuyOpenPriceAverageHedged: ((this.BuyOpenPriceAverageHedged != null) ? this.BuyOpenPriceAverageHedged : null),
      SellVolume: ((this.SellVolume != null) ? this.SellVolume : null),
      SellVolumeHedged: ((this.SellVolumeHedged != null) ? this.SellVolumeHedged : null),
      SellOpenPriceAverage: ((this.SellOpenPriceAverage != null) ? this.SellOpenPriceAverage : null),
      SellOpenPriceAverageHedged: ((this.SellOpenPriceAverageHedged != null) ? this.SellOpenPriceAverageHedged : null),
      NetVolume: ((this.NetVolume != null) ? this.NetVolume : null),
      IsProfitCalculated: ((this.IsProfitCalculated != null) ? this.IsProfitCalculated : null),
      TotalProfit: ((this.TotalProfit != null) ? this.TotalProfit : null),
      TotalProfitHedged: ((this.TotalProfitHedged != null) ? this.TotalProfitHedged : null),
      ProfitUncovered: ((this.ProfitUncovered != null) ? this.ProfitUncovered : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return FilledOrdersSummaryInfoBySymbol.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!FilledOrdersSummaryInfoBySymbol} other Object value
   * @returns {!FilledOrdersSummaryInfoBySymbol} Created struct
   */
  static fromObject (other) {
    return new FilledOrdersSummaryInfoBySymbol().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!FilledOrdersSummaryInfoBySymbol}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return FilledOrdersSummaryInfoBySymbol.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!FilledOrdersSummaryInfoBySymbol}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6437
  }
}

export { FilledOrdersSummaryInfoBySymbol };

/**
 * Fast Binary Encoding FilledOrdersSummaryInfoBySymbol field model
 */
class FieldModelFilledOrdersSummaryInfoBySymbol extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Symbol = new fbe.FieldModelString(buffer, 4 + 4)
    this.TotalOrders = new fbe.FieldModelUInt64(buffer, this.Symbol.fbeOffset + this.Symbol.fbeSize)
    this.TotalOrdersHedged = new fbe.FieldModelUInt64(buffer, this.TotalOrders.fbeOffset + this.TotalOrders.fbeSize)
    this.BuyVolume = new fbe.FieldModelDouble(buffer, this.TotalOrdersHedged.fbeOffset + this.TotalOrdersHedged.fbeSize)
    this.BuyVolumeHedged = new fbe.FieldModelDouble(buffer, this.BuyVolume.fbeOffset + this.BuyVolume.fbeSize)
    this.BuyOpenPriceAverage = new fbe.FieldModelDouble(buffer, this.BuyVolumeHedged.fbeOffset + this.BuyVolumeHedged.fbeSize)
    this.BuyOpenPriceAverageHedged = new fbe.FieldModelDouble(buffer, this.BuyOpenPriceAverage.fbeOffset + this.BuyOpenPriceAverage.fbeSize)
    this.SellVolume = new fbe.FieldModelDouble(buffer, this.BuyOpenPriceAverageHedged.fbeOffset + this.BuyOpenPriceAverageHedged.fbeSize)
    this.SellVolumeHedged = new fbe.FieldModelDouble(buffer, this.SellVolume.fbeOffset + this.SellVolume.fbeSize)
    this.SellOpenPriceAverage = new fbe.FieldModelDouble(buffer, this.SellVolumeHedged.fbeOffset + this.SellVolumeHedged.fbeSize)
    this.SellOpenPriceAverageHedged = new fbe.FieldModelDouble(buffer, this.SellOpenPriceAverage.fbeOffset + this.SellOpenPriceAverage.fbeSize)
    this.NetVolume = new fbe.FieldModelDouble(buffer, this.SellOpenPriceAverageHedged.fbeOffset + this.SellOpenPriceAverageHedged.fbeSize)
    this.IsProfitCalculated = new fbe.FieldModelBool(buffer, this.NetVolume.fbeOffset + this.NetVolume.fbeSize)
    this.TotalProfit = new fbe.FieldModelDouble(buffer, this.IsProfitCalculated.fbeOffset + this.IsProfitCalculated.fbeSize)
    this.TotalProfitHedged = new fbe.FieldModelDouble(buffer, this.TotalProfit.fbeOffset + this.TotalProfit.fbeSize)
    this.ProfitUncovered = new fbe.FieldModelDouble(buffer, this.TotalProfitHedged.fbeOffset + this.TotalProfitHedged.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelFilledOrdersSummaryInfoBySymbol}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelFilledOrdersSummaryInfoBySymbol}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Symbol.fbeSize + this.TotalOrders.fbeSize + this.TotalOrdersHedged.fbeSize + this.BuyVolume.fbeSize + this.BuyVolumeHedged.fbeSize + this.BuyOpenPriceAverage.fbeSize + this.BuyOpenPriceAverageHedged.fbeSize + this.SellVolume.fbeSize + this.SellVolumeHedged.fbeSize + this.SellOpenPriceAverage.fbeSize + this.SellOpenPriceAverageHedged.fbeSize + this.NetVolume.fbeSize + this.IsProfitCalculated.fbeSize + this.TotalProfit.fbeSize + this.TotalProfitHedged.fbeSize + this.ProfitUncovered.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelFilledOrdersSummaryInfoBySymbol}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Symbol.fbeExtra + this.TotalOrders.fbeExtra + this.TotalOrdersHedged.fbeExtra + this.BuyVolume.fbeExtra + this.BuyVolumeHedged.fbeExtra + this.BuyOpenPriceAverage.fbeExtra + this.BuyOpenPriceAverageHedged.fbeExtra + this.SellVolume.fbeExtra + this.SellVolumeHedged.fbeExtra + this.SellOpenPriceAverage.fbeExtra + this.SellOpenPriceAverageHedged.fbeExtra + this.NetVolume.fbeExtra + this.IsProfitCalculated.fbeExtra + this.TotalProfit.fbeExtra + this.TotalProfitHedged.fbeExtra + this.ProfitUncovered.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelFilledOrdersSummaryInfoBySymbol}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelFilledOrdersSummaryInfoBySymbol.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelFilledOrdersSummaryInfoBySymbol}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6437
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelFilledOrdersSummaryInfoBySymbol}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelFilledOrdersSummaryInfoBySymbol}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Symbol.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Symbol.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Symbol.fbeSize

    if ((fbeCurrentSize + this.TotalOrders.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalOrders.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalOrders.fbeSize

    if ((fbeCurrentSize + this.TotalOrdersHedged.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalOrdersHedged.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalOrdersHedged.fbeSize

    if ((fbeCurrentSize + this.BuyVolume.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BuyVolume.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BuyVolume.fbeSize

    if ((fbeCurrentSize + this.BuyVolumeHedged.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BuyVolumeHedged.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BuyVolumeHedged.fbeSize

    if ((fbeCurrentSize + this.BuyOpenPriceAverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BuyOpenPriceAverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BuyOpenPriceAverage.fbeSize

    if ((fbeCurrentSize + this.BuyOpenPriceAverageHedged.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.BuyOpenPriceAverageHedged.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BuyOpenPriceAverageHedged.fbeSize

    if ((fbeCurrentSize + this.SellVolume.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SellVolume.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SellVolume.fbeSize

    if ((fbeCurrentSize + this.SellVolumeHedged.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SellVolumeHedged.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SellVolumeHedged.fbeSize

    if ((fbeCurrentSize + this.SellOpenPriceAverage.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SellOpenPriceAverage.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SellOpenPriceAverage.fbeSize

    if ((fbeCurrentSize + this.SellOpenPriceAverageHedged.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SellOpenPriceAverageHedged.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SellOpenPriceAverageHedged.fbeSize

    if ((fbeCurrentSize + this.NetVolume.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NetVolume.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NetVolume.fbeSize

    if ((fbeCurrentSize + this.IsProfitCalculated.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsProfitCalculated.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsProfitCalculated.fbeSize

    if ((fbeCurrentSize + this.TotalProfit.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalProfit.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalProfit.fbeSize

    if ((fbeCurrentSize + this.TotalProfitHedged.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TotalProfitHedged.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalProfitHedged.fbeSize

    if ((fbeCurrentSize + this.ProfitUncovered.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProfitUncovered.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitUncovered.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelFilledOrdersSummaryInfoBySymbol}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelFilledOrdersSummaryInfoBySymbol}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelFilledOrdersSummaryInfoBySymbol}
   * @param {!FilledOrdersSummaryInfoBySymbol} fbeValue Default value, defaults is new FilledOrdersSummaryInfoBySymbol()
   * @returns {!FilledOrdersSummaryInfoBySymbol} FilledOrdersSummaryInfoBySymbol value
   */
  get (fbeValue = new FilledOrdersSummaryInfoBySymbol()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelFilledOrdersSummaryInfoBySymbol}
   * @param {!FilledOrdersSummaryInfoBySymbol} fbeValue FilledOrdersSummaryInfoBySymbol value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Symbol.fbeSize) <= fbeStructSize) {
      fbeValue.Symbol = this.Symbol.get()
    } else {
      fbeValue.Symbol = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Symbol.fbeSize

    if ((fbeCurrentSize + this.TotalOrders.fbeSize) <= fbeStructSize) {
      fbeValue.TotalOrders = this.TotalOrders.get()
    } else {
      fbeValue.TotalOrders = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalOrders.fbeSize

    if ((fbeCurrentSize + this.TotalOrdersHedged.fbeSize) <= fbeStructSize) {
      fbeValue.TotalOrdersHedged = this.TotalOrdersHedged.get()
    } else {
      fbeValue.TotalOrdersHedged = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalOrdersHedged.fbeSize

    if ((fbeCurrentSize + this.BuyVolume.fbeSize) <= fbeStructSize) {
      fbeValue.BuyVolume = this.BuyVolume.get()
    } else {
      fbeValue.BuyVolume = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BuyVolume.fbeSize

    if ((fbeCurrentSize + this.BuyVolumeHedged.fbeSize) <= fbeStructSize) {
      fbeValue.BuyVolumeHedged = this.BuyVolumeHedged.get()
    } else {
      fbeValue.BuyVolumeHedged = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BuyVolumeHedged.fbeSize

    if ((fbeCurrentSize + this.BuyOpenPriceAverage.fbeSize) <= fbeStructSize) {
      fbeValue.BuyOpenPriceAverage = this.BuyOpenPriceAverage.get()
    } else {
      fbeValue.BuyOpenPriceAverage = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BuyOpenPriceAverage.fbeSize

    if ((fbeCurrentSize + this.BuyOpenPriceAverageHedged.fbeSize) <= fbeStructSize) {
      fbeValue.BuyOpenPriceAverageHedged = this.BuyOpenPriceAverageHedged.get()
    } else {
      fbeValue.BuyOpenPriceAverageHedged = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.BuyOpenPriceAverageHedged.fbeSize

    if ((fbeCurrentSize + this.SellVolume.fbeSize) <= fbeStructSize) {
      fbeValue.SellVolume = this.SellVolume.get()
    } else {
      fbeValue.SellVolume = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SellVolume.fbeSize

    if ((fbeCurrentSize + this.SellVolumeHedged.fbeSize) <= fbeStructSize) {
      fbeValue.SellVolumeHedged = this.SellVolumeHedged.get()
    } else {
      fbeValue.SellVolumeHedged = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SellVolumeHedged.fbeSize

    if ((fbeCurrentSize + this.SellOpenPriceAverage.fbeSize) <= fbeStructSize) {
      fbeValue.SellOpenPriceAverage = this.SellOpenPriceAverage.get()
    } else {
      fbeValue.SellOpenPriceAverage = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SellOpenPriceAverage.fbeSize

    if ((fbeCurrentSize + this.SellOpenPriceAverageHedged.fbeSize) <= fbeStructSize) {
      fbeValue.SellOpenPriceAverageHedged = this.SellOpenPriceAverageHedged.get()
    } else {
      fbeValue.SellOpenPriceAverageHedged = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SellOpenPriceAverageHedged.fbeSize

    if ((fbeCurrentSize + this.NetVolume.fbeSize) <= fbeStructSize) {
      fbeValue.NetVolume = this.NetVolume.get()
    } else {
      fbeValue.NetVolume = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NetVolume.fbeSize

    if ((fbeCurrentSize + this.IsProfitCalculated.fbeSize) <= fbeStructSize) {
      fbeValue.IsProfitCalculated = this.IsProfitCalculated.get()
    } else {
      fbeValue.IsProfitCalculated = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsProfitCalculated.fbeSize

    if ((fbeCurrentSize + this.TotalProfit.fbeSize) <= fbeStructSize) {
      fbeValue.TotalProfit = this.TotalProfit.get()
    } else {
      fbeValue.TotalProfit = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalProfit.fbeSize

    if ((fbeCurrentSize + this.TotalProfitHedged.fbeSize) <= fbeStructSize) {
      fbeValue.TotalProfitHedged = this.TotalProfitHedged.get()
    } else {
      fbeValue.TotalProfitHedged = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TotalProfitHedged.fbeSize

    if ((fbeCurrentSize + this.ProfitUncovered.fbeSize) <= fbeStructSize) {
      fbeValue.ProfitUncovered = this.ProfitUncovered.get()
    } else {
      fbeValue.ProfitUncovered = 0.0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProfitUncovered.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelFilledOrdersSummaryInfoBySymbol}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelFilledOrdersSummaryInfoBySymbol}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelFilledOrdersSummaryInfoBySymbol}
   * @param {!FilledOrdersSummaryInfoBySymbol} fbeValue FilledOrdersSummaryInfoBySymbol value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelFilledOrdersSummaryInfoBySymbol}
   * @param {FilledOrdersSummaryInfoBySymbol} fbeValue FilledOrdersSummaryInfoBySymbol value
   */
  setFields (fbeValue) {
    this.Symbol.set(fbeValue.Symbol)
    this.TotalOrders.set(fbeValue.TotalOrders)
    this.TotalOrdersHedged.set(fbeValue.TotalOrdersHedged)
    this.BuyVolume.set(fbeValue.BuyVolume)
    this.BuyVolumeHedged.set(fbeValue.BuyVolumeHedged)
    this.BuyOpenPriceAverage.set(fbeValue.BuyOpenPriceAverage)
    this.BuyOpenPriceAverageHedged.set(fbeValue.BuyOpenPriceAverageHedged)
    this.SellVolume.set(fbeValue.SellVolume)
    this.SellVolumeHedged.set(fbeValue.SellVolumeHedged)
    this.SellOpenPriceAverage.set(fbeValue.SellOpenPriceAverage)
    this.SellOpenPriceAverageHedged.set(fbeValue.SellOpenPriceAverageHedged)
    this.NetVolume.set(fbeValue.NetVolume)
    this.IsProfitCalculated.set(fbeValue.IsProfitCalculated)
    this.TotalProfit.set(fbeValue.TotalProfit)
    this.TotalProfitHedged.set(fbeValue.TotalProfitHedged)
    this.ProfitUncovered.set(fbeValue.ProfitUncovered)
  }
}

export { FieldModelFilledOrdersSummaryInfoBySymbol };

/**
 * Fast Binary Encoding FilledOrdersSummaryInfoBySymbol model
 */
class FilledOrdersSummaryInfoBySymbolModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelFilledOrdersSummaryInfoBySymbol(this.buffer, 4)
  }

  /**
   * Get the FilledOrdersSummaryInfoBySymbol model
   * @this {!FilledOrdersSummaryInfoBySymbolModel}
   * @returns {!FieldModelFilledOrdersSummaryInfoBySymbol} model FilledOrdersSummaryInfoBySymbol model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!FilledOrdersSummaryInfoBySymbolModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!FilledOrdersSummaryInfoBySymbolModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return FilledOrdersSummaryInfoBySymbolModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!FilledOrdersSummaryInfoBySymbolModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelFilledOrdersSummaryInfoBySymbol.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!FilledOrdersSummaryInfoBySymbolModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!FilledOrdersSummaryInfoBySymbolModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!FilledOrdersSummaryInfoBySymbolModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!FilledOrdersSummaryInfoBySymbolModel}
   * @param {!FilledOrdersSummaryInfoBySymbol} value FilledOrdersSummaryInfoBySymbol value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!FilledOrdersSummaryInfoBySymbolModel}
   * @param {!FilledOrdersSummaryInfoBySymbol} value FilledOrdersSummaryInfoBySymbol value, defaults is new FilledOrdersSummaryInfoBySymbol()
   * @return {!object} Deserialized FilledOrdersSummaryInfoBySymbol value and its size
   */
  deserialize (value = new FilledOrdersSummaryInfoBySymbol()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new FilledOrdersSummaryInfoBySymbol(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new FilledOrdersSummaryInfoBySymbol(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!FilledOrdersSummaryInfoBySymbolModel}
   * @param {!number} prev Previous FilledOrdersSummaryInfoBySymbol model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { FilledOrdersSummaryInfoBySymbolModel };
