
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as feed from '../feed'
import * as history from '../history'
import * as storage from '../storage'
import * as client from '../client'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigUpdateRequest} from './ConfigUpdateRequest';
import {FieldModelConfigUpdateRequest} from './ConfigUpdateRequest';
import {SymbolModifyResponse} from './SymbolModifyResponse';
import {MarginCalculationType} from '../core/MarginCalculationType';
import {FieldModelMarginCalculationType} from '../core/MarginCalculationType';
import {ProfitCalculationType} from '../core/ProfitCalculationType';
import {FieldModelProfitCalculationType} from '../core/ProfitCalculationType';
import {SwapCalculationType} from '../core/SwapCalculationType';
import {FieldModelSwapCalculationType} from '../core/SwapCalculationType';
import {DayOfWeek} from '../core/DayOfWeek';
import {FieldModelDayOfWeek} from '../core/DayOfWeek';
import {TradingMode} from '../core/TradingMode';
import {FieldModelTradingMode} from '../core/TradingMode';

/**
 * SymbolModifyRequest struct
 */
class SymbolModifyRequest extends ConfigUpdateRequest {
  /**
   * Initialize struct
   * @param {!ConfigUpdateRequest=} parent
   * @param {UUID=} symbolId
   * @param {string=} symbolName
   * @param {number=} newSortOrder
   * @param {UUID=} newSymbolGroupId
   * @param {string=} newSymbolGroupName
   * @param {string=} newName
   * @param {string=} newComment
   * @param {string=} newDescription
   * @param {string=} newAlias
   * @param {string=} newSource
   * @param {number=} newPrecision
   * @param {UUID=} newMarginCurrencyId
   * @param {!boolean=} newMarginCurrencyIdReset
   * @param {string=} newMarginCurrencyName
   * @param {number=} newMarginCurrencyPrecision
   * @param {!boolean=} newMarginCurrencyPrecisionReset
   * @param {MarginCalculationType=} newMarginCalculationType
   * @param {UUID=} newProfitCurrencyId
   * @param {!boolean=} newProfitCurrencyIdReset
   * @param {string=} newProfitCurrencyName
   * @param {number=} newProfitCurrencyPrecision
   * @param {!boolean=} newProfitCurrencyPrecisionReset
   * @param {ProfitCalculationType=} newProfitCalculationType
   * @param {number=} newColor
   * @param {!boolean=} newColorReset
   * @param {boolean=} newIsTradeAllowed
   * @param {!boolean=} newIsTradeAllowedReset
   * @param {number=} newContractSize
   * @param {number=} newMinTradeAmount
   * @param {number=} newMaxTradeAmount
   * @param {number=} newTradeAmountStep
   * @param {number=} newDefaultExpiration
   * @param {!boolean=} newDefaultExpirationReset
   * @param {boolean=} newIsSwapEnabled
   * @param {SwapCalculationType=} newSwapType
   * @param {number=} newSwapLong
   * @param {number=} newSwapShort
   * @param {DayOfWeek=} newSwapTripleDay
   * @param {number=} newMarketOrderMarginRate
   * @param {number=} newLimitOrderMarginRate
   * @param {number=} newStopOrderMarginRate
   * @param {TradingMode=} newTradingMode
   * @param {!boolean=} newTradingModeReset
   * @param {number=} newStopsLevel
   * @param {boolean=} newIsFavorite
   * @param {number=} newSortOrderFavorite
   * @param {string=} newPostfix
   * @param {number=} newInitialMargin
   * @param {number=} newHedgedMargin
   * @param {number=} newSwapDaysInYear
   * @constructor
   */
  constructor (parent = new ConfigUpdateRequest(), symbolId = null, symbolName = null, newSortOrder = null, newSymbolGroupId = null, newSymbolGroupName = null, newName = null, newComment = null, newDescription = null, newAlias = null, newSource = null, newPrecision = null, newMarginCurrencyId = null, newMarginCurrencyIdReset = false, newMarginCurrencyName = null, newMarginCurrencyPrecision = null, newMarginCurrencyPrecisionReset = false, newMarginCalculationType = null, newProfitCurrencyId = null, newProfitCurrencyIdReset = false, newProfitCurrencyName = null, newProfitCurrencyPrecision = null, newProfitCurrencyPrecisionReset = false, newProfitCalculationType = null, newColor = null, newColorReset = false, newIsTradeAllowed = null, newIsTradeAllowedReset = false, newContractSize = null, newMinTradeAmount = null, newMaxTradeAmount = null, newTradeAmountStep = null, newDefaultExpiration = null, newDefaultExpirationReset = false, newIsSwapEnabled = null, newSwapType = null, newSwapLong = null, newSwapShort = null, newSwapTripleDay = null, newMarketOrderMarginRate = null, newLimitOrderMarginRate = null, newStopOrderMarginRate = null, newTradingMode = null, newTradingModeReset = false, newStopsLevel = null, newIsFavorite = null, newSortOrderFavorite = null, newPostfix = null, newInitialMargin = null, newHedgedMargin = null, newSwapDaysInYear = null) {
    super()
    super.copy(parent)
    this.SymbolId = symbolId
    this.SymbolName = symbolName
    this.NewSortOrder = newSortOrder
    this.NewSymbolGroupId = newSymbolGroupId
    this.NewSymbolGroupName = newSymbolGroupName
    this.NewName = newName
    this.NewComment = newComment
    this.NewDescription = newDescription
    this.NewAlias = newAlias
    this.NewSource = newSource
    this.NewPrecision = newPrecision
    this.NewMarginCurrencyId = newMarginCurrencyId
    this.NewMarginCurrencyIdReset = newMarginCurrencyIdReset
    this.NewMarginCurrencyName = newMarginCurrencyName
    this.NewMarginCurrencyPrecision = newMarginCurrencyPrecision
    this.NewMarginCurrencyPrecisionReset = newMarginCurrencyPrecisionReset
    this.NewMarginCalculationType = newMarginCalculationType
    this.NewProfitCurrencyId = newProfitCurrencyId
    this.NewProfitCurrencyIdReset = newProfitCurrencyIdReset
    this.NewProfitCurrencyName = newProfitCurrencyName
    this.NewProfitCurrencyPrecision = newProfitCurrencyPrecision
    this.NewProfitCurrencyPrecisionReset = newProfitCurrencyPrecisionReset
    this.NewProfitCalculationType = newProfitCalculationType
    this.NewColor = newColor
    this.NewColorReset = newColorReset
    this.NewIsTradeAllowed = newIsTradeAllowed
    this.NewIsTradeAllowedReset = newIsTradeAllowedReset
    this.NewContractSize = newContractSize
    this.NewMinTradeAmount = newMinTradeAmount
    this.NewMaxTradeAmount = newMaxTradeAmount
    this.NewTradeAmountStep = newTradeAmountStep
    this.NewDefaultExpiration = newDefaultExpiration
    this.NewDefaultExpirationReset = newDefaultExpirationReset
    this.NewIsSwapEnabled = newIsSwapEnabled
    this.NewSwapType = newSwapType
    this.NewSwapLong = newSwapLong
    this.NewSwapShort = newSwapShort
    this.NewSwapTripleDay = newSwapTripleDay
    this.NewMarketOrderMarginRate = newMarketOrderMarginRate
    this.NewLimitOrderMarginRate = newLimitOrderMarginRate
    this.NewStopOrderMarginRate = newStopOrderMarginRate
    this.NewTradingMode = newTradingMode
    this.NewTradingModeReset = newTradingModeReset
    this.NewStopsLevel = newStopsLevel
    this.NewIsFavorite = newIsFavorite
    this.NewSortOrderFavorite = newSortOrderFavorite
    this.NewPostfix = newPostfix
    this.NewInitialMargin = newInitialMargin
    this.NewHedgedMargin = newHedgedMargin
    this.NewSwapDaysInYear = newSwapDaysInYear
  }

  /**
   * Copy struct (shallow copy)
   * @this {!SymbolModifyRequest}
   * @param {!SymbolModifyRequest} other Other struct
   * @returns {!SymbolModifyRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.SymbolId != null) {
      this.SymbolId = new UUID(other.SymbolId)
    } else {
      this.SymbolId = null
    }
    if (other.SymbolName != null) {
      this.SymbolName = other.SymbolName
    } else {
      this.SymbolName = null
    }
    if (other.NewSortOrder != null) {
      this.NewSortOrder = other.NewSortOrder
    } else {
      this.NewSortOrder = null
    }
    if (other.NewSymbolGroupId != null) {
      this.NewSymbolGroupId = new UUID(other.NewSymbolGroupId)
    } else {
      this.NewSymbolGroupId = null
    }
    if (other.NewSymbolGroupName != null) {
      this.NewSymbolGroupName = other.NewSymbolGroupName
    } else {
      this.NewSymbolGroupName = null
    }
    if (other.NewName != null) {
      this.NewName = other.NewName
    } else {
      this.NewName = null
    }
    if (other.NewComment != null) {
      this.NewComment = other.NewComment
    } else {
      this.NewComment = null
    }
    if (other.NewDescription != null) {
      this.NewDescription = other.NewDescription
    } else {
      this.NewDescription = null
    }
    if (other.NewAlias != null) {
      this.NewAlias = other.NewAlias
    } else {
      this.NewAlias = null
    }
    if (other.NewSource != null) {
      this.NewSource = other.NewSource
    } else {
      this.NewSource = null
    }
    if (other.NewPrecision != null) {
      this.NewPrecision = other.NewPrecision
    } else {
      this.NewPrecision = null
    }
    if (other.NewMarginCurrencyId != null) {
      this.NewMarginCurrencyId = new UUID(other.NewMarginCurrencyId)
    } else {
      this.NewMarginCurrencyId = null
    }
    if (other.NewMarginCurrencyIdReset != null) {
      this.NewMarginCurrencyIdReset = other.NewMarginCurrencyIdReset
    } else {
      this.NewMarginCurrencyIdReset = null
    }
    if (other.NewMarginCurrencyName != null) {
      this.NewMarginCurrencyName = other.NewMarginCurrencyName
    } else {
      this.NewMarginCurrencyName = null
    }
    if (other.NewMarginCurrencyPrecision != null) {
      this.NewMarginCurrencyPrecision = other.NewMarginCurrencyPrecision
    } else {
      this.NewMarginCurrencyPrecision = null
    }
    if (other.NewMarginCurrencyPrecisionReset != null) {
      this.NewMarginCurrencyPrecisionReset = other.NewMarginCurrencyPrecisionReset
    } else {
      this.NewMarginCurrencyPrecisionReset = null
    }
    if (other.NewMarginCalculationType != null) {
      this.NewMarginCalculationType = MarginCalculationType.fromObject(other.NewMarginCalculationType)
    } else {
      this.NewMarginCalculationType = null
    }
    if (other.NewProfitCurrencyId != null) {
      this.NewProfitCurrencyId = new UUID(other.NewProfitCurrencyId)
    } else {
      this.NewProfitCurrencyId = null
    }
    if (other.NewProfitCurrencyIdReset != null) {
      this.NewProfitCurrencyIdReset = other.NewProfitCurrencyIdReset
    } else {
      this.NewProfitCurrencyIdReset = null
    }
    if (other.NewProfitCurrencyName != null) {
      this.NewProfitCurrencyName = other.NewProfitCurrencyName
    } else {
      this.NewProfitCurrencyName = null
    }
    if (other.NewProfitCurrencyPrecision != null) {
      this.NewProfitCurrencyPrecision = other.NewProfitCurrencyPrecision
    } else {
      this.NewProfitCurrencyPrecision = null
    }
    if (other.NewProfitCurrencyPrecisionReset != null) {
      this.NewProfitCurrencyPrecisionReset = other.NewProfitCurrencyPrecisionReset
    } else {
      this.NewProfitCurrencyPrecisionReset = null
    }
    if (other.NewProfitCalculationType != null) {
      this.NewProfitCalculationType = ProfitCalculationType.fromObject(other.NewProfitCalculationType)
    } else {
      this.NewProfitCalculationType = null
    }
    if (other.NewColor != null) {
      this.NewColor = other.NewColor
    } else {
      this.NewColor = null
    }
    if (other.NewColorReset != null) {
      this.NewColorReset = other.NewColorReset
    } else {
      this.NewColorReset = null
    }
    if (other.NewIsTradeAllowed != null) {
      this.NewIsTradeAllowed = other.NewIsTradeAllowed
    } else {
      this.NewIsTradeAllowed = null
    }
    if (other.NewIsTradeAllowedReset != null) {
      this.NewIsTradeAllowedReset = other.NewIsTradeAllowedReset
    } else {
      this.NewIsTradeAllowedReset = null
    }
    if (other.NewContractSize != null) {
      this.NewContractSize = other.NewContractSize
    } else {
      this.NewContractSize = null
    }
    if (other.NewMinTradeAmount != null) {
      this.NewMinTradeAmount = other.NewMinTradeAmount
    } else {
      this.NewMinTradeAmount = null
    }
    if (other.NewMaxTradeAmount != null) {
      this.NewMaxTradeAmount = other.NewMaxTradeAmount
    } else {
      this.NewMaxTradeAmount = null
    }
    if (other.NewTradeAmountStep != null) {
      this.NewTradeAmountStep = other.NewTradeAmountStep
    } else {
      this.NewTradeAmountStep = null
    }
    if (other.NewDefaultExpiration != null) {
      this.NewDefaultExpiration = UInt64.fromNumber(other.NewDefaultExpiration)
    } else {
      this.NewDefaultExpiration = null
    }
    if (other.NewDefaultExpirationReset != null) {
      this.NewDefaultExpirationReset = other.NewDefaultExpirationReset
    } else {
      this.NewDefaultExpirationReset = null
    }
    if (other.NewIsSwapEnabled != null) {
      this.NewIsSwapEnabled = other.NewIsSwapEnabled
    } else {
      this.NewIsSwapEnabled = null
    }
    if (other.NewSwapType != null) {
      this.NewSwapType = SwapCalculationType.fromObject(other.NewSwapType)
    } else {
      this.NewSwapType = null
    }
    if (other.NewSwapLong != null) {
      this.NewSwapLong = other.NewSwapLong
    } else {
      this.NewSwapLong = null
    }
    if (other.NewSwapShort != null) {
      this.NewSwapShort = other.NewSwapShort
    } else {
      this.NewSwapShort = null
    }
    if (other.NewSwapTripleDay != null) {
      this.NewSwapTripleDay = DayOfWeek.fromObject(other.NewSwapTripleDay)
    } else {
      this.NewSwapTripleDay = null
    }
    if (other.NewMarketOrderMarginRate != null) {
      this.NewMarketOrderMarginRate = other.NewMarketOrderMarginRate
    } else {
      this.NewMarketOrderMarginRate = null
    }
    if (other.NewLimitOrderMarginRate != null) {
      this.NewLimitOrderMarginRate = other.NewLimitOrderMarginRate
    } else {
      this.NewLimitOrderMarginRate = null
    }
    if (other.NewStopOrderMarginRate != null) {
      this.NewStopOrderMarginRate = other.NewStopOrderMarginRate
    } else {
      this.NewStopOrderMarginRate = null
    }
    if (other.NewTradingMode != null) {
      this.NewTradingMode = TradingMode.fromObject(other.NewTradingMode)
    } else {
      this.NewTradingMode = null
    }
    if (other.NewTradingModeReset != null) {
      this.NewTradingModeReset = other.NewTradingModeReset
    } else {
      this.NewTradingModeReset = null
    }
    if (other.NewStopsLevel != null) {
      this.NewStopsLevel = UInt64.fromNumber(other.NewStopsLevel)
    } else {
      this.NewStopsLevel = null
    }
    if (other.NewIsFavorite != null) {
      this.NewIsFavorite = other.NewIsFavorite
    } else {
      this.NewIsFavorite = null
    }
    if (other.NewSortOrderFavorite != null) {
      this.NewSortOrderFavorite = other.NewSortOrderFavorite
    } else {
      this.NewSortOrderFavorite = null
    }
    if (other.NewPostfix != null) {
      this.NewPostfix = other.NewPostfix
    } else {
      this.NewPostfix = null
    }
    if (other.NewInitialMargin != null) {
      this.NewInitialMargin = other.NewInitialMargin
    } else {
      this.NewInitialMargin = null
    }
    if (other.NewHedgedMargin != null) {
      this.NewHedgedMargin = other.NewHedgedMargin
    } else {
      this.NewHedgedMargin = null
    }
    if (other.NewSwapDaysInYear != null) {
      this.NewSwapDaysInYear = UInt64.fromNumber(other.NewSwapDaysInYear)
    } else {
      this.NewSwapDaysInYear = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!SymbolModifyRequest}
   * @returns {!SymbolModifyRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new SymbolModifyRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new SymbolModifyRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!SymbolModifyRequest}
   * @param {!SymbolModifyRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof SymbolModifyRequest)) {
      throw new TypeError('Instance of SymbolModifyRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!SymbolModifyRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      SymbolId: ((this.SymbolId != null) ? this.SymbolId.toString() : null),
      SymbolName: ((this.SymbolName != null) ? this.SymbolName : null),
      NewSortOrder: ((this.NewSortOrder != null) ? this.NewSortOrder : null),
      NewSymbolGroupId: ((this.NewSymbolGroupId != null) ? this.NewSymbolGroupId.toString() : null),
      NewSymbolGroupName: ((this.NewSymbolGroupName != null) ? this.NewSymbolGroupName : null),
      NewName: ((this.NewName != null) ? this.NewName : null),
      NewComment: ((this.NewComment != null) ? this.NewComment : null),
      NewDescription: ((this.NewDescription != null) ? this.NewDescription : null),
      NewAlias: ((this.NewAlias != null) ? this.NewAlias : null),
      NewSource: ((this.NewSource != null) ? this.NewSource : null),
      NewPrecision: ((this.NewPrecision != null) ? this.NewPrecision : null),
      NewMarginCurrencyId: ((this.NewMarginCurrencyId != null) ? this.NewMarginCurrencyId.toString() : null),
      NewMarginCurrencyIdReset: ((this.NewMarginCurrencyIdReset != null) ? this.NewMarginCurrencyIdReset : null),
      NewMarginCurrencyName: ((this.NewMarginCurrencyName != null) ? this.NewMarginCurrencyName : null),
      NewMarginCurrencyPrecision: ((this.NewMarginCurrencyPrecision != null) ? this.NewMarginCurrencyPrecision : null),
      NewMarginCurrencyPrecisionReset: ((this.NewMarginCurrencyPrecisionReset != null) ? this.NewMarginCurrencyPrecisionReset : null),
      NewMarginCalculationType: ((this.NewMarginCalculationType != null) ? this.NewMarginCalculationType : null),
      NewProfitCurrencyId: ((this.NewProfitCurrencyId != null) ? this.NewProfitCurrencyId.toString() : null),
      NewProfitCurrencyIdReset: ((this.NewProfitCurrencyIdReset != null) ? this.NewProfitCurrencyIdReset : null),
      NewProfitCurrencyName: ((this.NewProfitCurrencyName != null) ? this.NewProfitCurrencyName : null),
      NewProfitCurrencyPrecision: ((this.NewProfitCurrencyPrecision != null) ? this.NewProfitCurrencyPrecision : null),
      NewProfitCurrencyPrecisionReset: ((this.NewProfitCurrencyPrecisionReset != null) ? this.NewProfitCurrencyPrecisionReset : null),
      NewProfitCalculationType: ((this.NewProfitCalculationType != null) ? this.NewProfitCalculationType : null),
      NewColor: ((this.NewColor != null) ? this.NewColor : null),
      NewColorReset: ((this.NewColorReset != null) ? this.NewColorReset : null),
      NewIsTradeAllowed: ((this.NewIsTradeAllowed != null) ? this.NewIsTradeAllowed : null),
      NewIsTradeAllowedReset: ((this.NewIsTradeAllowedReset != null) ? this.NewIsTradeAllowedReset : null),
      NewContractSize: ((this.NewContractSize != null) ? this.NewContractSize : null),
      NewMinTradeAmount: ((this.NewMinTradeAmount != null) ? this.NewMinTradeAmount : null),
      NewMaxTradeAmount: ((this.NewMaxTradeAmount != null) ? this.NewMaxTradeAmount : null),
      NewTradeAmountStep: ((this.NewTradeAmountStep != null) ? this.NewTradeAmountStep : null),
      NewDefaultExpiration: ((this.NewDefaultExpiration != null) ? this.NewDefaultExpiration.toNumber() : null),
      NewDefaultExpirationReset: ((this.NewDefaultExpirationReset != null) ? this.NewDefaultExpirationReset : null),
      NewIsSwapEnabled: ((this.NewIsSwapEnabled != null) ? this.NewIsSwapEnabled : null),
      NewSwapType: ((this.NewSwapType != null) ? this.NewSwapType : null),
      NewSwapLong: ((this.NewSwapLong != null) ? this.NewSwapLong : null),
      NewSwapShort: ((this.NewSwapShort != null) ? this.NewSwapShort : null),
      NewSwapTripleDay: ((this.NewSwapTripleDay != null) ? this.NewSwapTripleDay : null),
      NewMarketOrderMarginRate: ((this.NewMarketOrderMarginRate != null) ? this.NewMarketOrderMarginRate : null),
      NewLimitOrderMarginRate: ((this.NewLimitOrderMarginRate != null) ? this.NewLimitOrderMarginRate : null),
      NewStopOrderMarginRate: ((this.NewStopOrderMarginRate != null) ? this.NewStopOrderMarginRate : null),
      NewTradingMode: ((this.NewTradingMode != null) ? this.NewTradingMode : null),
      NewTradingModeReset: ((this.NewTradingModeReset != null) ? this.NewTradingModeReset : null),
      NewStopsLevel: ((this.NewStopsLevel != null) ? this.NewStopsLevel.toNumber() : null),
      NewIsFavorite: ((this.NewIsFavorite != null) ? this.NewIsFavorite : null),
      NewSortOrderFavorite: ((this.NewSortOrderFavorite != null) ? this.NewSortOrderFavorite : null),
      NewPostfix: ((this.NewPostfix != null) ? this.NewPostfix : null),
      NewInitialMargin: ((this.NewInitialMargin != null) ? this.NewInitialMargin : null),
      NewHedgedMargin: ((this.NewHedgedMargin != null) ? this.NewHedgedMargin : null),
      NewSwapDaysInYear: ((this.NewSwapDaysInYear != null) ? this.NewSwapDaysInYear.toNumber() : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return SymbolModifyRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!SymbolModifyRequest} other Object value
   * @returns {!SymbolModifyRequest} Created struct
   */
  static fromObject (other) {
    return new SymbolModifyRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!SymbolModifyRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return SymbolModifyRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!SymbolModifyRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6113
  }
}

export { SymbolModifyRequest };

/**
 * Fast Binary Encoding SymbolModifyRequest field model
 */
class FieldModelSymbolModifyRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigUpdateRequest(buffer, 4 + 4)
    this.SymbolId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.SymbolName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.SymbolId.fbeOffset + this.SymbolId.fbeSize), buffer, this.SymbolId.fbeOffset + this.SymbolId.fbeSize)
    this.NewSortOrder = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.SymbolName.fbeOffset + this.SymbolName.fbeSize), buffer, this.SymbolName.fbeOffset + this.SymbolName.fbeSize)
    this.NewSymbolGroupId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize), buffer, this.NewSortOrder.fbeOffset + this.NewSortOrder.fbeSize)
    this.NewSymbolGroupName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewSymbolGroupId.fbeOffset + this.NewSymbolGroupId.fbeSize), buffer, this.NewSymbolGroupId.fbeOffset + this.NewSymbolGroupId.fbeSize)
    this.NewName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewSymbolGroupName.fbeOffset + this.NewSymbolGroupName.fbeSize), buffer, this.NewSymbolGroupName.fbeOffset + this.NewSymbolGroupName.fbeSize)
    this.NewComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewName.fbeOffset + this.NewName.fbeSize), buffer, this.NewName.fbeOffset + this.NewName.fbeSize)
    this.NewDescription = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize), buffer, this.NewComment.fbeOffset + this.NewComment.fbeSize)
    this.NewAlias = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize), buffer, this.NewDescription.fbeOffset + this.NewDescription.fbeSize)
    this.NewSource = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewAlias.fbeOffset + this.NewAlias.fbeSize), buffer, this.NewAlias.fbeOffset + this.NewAlias.fbeSize)
    this.NewPrecision = new fbe.FieldModelOptional(new fbe.FieldModelUInt8(buffer, this.NewSource.fbeOffset + this.NewSource.fbeSize), buffer, this.NewSource.fbeOffset + this.NewSource.fbeSize)
    this.NewMarginCurrencyId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.NewPrecision.fbeOffset + this.NewPrecision.fbeSize), buffer, this.NewPrecision.fbeOffset + this.NewPrecision.fbeSize)
    this.NewMarginCurrencyIdReset = new fbe.FieldModelBool(buffer, this.NewMarginCurrencyId.fbeOffset + this.NewMarginCurrencyId.fbeSize)
    this.NewMarginCurrencyName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewMarginCurrencyIdReset.fbeOffset + this.NewMarginCurrencyIdReset.fbeSize), buffer, this.NewMarginCurrencyIdReset.fbeOffset + this.NewMarginCurrencyIdReset.fbeSize)
    this.NewMarginCurrencyPrecision = new fbe.FieldModelOptional(new fbe.FieldModelUInt8(buffer, this.NewMarginCurrencyName.fbeOffset + this.NewMarginCurrencyName.fbeSize), buffer, this.NewMarginCurrencyName.fbeOffset + this.NewMarginCurrencyName.fbeSize)
    this.NewMarginCurrencyPrecisionReset = new fbe.FieldModelBool(buffer, this.NewMarginCurrencyPrecision.fbeOffset + this.NewMarginCurrencyPrecision.fbeSize)
    this.NewMarginCalculationType = new fbe.FieldModelOptional(new FieldModelMarginCalculationType(buffer, this.NewMarginCurrencyPrecisionReset.fbeOffset + this.NewMarginCurrencyPrecisionReset.fbeSize), buffer, this.NewMarginCurrencyPrecisionReset.fbeOffset + this.NewMarginCurrencyPrecisionReset.fbeSize)
    this.NewProfitCurrencyId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.NewMarginCalculationType.fbeOffset + this.NewMarginCalculationType.fbeSize), buffer, this.NewMarginCalculationType.fbeOffset + this.NewMarginCalculationType.fbeSize)
    this.NewProfitCurrencyIdReset = new fbe.FieldModelBool(buffer, this.NewProfitCurrencyId.fbeOffset + this.NewProfitCurrencyId.fbeSize)
    this.NewProfitCurrencyName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewProfitCurrencyIdReset.fbeOffset + this.NewProfitCurrencyIdReset.fbeSize), buffer, this.NewProfitCurrencyIdReset.fbeOffset + this.NewProfitCurrencyIdReset.fbeSize)
    this.NewProfitCurrencyPrecision = new fbe.FieldModelOptional(new fbe.FieldModelUInt8(buffer, this.NewProfitCurrencyName.fbeOffset + this.NewProfitCurrencyName.fbeSize), buffer, this.NewProfitCurrencyName.fbeOffset + this.NewProfitCurrencyName.fbeSize)
    this.NewProfitCurrencyPrecisionReset = new fbe.FieldModelBool(buffer, this.NewProfitCurrencyPrecision.fbeOffset + this.NewProfitCurrencyPrecision.fbeSize)
    this.NewProfitCalculationType = new fbe.FieldModelOptional(new FieldModelProfitCalculationType(buffer, this.NewProfitCurrencyPrecisionReset.fbeOffset + this.NewProfitCurrencyPrecisionReset.fbeSize), buffer, this.NewProfitCurrencyPrecisionReset.fbeOffset + this.NewProfitCurrencyPrecisionReset.fbeSize)
    this.NewColor = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.NewProfitCalculationType.fbeOffset + this.NewProfitCalculationType.fbeSize), buffer, this.NewProfitCalculationType.fbeOffset + this.NewProfitCalculationType.fbeSize)
    this.NewColorReset = new fbe.FieldModelBool(buffer, this.NewColor.fbeOffset + this.NewColor.fbeSize)
    this.NewIsTradeAllowed = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewColorReset.fbeOffset + this.NewColorReset.fbeSize), buffer, this.NewColorReset.fbeOffset + this.NewColorReset.fbeSize)
    this.NewIsTradeAllowedReset = new fbe.FieldModelBool(buffer, this.NewIsTradeAllowed.fbeOffset + this.NewIsTradeAllowed.fbeSize)
    this.NewContractSize = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewIsTradeAllowedReset.fbeOffset + this.NewIsTradeAllowedReset.fbeSize), buffer, this.NewIsTradeAllowedReset.fbeOffset + this.NewIsTradeAllowedReset.fbeSize)
    this.NewMinTradeAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewContractSize.fbeOffset + this.NewContractSize.fbeSize), buffer, this.NewContractSize.fbeOffset + this.NewContractSize.fbeSize)
    this.NewMaxTradeAmount = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewMinTradeAmount.fbeOffset + this.NewMinTradeAmount.fbeSize), buffer, this.NewMinTradeAmount.fbeOffset + this.NewMinTradeAmount.fbeSize)
    this.NewTradeAmountStep = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewMaxTradeAmount.fbeOffset + this.NewMaxTradeAmount.fbeSize), buffer, this.NewMaxTradeAmount.fbeOffset + this.NewMaxTradeAmount.fbeSize)
    this.NewDefaultExpiration = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewTradeAmountStep.fbeOffset + this.NewTradeAmountStep.fbeSize), buffer, this.NewTradeAmountStep.fbeOffset + this.NewTradeAmountStep.fbeSize)
    this.NewDefaultExpirationReset = new fbe.FieldModelBool(buffer, this.NewDefaultExpiration.fbeOffset + this.NewDefaultExpiration.fbeSize)
    this.NewIsSwapEnabled = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewDefaultExpirationReset.fbeOffset + this.NewDefaultExpirationReset.fbeSize), buffer, this.NewDefaultExpirationReset.fbeOffset + this.NewDefaultExpirationReset.fbeSize)
    this.NewSwapType = new fbe.FieldModelOptional(new FieldModelSwapCalculationType(buffer, this.NewIsSwapEnabled.fbeOffset + this.NewIsSwapEnabled.fbeSize), buffer, this.NewIsSwapEnabled.fbeOffset + this.NewIsSwapEnabled.fbeSize)
    this.NewSwapLong = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewSwapType.fbeOffset + this.NewSwapType.fbeSize), buffer, this.NewSwapType.fbeOffset + this.NewSwapType.fbeSize)
    this.NewSwapShort = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewSwapLong.fbeOffset + this.NewSwapLong.fbeSize), buffer, this.NewSwapLong.fbeOffset + this.NewSwapLong.fbeSize)
    this.NewSwapTripleDay = new fbe.FieldModelOptional(new FieldModelDayOfWeek(buffer, this.NewSwapShort.fbeOffset + this.NewSwapShort.fbeSize), buffer, this.NewSwapShort.fbeOffset + this.NewSwapShort.fbeSize)
    this.NewMarketOrderMarginRate = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewSwapTripleDay.fbeOffset + this.NewSwapTripleDay.fbeSize), buffer, this.NewSwapTripleDay.fbeOffset + this.NewSwapTripleDay.fbeSize)
    this.NewLimitOrderMarginRate = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewMarketOrderMarginRate.fbeOffset + this.NewMarketOrderMarginRate.fbeSize), buffer, this.NewMarketOrderMarginRate.fbeOffset + this.NewMarketOrderMarginRate.fbeSize)
    this.NewStopOrderMarginRate = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewLimitOrderMarginRate.fbeOffset + this.NewLimitOrderMarginRate.fbeSize), buffer, this.NewLimitOrderMarginRate.fbeOffset + this.NewLimitOrderMarginRate.fbeSize)
    this.NewTradingMode = new fbe.FieldModelOptional(new FieldModelTradingMode(buffer, this.NewStopOrderMarginRate.fbeOffset + this.NewStopOrderMarginRate.fbeSize), buffer, this.NewStopOrderMarginRate.fbeOffset + this.NewStopOrderMarginRate.fbeSize)
    this.NewTradingModeReset = new fbe.FieldModelBool(buffer, this.NewTradingMode.fbeOffset + this.NewTradingMode.fbeSize)
    this.NewStopsLevel = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewTradingModeReset.fbeOffset + this.NewTradingModeReset.fbeSize), buffer, this.NewTradingModeReset.fbeOffset + this.NewTradingModeReset.fbeSize)
    this.NewIsFavorite = new fbe.FieldModelOptional(new fbe.FieldModelBool(buffer, this.NewStopsLevel.fbeOffset + this.NewStopsLevel.fbeSize), buffer, this.NewStopsLevel.fbeOffset + this.NewStopsLevel.fbeSize)
    this.NewSortOrderFavorite = new fbe.FieldModelOptional(new fbe.FieldModelUInt32(buffer, this.NewIsFavorite.fbeOffset + this.NewIsFavorite.fbeSize), buffer, this.NewIsFavorite.fbeOffset + this.NewIsFavorite.fbeSize)
    this.NewPostfix = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.NewSortOrderFavorite.fbeOffset + this.NewSortOrderFavorite.fbeSize), buffer, this.NewSortOrderFavorite.fbeOffset + this.NewSortOrderFavorite.fbeSize)
    this.NewInitialMargin = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewPostfix.fbeOffset + this.NewPostfix.fbeSize), buffer, this.NewPostfix.fbeOffset + this.NewPostfix.fbeSize)
    this.NewHedgedMargin = new fbe.FieldModelOptional(new fbe.FieldModelDouble(buffer, this.NewInitialMargin.fbeOffset + this.NewInitialMargin.fbeSize), buffer, this.NewInitialMargin.fbeOffset + this.NewInitialMargin.fbeSize)
    this.NewSwapDaysInYear = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.NewHedgedMargin.fbeOffset + this.NewHedgedMargin.fbeSize), buffer, this.NewHedgedMargin.fbeOffset + this.NewHedgedMargin.fbeSize)
  }

  /**
   * Get the ConfigUpdateRequest field model
   * @this {!FieldModelSymbolModifyRequest}
   * @returns {!FieldModelConfigUpdateRequest} ConfigUpdateRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelSymbolModifyRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelSymbolModifyRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.SymbolId.fbeSize + this.SymbolName.fbeSize + this.NewSortOrder.fbeSize + this.NewSymbolGroupId.fbeSize + this.NewSymbolGroupName.fbeSize + this.NewName.fbeSize + this.NewComment.fbeSize + this.NewDescription.fbeSize + this.NewAlias.fbeSize + this.NewSource.fbeSize + this.NewPrecision.fbeSize + this.NewMarginCurrencyId.fbeSize + this.NewMarginCurrencyIdReset.fbeSize + this.NewMarginCurrencyName.fbeSize + this.NewMarginCurrencyPrecision.fbeSize + this.NewMarginCurrencyPrecisionReset.fbeSize + this.NewMarginCalculationType.fbeSize + this.NewProfitCurrencyId.fbeSize + this.NewProfitCurrencyIdReset.fbeSize + this.NewProfitCurrencyName.fbeSize + this.NewProfitCurrencyPrecision.fbeSize + this.NewProfitCurrencyPrecisionReset.fbeSize + this.NewProfitCalculationType.fbeSize + this.NewColor.fbeSize + this.NewColorReset.fbeSize + this.NewIsTradeAllowed.fbeSize + this.NewIsTradeAllowedReset.fbeSize + this.NewContractSize.fbeSize + this.NewMinTradeAmount.fbeSize + this.NewMaxTradeAmount.fbeSize + this.NewTradeAmountStep.fbeSize + this.NewDefaultExpiration.fbeSize + this.NewDefaultExpirationReset.fbeSize + this.NewIsSwapEnabled.fbeSize + this.NewSwapType.fbeSize + this.NewSwapLong.fbeSize + this.NewSwapShort.fbeSize + this.NewSwapTripleDay.fbeSize + this.NewMarketOrderMarginRate.fbeSize + this.NewLimitOrderMarginRate.fbeSize + this.NewStopOrderMarginRate.fbeSize + this.NewTradingMode.fbeSize + this.NewTradingModeReset.fbeSize + this.NewStopsLevel.fbeSize + this.NewIsFavorite.fbeSize + this.NewSortOrderFavorite.fbeSize + this.NewPostfix.fbeSize + this.NewInitialMargin.fbeSize + this.NewHedgedMargin.fbeSize + this.NewSwapDaysInYear.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelSymbolModifyRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.SymbolId.fbeExtra + this.SymbolName.fbeExtra + this.NewSortOrder.fbeExtra + this.NewSymbolGroupId.fbeExtra + this.NewSymbolGroupName.fbeExtra + this.NewName.fbeExtra + this.NewComment.fbeExtra + this.NewDescription.fbeExtra + this.NewAlias.fbeExtra + this.NewSource.fbeExtra + this.NewPrecision.fbeExtra + this.NewMarginCurrencyId.fbeExtra + this.NewMarginCurrencyIdReset.fbeExtra + this.NewMarginCurrencyName.fbeExtra + this.NewMarginCurrencyPrecision.fbeExtra + this.NewMarginCurrencyPrecisionReset.fbeExtra + this.NewMarginCalculationType.fbeExtra + this.NewProfitCurrencyId.fbeExtra + this.NewProfitCurrencyIdReset.fbeExtra + this.NewProfitCurrencyName.fbeExtra + this.NewProfitCurrencyPrecision.fbeExtra + this.NewProfitCurrencyPrecisionReset.fbeExtra + this.NewProfitCalculationType.fbeExtra + this.NewColor.fbeExtra + this.NewColorReset.fbeExtra + this.NewIsTradeAllowed.fbeExtra + this.NewIsTradeAllowedReset.fbeExtra + this.NewContractSize.fbeExtra + this.NewMinTradeAmount.fbeExtra + this.NewMaxTradeAmount.fbeExtra + this.NewTradeAmountStep.fbeExtra + this.NewDefaultExpiration.fbeExtra + this.NewDefaultExpirationReset.fbeExtra + this.NewIsSwapEnabled.fbeExtra + this.NewSwapType.fbeExtra + this.NewSwapLong.fbeExtra + this.NewSwapShort.fbeExtra + this.NewSwapTripleDay.fbeExtra + this.NewMarketOrderMarginRate.fbeExtra + this.NewLimitOrderMarginRate.fbeExtra + this.NewStopOrderMarginRate.fbeExtra + this.NewTradingMode.fbeExtra + this.NewTradingModeReset.fbeExtra + this.NewStopsLevel.fbeExtra + this.NewIsFavorite.fbeExtra + this.NewSortOrderFavorite.fbeExtra + this.NewPostfix.fbeExtra + this.NewInitialMargin.fbeExtra + this.NewHedgedMargin.fbeExtra + this.NewSwapDaysInYear.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelSymbolModifyRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelSymbolModifyRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelSymbolModifyRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6113
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelSymbolModifyRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelSymbolModifyRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SymbolId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.SymbolName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolName.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSortOrder.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewSymbolGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSymbolGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolGroupId.fbeSize

    if ((fbeCurrentSize + this.NewSymbolGroupName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSymbolGroupName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolGroupName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDescription.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewAlias.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewAlias.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAlias.fbeSize

    if ((fbeCurrentSize + this.NewSource.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSource.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSource.fbeSize

    if ((fbeCurrentSize + this.NewPrecision.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPrecision.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPrecision.fbeSize

    if ((fbeCurrentSize + this.NewMarginCurrencyId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMarginCurrencyId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarginCurrencyId.fbeSize

    if ((fbeCurrentSize + this.NewMarginCurrencyIdReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMarginCurrencyIdReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarginCurrencyIdReset.fbeSize

    if ((fbeCurrentSize + this.NewMarginCurrencyName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMarginCurrencyName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarginCurrencyName.fbeSize

    if ((fbeCurrentSize + this.NewMarginCurrencyPrecision.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMarginCurrencyPrecision.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarginCurrencyPrecision.fbeSize

    if ((fbeCurrentSize + this.NewMarginCurrencyPrecisionReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMarginCurrencyPrecisionReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarginCurrencyPrecisionReset.fbeSize

    if ((fbeCurrentSize + this.NewMarginCalculationType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMarginCalculationType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarginCalculationType.fbeSize

    if ((fbeCurrentSize + this.NewProfitCurrencyId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewProfitCurrencyId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewProfitCurrencyId.fbeSize

    if ((fbeCurrentSize + this.NewProfitCurrencyIdReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewProfitCurrencyIdReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewProfitCurrencyIdReset.fbeSize

    if ((fbeCurrentSize + this.NewProfitCurrencyName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewProfitCurrencyName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewProfitCurrencyName.fbeSize

    if ((fbeCurrentSize + this.NewProfitCurrencyPrecision.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewProfitCurrencyPrecision.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewProfitCurrencyPrecision.fbeSize

    if ((fbeCurrentSize + this.NewProfitCurrencyPrecisionReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewProfitCurrencyPrecisionReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewProfitCurrencyPrecisionReset.fbeSize

    if ((fbeCurrentSize + this.NewProfitCalculationType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewProfitCalculationType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewProfitCalculationType.fbeSize

    if ((fbeCurrentSize + this.NewColor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewColor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewColor.fbeSize

    if ((fbeCurrentSize + this.NewColorReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewColorReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewColorReset.fbeSize

    if ((fbeCurrentSize + this.NewIsTradeAllowed.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsTradeAllowed.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsTradeAllowed.fbeSize

    if ((fbeCurrentSize + this.NewIsTradeAllowedReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsTradeAllowedReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsTradeAllowedReset.fbeSize

    if ((fbeCurrentSize + this.NewContractSize.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewContractSize.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewContractSize.fbeSize

    if ((fbeCurrentSize + this.NewMinTradeAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMinTradeAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMinTradeAmount.fbeSize

    if ((fbeCurrentSize + this.NewMaxTradeAmount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMaxTradeAmount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxTradeAmount.fbeSize

    if ((fbeCurrentSize + this.NewTradeAmountStep.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewTradeAmountStep.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTradeAmountStep.fbeSize

    if ((fbeCurrentSize + this.NewDefaultExpiration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDefaultExpiration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultExpiration.fbeSize

    if ((fbeCurrentSize + this.NewDefaultExpirationReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewDefaultExpirationReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultExpirationReset.fbeSize

    if ((fbeCurrentSize + this.NewIsSwapEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsSwapEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsSwapEnabled.fbeSize

    if ((fbeCurrentSize + this.NewSwapType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSwapType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSwapType.fbeSize

    if ((fbeCurrentSize + this.NewSwapLong.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSwapLong.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSwapLong.fbeSize

    if ((fbeCurrentSize + this.NewSwapShort.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSwapShort.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSwapShort.fbeSize

    if ((fbeCurrentSize + this.NewSwapTripleDay.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSwapTripleDay.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSwapTripleDay.fbeSize

    if ((fbeCurrentSize + this.NewMarketOrderMarginRate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewMarketOrderMarginRate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarketOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.NewLimitOrderMarginRate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewLimitOrderMarginRate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLimitOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.NewStopOrderMarginRate.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStopOrderMarginRate.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.NewTradingMode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewTradingMode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTradingMode.fbeSize

    if ((fbeCurrentSize + this.NewTradingModeReset.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewTradingModeReset.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTradingModeReset.fbeSize

    if ((fbeCurrentSize + this.NewStopsLevel.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewStopsLevel.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopsLevel.fbeSize

    if ((fbeCurrentSize + this.NewIsFavorite.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewIsFavorite.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsFavorite.fbeSize

    if ((fbeCurrentSize + this.NewSortOrderFavorite.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSortOrderFavorite.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrderFavorite.fbeSize

    if ((fbeCurrentSize + this.NewPostfix.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewPostfix.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPostfix.fbeSize

    if ((fbeCurrentSize + this.NewInitialMargin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewInitialMargin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewInitialMargin.fbeSize

    if ((fbeCurrentSize + this.NewHedgedMargin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewHedgedMargin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewHedgedMargin.fbeSize

    if ((fbeCurrentSize + this.NewSwapDaysInYear.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.NewSwapDaysInYear.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSwapDaysInYear.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelSymbolModifyRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelSymbolModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelSymbolModifyRequest}
   * @param {!SymbolModifyRequest} fbeValue Default value, defaults is new SymbolModifyRequest()
   * @returns {!SymbolModifyRequest} SymbolModifyRequest value
   */
  get (fbeValue = new SymbolModifyRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelSymbolModifyRequest}
   * @param {!SymbolModifyRequest} fbeValue SymbolModifyRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.SymbolId.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolId = this.SymbolId.get()
    } else {
      fbeValue.SymbolId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.SymbolName.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolName = this.SymbolName.get()
    } else {
      fbeValue.SymbolName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolName.fbeSize

    if ((fbeCurrentSize + this.NewSortOrder.fbeSize) <= fbeStructSize) {
      fbeValue.NewSortOrder = this.NewSortOrder.get()
    } else {
      fbeValue.NewSortOrder = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrder.fbeSize

    if ((fbeCurrentSize + this.NewSymbolGroupId.fbeSize) <= fbeStructSize) {
      fbeValue.NewSymbolGroupId = this.NewSymbolGroupId.get()
    } else {
      fbeValue.NewSymbolGroupId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolGroupId.fbeSize

    if ((fbeCurrentSize + this.NewSymbolGroupName.fbeSize) <= fbeStructSize) {
      fbeValue.NewSymbolGroupName = this.NewSymbolGroupName.get()
    } else {
      fbeValue.NewSymbolGroupName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSymbolGroupName.fbeSize

    if ((fbeCurrentSize + this.NewName.fbeSize) <= fbeStructSize) {
      fbeValue.NewName = this.NewName.get()
    } else {
      fbeValue.NewName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewName.fbeSize

    if ((fbeCurrentSize + this.NewComment.fbeSize) <= fbeStructSize) {
      fbeValue.NewComment = this.NewComment.get()
    } else {
      fbeValue.NewComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewComment.fbeSize

    if ((fbeCurrentSize + this.NewDescription.fbeSize) <= fbeStructSize) {
      fbeValue.NewDescription = this.NewDescription.get()
    } else {
      fbeValue.NewDescription = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDescription.fbeSize

    if ((fbeCurrentSize + this.NewAlias.fbeSize) <= fbeStructSize) {
      fbeValue.NewAlias = this.NewAlias.get()
    } else {
      fbeValue.NewAlias = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewAlias.fbeSize

    if ((fbeCurrentSize + this.NewSource.fbeSize) <= fbeStructSize) {
      fbeValue.NewSource = this.NewSource.get()
    } else {
      fbeValue.NewSource = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSource.fbeSize

    if ((fbeCurrentSize + this.NewPrecision.fbeSize) <= fbeStructSize) {
      fbeValue.NewPrecision = this.NewPrecision.get()
    } else {
      fbeValue.NewPrecision = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPrecision.fbeSize

    if ((fbeCurrentSize + this.NewMarginCurrencyId.fbeSize) <= fbeStructSize) {
      fbeValue.NewMarginCurrencyId = this.NewMarginCurrencyId.get()
    } else {
      fbeValue.NewMarginCurrencyId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarginCurrencyId.fbeSize

    if ((fbeCurrentSize + this.NewMarginCurrencyIdReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewMarginCurrencyIdReset = this.NewMarginCurrencyIdReset.get()
    } else {
      fbeValue.NewMarginCurrencyIdReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarginCurrencyIdReset.fbeSize

    if ((fbeCurrentSize + this.NewMarginCurrencyName.fbeSize) <= fbeStructSize) {
      fbeValue.NewMarginCurrencyName = this.NewMarginCurrencyName.get()
    } else {
      fbeValue.NewMarginCurrencyName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarginCurrencyName.fbeSize

    if ((fbeCurrentSize + this.NewMarginCurrencyPrecision.fbeSize) <= fbeStructSize) {
      fbeValue.NewMarginCurrencyPrecision = this.NewMarginCurrencyPrecision.get()
    } else {
      fbeValue.NewMarginCurrencyPrecision = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarginCurrencyPrecision.fbeSize

    if ((fbeCurrentSize + this.NewMarginCurrencyPrecisionReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewMarginCurrencyPrecisionReset = this.NewMarginCurrencyPrecisionReset.get()
    } else {
      fbeValue.NewMarginCurrencyPrecisionReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarginCurrencyPrecisionReset.fbeSize

    if ((fbeCurrentSize + this.NewMarginCalculationType.fbeSize) <= fbeStructSize) {
      fbeValue.NewMarginCalculationType = this.NewMarginCalculationType.get()
    } else {
      fbeValue.NewMarginCalculationType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarginCalculationType.fbeSize

    if ((fbeCurrentSize + this.NewProfitCurrencyId.fbeSize) <= fbeStructSize) {
      fbeValue.NewProfitCurrencyId = this.NewProfitCurrencyId.get()
    } else {
      fbeValue.NewProfitCurrencyId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewProfitCurrencyId.fbeSize

    if ((fbeCurrentSize + this.NewProfitCurrencyIdReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewProfitCurrencyIdReset = this.NewProfitCurrencyIdReset.get()
    } else {
      fbeValue.NewProfitCurrencyIdReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewProfitCurrencyIdReset.fbeSize

    if ((fbeCurrentSize + this.NewProfitCurrencyName.fbeSize) <= fbeStructSize) {
      fbeValue.NewProfitCurrencyName = this.NewProfitCurrencyName.get()
    } else {
      fbeValue.NewProfitCurrencyName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewProfitCurrencyName.fbeSize

    if ((fbeCurrentSize + this.NewProfitCurrencyPrecision.fbeSize) <= fbeStructSize) {
      fbeValue.NewProfitCurrencyPrecision = this.NewProfitCurrencyPrecision.get()
    } else {
      fbeValue.NewProfitCurrencyPrecision = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewProfitCurrencyPrecision.fbeSize

    if ((fbeCurrentSize + this.NewProfitCurrencyPrecisionReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewProfitCurrencyPrecisionReset = this.NewProfitCurrencyPrecisionReset.get()
    } else {
      fbeValue.NewProfitCurrencyPrecisionReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewProfitCurrencyPrecisionReset.fbeSize

    if ((fbeCurrentSize + this.NewProfitCalculationType.fbeSize) <= fbeStructSize) {
      fbeValue.NewProfitCalculationType = this.NewProfitCalculationType.get()
    } else {
      fbeValue.NewProfitCalculationType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewProfitCalculationType.fbeSize

    if ((fbeCurrentSize + this.NewColor.fbeSize) <= fbeStructSize) {
      fbeValue.NewColor = this.NewColor.get()
    } else {
      fbeValue.NewColor = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewColor.fbeSize

    if ((fbeCurrentSize + this.NewColorReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewColorReset = this.NewColorReset.get()
    } else {
      fbeValue.NewColorReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewColorReset.fbeSize

    if ((fbeCurrentSize + this.NewIsTradeAllowed.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsTradeAllowed = this.NewIsTradeAllowed.get()
    } else {
      fbeValue.NewIsTradeAllowed = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsTradeAllowed.fbeSize

    if ((fbeCurrentSize + this.NewIsTradeAllowedReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsTradeAllowedReset = this.NewIsTradeAllowedReset.get()
    } else {
      fbeValue.NewIsTradeAllowedReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsTradeAllowedReset.fbeSize

    if ((fbeCurrentSize + this.NewContractSize.fbeSize) <= fbeStructSize) {
      fbeValue.NewContractSize = this.NewContractSize.get()
    } else {
      fbeValue.NewContractSize = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewContractSize.fbeSize

    if ((fbeCurrentSize + this.NewMinTradeAmount.fbeSize) <= fbeStructSize) {
      fbeValue.NewMinTradeAmount = this.NewMinTradeAmount.get()
    } else {
      fbeValue.NewMinTradeAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMinTradeAmount.fbeSize

    if ((fbeCurrentSize + this.NewMaxTradeAmount.fbeSize) <= fbeStructSize) {
      fbeValue.NewMaxTradeAmount = this.NewMaxTradeAmount.get()
    } else {
      fbeValue.NewMaxTradeAmount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMaxTradeAmount.fbeSize

    if ((fbeCurrentSize + this.NewTradeAmountStep.fbeSize) <= fbeStructSize) {
      fbeValue.NewTradeAmountStep = this.NewTradeAmountStep.get()
    } else {
      fbeValue.NewTradeAmountStep = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTradeAmountStep.fbeSize

    if ((fbeCurrentSize + this.NewDefaultExpiration.fbeSize) <= fbeStructSize) {
      fbeValue.NewDefaultExpiration = this.NewDefaultExpiration.get()
    } else {
      fbeValue.NewDefaultExpiration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultExpiration.fbeSize

    if ((fbeCurrentSize + this.NewDefaultExpirationReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewDefaultExpirationReset = this.NewDefaultExpirationReset.get()
    } else {
      fbeValue.NewDefaultExpirationReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewDefaultExpirationReset.fbeSize

    if ((fbeCurrentSize + this.NewIsSwapEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsSwapEnabled = this.NewIsSwapEnabled.get()
    } else {
      fbeValue.NewIsSwapEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsSwapEnabled.fbeSize

    if ((fbeCurrentSize + this.NewSwapType.fbeSize) <= fbeStructSize) {
      fbeValue.NewSwapType = this.NewSwapType.get()
    } else {
      fbeValue.NewSwapType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSwapType.fbeSize

    if ((fbeCurrentSize + this.NewSwapLong.fbeSize) <= fbeStructSize) {
      fbeValue.NewSwapLong = this.NewSwapLong.get()
    } else {
      fbeValue.NewSwapLong = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSwapLong.fbeSize

    if ((fbeCurrentSize + this.NewSwapShort.fbeSize) <= fbeStructSize) {
      fbeValue.NewSwapShort = this.NewSwapShort.get()
    } else {
      fbeValue.NewSwapShort = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSwapShort.fbeSize

    if ((fbeCurrentSize + this.NewSwapTripleDay.fbeSize) <= fbeStructSize) {
      fbeValue.NewSwapTripleDay = this.NewSwapTripleDay.get()
    } else {
      fbeValue.NewSwapTripleDay = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSwapTripleDay.fbeSize

    if ((fbeCurrentSize + this.NewMarketOrderMarginRate.fbeSize) <= fbeStructSize) {
      fbeValue.NewMarketOrderMarginRate = this.NewMarketOrderMarginRate.get()
    } else {
      fbeValue.NewMarketOrderMarginRate = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewMarketOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.NewLimitOrderMarginRate.fbeSize) <= fbeStructSize) {
      fbeValue.NewLimitOrderMarginRate = this.NewLimitOrderMarginRate.get()
    } else {
      fbeValue.NewLimitOrderMarginRate = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewLimitOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.NewStopOrderMarginRate.fbeSize) <= fbeStructSize) {
      fbeValue.NewStopOrderMarginRate = this.NewStopOrderMarginRate.get()
    } else {
      fbeValue.NewStopOrderMarginRate = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopOrderMarginRate.fbeSize

    if ((fbeCurrentSize + this.NewTradingMode.fbeSize) <= fbeStructSize) {
      fbeValue.NewTradingMode = this.NewTradingMode.get()
    } else {
      fbeValue.NewTradingMode = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTradingMode.fbeSize

    if ((fbeCurrentSize + this.NewTradingModeReset.fbeSize) <= fbeStructSize) {
      fbeValue.NewTradingModeReset = this.NewTradingModeReset.get()
    } else {
      fbeValue.NewTradingModeReset = false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewTradingModeReset.fbeSize

    if ((fbeCurrentSize + this.NewStopsLevel.fbeSize) <= fbeStructSize) {
      fbeValue.NewStopsLevel = this.NewStopsLevel.get()
    } else {
      fbeValue.NewStopsLevel = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewStopsLevel.fbeSize

    if ((fbeCurrentSize + this.NewIsFavorite.fbeSize) <= fbeStructSize) {
      fbeValue.NewIsFavorite = this.NewIsFavorite.get()
    } else {
      fbeValue.NewIsFavorite = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewIsFavorite.fbeSize

    if ((fbeCurrentSize + this.NewSortOrderFavorite.fbeSize) <= fbeStructSize) {
      fbeValue.NewSortOrderFavorite = this.NewSortOrderFavorite.get()
    } else {
      fbeValue.NewSortOrderFavorite = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSortOrderFavorite.fbeSize

    if ((fbeCurrentSize + this.NewPostfix.fbeSize) <= fbeStructSize) {
      fbeValue.NewPostfix = this.NewPostfix.get()
    } else {
      fbeValue.NewPostfix = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewPostfix.fbeSize

    if ((fbeCurrentSize + this.NewInitialMargin.fbeSize) <= fbeStructSize) {
      fbeValue.NewInitialMargin = this.NewInitialMargin.get()
    } else {
      fbeValue.NewInitialMargin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewInitialMargin.fbeSize

    if ((fbeCurrentSize + this.NewHedgedMargin.fbeSize) <= fbeStructSize) {
      fbeValue.NewHedgedMargin = this.NewHedgedMargin.get()
    } else {
      fbeValue.NewHedgedMargin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewHedgedMargin.fbeSize

    if ((fbeCurrentSize + this.NewSwapDaysInYear.fbeSize) <= fbeStructSize) {
      fbeValue.NewSwapDaysInYear = this.NewSwapDaysInYear.get()
    } else {
      fbeValue.NewSwapDaysInYear = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.NewSwapDaysInYear.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelSymbolModifyRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelSymbolModifyRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelSymbolModifyRequest}
   * @param {!SymbolModifyRequest} fbeValue SymbolModifyRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelSymbolModifyRequest}
   * @param {SymbolModifyRequest} fbeValue SymbolModifyRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.SymbolId.set(fbeValue.SymbolId)
    this.SymbolName.set(fbeValue.SymbolName)
    this.NewSortOrder.set(fbeValue.NewSortOrder)
    this.NewSymbolGroupId.set(fbeValue.NewSymbolGroupId)
    this.NewSymbolGroupName.set(fbeValue.NewSymbolGroupName)
    this.NewName.set(fbeValue.NewName)
    this.NewComment.set(fbeValue.NewComment)
    this.NewDescription.set(fbeValue.NewDescription)
    this.NewAlias.set(fbeValue.NewAlias)
    this.NewSource.set(fbeValue.NewSource)
    this.NewPrecision.set(fbeValue.NewPrecision)
    this.NewMarginCurrencyId.set(fbeValue.NewMarginCurrencyId)
    this.NewMarginCurrencyIdReset.set(fbeValue.NewMarginCurrencyIdReset)
    this.NewMarginCurrencyName.set(fbeValue.NewMarginCurrencyName)
    this.NewMarginCurrencyPrecision.set(fbeValue.NewMarginCurrencyPrecision)
    this.NewMarginCurrencyPrecisionReset.set(fbeValue.NewMarginCurrencyPrecisionReset)
    this.NewMarginCalculationType.set(fbeValue.NewMarginCalculationType)
    this.NewProfitCurrencyId.set(fbeValue.NewProfitCurrencyId)
    this.NewProfitCurrencyIdReset.set(fbeValue.NewProfitCurrencyIdReset)
    this.NewProfitCurrencyName.set(fbeValue.NewProfitCurrencyName)
    this.NewProfitCurrencyPrecision.set(fbeValue.NewProfitCurrencyPrecision)
    this.NewProfitCurrencyPrecisionReset.set(fbeValue.NewProfitCurrencyPrecisionReset)
    this.NewProfitCalculationType.set(fbeValue.NewProfitCalculationType)
    this.NewColor.set(fbeValue.NewColor)
    this.NewColorReset.set(fbeValue.NewColorReset)
    this.NewIsTradeAllowed.set(fbeValue.NewIsTradeAllowed)
    this.NewIsTradeAllowedReset.set(fbeValue.NewIsTradeAllowedReset)
    this.NewContractSize.set(fbeValue.NewContractSize)
    this.NewMinTradeAmount.set(fbeValue.NewMinTradeAmount)
    this.NewMaxTradeAmount.set(fbeValue.NewMaxTradeAmount)
    this.NewTradeAmountStep.set(fbeValue.NewTradeAmountStep)
    this.NewDefaultExpiration.set(fbeValue.NewDefaultExpiration)
    this.NewDefaultExpirationReset.set(fbeValue.NewDefaultExpirationReset)
    this.NewIsSwapEnabled.set(fbeValue.NewIsSwapEnabled)
    this.NewSwapType.set(fbeValue.NewSwapType)
    this.NewSwapLong.set(fbeValue.NewSwapLong)
    this.NewSwapShort.set(fbeValue.NewSwapShort)
    this.NewSwapTripleDay.set(fbeValue.NewSwapTripleDay)
    this.NewMarketOrderMarginRate.set(fbeValue.NewMarketOrderMarginRate)
    this.NewLimitOrderMarginRate.set(fbeValue.NewLimitOrderMarginRate)
    this.NewStopOrderMarginRate.set(fbeValue.NewStopOrderMarginRate)
    this.NewTradingMode.set(fbeValue.NewTradingMode)
    this.NewTradingModeReset.set(fbeValue.NewTradingModeReset)
    this.NewStopsLevel.set(fbeValue.NewStopsLevel)
    this.NewIsFavorite.set(fbeValue.NewIsFavorite)
    this.NewSortOrderFavorite.set(fbeValue.NewSortOrderFavorite)
    this.NewPostfix.set(fbeValue.NewPostfix)
    this.NewInitialMargin.set(fbeValue.NewInitialMargin)
    this.NewHedgedMargin.set(fbeValue.NewHedgedMargin)
    this.NewSwapDaysInYear.set(fbeValue.NewSwapDaysInYear)
  }
}

export { FieldModelSymbolModifyRequest };

/**
 * Fast Binary Encoding SymbolModifyRequest model
 */
class SymbolModifyRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelSymbolModifyRequest(this.buffer, 4)
  }

  /**
   * Get the SymbolModifyRequest model
   * @this {!SymbolModifyRequestModel}
   * @returns {!FieldModelSymbolModifyRequest} model SymbolModifyRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!SymbolModifyRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!SymbolModifyRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return SymbolModifyRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!SymbolModifyRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelSymbolModifyRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!SymbolModifyRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!SymbolModifyRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!SymbolModifyRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!SymbolModifyRequestModel}
   * @param {!SymbolModifyRequest} value SymbolModifyRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!SymbolModifyRequestModel}
   * @param {!SymbolModifyRequest} value SymbolModifyRequest value, defaults is new SymbolModifyRequest()
   * @return {!object} Deserialized SymbolModifyRequest value and its size
   */
  deserialize (value = new SymbolModifyRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new SymbolModifyRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new SymbolModifyRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!SymbolModifyRequestModel}
   * @param {!number} prev Previous SymbolModifyRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { SymbolModifyRequestModel };
SymbolModifyRequest.__has_response = true;
SymbolModifyRequest.__response_class = SymbolModifyResponse;
