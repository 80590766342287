import { DEFAULT_MAX_PRECISION } from "@constants/presets";

const getRoundedNumber = (value: number, precision: number): number => {
    if (precision === 0 && Number.isFinite(value)) return Math.trunc(value)
    if (precision < 1 || !Number.isInteger(precision) || !Number.isFinite(value)) return value;
    const shift = (val, exponent) => {
        val = `${val}e`.split('e');
        return +(`${val[0]}e${+val[1] + (exponent || 0)}`);
    };
    const n = shift(value, +precision);
    return shift(Math.trunc(n), -precision);
};

export const formatNumber = (
    value: number | string | undefined | null,
    precision: number = 2,
    placeholder: string = '',
    fillZeros: boolean = false,
): string => {
    let result = ''
    if (typeof value === 'number' && Number.isFinite(value)) {
        result = getRoundedNumber(value, precision).toString();
    }
    if (typeof value === 'string' && !!value) {
        result = getRoundedNumber(+value, precision).toString();
    }

    if (result && +result && fillZeros) {
        const decPart = result.split('.')[1];
        if (!decPart || (decPart && decPart.length < precision)) {
            result = (+result).toFixed(precision);
        }
    }

    return result || placeholder;
};

export const getNumberPrecision = (value: number): number => {
    const converted = value.toString();
    if (converted.includes('.')) {
        return converted.split('.')[1].length;
    }
    return 0;
};

export const searchFormatterForCutNumbers = (value: string | number | null | undefined, precision: number = DEFAULT_MAX_PRECISION, fillZeros: boolean = false) => {
    const formatted = formatNumber(value, precision, '', fillZeros);
    return formatted ? +formatted : value;
};

export const MAX_SAFE_UINT64 = 2097150;
