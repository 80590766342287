// Automatically generated by the Fast Binary Encoding compiler, do not modify!
// https://github.com/chronoxor/FastBinaryEncoding
// Source: inner.fbe
// Version: 1.4.0.0

/* eslint-disable prefer-const */
'use strict'

import * as util from 'util'

import * as big from './big'
import * as int64 from './int64'
import * as uuid from './uuid'

import * as fbe from './fbe'
import * as core from './core'
import * as history from './history'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {RejectDetail} from './inner/RejectDetail'
import {DashboardUpdateResult} from './inner/DashboardUpdateResult'
import {ClientReportTemplatePlaceholder} from './inner/ClientReportTemplatePlaceholder'
import {ClientAuthorizeRequest} from './inner/ClientAuthorizeRequest'
import {ClientAuthorizeRequestModel} from './inner/ClientAuthorizeRequest'
import {ClientAuthorizeResponse} from './inner/ClientAuthorizeResponse'
import {ClientAuthorizeResponseModel} from './inner/ClientAuthorizeResponse'
import {DrawingStorageChartRequest} from './inner/DrawingStorageChartRequest'
import {DrawingStorageChartRequestModel} from './inner/DrawingStorageChartRequest'
import {DrawingStorageChartResponse} from './inner/DrawingStorageChartResponse'
import {DrawingStorageChartResponseModel} from './inner/DrawingStorageChartResponse'
import {DrawingStorageDashboardRequest} from './inner/DrawingStorageDashboardRequest'
import {DrawingStorageDashboardRequestModel} from './inner/DrawingStorageDashboardRequest'
import {DrawingStorageDashboardResponse} from './inner/DrawingStorageDashboardResponse'
import {DrawingStorageDashboardResponseModel} from './inner/DrawingStorageDashboardResponse'
import {DrawingStorageNotFoundResponse} from './inner/DrawingStorageNotFoundResponse'
import {DrawingStorageNotFoundResponseModel} from './inner/DrawingStorageNotFoundResponse'
import {TradingCentralAuthenticationResponse} from './inner/TradingCentralAuthenticationResponse'
import {TradingCentralAuthenticationResponseModel} from './inner/TradingCentralAuthenticationResponse'
import {TradingCentralNewsArticleInfo} from './inner/TradingCentralNewsArticleInfo'
import {TradingCentralNewsArticleInfoModel} from './inner/TradingCentralNewsArticleInfo'
import {TradingCentralNewsArticle} from './inner/TradingCentralNewsArticle'
import {TradingCentralNewsArticleModel} from './inner/TradingCentralNewsArticle'
import {TradingAccountRequest} from './inner/TradingAccountRequest'
import {TradingAccountRequestModel} from './inner/TradingAccountRequest'
import {TradingAccountResponse} from './inner/TradingAccountResponse'
import {TradingAccountResponseModel} from './inner/TradingAccountResponse'
import {TradingAccountErrorResponse} from './inner/TradingAccountErrorResponse'
import {TradingAccountErrorResponseModel} from './inner/TradingAccountErrorResponse'
import {DrawingStorageDashboardUpdateFailureResponse} from './inner/DrawingStorageDashboardUpdateFailureResponse'
import {DrawingStorageDashboardUpdateFailureResponseModel} from './inner/DrawingStorageDashboardUpdateFailureResponse'
import {HeartbeatRequest} from './inner/HeartbeatRequest'
import {HeartbeatRequestModel} from './inner/HeartbeatRequest'
import {HeartbeatResponse} from './inner/HeartbeatResponse'
import {HeartbeatResponseModel} from './inner/HeartbeatResponse'
import {DrawingStorageDashboardsRequest} from './inner/DrawingStorageDashboardsRequest'
import {DrawingStorageDashboardsRequestModel} from './inner/DrawingStorageDashboardsRequest'
import {DrawingStorageDashboardData} from './inner/DrawingStorageDashboardData'
import {DrawingStorageDashboardDataModel} from './inner/DrawingStorageDashboardData'
import {DrawingStorageDashboardsResponse} from './inner/DrawingStorageDashboardsResponse'
import {DrawingStorageDashboardsResponseModel} from './inner/DrawingStorageDashboardsResponse'
import {DrawingStorageChartsRequest} from './inner/DrawingStorageChartsRequest'
import {DrawingStorageChartsRequestModel} from './inner/DrawingStorageChartsRequest'
import {DrawingStorageChartData} from './inner/DrawingStorageChartData'
import {DrawingStorageChartDataModel} from './inner/DrawingStorageChartData'
import {DrawingStorageChartsResponse} from './inner/DrawingStorageChartsResponse'
import {DrawingStorageChartsResponseModel} from './inner/DrawingStorageChartsResponse'
import {ModuleSystemInfoRequest} from './inner/ModuleSystemInfoRequest'
import {ModuleSystemInfoRequestModel} from './inner/ModuleSystemInfoRequest'
import {ModuleSystemInfoResponse} from './inner/ModuleSystemInfoResponse'
import {ModuleSystemInfoResponseModel} from './inner/ModuleSystemInfoResponse'
import {ClientReportsInsertRequest} from './inner/ClientReportsInsertRequest'
import {ClientReportsInsertRequestModel} from './inner/ClientReportsInsertRequest'
import {ClientReportsInsertResponse} from './inner/ClientReportsInsertResponse'
import {ClientReportsInsertResponseModel} from './inner/ClientReportsInsertResponse'
import {ClientReportsGetLastIdRequest} from './inner/ClientReportsGetLastIdRequest'
import {ClientReportsGetLastIdRequestModel} from './inner/ClientReportsGetLastIdRequest'
import {ClientReportsGetLastIdResponse} from './inner/ClientReportsGetLastIdResponse'
import {ClientReportsGetLastIdResponseModel} from './inner/ClientReportsGetLastIdResponse'

// Protocol major version
const ProtocolVersionMajor = 0
// Protocol minor version
const ProtocolVersionMinor = 6

export { ProtocolVersionMajor };
export { ProtocolVersionMinor };

/**
 * Fast Binary Encoding inner sender
 */
class Sender extends fbe.Sender {
  /**
   * Initialize inner sender with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreSender = new core.Sender(this.buffer)
    this._historySender = new history.Sender(this.buffer)
    this._ClientAuthorizeRequestModel = new ClientAuthorizeRequestModel(this.buffer)
    this._ClientAuthorizeResponseModel = new ClientAuthorizeResponseModel(this.buffer)
    this._DrawingStorageDashboardsRequestModel = new DrawingStorageDashboardsRequestModel(this.buffer)
    this._DrawingStorageDashboardsResponseModel = new DrawingStorageDashboardsResponseModel(this.buffer)
    this._DrawingStorageChartsRequestModel = new DrawingStorageChartsRequestModel(this.buffer)
    this._DrawingStorageChartsResponseModel = new DrawingStorageChartsResponseModel(this.buffer)
    this._ModuleSystemInfoRequestModel = new ModuleSystemInfoRequestModel(this.buffer)
    this._ModuleSystemInfoResponseModel = new ModuleSystemInfoResponseModel(this.buffer)
    this._ClientReportsInsertRequestModel = new ClientReportsInsertRequestModel(this.buffer)
    this._ClientReportsInsertResponseModel = new ClientReportsInsertResponseModel(this.buffer)
    this._ClientReportsGetLastIdRequestModel = new ClientReportsGetLastIdRequestModel(this.buffer)
    this._ClientReportsGetLastIdResponseModel = new ClientReportsGetLastIdResponseModel(this.buffer)
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
  }

  // Imported senders

  /**
   * Get imported core sender
   * @this {!Sender}
   * @returns {!core.Sender} core sender
   */
  get coreSender () {
    return this._coreSender
  }

  /**
   * Get imported history sender
   * @this {!Sender}
   * @returns {!history.Sender} history sender
   */
  get historySender () {
    return this._historySender
  }

  // Sender models accessors

  /**
   * Get ClientAuthorizeRequest model
   * @this {!Sender}
   * @returns {!ClientAuthorizeRequestModel} ClientAuthorizeRequest model
   */
  get ClientAuthorizeRequestModel () {
    return this._ClientAuthorizeRequestModel
  }

  /**
   * Get ClientAuthorizeResponse model
   * @this {!Sender}
   * @returns {!ClientAuthorizeResponseModel} ClientAuthorizeResponse model
   */
  get ClientAuthorizeResponseModel () {
    return this._ClientAuthorizeResponseModel
  }

  /**
   * Get DrawingStorageDashboardsRequest model
   * @this {!Sender}
   * @returns {!DrawingStorageDashboardsRequestModel} DrawingStorageDashboardsRequest model
   */
  get DrawingStorageDashboardsRequestModel () {
    return this._DrawingStorageDashboardsRequestModel
  }

  /**
   * Get DrawingStorageDashboardsResponse model
   * @this {!Sender}
   * @returns {!DrawingStorageDashboardsResponseModel} DrawingStorageDashboardsResponse model
   */
  get DrawingStorageDashboardsResponseModel () {
    return this._DrawingStorageDashboardsResponseModel
  }

  /**
   * Get DrawingStorageChartsRequest model
   * @this {!Sender}
   * @returns {!DrawingStorageChartsRequestModel} DrawingStorageChartsRequest model
   */
  get DrawingStorageChartsRequestModel () {
    return this._DrawingStorageChartsRequestModel
  }

  /**
   * Get DrawingStorageChartsResponse model
   * @this {!Sender}
   * @returns {!DrawingStorageChartsResponseModel} DrawingStorageChartsResponse model
   */
  get DrawingStorageChartsResponseModel () {
    return this._DrawingStorageChartsResponseModel
  }

  /**
   * Get ModuleSystemInfoRequest model
   * @this {!Sender}
   * @returns {!ModuleSystemInfoRequestModel} ModuleSystemInfoRequest model
   */
  get ModuleSystemInfoRequestModel () {
    return this._ModuleSystemInfoRequestModel
  }

  /**
   * Get ModuleSystemInfoResponse model
   * @this {!Sender}
   * @returns {!ModuleSystemInfoResponseModel} ModuleSystemInfoResponse model
   */
  get ModuleSystemInfoResponseModel () {
    return this._ModuleSystemInfoResponseModel
  }

  /**
   * Get ClientReportsInsertRequest model
   * @this {!Sender}
   * @returns {!ClientReportsInsertRequestModel} ClientReportsInsertRequest model
   */
  get ClientReportsInsertRequestModel () {
    return this._ClientReportsInsertRequestModel
  }

  /**
   * Get ClientReportsInsertResponse model
   * @this {!Sender}
   * @returns {!ClientReportsInsertResponseModel} ClientReportsInsertResponse model
   */
  get ClientReportsInsertResponseModel () {
    return this._ClientReportsInsertResponseModel
  }

  /**
   * Get ClientReportsGetLastIdRequest model
   * @this {!Sender}
   * @returns {!ClientReportsGetLastIdRequestModel} ClientReportsGetLastIdRequest model
   */
  get ClientReportsGetLastIdRequestModel () {
    return this._ClientReportsGetLastIdRequestModel
  }

  /**
   * Get ClientReportsGetLastIdResponse model
   * @this {!Sender}
   * @returns {!ClientReportsGetLastIdResponseModel} ClientReportsGetLastIdResponse model
   */
  get ClientReportsGetLastIdResponseModel () {
    return this._ClientReportsGetLastIdResponseModel
  }

  // Send methods

  /**
   * Send value
   * @this {!Sender}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof ClientAuthorizeRequest) && (value.fbeType === this.ClientAuthorizeRequestModel.fbeType)) {
      return this.send_ClientAuthorizeRequest(value)
    }
    if ((value instanceof ClientAuthorizeResponse) && (value.fbeType === this.ClientAuthorizeResponseModel.fbeType)) {
      return this.send_ClientAuthorizeResponse(value)
    }
    if ((value instanceof DrawingStorageDashboardsRequest) && (value.fbeType === this.DrawingStorageDashboardsRequestModel.fbeType)) {
      return this.send_DrawingStorageDashboardsRequest(value)
    }
    if ((value instanceof DrawingStorageDashboardsResponse) && (value.fbeType === this.DrawingStorageDashboardsResponseModel.fbeType)) {
      return this.send_DrawingStorageDashboardsResponse(value)
    }
    if ((value instanceof DrawingStorageChartsRequest) && (value.fbeType === this.DrawingStorageChartsRequestModel.fbeType)) {
      return this.send_DrawingStorageChartsRequest(value)
    }
    if ((value instanceof DrawingStorageChartsResponse) && (value.fbeType === this.DrawingStorageChartsResponseModel.fbeType)) {
      return this.send_DrawingStorageChartsResponse(value)
    }
    if ((value instanceof ModuleSystemInfoRequest) && (value.fbeType === this.ModuleSystemInfoRequestModel.fbeType)) {
      return this.send_ModuleSystemInfoRequest(value)
    }
    if ((value instanceof ModuleSystemInfoResponse) && (value.fbeType === this.ModuleSystemInfoResponseModel.fbeType)) {
      return this.send_ModuleSystemInfoResponse(value)
    }
    if ((value instanceof ClientReportsInsertRequest) && (value.fbeType === this.ClientReportsInsertRequestModel.fbeType)) {
      return this.send_ClientReportsInsertRequest(value)
    }
    if ((value instanceof ClientReportsInsertResponse) && (value.fbeType === this.ClientReportsInsertResponseModel.fbeType)) {
      return this.send_ClientReportsInsertResponse(value)
    }
    if ((value instanceof ClientReportsGetLastIdRequest) && (value.fbeType === this.ClientReportsGetLastIdRequestModel.fbeType)) {
      return this.send_ClientReportsGetLastIdRequest(value)
    }
    if ((value instanceof ClientReportsGetLastIdResponse) && (value.fbeType === this.ClientReportsGetLastIdResponseModel.fbeType)) {
      return this.send_ClientReportsGetLastIdResponse(value)
    }
    let result = 0
    result = this._coreSender.send(value)
    if (result > 0) {
      return result
    }
    result = this._historySender.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send ClientAuthorizeRequest value
   * @this {!Sender}
   * @param {!ClientAuthorizeRequest} value ClientAuthorizeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientAuthorizeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientAuthorizeRequestModel.serialize(value)
    console.assert((serialized > 0), 'inner.ClientAuthorizeRequest serialization failed!')
    console.assert(this.ClientAuthorizeRequestModel.verify(), 'inner.ClientAuthorizeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientAuthorizeResponse value
   * @this {!Sender}
   * @param {!ClientAuthorizeResponse} value ClientAuthorizeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientAuthorizeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientAuthorizeResponseModel.serialize(value)
    console.assert((serialized > 0), 'inner.ClientAuthorizeResponse serialization failed!')
    console.assert(this.ClientAuthorizeResponseModel.verify(), 'inner.ClientAuthorizeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DrawingStorageDashboardsRequest value
   * @this {!Sender}
   * @param {!DrawingStorageDashboardsRequest} value DrawingStorageDashboardsRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DrawingStorageDashboardsRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DrawingStorageDashboardsRequestModel.serialize(value)
    console.assert((serialized > 0), 'inner.DrawingStorageDashboardsRequest serialization failed!')
    console.assert(this.DrawingStorageDashboardsRequestModel.verify(), 'inner.DrawingStorageDashboardsRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DrawingStorageDashboardsResponse value
   * @this {!Sender}
   * @param {!DrawingStorageDashboardsResponse} value DrawingStorageDashboardsResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DrawingStorageDashboardsResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DrawingStorageDashboardsResponseModel.serialize(value)
    console.assert((serialized > 0), 'inner.DrawingStorageDashboardsResponse serialization failed!')
    console.assert(this.DrawingStorageDashboardsResponseModel.verify(), 'inner.DrawingStorageDashboardsResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DrawingStorageChartsRequest value
   * @this {!Sender}
   * @param {!DrawingStorageChartsRequest} value DrawingStorageChartsRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DrawingStorageChartsRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DrawingStorageChartsRequestModel.serialize(value)
    console.assert((serialized > 0), 'inner.DrawingStorageChartsRequest serialization failed!')
    console.assert(this.DrawingStorageChartsRequestModel.verify(), 'inner.DrawingStorageChartsRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DrawingStorageChartsResponse value
   * @this {!Sender}
   * @param {!DrawingStorageChartsResponse} value DrawingStorageChartsResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DrawingStorageChartsResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DrawingStorageChartsResponseModel.serialize(value)
    console.assert((serialized > 0), 'inner.DrawingStorageChartsResponse serialization failed!')
    console.assert(this.DrawingStorageChartsResponseModel.verify(), 'inner.DrawingStorageChartsResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleSystemInfoRequest value
   * @this {!Sender}
   * @param {!ModuleSystemInfoRequest} value ModuleSystemInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleSystemInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleSystemInfoRequestModel.serialize(value)
    console.assert((serialized > 0), 'inner.ModuleSystemInfoRequest serialization failed!')
    console.assert(this.ModuleSystemInfoRequestModel.verify(), 'inner.ModuleSystemInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleSystemInfoResponse value
   * @this {!Sender}
   * @param {!ModuleSystemInfoResponse} value ModuleSystemInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleSystemInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleSystemInfoResponseModel.serialize(value)
    console.assert((serialized > 0), 'inner.ModuleSystemInfoResponse serialization failed!')
    console.assert(this.ModuleSystemInfoResponseModel.verify(), 'inner.ModuleSystemInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsInsertRequest value
   * @this {!Sender}
   * @param {!ClientReportsInsertRequest} value ClientReportsInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsInsertRequestModel.serialize(value)
    console.assert((serialized > 0), 'inner.ClientReportsInsertRequest serialization failed!')
    console.assert(this.ClientReportsInsertRequestModel.verify(), 'inner.ClientReportsInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsInsertResponse value
   * @this {!Sender}
   * @param {!ClientReportsInsertResponse} value ClientReportsInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsInsertResponseModel.serialize(value)
    console.assert((serialized > 0), 'inner.ClientReportsInsertResponse serialization failed!')
    console.assert(this.ClientReportsInsertResponseModel.verify(), 'inner.ClientReportsInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsGetLastIdRequest value
   * @this {!Sender}
   * @param {!ClientReportsGetLastIdRequest} value ClientReportsGetLastIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsGetLastIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsGetLastIdRequestModel.serialize(value)
    console.assert((serialized > 0), 'inner.ClientReportsGetLastIdRequest serialization failed!')
    console.assert(this.ClientReportsGetLastIdRequestModel.verify(), 'inner.ClientReportsGetLastIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsGetLastIdResponse value
   * @this {!Sender}
   * @param {!ClientReportsGetLastIdResponse} value ClientReportsGetLastIdResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsGetLastIdResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsGetLastIdResponseModel.serialize(value)
    console.assert((serialized > 0), 'inner.ClientReportsGetLastIdResponse serialization failed!')
    console.assert(this.ClientReportsGetLastIdResponseModel.verify(), 'inner.ClientReportsGetLastIdResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Sender}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'inner.Sender.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Sender}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreSender.onSendHandler = handler
    this._historySender.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Sender}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreSender.onSendLogHandler = handler
    this._historySender.onSendLogHandler = handler
  }
}

export {Sender };

/**
 * Fast Binary Encoding inner receiver
 */
class Receiver extends fbe.Receiver {
  /**
   * Initialize inner receiver with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreReceiver = new core.Receiver(this.buffer)
    this._historyReceiver = new history.Receiver(this.buffer)
    this._ClientAuthorizeRequestValue = new ClientAuthorizeRequest()
    this._ClientAuthorizeRequestModel = new ClientAuthorizeRequestModel()
    this._ClientAuthorizeResponseValue = new ClientAuthorizeResponse()
    this._ClientAuthorizeResponseModel = new ClientAuthorizeResponseModel()
    this._DrawingStorageDashboardsRequestValue = new DrawingStorageDashboardsRequest()
    this._DrawingStorageDashboardsRequestModel = new DrawingStorageDashboardsRequestModel()
    this._DrawingStorageDashboardsResponseValue = new DrawingStorageDashboardsResponse()
    this._DrawingStorageDashboardsResponseModel = new DrawingStorageDashboardsResponseModel()
    this._DrawingStorageChartsRequestValue = new DrawingStorageChartsRequest()
    this._DrawingStorageChartsRequestModel = new DrawingStorageChartsRequestModel()
    this._DrawingStorageChartsResponseValue = new DrawingStorageChartsResponse()
    this._DrawingStorageChartsResponseModel = new DrawingStorageChartsResponseModel()
    this._ModuleSystemInfoRequestValue = new ModuleSystemInfoRequest()
    this._ModuleSystemInfoRequestModel = new ModuleSystemInfoRequestModel()
    this._ModuleSystemInfoResponseValue = new ModuleSystemInfoResponse()
    this._ModuleSystemInfoResponseModel = new ModuleSystemInfoResponseModel()
    this._ClientReportsInsertRequestValue = new ClientReportsInsertRequest()
    this._ClientReportsInsertRequestModel = new ClientReportsInsertRequestModel()
    this._ClientReportsInsertResponseValue = new ClientReportsInsertResponse()
    this._ClientReportsInsertResponseModel = new ClientReportsInsertResponseModel()
    this._ClientReportsGetLastIdRequestValue = new ClientReportsGetLastIdRequest()
    this._ClientReportsGetLastIdRequestModel = new ClientReportsGetLastIdRequestModel()
    this._ClientReportsGetLastIdResponseValue = new ClientReportsGetLastIdResponse()
    this._ClientReportsGetLastIdResponseModel = new ClientReportsGetLastIdResponseModel()
    this.onReceiveLogHandler = this.onReceiveLog
  }

  // Imported receivers

  /**
   * Get imported core receiver
   * @this {!Receiver}
   * @returns {Receiver} core receiver
   */
  get coreReceiver () {
    return this._coreReceiver
  }

  /**
   * Set imported core receiver
   * @this {!Receiver}
   * @param {Receiver} receiver core receiver
   */
  set coreReceiver (receiver) {
    this._coreReceiver = receiver
  }

  /**
   * Get imported history receiver
   * @this {!Receiver}
   * @returns {Receiver} history receiver
   */
  get historyReceiver () {
    return this._historyReceiver
  }

  /**
   * Set imported history receiver
   * @this {!Receiver}
   * @param {Receiver} receiver history receiver
   */
  set historyReceiver (receiver) {
    this._historyReceiver = receiver
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * ClientAuthorizeRequest receive handler
   * @this {!Receiver}
   * @param {!ClientAuthorizeRequest} value ClientAuthorizeRequest received value
   */
  onReceive_ClientAuthorizeRequest (value) {}  // eslint-disable-line

  /**
   * ClientAuthorizeResponse receive handler
   * @this {!Receiver}
   * @param {!ClientAuthorizeResponse} value ClientAuthorizeResponse received value
   */
  onReceive_ClientAuthorizeResponse (value) {}  // eslint-disable-line

  /**
   * DrawingStorageDashboardsRequest receive handler
   * @this {!Receiver}
   * @param {!DrawingStorageDashboardsRequest} value DrawingStorageDashboardsRequest received value
   */
  onReceive_DrawingStorageDashboardsRequest (value) {}  // eslint-disable-line

  /**
   * DrawingStorageDashboardsResponse receive handler
   * @this {!Receiver}
   * @param {!DrawingStorageDashboardsResponse} value DrawingStorageDashboardsResponse received value
   */
  onReceive_DrawingStorageDashboardsResponse (value) {}  // eslint-disable-line

  /**
   * DrawingStorageChartsRequest receive handler
   * @this {!Receiver}
   * @param {!DrawingStorageChartsRequest} value DrawingStorageChartsRequest received value
   */
  onReceive_DrawingStorageChartsRequest (value) {}  // eslint-disable-line

  /**
   * DrawingStorageChartsResponse receive handler
   * @this {!Receiver}
   * @param {!DrawingStorageChartsResponse} value DrawingStorageChartsResponse received value
   */
  onReceive_DrawingStorageChartsResponse (value) {}  // eslint-disable-line

  /**
   * ModuleSystemInfoRequest receive handler
   * @this {!Receiver}
   * @param {!ModuleSystemInfoRequest} value ModuleSystemInfoRequest received value
   */
  onReceive_ModuleSystemInfoRequest (value) {}  // eslint-disable-line

  /**
   * ModuleSystemInfoResponse receive handler
   * @this {!Receiver}
   * @param {!ModuleSystemInfoResponse} value ModuleSystemInfoResponse received value
   */
  onReceive_ModuleSystemInfoResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportsInsertRequest receive handler
   * @this {!Receiver}
   * @param {!ClientReportsInsertRequest} value ClientReportsInsertRequest received value
   */
  onReceive_ClientReportsInsertRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsInsertResponse receive handler
   * @this {!Receiver}
   * @param {!ClientReportsInsertResponse} value ClientReportsInsertResponse received value
   */
  onReceive_ClientReportsInsertResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportsGetLastIdRequest receive handler
   * @this {!Receiver}
   * @param {!ClientReportsGetLastIdRequest} value ClientReportsGetLastIdRequest received value
   */
  onReceive_ClientReportsGetLastIdRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsGetLastIdResponse receive handler
   * @this {!Receiver}
   * @param {!ClientReportsGetLastIdResponse} value ClientReportsGetLastIdResponse received value
   */
  onReceive_ClientReportsGetLastIdResponse (value) {}  // eslint-disable-line

  /**
   * inner receive message handler
   * @this {!Receiver}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case ClientAuthorizeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientAuthorizeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientAuthorizeRequestModel.verify(), 'inner.ClientAuthorizeRequest validation failed!')
        let deserialized = this._ClientAuthorizeRequestModel.deserialize(this._ClientAuthorizeRequestValue)
        console.assert((deserialized.size > 0), 'inner.ClientAuthorizeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientAuthorizeRequestValue)
        this.onReceive_ClientAuthorizeRequest(this._ClientAuthorizeRequestValue)
        return true
      }
      case ClientAuthorizeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientAuthorizeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientAuthorizeResponseModel.verify(), 'inner.ClientAuthorizeResponse validation failed!')
        let deserialized = this._ClientAuthorizeResponseModel.deserialize(this._ClientAuthorizeResponseValue)
        console.assert((deserialized.size > 0), 'inner.ClientAuthorizeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientAuthorizeResponseValue)
        this.onReceive_ClientAuthorizeResponse(this._ClientAuthorizeResponseValue)
        return true
      }
      case DrawingStorageDashboardsRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DrawingStorageDashboardsRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DrawingStorageDashboardsRequestModel.verify(), 'inner.DrawingStorageDashboardsRequest validation failed!')
        let deserialized = this._DrawingStorageDashboardsRequestModel.deserialize(this._DrawingStorageDashboardsRequestValue)
        console.assert((deserialized.size > 0), 'inner.DrawingStorageDashboardsRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DrawingStorageDashboardsRequestValue)
        this.onReceive_DrawingStorageDashboardsRequest(this._DrawingStorageDashboardsRequestValue)
        return true
      }
      case DrawingStorageDashboardsResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DrawingStorageDashboardsResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DrawingStorageDashboardsResponseModel.verify(), 'inner.DrawingStorageDashboardsResponse validation failed!')
        let deserialized = this._DrawingStorageDashboardsResponseModel.deserialize(this._DrawingStorageDashboardsResponseValue)
        console.assert((deserialized.size > 0), 'inner.DrawingStorageDashboardsResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DrawingStorageDashboardsResponseValue)
        this.onReceive_DrawingStorageDashboardsResponse(this._DrawingStorageDashboardsResponseValue)
        return true
      }
      case DrawingStorageChartsRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DrawingStorageChartsRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DrawingStorageChartsRequestModel.verify(), 'inner.DrawingStorageChartsRequest validation failed!')
        let deserialized = this._DrawingStorageChartsRequestModel.deserialize(this._DrawingStorageChartsRequestValue)
        console.assert((deserialized.size > 0), 'inner.DrawingStorageChartsRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DrawingStorageChartsRequestValue)
        this.onReceive_DrawingStorageChartsRequest(this._DrawingStorageChartsRequestValue)
        return true
      }
      case DrawingStorageChartsResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DrawingStorageChartsResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DrawingStorageChartsResponseModel.verify(), 'inner.DrawingStorageChartsResponse validation failed!')
        let deserialized = this._DrawingStorageChartsResponseModel.deserialize(this._DrawingStorageChartsResponseValue)
        console.assert((deserialized.size > 0), 'inner.DrawingStorageChartsResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DrawingStorageChartsResponseValue)
        this.onReceive_DrawingStorageChartsResponse(this._DrawingStorageChartsResponseValue)
        return true
      }
      case ModuleSystemInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleSystemInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleSystemInfoRequestModel.verify(), 'inner.ModuleSystemInfoRequest validation failed!')
        let deserialized = this._ModuleSystemInfoRequestModel.deserialize(this._ModuleSystemInfoRequestValue)
        console.assert((deserialized.size > 0), 'inner.ModuleSystemInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleSystemInfoRequestValue)
        this.onReceive_ModuleSystemInfoRequest(this._ModuleSystemInfoRequestValue)
        return true
      }
      case ModuleSystemInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleSystemInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleSystemInfoResponseModel.verify(), 'inner.ModuleSystemInfoResponse validation failed!')
        let deserialized = this._ModuleSystemInfoResponseModel.deserialize(this._ModuleSystemInfoResponseValue)
        console.assert((deserialized.size > 0), 'inner.ModuleSystemInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleSystemInfoResponseValue)
        this.onReceive_ModuleSystemInfoResponse(this._ModuleSystemInfoResponseValue)
        return true
      }
      case ClientReportsInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsInsertRequestModel.verify(), 'inner.ClientReportsInsertRequest validation failed!')
        let deserialized = this._ClientReportsInsertRequestModel.deserialize(this._ClientReportsInsertRequestValue)
        console.assert((deserialized.size > 0), 'inner.ClientReportsInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsInsertRequestValue)
        this.onReceive_ClientReportsInsertRequest(this._ClientReportsInsertRequestValue)
        return true
      }
      case ClientReportsInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsInsertResponseModel.verify(), 'inner.ClientReportsInsertResponse validation failed!')
        let deserialized = this._ClientReportsInsertResponseModel.deserialize(this._ClientReportsInsertResponseValue)
        console.assert((deserialized.size > 0), 'inner.ClientReportsInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsInsertResponseValue)
        this.onReceive_ClientReportsInsertResponse(this._ClientReportsInsertResponseValue)
        return true
      }
      case ClientReportsGetLastIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsGetLastIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetLastIdRequestModel.verify(), 'inner.ClientReportsGetLastIdRequest validation failed!')
        let deserialized = this._ClientReportsGetLastIdRequestModel.deserialize(this._ClientReportsGetLastIdRequestValue)
        console.assert((deserialized.size > 0), 'inner.ClientReportsGetLastIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsGetLastIdRequestValue)
        this.onReceive_ClientReportsGetLastIdRequest(this._ClientReportsGetLastIdRequestValue)
        return true
      }
      case ClientReportsGetLastIdResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsGetLastIdResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetLastIdResponseModel.verify(), 'inner.ClientReportsGetLastIdResponse validation failed!')
        let deserialized = this._ClientReportsGetLastIdResponseModel.deserialize(this._ClientReportsGetLastIdResponseValue)
        console.assert((deserialized.size > 0), 'inner.ClientReportsGetLastIdResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsGetLastIdResponseValue)
        this.onReceive_ClientReportsGetLastIdResponse(this._ClientReportsGetLastIdResponseValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreReceiver != null) && this.coreReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.historyReceiver != null) && this.historyReceiver.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Receiver}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreReceiver.onReceiveLogHandler = handler
    this._historyReceiver.onReceiveLogHandler = handler
  }
}

export { Receiver };

/**
 * Fast Binary Encoding inner proxy
 */
class Proxy extends fbe.Receiver {
  /**
   * Initialize inner proxy with the given buffer
   * @param {!fbe.WriteBuffer} buffer Write buffer, defaults is new WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer, false)
    this._coreProxy = new core.Proxy(this.buffer)
    this._historyProxy = new history.Proxy(this.buffer)
    this._ClientAuthorizeRequestModel = new ClientAuthorizeRequestModel()
    this._ClientAuthorizeResponseModel = new ClientAuthorizeResponseModel()
    this._DrawingStorageDashboardsRequestModel = new DrawingStorageDashboardsRequestModel()
    this._DrawingStorageDashboardsResponseModel = new DrawingStorageDashboardsResponseModel()
    this._DrawingStorageChartsRequestModel = new DrawingStorageChartsRequestModel()
    this._DrawingStorageChartsResponseModel = new DrawingStorageChartsResponseModel()
    this._ModuleSystemInfoRequestModel = new ModuleSystemInfoRequestModel()
    this._ModuleSystemInfoResponseModel = new ModuleSystemInfoResponseModel()
    this._ClientReportsInsertRequestModel = new ClientReportsInsertRequestModel()
    this._ClientReportsInsertResponseModel = new ClientReportsInsertResponseModel()
    this._ClientReportsGetLastIdRequestModel = new ClientReportsGetLastIdRequestModel()
    this._ClientReportsGetLastIdResponseModel = new ClientReportsGetLastIdResponseModel()
  }

  // Imported proxy

  /**
   * Get imported core proxy
   * @this {!Proxy}
   * @returns {Proxy} core proxy
   */
  get coreProxy () {
    return this._coreProxy
  }

  /**
   * Set imported core proxy
   * @this {!Proxy}
   * @param {Proxy} proxy core proxy
   */
  set coreProxy (proxy) {
    this._coreProxy = proxy
  }

  /**
   * Get imported history proxy
   * @this {!Proxy}
   * @returns {Proxy} history proxy
   */
  get historyProxy () {
    return this._historyProxy
  }

  /**
   * Set imported history proxy
   * @this {!Proxy}
   * @param {Proxy} proxy history proxy
   */
  set historyProxy (proxy) {
    this._historyProxy = proxy
  }

  // Proxy handlers

  /**
   * ClientAuthorizeRequest proxy handler
   * @this {!Proxy}
   * @param {!ClientAuthorizeRequest} model ClientAuthorizeRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientAuthorizeRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientAuthorizeResponse proxy handler
   * @this {!Proxy}
   * @param {!ClientAuthorizeResponse} model ClientAuthorizeResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientAuthorizeResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DrawingStorageDashboardsRequest proxy handler
   * @this {!Proxy}
   * @param {!DrawingStorageDashboardsRequest} model DrawingStorageDashboardsRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DrawingStorageDashboardsRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DrawingStorageDashboardsResponse proxy handler
   * @this {!Proxy}
   * @param {!DrawingStorageDashboardsResponse} model DrawingStorageDashboardsResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DrawingStorageDashboardsResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DrawingStorageChartsRequest proxy handler
   * @this {!Proxy}
   * @param {!DrawingStorageChartsRequest} model DrawingStorageChartsRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DrawingStorageChartsRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * DrawingStorageChartsResponse proxy handler
   * @this {!Proxy}
   * @param {!DrawingStorageChartsResponse} model DrawingStorageChartsResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_DrawingStorageChartsResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModuleSystemInfoRequest proxy handler
   * @this {!Proxy}
   * @param {!ModuleSystemInfoRequest} model ModuleSystemInfoRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModuleSystemInfoRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ModuleSystemInfoResponse proxy handler
   * @this {!Proxy}
   * @param {!ModuleSystemInfoResponse} model ModuleSystemInfoResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ModuleSystemInfoResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsInsertRequest proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsInsertRequest} model ClientReportsInsertRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsInsertRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsInsertResponse proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsInsertResponse} model ClientReportsInsertResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsInsertResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsGetLastIdRequest proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsGetLastIdRequest} model ClientReportsGetLastIdRequest model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsGetLastIdRequest (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * ClientReportsGetLastIdResponse proxy handler
   * @this {!Proxy}
   * @param {!ClientReportsGetLastIdResponse} model ClientReportsGetLastIdResponse model
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onProxy_ClientReportsGetLastIdResponse (model, type, buffer, offset, size) {}  // eslint-disable-line

  /**
   * inner receive message handler
   * @this {!Proxy}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case ClientAuthorizeRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientAuthorizeRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientAuthorizeRequestModel.verify(), 'inner.ClientAuthorizeRequest validation failed!')

        let fbeBegin = this._ClientAuthorizeRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientAuthorizeRequest(this._ClientAuthorizeRequestModel, type, buffer, offset, size)
        this._ClientAuthorizeRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientAuthorizeResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientAuthorizeResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientAuthorizeResponseModel.verify(), 'inner.ClientAuthorizeResponse validation failed!')

        let fbeBegin = this._ClientAuthorizeResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientAuthorizeResponse(this._ClientAuthorizeResponseModel, type, buffer, offset, size)
        this._ClientAuthorizeResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DrawingStorageDashboardsRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DrawingStorageDashboardsRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DrawingStorageDashboardsRequestModel.verify(), 'inner.DrawingStorageDashboardsRequest validation failed!')

        let fbeBegin = this._DrawingStorageDashboardsRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DrawingStorageDashboardsRequest(this._DrawingStorageDashboardsRequestModel, type, buffer, offset, size)
        this._DrawingStorageDashboardsRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DrawingStorageDashboardsResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DrawingStorageDashboardsResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DrawingStorageDashboardsResponseModel.verify(), 'inner.DrawingStorageDashboardsResponse validation failed!')

        let fbeBegin = this._DrawingStorageDashboardsResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DrawingStorageDashboardsResponse(this._DrawingStorageDashboardsResponseModel, type, buffer, offset, size)
        this._DrawingStorageDashboardsResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case DrawingStorageChartsRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DrawingStorageChartsRequestModel.attachBuffer(buffer, offset)
        console.assert(this._DrawingStorageChartsRequestModel.verify(), 'inner.DrawingStorageChartsRequest validation failed!')

        let fbeBegin = this._DrawingStorageChartsRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DrawingStorageChartsRequest(this._DrawingStorageChartsRequestModel, type, buffer, offset, size)
        this._DrawingStorageChartsRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case DrawingStorageChartsResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._DrawingStorageChartsResponseModel.attachBuffer(buffer, offset)
        console.assert(this._DrawingStorageChartsResponseModel.verify(), 'inner.DrawingStorageChartsResponse validation failed!')

        let fbeBegin = this._DrawingStorageChartsResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_DrawingStorageChartsResponse(this._DrawingStorageChartsResponseModel, type, buffer, offset, size)
        this._DrawingStorageChartsResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ModuleSystemInfoRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModuleSystemInfoRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleSystemInfoRequestModel.verify(), 'inner.ModuleSystemInfoRequest validation failed!')

        let fbeBegin = this._ModuleSystemInfoRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModuleSystemInfoRequest(this._ModuleSystemInfoRequestModel, type, buffer, offset, size)
        this._ModuleSystemInfoRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ModuleSystemInfoResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ModuleSystemInfoResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleSystemInfoResponseModel.verify(), 'inner.ModuleSystemInfoResponse validation failed!')

        let fbeBegin = this._ModuleSystemInfoResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ModuleSystemInfoResponse(this._ModuleSystemInfoResponseModel, type, buffer, offset, size)
        this._ModuleSystemInfoResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsInsertRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsInsertRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsInsertRequestModel.verify(), 'inner.ClientReportsInsertRequest validation failed!')

        let fbeBegin = this._ClientReportsInsertRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsInsertRequest(this._ClientReportsInsertRequestModel, type, buffer, offset, size)
        this._ClientReportsInsertRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsInsertResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsInsertResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsInsertResponseModel.verify(), 'inner.ClientReportsInsertResponse validation failed!')

        let fbeBegin = this._ClientReportsInsertResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsInsertResponse(this._ClientReportsInsertResponseModel, type, buffer, offset, size)
        this._ClientReportsInsertResponseModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsGetLastIdRequestModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsGetLastIdRequestModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetLastIdRequestModel.verify(), 'inner.ClientReportsGetLastIdRequest validation failed!')

        let fbeBegin = this._ClientReportsGetLastIdRequestModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsGetLastIdRequest(this._ClientReportsGetLastIdRequestModel, type, buffer, offset, size)
        this._ClientReportsGetLastIdRequestModel.model.getEnd(fbeBegin)
        return true
      }
      case ClientReportsGetLastIdResponseModel.fbeType: {
        // Attach the FBE stream to the proxy model
        this._ClientReportsGetLastIdResponseModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetLastIdResponseModel.verify(), 'inner.ClientReportsGetLastIdResponse validation failed!')

        let fbeBegin = this._ClientReportsGetLastIdResponseModel.model.getBegin()
        if (fbeBegin === 0) {
          return false
        }
        // Call proxy handler
        this.onProxy_ClientReportsGetLastIdResponse(this._ClientReportsGetLastIdResponseModel, type, buffer, offset, size)
        this._ClientReportsGetLastIdResponseModel.model.getEnd(fbeBegin)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreProxy != null) && this.coreProxy.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.historyProxy != null) && this.historyProxy.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }
}

export { Proxy };

/**
 * Fast Binary Encoding inner client
 */
class Client extends fbe.Client {
  /**
   * Initialize inner client with the given buffers
   * @param {!fbe.WriteBuffer} sendBuffer Send buffer, defaults is new fbe.WriteBuffer()
   * @param {!fbe.WriteBuffer} receiveBuffer Receive buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (sendBuffer = new fbe.WriteBuffer(), receiveBuffer = new fbe.WriteBuffer()) {
    super(sendBuffer, receiveBuffer, false)
    this._coreClient = new core.Client(this.sendBuffer, this.receiveBuffer)
    this._historyClient = new history.Client(this.sendBuffer, this.receiveBuffer)
    this._ClientAuthorizeRequestSenderModel = new ClientAuthorizeRequestModel(this.sendBuffer)
    this._ClientAuthorizeRequestReceiverValue = new ClientAuthorizeRequest()
    this._ClientAuthorizeRequestReceiverModel = new ClientAuthorizeRequestModel()
    this._ClientAuthorizeResponseSenderModel = new ClientAuthorizeResponseModel(this.sendBuffer)
    this._ClientAuthorizeResponseReceiverValue = new ClientAuthorizeResponse()
    this._ClientAuthorizeResponseReceiverModel = new ClientAuthorizeResponseModel()
    this._DrawingStorageDashboardsRequestSenderModel = new DrawingStorageDashboardsRequestModel(this.sendBuffer)
    this._DrawingStorageDashboardsRequestReceiverValue = new DrawingStorageDashboardsRequest()
    this._DrawingStorageDashboardsRequestReceiverModel = new DrawingStorageDashboardsRequestModel()
    this._DrawingStorageDashboardsResponseSenderModel = new DrawingStorageDashboardsResponseModel(this.sendBuffer)
    this._DrawingStorageDashboardsResponseReceiverValue = new DrawingStorageDashboardsResponse()
    this._DrawingStorageDashboardsResponseReceiverModel = new DrawingStorageDashboardsResponseModel()
    this._DrawingStorageChartsRequestSenderModel = new DrawingStorageChartsRequestModel(this.sendBuffer)
    this._DrawingStorageChartsRequestReceiverValue = new DrawingStorageChartsRequest()
    this._DrawingStorageChartsRequestReceiverModel = new DrawingStorageChartsRequestModel()
    this._DrawingStorageChartsResponseSenderModel = new DrawingStorageChartsResponseModel(this.sendBuffer)
    this._DrawingStorageChartsResponseReceiverValue = new DrawingStorageChartsResponse()
    this._DrawingStorageChartsResponseReceiverModel = new DrawingStorageChartsResponseModel()
    this._ModuleSystemInfoRequestSenderModel = new ModuleSystemInfoRequestModel(this.sendBuffer)
    this._ModuleSystemInfoRequestReceiverValue = new ModuleSystemInfoRequest()
    this._ModuleSystemInfoRequestReceiverModel = new ModuleSystemInfoRequestModel()
    this._ModuleSystemInfoResponseSenderModel = new ModuleSystemInfoResponseModel(this.sendBuffer)
    this._ModuleSystemInfoResponseReceiverValue = new ModuleSystemInfoResponse()
    this._ModuleSystemInfoResponseReceiverModel = new ModuleSystemInfoResponseModel()
    this._ClientReportsInsertRequestSenderModel = new ClientReportsInsertRequestModel(this.sendBuffer)
    this._ClientReportsInsertRequestReceiverValue = new ClientReportsInsertRequest()
    this._ClientReportsInsertRequestReceiverModel = new ClientReportsInsertRequestModel()
    this._ClientReportsInsertResponseSenderModel = new ClientReportsInsertResponseModel(this.sendBuffer)
    this._ClientReportsInsertResponseReceiverValue = new ClientReportsInsertResponse()
    this._ClientReportsInsertResponseReceiverModel = new ClientReportsInsertResponseModel()
    this._ClientReportsGetLastIdRequestSenderModel = new ClientReportsGetLastIdRequestModel(this.sendBuffer)
    this._ClientReportsGetLastIdRequestReceiverValue = new ClientReportsGetLastIdRequest()
    this._ClientReportsGetLastIdRequestReceiverModel = new ClientReportsGetLastIdRequestModel()
    this._ClientReportsGetLastIdResponseSenderModel = new ClientReportsGetLastIdResponseModel(this.sendBuffer)
    this._ClientReportsGetLastIdResponseReceiverValue = new ClientReportsGetLastIdResponse()
    this._ClientReportsGetLastIdResponseReceiverModel = new ClientReportsGetLastIdResponseModel()
    this.onSendHandler = this.onSend
    this.onSendLogHandler = this.onSendLog
    this.onReceiveLogHandler = this.onReceiveLog
    this._timestamp = 0
    this._requests_by_id = new Map()
    this._requests_by_timestamp = new Map()
  }

  // Imported clients

  /**
   * Get imported core client
   * @this {!Client}
   * @returns {!core.Client} core client
   */
  get coreClient () {
    return this._coreClient
  }

  /**
   * Get imported history client
   * @this {!Client}
   * @returns {!history.Client} history client
   */
  get historyClient () {
    return this._historyClient
  }

  // Sender models accessors

  /**
   * Get ClientAuthorizeRequest model
   * @this {!Client}
   * @returns {!ClientAuthorizeRequestModel} ClientAuthorizeRequest sender model
   */
  get ClientAuthorizeRequestSenderModel () {
    return this._ClientAuthorizeRequestSenderModel
  }

  /**
   * Get ClientAuthorizeResponse model
   * @this {!Client}
   * @returns {!ClientAuthorizeResponseModel} ClientAuthorizeResponse sender model
   */
  get ClientAuthorizeResponseSenderModel () {
    return this._ClientAuthorizeResponseSenderModel
  }

  /**
   * Get DrawingStorageDashboardsRequest model
   * @this {!Client}
   * @returns {!DrawingStorageDashboardsRequestModel} DrawingStorageDashboardsRequest sender model
   */
  get DrawingStorageDashboardsRequestSenderModel () {
    return this._DrawingStorageDashboardsRequestSenderModel
  }

  /**
   * Get DrawingStorageDashboardsResponse model
   * @this {!Client}
   * @returns {!DrawingStorageDashboardsResponseModel} DrawingStorageDashboardsResponse sender model
   */
  get DrawingStorageDashboardsResponseSenderModel () {
    return this._DrawingStorageDashboardsResponseSenderModel
  }

  /**
   * Get DrawingStorageChartsRequest model
   * @this {!Client}
   * @returns {!DrawingStorageChartsRequestModel} DrawingStorageChartsRequest sender model
   */
  get DrawingStorageChartsRequestSenderModel () {
    return this._DrawingStorageChartsRequestSenderModel
  }

  /**
   * Get DrawingStorageChartsResponse model
   * @this {!Client}
   * @returns {!DrawingStorageChartsResponseModel} DrawingStorageChartsResponse sender model
   */
  get DrawingStorageChartsResponseSenderModel () {
    return this._DrawingStorageChartsResponseSenderModel
  }

  /**
   * Get ModuleSystemInfoRequest model
   * @this {!Client}
   * @returns {!ModuleSystemInfoRequestModel} ModuleSystemInfoRequest sender model
   */
  get ModuleSystemInfoRequestSenderModel () {
    return this._ModuleSystemInfoRequestSenderModel
  }

  /**
   * Get ModuleSystemInfoResponse model
   * @this {!Client}
   * @returns {!ModuleSystemInfoResponseModel} ModuleSystemInfoResponse sender model
   */
  get ModuleSystemInfoResponseSenderModel () {
    return this._ModuleSystemInfoResponseSenderModel
  }

  /**
   * Get ClientReportsInsertRequest model
   * @this {!Client}
   * @returns {!ClientReportsInsertRequestModel} ClientReportsInsertRequest sender model
   */
  get ClientReportsInsertRequestSenderModel () {
    return this._ClientReportsInsertRequestSenderModel
  }

  /**
   * Get ClientReportsInsertResponse model
   * @this {!Client}
   * @returns {!ClientReportsInsertResponseModel} ClientReportsInsertResponse sender model
   */
  get ClientReportsInsertResponseSenderModel () {
    return this._ClientReportsInsertResponseSenderModel
  }

  /**
   * Get ClientReportsGetLastIdRequest model
   * @this {!Client}
   * @returns {!ClientReportsGetLastIdRequestModel} ClientReportsGetLastIdRequest sender model
   */
  get ClientReportsGetLastIdRequestSenderModel () {
    return this._ClientReportsGetLastIdRequestSenderModel
  }

  /**
   * Get ClientReportsGetLastIdResponse model
   * @this {!Client}
   * @returns {!ClientReportsGetLastIdResponseModel} ClientReportsGetLastIdResponse sender model
   */
  get ClientReportsGetLastIdResponseSenderModel () {
    return this._ClientReportsGetLastIdResponseSenderModel
  }

  // Reset and watchdog methods

  /**
   * Reset the client
   * @this {!Client}
   */
  reset () {
    super.reset()
    this.resetRequests()
  }

  /**
   * Watchdog for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp
   */
  watchdog (utc) {
    this.watchdogRequests(utc)
  }

  // Send methods

  /**
   * Send value
   * @this {!Client}
   * @param {!object} value Value to send
   * @returns {!number} Sent bytes
   */
  send (value) {
    if ((value instanceof ClientAuthorizeRequest) && (value.fbeType === this.ClientAuthorizeRequestSenderModel.fbeType)) {
      return this.send_ClientAuthorizeRequest(value)
    }
    if ((value instanceof ClientAuthorizeResponse) && (value.fbeType === this.ClientAuthorizeResponseSenderModel.fbeType)) {
      return this.send_ClientAuthorizeResponse(value)
    }
    if ((value instanceof DrawingStorageDashboardsRequest) && (value.fbeType === this.DrawingStorageDashboardsRequestSenderModel.fbeType)) {
      return this.send_DrawingStorageDashboardsRequest(value)
    }
    if ((value instanceof DrawingStorageDashboardsResponse) && (value.fbeType === this.DrawingStorageDashboardsResponseSenderModel.fbeType)) {
      return this.send_DrawingStorageDashboardsResponse(value)
    }
    if ((value instanceof DrawingStorageChartsRequest) && (value.fbeType === this.DrawingStorageChartsRequestSenderModel.fbeType)) {
      return this.send_DrawingStorageChartsRequest(value)
    }
    if ((value instanceof DrawingStorageChartsResponse) && (value.fbeType === this.DrawingStorageChartsResponseSenderModel.fbeType)) {
      return this.send_DrawingStorageChartsResponse(value)
    }
    if ((value instanceof ModuleSystemInfoRequest) && (value.fbeType === this.ModuleSystemInfoRequestSenderModel.fbeType)) {
      return this.send_ModuleSystemInfoRequest(value)
    }
    if ((value instanceof ModuleSystemInfoResponse) && (value.fbeType === this.ModuleSystemInfoResponseSenderModel.fbeType)) {
      return this.send_ModuleSystemInfoResponse(value)
    }
    if ((value instanceof ClientReportsInsertRequest) && (value.fbeType === this.ClientReportsInsertRequestSenderModel.fbeType)) {
      return this.send_ClientReportsInsertRequest(value)
    }
    if ((value instanceof ClientReportsInsertResponse) && (value.fbeType === this.ClientReportsInsertResponseSenderModel.fbeType)) {
      return this.send_ClientReportsInsertResponse(value)
    }
    if ((value instanceof ClientReportsGetLastIdRequest) && (value.fbeType === this.ClientReportsGetLastIdRequestSenderModel.fbeType)) {
      return this.send_ClientReportsGetLastIdRequest(value)
    }
    if ((value instanceof ClientReportsGetLastIdResponse) && (value.fbeType === this.ClientReportsGetLastIdResponseSenderModel.fbeType)) {
      return this.send_ClientReportsGetLastIdResponse(value)
    }
    let result = 0
    result = this._coreClient.send(value)
    if (result > 0) {
      return result
    }
    result = this._historyClient.send(value)
    if (result > 0) {
      return result
    }
    return 0
  }

  /**
   * Send ClientAuthorizeRequest value
   * @this {!Client}
   * @param {!ClientAuthorizeRequest} value ClientAuthorizeRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientAuthorizeRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientAuthorizeRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'inner.ClientAuthorizeRequest serialization failed!')
    console.assert(this.ClientAuthorizeRequestSenderModel.verify(), 'inner.ClientAuthorizeRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientAuthorizeResponse value
   * @this {!Client}
   * @param {!ClientAuthorizeResponse} value ClientAuthorizeResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientAuthorizeResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientAuthorizeResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'inner.ClientAuthorizeResponse serialization failed!')
    console.assert(this.ClientAuthorizeResponseSenderModel.verify(), 'inner.ClientAuthorizeResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DrawingStorageDashboardsRequest value
   * @this {!Client}
   * @param {!DrawingStorageDashboardsRequest} value DrawingStorageDashboardsRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DrawingStorageDashboardsRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DrawingStorageDashboardsRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'inner.DrawingStorageDashboardsRequest serialization failed!')
    console.assert(this.DrawingStorageDashboardsRequestSenderModel.verify(), 'inner.DrawingStorageDashboardsRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DrawingStorageDashboardsResponse value
   * @this {!Client}
   * @param {!DrawingStorageDashboardsResponse} value DrawingStorageDashboardsResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DrawingStorageDashboardsResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DrawingStorageDashboardsResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'inner.DrawingStorageDashboardsResponse serialization failed!')
    console.assert(this.DrawingStorageDashboardsResponseSenderModel.verify(), 'inner.DrawingStorageDashboardsResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DrawingStorageChartsRequest value
   * @this {!Client}
   * @param {!DrawingStorageChartsRequest} value DrawingStorageChartsRequest value to send
   * @returns {!number} Sent bytes
   */
  send_DrawingStorageChartsRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DrawingStorageChartsRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'inner.DrawingStorageChartsRequest serialization failed!')
    console.assert(this.DrawingStorageChartsRequestSenderModel.verify(), 'inner.DrawingStorageChartsRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send DrawingStorageChartsResponse value
   * @this {!Client}
   * @param {!DrawingStorageChartsResponse} value DrawingStorageChartsResponse value to send
   * @returns {!number} Sent bytes
   */
  send_DrawingStorageChartsResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.DrawingStorageChartsResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'inner.DrawingStorageChartsResponse serialization failed!')
    console.assert(this.DrawingStorageChartsResponseSenderModel.verify(), 'inner.DrawingStorageChartsResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleSystemInfoRequest value
   * @this {!Client}
   * @param {!ModuleSystemInfoRequest} value ModuleSystemInfoRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleSystemInfoRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleSystemInfoRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'inner.ModuleSystemInfoRequest serialization failed!')
    console.assert(this.ModuleSystemInfoRequestSenderModel.verify(), 'inner.ModuleSystemInfoRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ModuleSystemInfoResponse value
   * @this {!Client}
   * @param {!ModuleSystemInfoResponse} value ModuleSystemInfoResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ModuleSystemInfoResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ModuleSystemInfoResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'inner.ModuleSystemInfoResponse serialization failed!')
    console.assert(this.ModuleSystemInfoResponseSenderModel.verify(), 'inner.ModuleSystemInfoResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsInsertRequest value
   * @this {!Client}
   * @param {!ClientReportsInsertRequest} value ClientReportsInsertRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsInsertRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsInsertRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'inner.ClientReportsInsertRequest serialization failed!')
    console.assert(this.ClientReportsInsertRequestSenderModel.verify(), 'inner.ClientReportsInsertRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsInsertResponse value
   * @this {!Client}
   * @param {!ClientReportsInsertResponse} value ClientReportsInsertResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsInsertResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsInsertResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'inner.ClientReportsInsertResponse serialization failed!')
    console.assert(this.ClientReportsInsertResponseSenderModel.verify(), 'inner.ClientReportsInsertResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsGetLastIdRequest value
   * @this {!Client}
   * @param {!ClientReportsGetLastIdRequest} value ClientReportsGetLastIdRequest value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsGetLastIdRequest (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsGetLastIdRequestSenderModel.serialize(value)
    console.assert((serialized > 0), 'inner.ClientReportsGetLastIdRequest serialization failed!')
    console.assert(this.ClientReportsGetLastIdRequestSenderModel.verify(), 'inner.ClientReportsGetLastIdRequest validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send ClientReportsGetLastIdResponse value
   * @this {!Client}
   * @param {!ClientReportsGetLastIdResponse} value ClientReportsGetLastIdResponse value to send
   * @returns {!number} Sent bytes
   */
  send_ClientReportsGetLastIdResponse (value) { // eslint-disable-line
    // Serialize the value into the FBE stream
    let serialized = this.ClientReportsGetLastIdResponseSenderModel.serialize(value)
    console.assert((serialized > 0), 'inner.ClientReportsGetLastIdResponse serialization failed!')
    console.assert(this.ClientReportsGetLastIdResponseSenderModel.verify(), 'inner.ClientReportsGetLastIdResponse validation failed!')


    // Send the serialized value
    return this.sendSerialized(serialized)
  }

  /**
   * Send message handler
   * @this {!Client}
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   */
  onSend (buffer, offset, size) {
    console.assert(true, 'inner.Client.onSend() not implemented!')
    debugger // eslint-disable-line
    return 0
  }

  /**
   * Setup send message handler
   * @this {!Client}
   * @param {!function} handler Send message handler
   */
  set onSendHandler (handler) { // eslint-disable-line
    this.onSend = handler
    this._coreClient.onSendHandler = handler
    this._historyClient.onSendHandler = handler
  }

  /**
   * Setup send log message handler
   * @this {!Client}
   * @param {!function} handler Send log message handler
   */
  set onSendLogHandler (handler) { // eslint-disable-line
    this.onSendLog = handler
    this._coreClient.onSendLogHandler = handler
    this._historyClient.onSendLogHandler = handler
  }

  // Receive handlers
  onReceive_any (value) {}  // eslint-disable-line

  /**
   * ClientAuthorizeRequest receive handler
   * @this {!Client}
   * @param {!ClientAuthorizeRequest} value ClientAuthorizeRequest received value
   */
  onReceive_ClientAuthorizeRequest (value) {}  // eslint-disable-line

  /**
   * ClientAuthorizeResponse receive handler
   * @this {!Client}
   * @param {!ClientAuthorizeResponse} value ClientAuthorizeResponse received value
   */
  onReceive_ClientAuthorizeResponse (value) {}  // eslint-disable-line

  /**
   * DrawingStorageDashboardsRequest receive handler
   * @this {!Client}
   * @param {!DrawingStorageDashboardsRequest} value DrawingStorageDashboardsRequest received value
   */
  onReceive_DrawingStorageDashboardsRequest (value) {}  // eslint-disable-line

  /**
   * DrawingStorageDashboardsResponse receive handler
   * @this {!Client}
   * @param {!DrawingStorageDashboardsResponse} value DrawingStorageDashboardsResponse received value
   */
  onReceive_DrawingStorageDashboardsResponse (value) {}  // eslint-disable-line

  /**
   * DrawingStorageChartsRequest receive handler
   * @this {!Client}
   * @param {!DrawingStorageChartsRequest} value DrawingStorageChartsRequest received value
   */
  onReceive_DrawingStorageChartsRequest (value) {}  // eslint-disable-line

  /**
   * DrawingStorageChartsResponse receive handler
   * @this {!Client}
   * @param {!DrawingStorageChartsResponse} value DrawingStorageChartsResponse received value
   */
  onReceive_DrawingStorageChartsResponse (value) {}  // eslint-disable-line

  /**
   * ModuleSystemInfoRequest receive handler
   * @this {!Client}
   * @param {!ModuleSystemInfoRequest} value ModuleSystemInfoRequest received value
   */
  onReceive_ModuleSystemInfoRequest (value) {}  // eslint-disable-line

  /**
   * ModuleSystemInfoResponse receive handler
   * @this {!Client}
   * @param {!ModuleSystemInfoResponse} value ModuleSystemInfoResponse received value
   */
  onReceive_ModuleSystemInfoResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportsInsertRequest receive handler
   * @this {!Client}
   * @param {!ClientReportsInsertRequest} value ClientReportsInsertRequest received value
   */
  onReceive_ClientReportsInsertRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsInsertResponse receive handler
   * @this {!Client}
   * @param {!ClientReportsInsertResponse} value ClientReportsInsertResponse received value
   */
  onReceive_ClientReportsInsertResponse (value) {}  // eslint-disable-line

  /**
   * ClientReportsGetLastIdRequest receive handler
   * @this {!Client}
   * @param {!ClientReportsGetLastIdRequest} value ClientReportsGetLastIdRequest received value
   */
  onReceive_ClientReportsGetLastIdRequest (value) {}  // eslint-disable-line

  /**
   * ClientReportsGetLastIdResponse receive handler
   * @this {!Client}
   * @param {!ClientReportsGetLastIdResponse} value ClientReportsGetLastIdResponse received value
   */
  onReceive_ClientReportsGetLastIdResponse (value) {}  // eslint-disable-line

  /**
   * inner receive message handler
   * @this {!Client}
   * @param {!number} type Message type
   * @param {!Uint8Array} buffer Buffer to send
   * @param {!number} offset Buffer offset
   * @param {!number} size Buffer size
   * @returns {!boolean} Success flag
   */
  onReceive (type, buffer, offset, size) {
    switch (type) {
      case ClientAuthorizeRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientAuthorizeRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientAuthorizeRequestReceiverModel.verify(), 'inner.ClientAuthorizeRequest validation failed!')
        let deserialized = this._ClientAuthorizeRequestReceiverModel.deserialize(this._ClientAuthorizeRequestReceiverValue)
        console.assert((deserialized.size > 0), 'inner.ClientAuthorizeRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientAuthorizeRequestReceiverValue)
        this.onReceive_ClientAuthorizeRequest(this._ClientAuthorizeRequestReceiverValue)
        return true
      }
      case ClientAuthorizeResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientAuthorizeResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientAuthorizeResponseReceiverModel.verify(), 'inner.ClientAuthorizeResponse validation failed!')
        let deserialized = this._ClientAuthorizeResponseReceiverModel.deserialize(this._ClientAuthorizeResponseReceiverValue)
        console.assert((deserialized.size > 0), 'inner.ClientAuthorizeResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientAuthorizeResponseReceiverValue)
        this.onReceive_ClientAuthorizeResponse(this._ClientAuthorizeResponseReceiverValue)
        return true
      }
      case DrawingStorageDashboardsRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DrawingStorageDashboardsRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DrawingStorageDashboardsRequestReceiverModel.verify(), 'inner.DrawingStorageDashboardsRequest validation failed!')
        let deserialized = this._DrawingStorageDashboardsRequestReceiverModel.deserialize(this._DrawingStorageDashboardsRequestReceiverValue)
        console.assert((deserialized.size > 0), 'inner.DrawingStorageDashboardsRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DrawingStorageDashboardsRequestReceiverValue)
        this.onReceive_DrawingStorageDashboardsRequest(this._DrawingStorageDashboardsRequestReceiverValue)
        return true
      }
      case DrawingStorageDashboardsResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DrawingStorageDashboardsResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DrawingStorageDashboardsResponseReceiverModel.verify(), 'inner.DrawingStorageDashboardsResponse validation failed!')
        let deserialized = this._DrawingStorageDashboardsResponseReceiverModel.deserialize(this._DrawingStorageDashboardsResponseReceiverValue)
        console.assert((deserialized.size > 0), 'inner.DrawingStorageDashboardsResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DrawingStorageDashboardsResponseReceiverValue)
        this.onReceive_DrawingStorageDashboardsResponse(this._DrawingStorageDashboardsResponseReceiverValue)
        return true
      }
      case DrawingStorageChartsRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DrawingStorageChartsRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DrawingStorageChartsRequestReceiverModel.verify(), 'inner.DrawingStorageChartsRequest validation failed!')
        let deserialized = this._DrawingStorageChartsRequestReceiverModel.deserialize(this._DrawingStorageChartsRequestReceiverValue)
        console.assert((deserialized.size > 0), 'inner.DrawingStorageChartsRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DrawingStorageChartsRequestReceiverValue)
        this.onReceive_DrawingStorageChartsRequest(this._DrawingStorageChartsRequestReceiverValue)
        return true
      }
      case DrawingStorageChartsResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._DrawingStorageChartsResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._DrawingStorageChartsResponseReceiverModel.verify(), 'inner.DrawingStorageChartsResponse validation failed!')
        let deserialized = this._DrawingStorageChartsResponseReceiverModel.deserialize(this._DrawingStorageChartsResponseReceiverValue)
        console.assert((deserialized.size > 0), 'inner.DrawingStorageChartsResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._DrawingStorageChartsResponseReceiverValue)
        this.onReceive_DrawingStorageChartsResponse(this._DrawingStorageChartsResponseReceiverValue)
        return true
      }
      case ModuleSystemInfoRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleSystemInfoRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleSystemInfoRequestReceiverModel.verify(), 'inner.ModuleSystemInfoRequest validation failed!')
        let deserialized = this._ModuleSystemInfoRequestReceiverModel.deserialize(this._ModuleSystemInfoRequestReceiverValue)
        console.assert((deserialized.size > 0), 'inner.ModuleSystemInfoRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleSystemInfoRequestReceiverValue)
        this.onReceive_ModuleSystemInfoRequest(this._ModuleSystemInfoRequestReceiverValue)
        return true
      }
      case ModuleSystemInfoResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ModuleSystemInfoResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ModuleSystemInfoResponseReceiverModel.verify(), 'inner.ModuleSystemInfoResponse validation failed!')
        let deserialized = this._ModuleSystemInfoResponseReceiverModel.deserialize(this._ModuleSystemInfoResponseReceiverValue)
        console.assert((deserialized.size > 0), 'inner.ModuleSystemInfoResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ModuleSystemInfoResponseReceiverValue)
        this.onReceive_ModuleSystemInfoResponse(this._ModuleSystemInfoResponseReceiverValue)
        return true
      }
      case ClientReportsInsertRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsInsertRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsInsertRequestReceiverModel.verify(), 'inner.ClientReportsInsertRequest validation failed!')
        let deserialized = this._ClientReportsInsertRequestReceiverModel.deserialize(this._ClientReportsInsertRequestReceiverValue)
        console.assert((deserialized.size > 0), 'inner.ClientReportsInsertRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsInsertRequestReceiverValue)
        this.onReceive_ClientReportsInsertRequest(this._ClientReportsInsertRequestReceiverValue)
        return true
      }
      case ClientReportsInsertResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsInsertResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsInsertResponseReceiverModel.verify(), 'inner.ClientReportsInsertResponse validation failed!')
        let deserialized = this._ClientReportsInsertResponseReceiverModel.deserialize(this._ClientReportsInsertResponseReceiverValue)
        console.assert((deserialized.size > 0), 'inner.ClientReportsInsertResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsInsertResponseReceiverValue)
        this.onReceive_ClientReportsInsertResponse(this._ClientReportsInsertResponseReceiverValue)
        return true
      }
      case ClientReportsGetLastIdRequestModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsGetLastIdRequestReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetLastIdRequestReceiverModel.verify(), 'inner.ClientReportsGetLastIdRequest validation failed!')
        let deserialized = this._ClientReportsGetLastIdRequestReceiverModel.deserialize(this._ClientReportsGetLastIdRequestReceiverValue)
        console.assert((deserialized.size > 0), 'inner.ClientReportsGetLastIdRequest deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsGetLastIdRequestReceiverValue)
        this.onReceive_ClientReportsGetLastIdRequest(this._ClientReportsGetLastIdRequestReceiverValue)
        return true
      }
      case ClientReportsGetLastIdResponseModel.fbeType: {
        // Deserialize the value from the FBE stream
        this._ClientReportsGetLastIdResponseReceiverModel.attachBuffer(buffer, offset)
        console.assert(this._ClientReportsGetLastIdResponseReceiverModel.verify(), 'inner.ClientReportsGetLastIdResponse validation failed!')
        let deserialized = this._ClientReportsGetLastIdResponseReceiverModel.deserialize(this._ClientReportsGetLastIdResponseReceiverValue)
        console.assert((deserialized.size > 0), 'inner.ClientReportsGetLastIdResponse deserialization failed!')


        // Call receive handler with deserialized value
        this.onReceive_any(this._ClientReportsGetLastIdResponseReceiverValue)
        this.onReceive_ClientReportsGetLastIdResponse(this._ClientReportsGetLastIdResponseReceiverValue)
        return true
      }
      default: break
    }

    // noinspection RedundantIfStatementJS
    if ((this.coreClient != null) && this.coreClient.onReceive(type, buffer, offset, size)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if ((this.historyClient != null) && this.historyClient.onReceive(type, buffer, offset, size)) {
      return true
    }

    return false
  }

  /**
   * Setup receive log message handler
   * @this {!Client}
   * @param {!function} handler Receive log message handler
   */
  set onReceiveLogHandler (handler) { // eslint-disable-line
    this.onReceiveLog = handler
    this._coreClient.onReceiveLogHandler = handler
    this._historyClient.onReceiveLogHandler = handler
  }

  // Request methods

  /**
   * Request value
   * @this {!Client}
   * @param {!object} value Value to request
   * @param {!number} timeout Timeout in milliseconds (default is 0)
   * @returns {Promise} Response promise
   */
  request (value, timeout = 0) {
    let promise = new fbe.DeferredPromise()
    let current = Date.now()

    // Send the request message
    let serialized = this.send(value)
    if (serialized > 0) {
      // Calculate the unique timestamp
      this._timestamp = (current <= this._timestamp) ? this._timestamp + 1 : current

      // Register the request
      this._requests_by_id[value.id] = [this._timestamp, timeout * 1000000, promise]
      if (timeout > 0) {
        this._requests_by_timestamp[this._timestamp] = value.id
      }
    } else {
      promise.reject(new Error('Serialization failed!'))
    }

    return promise
  }

  /**
   * Response value
   * @this {!Client}
   * @param {!object} value Value to response
   * @returns {!boolean} Response handle flag
   */
  response (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.resolve(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.response(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._historyClient.response(value)) {
      return true
    }

    return false
  }

  /**
   * Reject value
   * @this {!Client}
   * @param {!object} value Value to reject
   * @returns {!boolean} Reject handle flag
   */
  reject (value) {
    let item = this._requests_by_id.get(value.id)
    if (item != null) {
      let timestamp = item[0]
      let promise = item[2]
      promise.reject(value)
      this._requests_by_id.delete(value.id)
      this._requests_by_timestamp.delete(timestamp)
      return true
    }

    // noinspection RedundantIfStatementJS
    if (this._coreClient.reject(value)) {
      return true
    }
    // noinspection RedundantIfStatementJS
    if (this._historyClient.reject(value)) {
      return true
    }

    return false
  }

  /**
   * Reset client requests
   * @this {!Client}
   */
  resetRequests () {
    this._coreClient.resetRequests()
    this._historyClient.resetRequests()

    for (let [, value] of this._requests_by_id) {
      value[2].reject(new Error('Reset client!'))
    }
    this._requests_by_id.clear()
    this._requests_by_timestamp.clear()
  }

  /**
   * Watchdog client requests for timeouts
   * @this {!Client}
   * @param {!number} utc UTC timestamp in milliseconds
   */
  watchdogRequests (utc) {
    this._coreClient.watchdogRequests(utc)
    this._historyClient.watchdogRequests(utc)

    for (let [, value] of this._requests_by_timestamp) {
      let item = this._requests_by_id[value]
      let id = value
      let timestamp = item[0]
      let timespan = item[1]
      if ((timestamp + timespan) <= utc) {
        let promise = item[2]
        promise.reject(new Error('Timeout!'))
        this._requests_by_id.delete(id)
        this._requests_by_timestamp.delete(timestamp)
      }
    }
  }
}

export { Client };
