
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {FilterUint64} from './FilterUint64';
import {FieldModelFilterUint64} from './FilterUint64';
import {FilterString} from './FilterString';
import {FieldModelFilterString} from './FilterString';
import {FilterBool} from './FilterBool';
import {FieldModelFilterBool} from './FilterBool';
import {FilterEnum} from './FilterEnum';
import {FieldModelFilterEnum} from './FilterEnum';
import {FilterTime} from './FilterTime';
import {FieldModelFilterTime} from './FilterTime';
import {SocialTradingMasterAccountFiltration} from '../core/SocialTradingMasterAccountFiltration';
import {FieldModelSocialTradingMasterAccountFiltration} from '../core/SocialTradingMasterAccountFiltration';
import {SocialTradingInvestorAccountFiltration} from '../core/SocialTradingInvestorAccountFiltration';
import {FieldModelSocialTradingInvestorAccountFiltration} from '../core/SocialTradingInvestorAccountFiltration';

/**
 * AccountFiltration struct
 */
class AccountFiltration {
  /**
   * Initialize struct
   * @param {FilterUint64=} login
   * @param {FilterString=} name
   * @param {FilterString=} country
   * @param {FilterString=} city
   * @param {FilterString=} state
   * @param {FilterString=} zipCode
   * @param {FilterString=} address
   * @param {FilterString=} phone
   * @param {FilterString=} email
   * @param {FilterString=} identifier
   * @param {FilterString=} status
   * @param {FilterString=} comment
   * @param {FilterString=} description
   * @param {FilterBool=} isEnabled
   * @param {FilterBool=} isOTPEnabled
   * @param {FilterBool=} isTradeEnabled
   * @param {FilterEnum=} authenticationType
   * @param {FilterEnum=} accountingType
   * @param {FilterUint64=} agentAccount
   * @param {FilterUint64=} maxPendingOrders
   * @param {FilterTime=} created
   * @param {FilterTime=} modified
   * @param {FilterTime=} archived
   * @param {FilterTime=} deleted
   * @param {FilterTime=} lastLogin
   * @param {FilterEnum=} reportsSubscriptionType
   * @param {FilterUint64=} maxOrders
   * @param {FilterString=} externalClientId
   * @param {FilterString=} externalAccountId
   * @param {FilterString=} localLanguageName
   * @param {FilterEnum=} socialTradingAccountType
   * @param {SocialTradingMasterAccountFiltration=} socialTradingMasterAccountFiltration
   * @param {SocialTradingInvestorAccountFiltration=} socialTradingInvestorAccountFiltration
   * @constructor
   */
  constructor (login = null, name = null, country = null, city = null, state = null, zipCode = null, address = null, phone = null, email = null, identifier = null, status = null, comment = null, description = null, isEnabled = null, isOTPEnabled = null, isTradeEnabled = null, authenticationType = null, accountingType = null, agentAccount = null, maxPendingOrders = null, created = null, modified = null, archived = null, deleted = null, lastLogin = null, reportsSubscriptionType = null, maxOrders = null, externalClientId = null, externalAccountId = null, localLanguageName = null, socialTradingAccountType = null, socialTradingMasterAccountFiltration = null, socialTradingInvestorAccountFiltration = null) {
    this.Login = login
    this.Name = name
    this.Country = country
    this.City = city
    this.State = state
    this.ZipCode = zipCode
    this.Address = address
    this.Phone = phone
    this.Email = email
    this.Identifier = identifier
    this.Status = status
    this.Comment = comment
    this.Description = description
    this.IsEnabled = isEnabled
    this.IsOTPEnabled = isOTPEnabled
    this.IsTradeEnabled = isTradeEnabled
    this.AuthenticationType = authenticationType
    this.AccountingType = accountingType
    this.AgentAccount = agentAccount
    this.MaxPendingOrders = maxPendingOrders
    this.Created = created
    this.Modified = modified
    this.Archived = archived
    this.Deleted = deleted
    this.LastLogin = lastLogin
    this.ReportsSubscriptionType = reportsSubscriptionType
    this.MaxOrders = maxOrders
    this.ExternalClientId = externalClientId
    this.ExternalAccountId = externalAccountId
    this.LocalLanguageName = localLanguageName
    this.SocialTradingAccountType = socialTradingAccountType
    this.SocialTradingMasterAccountFiltration = socialTradingMasterAccountFiltration
    this.SocialTradingInvestorAccountFiltration = socialTradingInvestorAccountFiltration
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AccountFiltration}
   * @param {!AccountFiltration} other Other struct
   * @returns {!AccountFiltration} This struct
   */
  copy (other) {
    if (other.Login != null) {
      this.Login = FilterUint64.fromObject(other.Login)
    } else {
      this.Login = null
    }
    if (other.Name != null) {
      this.Name = FilterString.fromObject(other.Name)
    } else {
      this.Name = null
    }
    if (other.Country != null) {
      this.Country = FilterString.fromObject(other.Country)
    } else {
      this.Country = null
    }
    if (other.City != null) {
      this.City = FilterString.fromObject(other.City)
    } else {
      this.City = null
    }
    if (other.State != null) {
      this.State = FilterString.fromObject(other.State)
    } else {
      this.State = null
    }
    if (other.ZipCode != null) {
      this.ZipCode = FilterString.fromObject(other.ZipCode)
    } else {
      this.ZipCode = null
    }
    if (other.Address != null) {
      this.Address = FilterString.fromObject(other.Address)
    } else {
      this.Address = null
    }
    if (other.Phone != null) {
      this.Phone = FilterString.fromObject(other.Phone)
    } else {
      this.Phone = null
    }
    if (other.Email != null) {
      this.Email = FilterString.fromObject(other.Email)
    } else {
      this.Email = null
    }
    if (other.Identifier != null) {
      this.Identifier = FilterString.fromObject(other.Identifier)
    } else {
      this.Identifier = null
    }
    if (other.Status != null) {
      this.Status = FilterString.fromObject(other.Status)
    } else {
      this.Status = null
    }
    if (other.Comment != null) {
      this.Comment = FilterString.fromObject(other.Comment)
    } else {
      this.Comment = null
    }
    if (other.Description != null) {
      this.Description = FilterString.fromObject(other.Description)
    } else {
      this.Description = null
    }
    if (other.IsEnabled != null) {
      this.IsEnabled = FilterBool.fromObject(other.IsEnabled)
    } else {
      this.IsEnabled = null
    }
    if (other.IsOTPEnabled != null) {
      this.IsOTPEnabled = FilterBool.fromObject(other.IsOTPEnabled)
    } else {
      this.IsOTPEnabled = null
    }
    if (other.IsTradeEnabled != null) {
      this.IsTradeEnabled = FilterBool.fromObject(other.IsTradeEnabled)
    } else {
      this.IsTradeEnabled = null
    }
    if (other.AuthenticationType != null) {
      this.AuthenticationType = FilterEnum.fromObject(other.AuthenticationType)
    } else {
      this.AuthenticationType = null
    }
    if (other.AccountingType != null) {
      this.AccountingType = FilterEnum.fromObject(other.AccountingType)
    } else {
      this.AccountingType = null
    }
    if (other.AgentAccount != null) {
      this.AgentAccount = FilterUint64.fromObject(other.AgentAccount)
    } else {
      this.AgentAccount = null
    }
    if (other.MaxPendingOrders != null) {
      this.MaxPendingOrders = FilterUint64.fromObject(other.MaxPendingOrders)
    } else {
      this.MaxPendingOrders = null
    }
    if (other.Created != null) {
      this.Created = FilterTime.fromObject(other.Created)
    } else {
      this.Created = null
    }
    if (other.Modified != null) {
      this.Modified = FilterTime.fromObject(other.Modified)
    } else {
      this.Modified = null
    }
    if (other.Archived != null) {
      this.Archived = FilterTime.fromObject(other.Archived)
    } else {
      this.Archived = null
    }
    if (other.Deleted != null) {
      this.Deleted = FilterTime.fromObject(other.Deleted)
    } else {
      this.Deleted = null
    }
    if (other.LastLogin != null) {
      this.LastLogin = FilterTime.fromObject(other.LastLogin)
    } else {
      this.LastLogin = null
    }
    if (other.ReportsSubscriptionType != null) {
      this.ReportsSubscriptionType = FilterEnum.fromObject(other.ReportsSubscriptionType)
    } else {
      this.ReportsSubscriptionType = null
    }
    if (other.MaxOrders != null) {
      this.MaxOrders = FilterUint64.fromObject(other.MaxOrders)
    } else {
      this.MaxOrders = null
    }
    if (other.ExternalClientId != null) {
      this.ExternalClientId = FilterString.fromObject(other.ExternalClientId)
    } else {
      this.ExternalClientId = null
    }
    if (other.ExternalAccountId != null) {
      this.ExternalAccountId = FilterString.fromObject(other.ExternalAccountId)
    } else {
      this.ExternalAccountId = null
    }
    if (other.LocalLanguageName != null) {
      this.LocalLanguageName = FilterString.fromObject(other.LocalLanguageName)
    } else {
      this.LocalLanguageName = null
    }
    if (other.SocialTradingAccountType != null) {
      this.SocialTradingAccountType = FilterEnum.fromObject(other.SocialTradingAccountType)
    } else {
      this.SocialTradingAccountType = null
    }
    if (other.SocialTradingMasterAccountFiltration != null) {
      this.SocialTradingMasterAccountFiltration = SocialTradingMasterAccountFiltration.fromObject(other.SocialTradingMasterAccountFiltration)
    } else {
      this.SocialTradingMasterAccountFiltration = null
    }
    if (other.SocialTradingInvestorAccountFiltration != null) {
      this.SocialTradingInvestorAccountFiltration = SocialTradingInvestorAccountFiltration.fromObject(other.SocialTradingInvestorAccountFiltration)
    } else {
      this.SocialTradingInvestorAccountFiltration = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AccountFiltration}
   * @returns {!AccountFiltration} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccountFiltrationModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccountFiltrationModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AccountFiltration}
   * @param {!AccountFiltration} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AccountFiltration)) {
      throw new TypeError('Instance of AccountFiltration is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AccountFiltration}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Login: ((this.Login != null) ? this.Login : null),
      Name: ((this.Name != null) ? this.Name : null),
      Country: ((this.Country != null) ? this.Country : null),
      City: ((this.City != null) ? this.City : null),
      State: ((this.State != null) ? this.State : null),
      ZipCode: ((this.ZipCode != null) ? this.ZipCode : null),
      Address: ((this.Address != null) ? this.Address : null),
      Phone: ((this.Phone != null) ? this.Phone : null),
      Email: ((this.Email != null) ? this.Email : null),
      Identifier: ((this.Identifier != null) ? this.Identifier : null),
      Status: ((this.Status != null) ? this.Status : null),
      Comment: ((this.Comment != null) ? this.Comment : null),
      Description: ((this.Description != null) ? this.Description : null),
      IsEnabled: ((this.IsEnabled != null) ? this.IsEnabled : null),
      IsOTPEnabled: ((this.IsOTPEnabled != null) ? this.IsOTPEnabled : null),
      IsTradeEnabled: ((this.IsTradeEnabled != null) ? this.IsTradeEnabled : null),
      AuthenticationType: ((this.AuthenticationType != null) ? this.AuthenticationType : null),
      AccountingType: ((this.AccountingType != null) ? this.AccountingType : null),
      AgentAccount: ((this.AgentAccount != null) ? this.AgentAccount : null),
      MaxPendingOrders: ((this.MaxPendingOrders != null) ? this.MaxPendingOrders : null),
      Created: ((this.Created != null) ? this.Created : null),
      Modified: ((this.Modified != null) ? this.Modified : null),
      Archived: ((this.Archived != null) ? this.Archived : null),
      Deleted: ((this.Deleted != null) ? this.Deleted : null),
      LastLogin: ((this.LastLogin != null) ? this.LastLogin : null),
      ReportsSubscriptionType: ((this.ReportsSubscriptionType != null) ? this.ReportsSubscriptionType : null),
      MaxOrders: ((this.MaxOrders != null) ? this.MaxOrders : null),
      ExternalClientId: ((this.ExternalClientId != null) ? this.ExternalClientId : null),
      ExternalAccountId: ((this.ExternalAccountId != null) ? this.ExternalAccountId : null),
      LocalLanguageName: ((this.LocalLanguageName != null) ? this.LocalLanguageName : null),
      SocialTradingAccountType: ((this.SocialTradingAccountType != null) ? this.SocialTradingAccountType : null),
      SocialTradingMasterAccountFiltration: ((this.SocialTradingMasterAccountFiltration != null) ? this.SocialTradingMasterAccountFiltration : null),
      SocialTradingInvestorAccountFiltration: ((this.SocialTradingInvestorAccountFiltration != null) ? this.SocialTradingInvestorAccountFiltration : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AccountFiltration.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AccountFiltration} other Object value
   * @returns {!AccountFiltration} Created struct
   */
  static fromObject (other) {
    return new AccountFiltration().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AccountFiltration}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AccountFiltration.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AccountFiltration}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 120
  }
}

export { AccountFiltration };

/**
 * Fast Binary Encoding AccountFiltration field model
 */
class FieldModelAccountFiltration extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Login = new fbe.FieldModelOptional(new FieldModelFilterUint64(buffer, 4 + 4), buffer, 4 + 4)
    this.Name = new fbe.FieldModelOptional(new FieldModelFilterString(buffer, this.Login.fbeOffset + this.Login.fbeSize), buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.Country = new fbe.FieldModelOptional(new FieldModelFilterString(buffer, this.Name.fbeOffset + this.Name.fbeSize), buffer, this.Name.fbeOffset + this.Name.fbeSize)
    this.City = new fbe.FieldModelOptional(new FieldModelFilterString(buffer, this.Country.fbeOffset + this.Country.fbeSize), buffer, this.Country.fbeOffset + this.Country.fbeSize)
    this.State = new fbe.FieldModelOptional(new FieldModelFilterString(buffer, this.City.fbeOffset + this.City.fbeSize), buffer, this.City.fbeOffset + this.City.fbeSize)
    this.ZipCode = new fbe.FieldModelOptional(new FieldModelFilterString(buffer, this.State.fbeOffset + this.State.fbeSize), buffer, this.State.fbeOffset + this.State.fbeSize)
    this.Address = new fbe.FieldModelOptional(new FieldModelFilterString(buffer, this.ZipCode.fbeOffset + this.ZipCode.fbeSize), buffer, this.ZipCode.fbeOffset + this.ZipCode.fbeSize)
    this.Phone = new fbe.FieldModelOptional(new FieldModelFilterString(buffer, this.Address.fbeOffset + this.Address.fbeSize), buffer, this.Address.fbeOffset + this.Address.fbeSize)
    this.Email = new fbe.FieldModelOptional(new FieldModelFilterString(buffer, this.Phone.fbeOffset + this.Phone.fbeSize), buffer, this.Phone.fbeOffset + this.Phone.fbeSize)
    this.Identifier = new fbe.FieldModelOptional(new FieldModelFilterString(buffer, this.Email.fbeOffset + this.Email.fbeSize), buffer, this.Email.fbeOffset + this.Email.fbeSize)
    this.Status = new fbe.FieldModelOptional(new FieldModelFilterString(buffer, this.Identifier.fbeOffset + this.Identifier.fbeSize), buffer, this.Identifier.fbeOffset + this.Identifier.fbeSize)
    this.Comment = new fbe.FieldModelOptional(new FieldModelFilterString(buffer, this.Status.fbeOffset + this.Status.fbeSize), buffer, this.Status.fbeOffset + this.Status.fbeSize)
    this.Description = new fbe.FieldModelOptional(new FieldModelFilterString(buffer, this.Comment.fbeOffset + this.Comment.fbeSize), buffer, this.Comment.fbeOffset + this.Comment.fbeSize)
    this.IsEnabled = new fbe.FieldModelOptional(new FieldModelFilterBool(buffer, this.Description.fbeOffset + this.Description.fbeSize), buffer, this.Description.fbeOffset + this.Description.fbeSize)
    this.IsOTPEnabled = new fbe.FieldModelOptional(new FieldModelFilterBool(buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize), buffer, this.IsEnabled.fbeOffset + this.IsEnabled.fbeSize)
    this.IsTradeEnabled = new fbe.FieldModelOptional(new FieldModelFilterBool(buffer, this.IsOTPEnabled.fbeOffset + this.IsOTPEnabled.fbeSize), buffer, this.IsOTPEnabled.fbeOffset + this.IsOTPEnabled.fbeSize)
    this.AuthenticationType = new fbe.FieldModelOptional(new FieldModelFilterEnum(buffer, this.IsTradeEnabled.fbeOffset + this.IsTradeEnabled.fbeSize), buffer, this.IsTradeEnabled.fbeOffset + this.IsTradeEnabled.fbeSize)
    this.AccountingType = new fbe.FieldModelOptional(new FieldModelFilterEnum(buffer, this.AuthenticationType.fbeOffset + this.AuthenticationType.fbeSize), buffer, this.AuthenticationType.fbeOffset + this.AuthenticationType.fbeSize)
    this.AgentAccount = new fbe.FieldModelOptional(new FieldModelFilterUint64(buffer, this.AccountingType.fbeOffset + this.AccountingType.fbeSize), buffer, this.AccountingType.fbeOffset + this.AccountingType.fbeSize)
    this.MaxPendingOrders = new fbe.FieldModelOptional(new FieldModelFilterUint64(buffer, this.AgentAccount.fbeOffset + this.AgentAccount.fbeSize), buffer, this.AgentAccount.fbeOffset + this.AgentAccount.fbeSize)
    this.Created = new fbe.FieldModelOptional(new FieldModelFilterTime(buffer, this.MaxPendingOrders.fbeOffset + this.MaxPendingOrders.fbeSize), buffer, this.MaxPendingOrders.fbeOffset + this.MaxPendingOrders.fbeSize)
    this.Modified = new fbe.FieldModelOptional(new FieldModelFilterTime(buffer, this.Created.fbeOffset + this.Created.fbeSize), buffer, this.Created.fbeOffset + this.Created.fbeSize)
    this.Archived = new fbe.FieldModelOptional(new FieldModelFilterTime(buffer, this.Modified.fbeOffset + this.Modified.fbeSize), buffer, this.Modified.fbeOffset + this.Modified.fbeSize)
    this.Deleted = new fbe.FieldModelOptional(new FieldModelFilterTime(buffer, this.Archived.fbeOffset + this.Archived.fbeSize), buffer, this.Archived.fbeOffset + this.Archived.fbeSize)
    this.LastLogin = new fbe.FieldModelOptional(new FieldModelFilterTime(buffer, this.Deleted.fbeOffset + this.Deleted.fbeSize), buffer, this.Deleted.fbeOffset + this.Deleted.fbeSize)
    this.ReportsSubscriptionType = new fbe.FieldModelOptional(new FieldModelFilterEnum(buffer, this.LastLogin.fbeOffset + this.LastLogin.fbeSize), buffer, this.LastLogin.fbeOffset + this.LastLogin.fbeSize)
    this.MaxOrders = new fbe.FieldModelOptional(new FieldModelFilterUint64(buffer, this.ReportsSubscriptionType.fbeOffset + this.ReportsSubscriptionType.fbeSize), buffer, this.ReportsSubscriptionType.fbeOffset + this.ReportsSubscriptionType.fbeSize)
    this.ExternalClientId = new fbe.FieldModelOptional(new FieldModelFilterString(buffer, this.MaxOrders.fbeOffset + this.MaxOrders.fbeSize), buffer, this.MaxOrders.fbeOffset + this.MaxOrders.fbeSize)
    this.ExternalAccountId = new fbe.FieldModelOptional(new FieldModelFilterString(buffer, this.ExternalClientId.fbeOffset + this.ExternalClientId.fbeSize), buffer, this.ExternalClientId.fbeOffset + this.ExternalClientId.fbeSize)
    this.LocalLanguageName = new fbe.FieldModelOptional(new FieldModelFilterString(buffer, this.ExternalAccountId.fbeOffset + this.ExternalAccountId.fbeSize), buffer, this.ExternalAccountId.fbeOffset + this.ExternalAccountId.fbeSize)
    this.SocialTradingAccountType = new fbe.FieldModelOptional(new FieldModelFilterEnum(buffer, this.LocalLanguageName.fbeOffset + this.LocalLanguageName.fbeSize), buffer, this.LocalLanguageName.fbeOffset + this.LocalLanguageName.fbeSize)
    this.SocialTradingMasterAccountFiltration = new fbe.FieldModelOptional(new FieldModelSocialTradingMasterAccountFiltration(buffer, this.SocialTradingAccountType.fbeOffset + this.SocialTradingAccountType.fbeSize), buffer, this.SocialTradingAccountType.fbeOffset + this.SocialTradingAccountType.fbeSize)
    this.SocialTradingInvestorAccountFiltration = new fbe.FieldModelOptional(new FieldModelSocialTradingInvestorAccountFiltration(buffer, this.SocialTradingMasterAccountFiltration.fbeOffset + this.SocialTradingMasterAccountFiltration.fbeSize), buffer, this.SocialTradingMasterAccountFiltration.fbeOffset + this.SocialTradingMasterAccountFiltration.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelAccountFiltration}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccountFiltration}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Login.fbeSize + this.Name.fbeSize + this.Country.fbeSize + this.City.fbeSize + this.State.fbeSize + this.ZipCode.fbeSize + this.Address.fbeSize + this.Phone.fbeSize + this.Email.fbeSize + this.Identifier.fbeSize + this.Status.fbeSize + this.Comment.fbeSize + this.Description.fbeSize + this.IsEnabled.fbeSize + this.IsOTPEnabled.fbeSize + this.IsTradeEnabled.fbeSize + this.AuthenticationType.fbeSize + this.AccountingType.fbeSize + this.AgentAccount.fbeSize + this.MaxPendingOrders.fbeSize + this.Created.fbeSize + this.Modified.fbeSize + this.Archived.fbeSize + this.Deleted.fbeSize + this.LastLogin.fbeSize + this.ReportsSubscriptionType.fbeSize + this.MaxOrders.fbeSize + this.ExternalClientId.fbeSize + this.ExternalAccountId.fbeSize + this.LocalLanguageName.fbeSize + this.SocialTradingAccountType.fbeSize + this.SocialTradingMasterAccountFiltration.fbeSize + this.SocialTradingInvestorAccountFiltration.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccountFiltration}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Login.fbeExtra + this.Name.fbeExtra + this.Country.fbeExtra + this.City.fbeExtra + this.State.fbeExtra + this.ZipCode.fbeExtra + this.Address.fbeExtra + this.Phone.fbeExtra + this.Email.fbeExtra + this.Identifier.fbeExtra + this.Status.fbeExtra + this.Comment.fbeExtra + this.Description.fbeExtra + this.IsEnabled.fbeExtra + this.IsOTPEnabled.fbeExtra + this.IsTradeEnabled.fbeExtra + this.AuthenticationType.fbeExtra + this.AccountingType.fbeExtra + this.AgentAccount.fbeExtra + this.MaxPendingOrders.fbeExtra + this.Created.fbeExtra + this.Modified.fbeExtra + this.Archived.fbeExtra + this.Deleted.fbeExtra + this.LastLogin.fbeExtra + this.ReportsSubscriptionType.fbeExtra + this.MaxOrders.fbeExtra + this.ExternalClientId.fbeExtra + this.ExternalAccountId.fbeExtra + this.LocalLanguageName.fbeExtra + this.SocialTradingAccountType.fbeExtra + this.SocialTradingMasterAccountFiltration.fbeExtra + this.SocialTradingInvestorAccountFiltration.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccountFiltration}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccountFiltration.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccountFiltration}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 120
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccountFiltration}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccountFiltration}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Name.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Country.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Country.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Country.fbeSize

    if ((fbeCurrentSize + this.City.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.City.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.City.fbeSize

    if ((fbeCurrentSize + this.State.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.State.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.State.fbeSize

    if ((fbeCurrentSize + this.ZipCode.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ZipCode.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ZipCode.fbeSize

    if ((fbeCurrentSize + this.Address.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Address.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Address.fbeSize

    if ((fbeCurrentSize + this.Phone.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Phone.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Phone.fbeSize

    if ((fbeCurrentSize + this.Email.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Email.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Email.fbeSize

    if ((fbeCurrentSize + this.Identifier.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Identifier.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Identifier.fbeSize

    if ((fbeCurrentSize + this.Status.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Status.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Status.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Comment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Description.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.IsOTPEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsOTPEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.IsTradeEnabled.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IsTradeEnabled.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeEnabled.fbeSize

    if ((fbeCurrentSize + this.AuthenticationType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AuthenticationType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AuthenticationType.fbeSize

    if ((fbeCurrentSize + this.AccountingType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountingType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountingType.fbeSize

    if ((fbeCurrentSize + this.AgentAccount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AgentAccount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AgentAccount.fbeSize

    if ((fbeCurrentSize + this.MaxPendingOrders.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxPendingOrders.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxPendingOrders.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Created.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    if ((fbeCurrentSize + this.Modified.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Modified.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modified.fbeSize

    if ((fbeCurrentSize + this.Archived.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Archived.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Archived.fbeSize

    if ((fbeCurrentSize + this.Deleted.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Deleted.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deleted.fbeSize

    if ((fbeCurrentSize + this.LastLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LastLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastLogin.fbeSize

    if ((fbeCurrentSize + this.ReportsSubscriptionType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ReportsSubscriptionType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ReportsSubscriptionType.fbeSize

    if ((fbeCurrentSize + this.MaxOrders.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.MaxOrders.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxOrders.fbeSize

    if ((fbeCurrentSize + this.ExternalClientId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExternalClientId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalClientId.fbeSize

    if ((fbeCurrentSize + this.ExternalAccountId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExternalAccountId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalAccountId.fbeSize

    if ((fbeCurrentSize + this.LocalLanguageName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LocalLanguageName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LocalLanguageName.fbeSize

    if ((fbeCurrentSize + this.SocialTradingAccountType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingAccountType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingAccountType.fbeSize

    if ((fbeCurrentSize + this.SocialTradingMasterAccountFiltration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingMasterAccountFiltration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingMasterAccountFiltration.fbeSize

    if ((fbeCurrentSize + this.SocialTradingInvestorAccountFiltration.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SocialTradingInvestorAccountFiltration.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingInvestorAccountFiltration.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccountFiltration}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccountFiltration}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccountFiltration}
   * @param {!AccountFiltration} fbeValue Default value, defaults is new AccountFiltration()
   * @returns {!AccountFiltration} AccountFiltration value
   */
  get (fbeValue = new AccountFiltration()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccountFiltration}
   * @param {!AccountFiltration} fbeValue AccountFiltration value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.Name.fbeSize) <= fbeStructSize) {
      fbeValue.Name = this.Name.get()
    } else {
      fbeValue.Name = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Name.fbeSize

    if ((fbeCurrentSize + this.Country.fbeSize) <= fbeStructSize) {
      fbeValue.Country = this.Country.get()
    } else {
      fbeValue.Country = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Country.fbeSize

    if ((fbeCurrentSize + this.City.fbeSize) <= fbeStructSize) {
      fbeValue.City = this.City.get()
    } else {
      fbeValue.City = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.City.fbeSize

    if ((fbeCurrentSize + this.State.fbeSize) <= fbeStructSize) {
      fbeValue.State = this.State.get()
    } else {
      fbeValue.State = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.State.fbeSize

    if ((fbeCurrentSize + this.ZipCode.fbeSize) <= fbeStructSize) {
      fbeValue.ZipCode = this.ZipCode.get()
    } else {
      fbeValue.ZipCode = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ZipCode.fbeSize

    if ((fbeCurrentSize + this.Address.fbeSize) <= fbeStructSize) {
      fbeValue.Address = this.Address.get()
    } else {
      fbeValue.Address = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Address.fbeSize

    if ((fbeCurrentSize + this.Phone.fbeSize) <= fbeStructSize) {
      fbeValue.Phone = this.Phone.get()
    } else {
      fbeValue.Phone = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Phone.fbeSize

    if ((fbeCurrentSize + this.Email.fbeSize) <= fbeStructSize) {
      fbeValue.Email = this.Email.get()
    } else {
      fbeValue.Email = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Email.fbeSize

    if ((fbeCurrentSize + this.Identifier.fbeSize) <= fbeStructSize) {
      fbeValue.Identifier = this.Identifier.get()
    } else {
      fbeValue.Identifier = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Identifier.fbeSize

    if ((fbeCurrentSize + this.Status.fbeSize) <= fbeStructSize) {
      fbeValue.Status = this.Status.get()
    } else {
      fbeValue.Status = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Status.fbeSize

    if ((fbeCurrentSize + this.Comment.fbeSize) <= fbeStructSize) {
      fbeValue.Comment = this.Comment.get()
    } else {
      fbeValue.Comment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Comment.fbeSize

    if ((fbeCurrentSize + this.Description.fbeSize) <= fbeStructSize) {
      fbeValue.Description = this.Description.get()
    } else {
      fbeValue.Description = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Description.fbeSize

    if ((fbeCurrentSize + this.IsEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsEnabled = this.IsEnabled.get()
    } else {
      fbeValue.IsEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsEnabled.fbeSize

    if ((fbeCurrentSize + this.IsOTPEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsOTPEnabled = this.IsOTPEnabled.get()
    } else {
      fbeValue.IsOTPEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsOTPEnabled.fbeSize

    if ((fbeCurrentSize + this.IsTradeEnabled.fbeSize) <= fbeStructSize) {
      fbeValue.IsTradeEnabled = this.IsTradeEnabled.get()
    } else {
      fbeValue.IsTradeEnabled = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IsTradeEnabled.fbeSize

    if ((fbeCurrentSize + this.AuthenticationType.fbeSize) <= fbeStructSize) {
      fbeValue.AuthenticationType = this.AuthenticationType.get()
    } else {
      fbeValue.AuthenticationType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AuthenticationType.fbeSize

    if ((fbeCurrentSize + this.AccountingType.fbeSize) <= fbeStructSize) {
      fbeValue.AccountingType = this.AccountingType.get()
    } else {
      fbeValue.AccountingType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountingType.fbeSize

    if ((fbeCurrentSize + this.AgentAccount.fbeSize) <= fbeStructSize) {
      fbeValue.AgentAccount = this.AgentAccount.get()
    } else {
      fbeValue.AgentAccount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AgentAccount.fbeSize

    if ((fbeCurrentSize + this.MaxPendingOrders.fbeSize) <= fbeStructSize) {
      fbeValue.MaxPendingOrders = this.MaxPendingOrders.get()
    } else {
      fbeValue.MaxPendingOrders = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxPendingOrders.fbeSize

    if ((fbeCurrentSize + this.Created.fbeSize) <= fbeStructSize) {
      fbeValue.Created = this.Created.get()
    } else {
      fbeValue.Created = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Created.fbeSize

    if ((fbeCurrentSize + this.Modified.fbeSize) <= fbeStructSize) {
      fbeValue.Modified = this.Modified.get()
    } else {
      fbeValue.Modified = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Modified.fbeSize

    if ((fbeCurrentSize + this.Archived.fbeSize) <= fbeStructSize) {
      fbeValue.Archived = this.Archived.get()
    } else {
      fbeValue.Archived = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Archived.fbeSize

    if ((fbeCurrentSize + this.Deleted.fbeSize) <= fbeStructSize) {
      fbeValue.Deleted = this.Deleted.get()
    } else {
      fbeValue.Deleted = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Deleted.fbeSize

    if ((fbeCurrentSize + this.LastLogin.fbeSize) <= fbeStructSize) {
      fbeValue.LastLogin = this.LastLogin.get()
    } else {
      fbeValue.LastLogin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastLogin.fbeSize

    if ((fbeCurrentSize + this.ReportsSubscriptionType.fbeSize) <= fbeStructSize) {
      fbeValue.ReportsSubscriptionType = this.ReportsSubscriptionType.get()
    } else {
      fbeValue.ReportsSubscriptionType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ReportsSubscriptionType.fbeSize

    if ((fbeCurrentSize + this.MaxOrders.fbeSize) <= fbeStructSize) {
      fbeValue.MaxOrders = this.MaxOrders.get()
    } else {
      fbeValue.MaxOrders = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.MaxOrders.fbeSize

    if ((fbeCurrentSize + this.ExternalClientId.fbeSize) <= fbeStructSize) {
      fbeValue.ExternalClientId = this.ExternalClientId.get()
    } else {
      fbeValue.ExternalClientId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalClientId.fbeSize

    if ((fbeCurrentSize + this.ExternalAccountId.fbeSize) <= fbeStructSize) {
      fbeValue.ExternalAccountId = this.ExternalAccountId.get()
    } else {
      fbeValue.ExternalAccountId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalAccountId.fbeSize

    if ((fbeCurrentSize + this.LocalLanguageName.fbeSize) <= fbeStructSize) {
      fbeValue.LocalLanguageName = this.LocalLanguageName.get()
    } else {
      fbeValue.LocalLanguageName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LocalLanguageName.fbeSize

    if ((fbeCurrentSize + this.SocialTradingAccountType.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingAccountType = this.SocialTradingAccountType.get()
    } else {
      fbeValue.SocialTradingAccountType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingAccountType.fbeSize

    if ((fbeCurrentSize + this.SocialTradingMasterAccountFiltration.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingMasterAccountFiltration = this.SocialTradingMasterAccountFiltration.get()
    } else {
      fbeValue.SocialTradingMasterAccountFiltration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingMasterAccountFiltration.fbeSize

    if ((fbeCurrentSize + this.SocialTradingInvestorAccountFiltration.fbeSize) <= fbeStructSize) {
      fbeValue.SocialTradingInvestorAccountFiltration = this.SocialTradingInvestorAccountFiltration.get()
    } else {
      fbeValue.SocialTradingInvestorAccountFiltration = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SocialTradingInvestorAccountFiltration.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccountFiltration}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccountFiltration}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccountFiltration}
   * @param {!AccountFiltration} fbeValue AccountFiltration value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccountFiltration}
   * @param {AccountFiltration} fbeValue AccountFiltration value
   */
  setFields (fbeValue) {
    this.Login.set(fbeValue.Login)
    this.Name.set(fbeValue.Name)
    this.Country.set(fbeValue.Country)
    this.City.set(fbeValue.City)
    this.State.set(fbeValue.State)
    this.ZipCode.set(fbeValue.ZipCode)
    this.Address.set(fbeValue.Address)
    this.Phone.set(fbeValue.Phone)
    this.Email.set(fbeValue.Email)
    this.Identifier.set(fbeValue.Identifier)
    this.Status.set(fbeValue.Status)
    this.Comment.set(fbeValue.Comment)
    this.Description.set(fbeValue.Description)
    this.IsEnabled.set(fbeValue.IsEnabled)
    this.IsOTPEnabled.set(fbeValue.IsOTPEnabled)
    this.IsTradeEnabled.set(fbeValue.IsTradeEnabled)
    this.AuthenticationType.set(fbeValue.AuthenticationType)
    this.AccountingType.set(fbeValue.AccountingType)
    this.AgentAccount.set(fbeValue.AgentAccount)
    this.MaxPendingOrders.set(fbeValue.MaxPendingOrders)
    this.Created.set(fbeValue.Created)
    this.Modified.set(fbeValue.Modified)
    this.Archived.set(fbeValue.Archived)
    this.Deleted.set(fbeValue.Deleted)
    this.LastLogin.set(fbeValue.LastLogin)
    this.ReportsSubscriptionType.set(fbeValue.ReportsSubscriptionType)
    this.MaxOrders.set(fbeValue.MaxOrders)
    this.ExternalClientId.set(fbeValue.ExternalClientId)
    this.ExternalAccountId.set(fbeValue.ExternalAccountId)
    this.LocalLanguageName.set(fbeValue.LocalLanguageName)
    this.SocialTradingAccountType.set(fbeValue.SocialTradingAccountType)
    this.SocialTradingMasterAccountFiltration.set(fbeValue.SocialTradingMasterAccountFiltration)
    this.SocialTradingInvestorAccountFiltration.set(fbeValue.SocialTradingInvestorAccountFiltration)
  }
}

export { FieldModelAccountFiltration };

/**
 * Fast Binary Encoding AccountFiltration model
 */
class AccountFiltrationModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccountFiltration(this.buffer, 4)
  }

  /**
   * Get the AccountFiltration model
   * @this {!AccountFiltrationModel}
   * @returns {!FieldModelAccountFiltration} model AccountFiltration model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccountFiltrationModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccountFiltrationModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccountFiltrationModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccountFiltrationModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccountFiltration.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccountFiltrationModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccountFiltrationModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccountFiltrationModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccountFiltrationModel}
   * @param {!AccountFiltration} value AccountFiltration value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccountFiltrationModel}
   * @param {!AccountFiltration} value AccountFiltration value, defaults is new AccountFiltration()
   * @return {!object} Deserialized AccountFiltration value and its size
   */
  deserialize (value = new AccountFiltration()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AccountFiltration(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AccountFiltration(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccountFiltrationModel}
   * @param {!number} prev Previous AccountFiltration model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccountFiltrationModel };
