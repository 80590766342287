
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Order} from '../core/Order';
import {FieldModelOrder} from '../core/Order';
import {TradeTransactionReason} from '../core/TradeTransactionReason';
import {FieldModelTradeTransactionReason} from '../core/TradeTransactionReason';

/**
 * OrderInsertRequestParams struct
 */
class OrderInsertRequestParams {
  /**
   * Initialize struct
   * @param {!Order=} order
   * @param {!UUID=} accountGroupId
   * @param {string=} externalClientId
   * @param {!TradeTransactionReason=} transactionReason
   * @param {string=} managerComment
   * @constructor
   */
  constructor (order = new Order(), accountGroupId = new UUID(), externalClientId = null, transactionReason = new TradeTransactionReason(TradeTransactionReason.UNKNOWN), managerComment = null) {
    this.Order = order
    this.AccountGroupId = accountGroupId
    this.ExternalClientId = externalClientId
    this.TransactionReason = transactionReason
    this.ManagerComment = managerComment
  }

  /**
   * Copy struct (shallow copy)
   * @this {!OrderInsertRequestParams}
   * @param {!OrderInsertRequestParams} other Other struct
   * @returns {!OrderInsertRequestParams} This struct
   */
  copy (other) {
    if (other.Order != null) {
      this.Order = Order.fromObject(other.Order)
    } else {
      this.Order = null
    }
    if (other.AccountGroupId != null) {
      this.AccountGroupId = new UUID(other.AccountGroupId)
    } else {
      this.AccountGroupId = null
    }
    if (other.ExternalClientId != null) {
      this.ExternalClientId = other.ExternalClientId
    } else {
      this.ExternalClientId = null
    }
    if (other.TransactionReason != null) {
      this.TransactionReason = TradeTransactionReason.fromObject(other.TransactionReason)
    } else {
      this.TransactionReason = null
    }
    if (other.ManagerComment != null) {
      this.ManagerComment = other.ManagerComment
    } else {
      this.ManagerComment = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!OrderInsertRequestParams}
   * @returns {!OrderInsertRequestParams} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new OrderInsertRequestParamsModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new OrderInsertRequestParamsModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!OrderInsertRequestParams}
   * @param {!OrderInsertRequestParams} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof OrderInsertRequestParams)) {
      throw new TypeError('Instance of OrderInsertRequestParams is required!')
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!OrderInsertRequestParams}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    return {
      Order: ((this.Order != null) ? this.Order : null),
      AccountGroupId: ((this.AccountGroupId != null) ? this.AccountGroupId.toString() : null),
      ExternalClientId: ((this.ExternalClientId != null) ? this.ExternalClientId : null),
      TransactionReason: ((this.TransactionReason != null) ? this.TransactionReason : null),
      ManagerComment: ((this.ManagerComment != null) ? this.ManagerComment : null)
    }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return OrderInsertRequestParams.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!OrderInsertRequestParams} other Object value
   * @returns {!OrderInsertRequestParams} Created struct
   */
  static fromObject (other) {
    return new OrderInsertRequestParams().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!OrderInsertRequestParams}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return OrderInsertRequestParams.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!OrderInsertRequestParams}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 4133
  }
}

export { OrderInsertRequestParams };

/**
 * Fast Binary Encoding OrderInsertRequestParams field model
 */
class FieldModelOrderInsertRequestParams extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this.Order = new FieldModelOrder(buffer, 4 + 4)
    this.AccountGroupId = new fbe.FieldModelUUID(buffer, this.Order.fbeOffset + this.Order.fbeSize)
    this.ExternalClientId = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.AccountGroupId.fbeOffset + this.AccountGroupId.fbeSize), buffer, this.AccountGroupId.fbeOffset + this.AccountGroupId.fbeSize)
    this.TransactionReason = new FieldModelTradeTransactionReason(buffer, this.ExternalClientId.fbeOffset + this.ExternalClientId.fbeSize)
    this.ManagerComment = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.TransactionReason.fbeOffset + this.TransactionReason.fbeSize), buffer, this.TransactionReason.fbeOffset + this.TransactionReason.fbeSize)
  }

  /**
   * Get the field size
   * @this {!FieldModelOrderInsertRequestParams}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelOrderInsertRequestParams}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.Order.fbeSize + this.AccountGroupId.fbeSize + this.ExternalClientId.fbeSize + this.TransactionReason.fbeSize + this.ManagerComment.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelOrderInsertRequestParams}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.Order.fbeExtra + this.AccountGroupId.fbeExtra + this.ExternalClientId.fbeExtra + this.TransactionReason.fbeExtra + this.ManagerComment.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelOrderInsertRequestParams}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelOrderInsertRequestParams.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelOrderInsertRequestParams}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 4133
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelOrderInsertRequestParams}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelOrderInsertRequestParams}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Order.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Order.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Order.fbeSize

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroupId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.ExternalClientId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ExternalClientId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalClientId.fbeSize

    if ((fbeCurrentSize + this.TransactionReason.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.TransactionReason.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionReason.fbeSize

    if ((fbeCurrentSize + this.ManagerComment.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ManagerComment.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerComment.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelOrderInsertRequestParams}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelOrderInsertRequestParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelOrderInsertRequestParams}
   * @param {!OrderInsertRequestParams} fbeValue Default value, defaults is new OrderInsertRequestParams()
   * @returns {!OrderInsertRequestParams} OrderInsertRequestParams value
   */
  get (fbeValue = new OrderInsertRequestParams()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelOrderInsertRequestParams}
   * @param {!OrderInsertRequestParams} fbeValue OrderInsertRequestParams value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.Order.fbeSize) <= fbeStructSize) {
      fbeValue.Order = this.Order.get()
    } else {
      fbeValue.Order = new Order()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Order.fbeSize

    if ((fbeCurrentSize + this.AccountGroupId.fbeSize) <= fbeStructSize) {
      fbeValue.AccountGroupId = this.AccountGroupId.get()
    } else {
      fbeValue.AccountGroupId = new UUID()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroupId.fbeSize

    if ((fbeCurrentSize + this.ExternalClientId.fbeSize) <= fbeStructSize) {
      fbeValue.ExternalClientId = this.ExternalClientId.get()
    } else {
      fbeValue.ExternalClientId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ExternalClientId.fbeSize

    if ((fbeCurrentSize + this.TransactionReason.fbeSize) <= fbeStructSize) {
      fbeValue.TransactionReason = this.TransactionReason.get(new TradeTransactionReason(TradeTransactionReason.UNKNOWN))
    } else {
      fbeValue.TransactionReason = new TradeTransactionReason(TradeTransactionReason.UNKNOWN)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.TransactionReason.fbeSize

    if ((fbeCurrentSize + this.ManagerComment.fbeSize) <= fbeStructSize) {
      fbeValue.ManagerComment = this.ManagerComment.get()
    } else {
      fbeValue.ManagerComment = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ManagerComment.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelOrderInsertRequestParams}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelOrderInsertRequestParams}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelOrderInsertRequestParams}
   * @param {!OrderInsertRequestParams} fbeValue OrderInsertRequestParams value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelOrderInsertRequestParams}
   * @param {OrderInsertRequestParams} fbeValue OrderInsertRequestParams value
   */
  setFields (fbeValue) {
    this.Order.set(fbeValue.Order)
    this.AccountGroupId.set(fbeValue.AccountGroupId)
    this.ExternalClientId.set(fbeValue.ExternalClientId)
    this.TransactionReason.set(fbeValue.TransactionReason)
    this.ManagerComment.set(fbeValue.ManagerComment)
  }
}

export { FieldModelOrderInsertRequestParams };

/**
 * Fast Binary Encoding OrderInsertRequestParams model
 */
class OrderInsertRequestParamsModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelOrderInsertRequestParams(this.buffer, 4)
  }

  /**
   * Get the OrderInsertRequestParams model
   * @this {!OrderInsertRequestParamsModel}
   * @returns {!FieldModelOrderInsertRequestParams} model OrderInsertRequestParams model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!OrderInsertRequestParamsModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!OrderInsertRequestParamsModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return OrderInsertRequestParamsModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!OrderInsertRequestParamsModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelOrderInsertRequestParams.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!OrderInsertRequestParamsModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!OrderInsertRequestParamsModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!OrderInsertRequestParamsModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!OrderInsertRequestParamsModel}
   * @param {!OrderInsertRequestParams} value OrderInsertRequestParams value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!OrderInsertRequestParamsModel}
   * @param {!OrderInsertRequestParams} value OrderInsertRequestParams value, defaults is new OrderInsertRequestParams()
   * @return {!object} Deserialized OrderInsertRequestParams value and its size
   */
  deserialize (value = new OrderInsertRequestParams()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new OrderInsertRequestParams(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new OrderInsertRequestParams(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!OrderInsertRequestParamsModel}
   * @param {!number} prev Previous OrderInsertRequestParams model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { OrderInsertRequestParamsModel };
