
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as feed from '../feed'
import * as history from '../history'
import * as storage from '../storage'
import * as client from '../client'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {ConfigRequest} from './ConfigRequest';
import {FieldModelConfigRequest} from './ConfigRequest';
import {TradingCalendarRulesGetResponse} from './TradingCalendarRulesGetResponse';

/**
 * TradingCalendarRulesGetRequest struct
 */
class TradingCalendarRulesGetRequest extends ConfigRequest {
  /**
   * Initialize struct
   * @param {!ConfigRequest=} parent
   * @param {!Date=} requestFrom
   * @param {!Date=} requestTo
   * @param {UUID=} symbolId
   * @param {string=} symbolName
   * @constructor
   */
  constructor (parent = new ConfigRequest(), requestFrom = new Date(0), requestTo = new Date(0), symbolId = null, symbolName = null) {
    super()
    super.copy(parent)
    this.RequestFrom = requestFrom
    this.RequestTo = requestTo
    this.SymbolId = symbolId
    this.SymbolName = symbolName
  }

  /**
   * Copy struct (shallow copy)
   * @this {!TradingCalendarRulesGetRequest}
   * @param {!TradingCalendarRulesGetRequest} other Other struct
   * @returns {!TradingCalendarRulesGetRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.RequestFrom != null) {
      if (other.RequestFrom instanceof Date) {
        this.RequestFrom = new Date(other.RequestFrom.getTime())
      } else {
        this.RequestFrom = new Date(Math.round(other.RequestFrom / 1000000))
      }
    } else {
      this.RequestFrom = null
    }
    if (other.RequestTo != null) {
      if (other.RequestTo instanceof Date) {
        this.RequestTo = new Date(other.RequestTo.getTime())
      } else {
        this.RequestTo = new Date(Math.round(other.RequestTo / 1000000))
      }
    } else {
      this.RequestTo = null
    }
    if (other.SymbolId != null) {
      this.SymbolId = new UUID(other.SymbolId)
    } else {
      this.SymbolId = null
    }
    if (other.SymbolName != null) {
      this.SymbolName = other.SymbolName
    } else {
      this.SymbolName = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!TradingCalendarRulesGetRequest}
   * @returns {!TradingCalendarRulesGetRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new TradingCalendarRulesGetRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new TradingCalendarRulesGetRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!TradingCalendarRulesGetRequest}
   * @param {!TradingCalendarRulesGetRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof TradingCalendarRulesGetRequest)) {
      throw new TypeError('Instance of TradingCalendarRulesGetRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!TradingCalendarRulesGetRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      RequestFrom: ((this.RequestFrom != null) ? (this.RequestFrom.getTime() * 1000000) : null),
      RequestTo: ((this.RequestTo != null) ? (this.RequestTo.getTime() * 1000000) : null),
      SymbolId: ((this.SymbolId != null) ? this.SymbolId.toString() : null),
      SymbolName: ((this.SymbolName != null) ? this.SymbolName : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return TradingCalendarRulesGetRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!TradingCalendarRulesGetRequest} other Object value
   * @returns {!TradingCalendarRulesGetRequest} Created struct
   */
  static fromObject (other) {
    return new TradingCalendarRulesGetRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!TradingCalendarRulesGetRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return TradingCalendarRulesGetRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!TradingCalendarRulesGetRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6304
  }
}

export { TradingCalendarRulesGetRequest };

/**
 * Fast Binary Encoding TradingCalendarRulesGetRequest field model
 */
class FieldModelTradingCalendarRulesGetRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelConfigRequest(buffer, 4 + 4)
    this.RequestFrom = new fbe.FieldModelTimestamp(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.RequestTo = new fbe.FieldModelTimestamp(buffer, this.RequestFrom.fbeOffset + this.RequestFrom.fbeSize)
    this.SymbolId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.RequestTo.fbeOffset + this.RequestTo.fbeSize), buffer, this.RequestTo.fbeOffset + this.RequestTo.fbeSize)
    this.SymbolName = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.SymbolId.fbeOffset + this.SymbolId.fbeSize), buffer, this.SymbolId.fbeOffset + this.SymbolId.fbeSize)
  }

  /**
   * Get the ConfigRequest field model
   * @this {!FieldModelTradingCalendarRulesGetRequest}
   * @returns {!FieldModelConfigRequest} ConfigRequest field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelTradingCalendarRulesGetRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelTradingCalendarRulesGetRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.RequestFrom.fbeSize + this.RequestTo.fbeSize + this.SymbolId.fbeSize + this.SymbolName.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelTradingCalendarRulesGetRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.RequestFrom.fbeExtra + this.RequestTo.fbeExtra + this.SymbolId.fbeExtra + this.SymbolName.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelTradingCalendarRulesGetRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelTradingCalendarRulesGetRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelTradingCalendarRulesGetRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6304
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelTradingCalendarRulesGetRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelTradingCalendarRulesGetRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.RequestFrom.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RequestFrom.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestFrom.fbeSize

    if ((fbeCurrentSize + this.RequestTo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RequestTo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestTo.fbeSize

    if ((fbeCurrentSize + this.SymbolId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.SymbolName.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.SymbolName.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolName.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelTradingCalendarRulesGetRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelTradingCalendarRulesGetRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelTradingCalendarRulesGetRequest}
   * @param {!TradingCalendarRulesGetRequest} fbeValue Default value, defaults is new TradingCalendarRulesGetRequest()
   * @returns {!TradingCalendarRulesGetRequest} TradingCalendarRulesGetRequest value
   */
  get (fbeValue = new TradingCalendarRulesGetRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelTradingCalendarRulesGetRequest}
   * @param {!TradingCalendarRulesGetRequest} fbeValue TradingCalendarRulesGetRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.RequestFrom.fbeSize) <= fbeStructSize) {
      fbeValue.RequestFrom = this.RequestFrom.get()
    } else {
      fbeValue.RequestFrom = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestFrom.fbeSize

    if ((fbeCurrentSize + this.RequestTo.fbeSize) <= fbeStructSize) {
      fbeValue.RequestTo = this.RequestTo.get()
    } else {
      fbeValue.RequestTo = new Date(0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestTo.fbeSize

    if ((fbeCurrentSize + this.SymbolId.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolId = this.SymbolId.get()
    } else {
      fbeValue.SymbolId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolId.fbeSize

    if ((fbeCurrentSize + this.SymbolName.fbeSize) <= fbeStructSize) {
      fbeValue.SymbolName = this.SymbolName.get()
    } else {
      fbeValue.SymbolName = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.SymbolName.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelTradingCalendarRulesGetRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelTradingCalendarRulesGetRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelTradingCalendarRulesGetRequest}
   * @param {!TradingCalendarRulesGetRequest} fbeValue TradingCalendarRulesGetRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelTradingCalendarRulesGetRequest}
   * @param {TradingCalendarRulesGetRequest} fbeValue TradingCalendarRulesGetRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.RequestFrom.set(fbeValue.RequestFrom)
    this.RequestTo.set(fbeValue.RequestTo)
    this.SymbolId.set(fbeValue.SymbolId)
    this.SymbolName.set(fbeValue.SymbolName)
  }
}

export { FieldModelTradingCalendarRulesGetRequest };

/**
 * Fast Binary Encoding TradingCalendarRulesGetRequest model
 */
class TradingCalendarRulesGetRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelTradingCalendarRulesGetRequest(this.buffer, 4)
  }

  /**
   * Get the TradingCalendarRulesGetRequest model
   * @this {!TradingCalendarRulesGetRequestModel}
   * @returns {!FieldModelTradingCalendarRulesGetRequest} model TradingCalendarRulesGetRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!TradingCalendarRulesGetRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!TradingCalendarRulesGetRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return TradingCalendarRulesGetRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!TradingCalendarRulesGetRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelTradingCalendarRulesGetRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!TradingCalendarRulesGetRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!TradingCalendarRulesGetRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!TradingCalendarRulesGetRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!TradingCalendarRulesGetRequestModel}
   * @param {!TradingCalendarRulesGetRequest} value TradingCalendarRulesGetRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!TradingCalendarRulesGetRequestModel}
   * @param {!TradingCalendarRulesGetRequest} value TradingCalendarRulesGetRequest value, defaults is new TradingCalendarRulesGetRequest()
   * @return {!object} Deserialized TradingCalendarRulesGetRequest value and its size
   */
  deserialize (value = new TradingCalendarRulesGetRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new TradingCalendarRulesGetRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new TradingCalendarRulesGetRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!TradingCalendarRulesGetRequestModel}
   * @param {!number} prev Previous TradingCalendarRulesGetRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { TradingCalendarRulesGetRequestModel };
TradingCalendarRulesGetRequest.__has_response = true;
TradingCalendarRulesGetRequest.__response_class = TradingCalendarRulesGetResponse;
