
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as feed from '../feed'
import * as history from '../history'
import * as storage from '../storage'
import * as client from '../client'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Response} from '../core/Response';
import {FieldModelResponse} from '../core/Response';
import {Account} from '../core/Account';
import {FieldModelAccount} from '../core/Account';
import {AccountMargin} from '../core/AccountMargin';
import {FieldModelAccountMargin} from '../core/AccountMargin';

/**
 * AccountSocialTradingMasterCreateResponse struct
 */
class AccountSocialTradingMasterCreateResponse extends Response {
  /**
   * Initialize struct
   * @param {!Response=} parent
   * @param {!Account=} account
   * @param {AccountMargin=} margin
   * @param {!string=} password
   * @param {!string=} investorPassword
   * @param {!string=} phonePassword
   * @param {string=} oTPSecret
   * @param {Account=} commissionAccount
   * @param {AccountMargin=} commissionMargin
   * @param {string=} commissionPassword
   * @param {string=} commissionInvestorPassword
   * @param {string=} commissionPhonePassword
   * @param {string=} commissionOTPSecret
   * @constructor
   */
  constructor (parent = new Response(), account = new Account(), margin = null, password = '', investorPassword = '', phonePassword = '', oTPSecret = null, commissionAccount = null, commissionMargin = null, commissionPassword = null, commissionInvestorPassword = null, commissionPhonePassword = null, commissionOTPSecret = null) {
    super()
    super.copy(parent)
    this.Account = account
    this.Margin = margin
    this.Password = password
    this.InvestorPassword = investorPassword
    this.PhonePassword = phonePassword
    this.OTPSecret = oTPSecret
    this.CommissionAccount = commissionAccount
    this.CommissionMargin = commissionMargin
    this.CommissionPassword = commissionPassword
    this.CommissionInvestorPassword = commissionInvestorPassword
    this.CommissionPhonePassword = commissionPhonePassword
    this.CommissionOTPSecret = commissionOTPSecret
  }

  /**
   * Copy struct (shallow copy)
   * @this {!AccountSocialTradingMasterCreateResponse}
   * @param {!AccountSocialTradingMasterCreateResponse} other Other struct
   * @returns {!AccountSocialTradingMasterCreateResponse} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.Account != null) {
      this.Account = Account.fromObject(other.Account)
    } else {
      this.Account = null
    }
    if (other.Margin != null) {
      this.Margin = AccountMargin.fromObject(other.Margin)
    } else {
      this.Margin = null
    }
    if (other.Password != null) {
      this.Password = other.Password
    } else {
      this.Password = null
    }
    if (other.InvestorPassword != null) {
      this.InvestorPassword = other.InvestorPassword
    } else {
      this.InvestorPassword = null
    }
    if (other.PhonePassword != null) {
      this.PhonePassword = other.PhonePassword
    } else {
      this.PhonePassword = null
    }
    if (other.OTPSecret != null) {
      this.OTPSecret = other.OTPSecret
    } else {
      this.OTPSecret = null
    }
    if (other.CommissionAccount != null) {
      this.CommissionAccount = Account.fromObject(other.CommissionAccount)
    } else {
      this.CommissionAccount = null
    }
    if (other.CommissionMargin != null) {
      this.CommissionMargin = AccountMargin.fromObject(other.CommissionMargin)
    } else {
      this.CommissionMargin = null
    }
    if (other.CommissionPassword != null) {
      this.CommissionPassword = other.CommissionPassword
    } else {
      this.CommissionPassword = null
    }
    if (other.CommissionInvestorPassword != null) {
      this.CommissionInvestorPassword = other.CommissionInvestorPassword
    } else {
      this.CommissionInvestorPassword = null
    }
    if (other.CommissionPhonePassword != null) {
      this.CommissionPhonePassword = other.CommissionPhonePassword
    } else {
      this.CommissionPhonePassword = null
    }
    if (other.CommissionOTPSecret != null) {
      this.CommissionOTPSecret = other.CommissionOTPSecret
    } else {
      this.CommissionOTPSecret = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!AccountSocialTradingMasterCreateResponse}
   * @returns {!AccountSocialTradingMasterCreateResponse} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new AccountSocialTradingMasterCreateResponseModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new AccountSocialTradingMasterCreateResponseModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!AccountSocialTradingMasterCreateResponse}
   * @param {!AccountSocialTradingMasterCreateResponse} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof AccountSocialTradingMasterCreateResponse)) {
      throw new TypeError('Instance of AccountSocialTradingMasterCreateResponse is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!AccountSocialTradingMasterCreateResponse}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      Account: ((this.Account != null) ? this.Account : null),
      Margin: ((this.Margin != null) ? this.Margin : null),
      Password: ((this.Password != null) ? this.Password : null),
      InvestorPassword: ((this.InvestorPassword != null) ? this.InvestorPassword : null),
      PhonePassword: ((this.PhonePassword != null) ? this.PhonePassword : null),
      OTPSecret: ((this.OTPSecret != null) ? this.OTPSecret : null),
      CommissionAccount: ((this.CommissionAccount != null) ? this.CommissionAccount : null),
      CommissionMargin: ((this.CommissionMargin != null) ? this.CommissionMargin : null),
      CommissionPassword: ((this.CommissionPassword != null) ? this.CommissionPassword : null),
      CommissionInvestorPassword: ((this.CommissionInvestorPassword != null) ? this.CommissionInvestorPassword : null),
      CommissionPhonePassword: ((this.CommissionPhonePassword != null) ? this.CommissionPhonePassword : null),
      CommissionOTPSecret: ((this.CommissionOTPSecret != null) ? this.CommissionOTPSecret : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return AccountSocialTradingMasterCreateResponse.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!AccountSocialTradingMasterCreateResponse} other Object value
   * @returns {!AccountSocialTradingMasterCreateResponse} Created struct
   */
  static fromObject (other) {
    return new AccountSocialTradingMasterCreateResponse().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!AccountSocialTradingMasterCreateResponse}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return AccountSocialTradingMasterCreateResponse.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!AccountSocialTradingMasterCreateResponse}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6479
  }
}

export { AccountSocialTradingMasterCreateResponse };

/**
 * Fast Binary Encoding AccountSocialTradingMasterCreateResponse field model
 */
class FieldModelAccountSocialTradingMasterCreateResponse extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelResponse(buffer, 4 + 4)
    this.Account = new FieldModelAccount(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.Margin = new fbe.FieldModelOptional(new FieldModelAccountMargin(buffer, this.Account.fbeOffset + this.Account.fbeSize), buffer, this.Account.fbeOffset + this.Account.fbeSize)
    this.Password = new fbe.FieldModelString(buffer, this.Margin.fbeOffset + this.Margin.fbeSize)
    this.InvestorPassword = new fbe.FieldModelString(buffer, this.Password.fbeOffset + this.Password.fbeSize)
    this.PhonePassword = new fbe.FieldModelString(buffer, this.InvestorPassword.fbeOffset + this.InvestorPassword.fbeSize)
    this.OTPSecret = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.PhonePassword.fbeOffset + this.PhonePassword.fbeSize), buffer, this.PhonePassword.fbeOffset + this.PhonePassword.fbeSize)
    this.CommissionAccount = new fbe.FieldModelOptional(new FieldModelAccount(buffer, this.OTPSecret.fbeOffset + this.OTPSecret.fbeSize), buffer, this.OTPSecret.fbeOffset + this.OTPSecret.fbeSize)
    this.CommissionMargin = new fbe.FieldModelOptional(new FieldModelAccountMargin(buffer, this.CommissionAccount.fbeOffset + this.CommissionAccount.fbeSize), buffer, this.CommissionAccount.fbeOffset + this.CommissionAccount.fbeSize)
    this.CommissionPassword = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.CommissionMargin.fbeOffset + this.CommissionMargin.fbeSize), buffer, this.CommissionMargin.fbeOffset + this.CommissionMargin.fbeSize)
    this.CommissionInvestorPassword = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.CommissionPassword.fbeOffset + this.CommissionPassword.fbeSize), buffer, this.CommissionPassword.fbeOffset + this.CommissionPassword.fbeSize)
    this.CommissionPhonePassword = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.CommissionInvestorPassword.fbeOffset + this.CommissionInvestorPassword.fbeSize), buffer, this.CommissionInvestorPassword.fbeOffset + this.CommissionInvestorPassword.fbeSize)
    this.CommissionOTPSecret = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.CommissionPhonePassword.fbeOffset + this.CommissionPhonePassword.fbeSize), buffer, this.CommissionPhonePassword.fbeOffset + this.CommissionPhonePassword.fbeSize)
  }

  /**
   * Get the core.Response field model
   * @this {!FieldModelAccountSocialTradingMasterCreateResponse}
   * @returns {!FieldModelResponse} core.Response field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelAccountSocialTradingMasterCreateResponse}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelAccountSocialTradingMasterCreateResponse}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.Account.fbeSize + this.Margin.fbeSize + this.Password.fbeSize + this.InvestorPassword.fbeSize + this.PhonePassword.fbeSize + this.OTPSecret.fbeSize + this.CommissionAccount.fbeSize + this.CommissionMargin.fbeSize + this.CommissionPassword.fbeSize + this.CommissionInvestorPassword.fbeSize + this.CommissionPhonePassword.fbeSize + this.CommissionOTPSecret.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelAccountSocialTradingMasterCreateResponse}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.Account.fbeExtra + this.Margin.fbeExtra + this.Password.fbeExtra + this.InvestorPassword.fbeExtra + this.PhonePassword.fbeExtra + this.OTPSecret.fbeExtra + this.CommissionAccount.fbeExtra + this.CommissionMargin.fbeExtra + this.CommissionPassword.fbeExtra + this.CommissionInvestorPassword.fbeExtra + this.CommissionPhonePassword.fbeExtra + this.CommissionOTPSecret.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelAccountSocialTradingMasterCreateResponse}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelAccountSocialTradingMasterCreateResponse.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelAccountSocialTradingMasterCreateResponse}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6479
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelAccountSocialTradingMasterCreateResponse}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelAccountSocialTradingMasterCreateResponse}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Account.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Account.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Account.fbeSize

    if ((fbeCurrentSize + this.Margin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Margin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Margin.fbeSize

    if ((fbeCurrentSize + this.Password.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Password.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Password.fbeSize

    if ((fbeCurrentSize + this.InvestorPassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.InvestorPassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.InvestorPassword.fbeSize

    if ((fbeCurrentSize + this.PhonePassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PhonePassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PhonePassword.fbeSize

    if ((fbeCurrentSize + this.OTPSecret.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OTPSecret.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OTPSecret.fbeSize

    if ((fbeCurrentSize + this.CommissionAccount.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CommissionAccount.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionAccount.fbeSize

    if ((fbeCurrentSize + this.CommissionMargin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CommissionMargin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionMargin.fbeSize

    if ((fbeCurrentSize + this.CommissionPassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CommissionPassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionPassword.fbeSize

    if ((fbeCurrentSize + this.CommissionInvestorPassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CommissionInvestorPassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionInvestorPassword.fbeSize

    if ((fbeCurrentSize + this.CommissionPhonePassword.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CommissionPhonePassword.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionPhonePassword.fbeSize

    if ((fbeCurrentSize + this.CommissionOTPSecret.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.CommissionOTPSecret.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionOTPSecret.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelAccountSocialTradingMasterCreateResponse}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelAccountSocialTradingMasterCreateResponse}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelAccountSocialTradingMasterCreateResponse}
   * @param {!AccountSocialTradingMasterCreateResponse} fbeValue Default value, defaults is new AccountSocialTradingMasterCreateResponse()
   * @returns {!AccountSocialTradingMasterCreateResponse} AccountSocialTradingMasterCreateResponse value
   */
  get (fbeValue = new AccountSocialTradingMasterCreateResponse()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelAccountSocialTradingMasterCreateResponse}
   * @param {!AccountSocialTradingMasterCreateResponse} fbeValue AccountSocialTradingMasterCreateResponse value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.Account.fbeSize) <= fbeStructSize) {
      fbeValue.Account = this.Account.get()
    } else {
      fbeValue.Account = new Account()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Account.fbeSize

    if ((fbeCurrentSize + this.Margin.fbeSize) <= fbeStructSize) {
      fbeValue.Margin = this.Margin.get()
    } else {
      fbeValue.Margin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Margin.fbeSize

    if ((fbeCurrentSize + this.Password.fbeSize) <= fbeStructSize) {
      fbeValue.Password = this.Password.get()
    } else {
      fbeValue.Password = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Password.fbeSize

    if ((fbeCurrentSize + this.InvestorPassword.fbeSize) <= fbeStructSize) {
      fbeValue.InvestorPassword = this.InvestorPassword.get()
    } else {
      fbeValue.InvestorPassword = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.InvestorPassword.fbeSize

    if ((fbeCurrentSize + this.PhonePassword.fbeSize) <= fbeStructSize) {
      fbeValue.PhonePassword = this.PhonePassword.get()
    } else {
      fbeValue.PhonePassword = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PhonePassword.fbeSize

    if ((fbeCurrentSize + this.OTPSecret.fbeSize) <= fbeStructSize) {
      fbeValue.OTPSecret = this.OTPSecret.get()
    } else {
      fbeValue.OTPSecret = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OTPSecret.fbeSize

    if ((fbeCurrentSize + this.CommissionAccount.fbeSize) <= fbeStructSize) {
      fbeValue.CommissionAccount = this.CommissionAccount.get()
    } else {
      fbeValue.CommissionAccount = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionAccount.fbeSize

    if ((fbeCurrentSize + this.CommissionMargin.fbeSize) <= fbeStructSize) {
      fbeValue.CommissionMargin = this.CommissionMargin.get()
    } else {
      fbeValue.CommissionMargin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionMargin.fbeSize

    if ((fbeCurrentSize + this.CommissionPassword.fbeSize) <= fbeStructSize) {
      fbeValue.CommissionPassword = this.CommissionPassword.get()
    } else {
      fbeValue.CommissionPassword = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionPassword.fbeSize

    if ((fbeCurrentSize + this.CommissionInvestorPassword.fbeSize) <= fbeStructSize) {
      fbeValue.CommissionInvestorPassword = this.CommissionInvestorPassword.get()
    } else {
      fbeValue.CommissionInvestorPassword = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionInvestorPassword.fbeSize

    if ((fbeCurrentSize + this.CommissionPhonePassword.fbeSize) <= fbeStructSize) {
      fbeValue.CommissionPhonePassword = this.CommissionPhonePassword.get()
    } else {
      fbeValue.CommissionPhonePassword = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionPhonePassword.fbeSize

    if ((fbeCurrentSize + this.CommissionOTPSecret.fbeSize) <= fbeStructSize) {
      fbeValue.CommissionOTPSecret = this.CommissionOTPSecret.get()
    } else {
      fbeValue.CommissionOTPSecret = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.CommissionOTPSecret.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelAccountSocialTradingMasterCreateResponse}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelAccountSocialTradingMasterCreateResponse}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelAccountSocialTradingMasterCreateResponse}
   * @param {!AccountSocialTradingMasterCreateResponse} fbeValue AccountSocialTradingMasterCreateResponse value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelAccountSocialTradingMasterCreateResponse}
   * @param {AccountSocialTradingMasterCreateResponse} fbeValue AccountSocialTradingMasterCreateResponse value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.Account.set(fbeValue.Account)
    this.Margin.set(fbeValue.Margin)
    this.Password.set(fbeValue.Password)
    this.InvestorPassword.set(fbeValue.InvestorPassword)
    this.PhonePassword.set(fbeValue.PhonePassword)
    this.OTPSecret.set(fbeValue.OTPSecret)
    this.CommissionAccount.set(fbeValue.CommissionAccount)
    this.CommissionMargin.set(fbeValue.CommissionMargin)
    this.CommissionPassword.set(fbeValue.CommissionPassword)
    this.CommissionInvestorPassword.set(fbeValue.CommissionInvestorPassword)
    this.CommissionPhonePassword.set(fbeValue.CommissionPhonePassword)
    this.CommissionOTPSecret.set(fbeValue.CommissionOTPSecret)
  }
}

export { FieldModelAccountSocialTradingMasterCreateResponse };

/**
 * Fast Binary Encoding AccountSocialTradingMasterCreateResponse model
 */
class AccountSocialTradingMasterCreateResponseModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelAccountSocialTradingMasterCreateResponse(this.buffer, 4)
  }

  /**
   * Get the AccountSocialTradingMasterCreateResponse model
   * @this {!AccountSocialTradingMasterCreateResponseModel}
   * @returns {!FieldModelAccountSocialTradingMasterCreateResponse} model AccountSocialTradingMasterCreateResponse model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!AccountSocialTradingMasterCreateResponseModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!AccountSocialTradingMasterCreateResponseModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return AccountSocialTradingMasterCreateResponseModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!AccountSocialTradingMasterCreateResponseModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelAccountSocialTradingMasterCreateResponse.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!AccountSocialTradingMasterCreateResponseModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!AccountSocialTradingMasterCreateResponseModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!AccountSocialTradingMasterCreateResponseModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!AccountSocialTradingMasterCreateResponseModel}
   * @param {!AccountSocialTradingMasterCreateResponse} value AccountSocialTradingMasterCreateResponse value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!AccountSocialTradingMasterCreateResponseModel}
   * @param {!AccountSocialTradingMasterCreateResponse} value AccountSocialTradingMasterCreateResponse value, defaults is new AccountSocialTradingMasterCreateResponse()
   * @return {!object} Deserialized AccountSocialTradingMasterCreateResponse value and its size
   */
  deserialize (value = new AccountSocialTradingMasterCreateResponse()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new AccountSocialTradingMasterCreateResponse(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new AccountSocialTradingMasterCreateResponse(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!AccountSocialTradingMasterCreateResponseModel}
   * @param {!number} prev Previous AccountSocialTradingMasterCreateResponse model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { AccountSocialTradingMasterCreateResponseModel };
