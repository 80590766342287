
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as feed from '../feed'
import * as history from '../history'
import * as storage from '../storage'
import * as client from '../client'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {LoginWithPasswordResponse} from './LoginWithPasswordResponse';

/**
 * LoginWithPasswordRequest struct
 */
class LoginWithPasswordRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {!Map=} userMetadata
   * @param {!number=} protocolVersionMajor
   * @param {!number=} protocolVersionMinor
   * @param {!number=} login
   * @param {!string=} password
   * @param {string=} oTPToken
   * @constructor
   */
  constructor (parent = new Request(), userMetadata = new Map(), protocolVersionMajor = 0, protocolVersionMinor = 0, login = new UInt64(0, 0), password = '', oTPToken = null) {
    super()
    super.copy(parent)
    this.UserMetadata = userMetadata
    this.ProtocolVersionMajor = protocolVersionMajor
    this.ProtocolVersionMinor = protocolVersionMinor
    this.Login = login
    this.Password = password
    this.OTPToken = oTPToken
  }

  /**
   * Copy struct (shallow copy)
   * @this {!LoginWithPasswordRequest}
   * @param {!LoginWithPasswordRequest} other Other struct
   * @returns {!LoginWithPasswordRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.UserMetadata != null) {
      this.UserMetadata = new Map()
      Object.keys(other.UserMetadata).forEach(key => {
        if (key != null) {
          let tempKey
          tempKey = key
          if (other.UserMetadata[key] != null) {
            let tempValue
            tempValue = other.UserMetadata[key]
            this.UserMetadata.set(tempKey, tempValue)
          } else {
            this.UserMetadata.set(tempKey, null)
          }
        }
      })
    } else {
      this.UserMetadata = null
    }
    if (other.ProtocolVersionMajor != null) {
      this.ProtocolVersionMajor = other.ProtocolVersionMajor
    } else {
      this.ProtocolVersionMajor = null
    }
    if (other.ProtocolVersionMinor != null) {
      this.ProtocolVersionMinor = other.ProtocolVersionMinor
    } else {
      this.ProtocolVersionMinor = null
    }
    if (other.Login != null) {
      this.Login = UInt64.fromNumber(other.Login)
    } else {
      this.Login = null
    }
    if (other.Password != null) {
      this.Password = other.Password
    } else {
      this.Password = null
    }
    if (other.OTPToken != null) {
      this.OTPToken = other.OTPToken
    } else {
      this.OTPToken = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!LoginWithPasswordRequest}
   * @returns {!LoginWithPasswordRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new LoginWithPasswordRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new LoginWithPasswordRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!LoginWithPasswordRequest}
   * @param {!LoginWithPasswordRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof LoginWithPasswordRequest)) {
      throw new TypeError('Instance of LoginWithPasswordRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!LoginWithPasswordRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      UserMetadata: ((this.UserMetadata != null) ? fbe.MapToObject(new Map(Array.from(this.UserMetadata, item => [((item[0] != null) ? item[0] : null), ((item[1] != null) ? item[1] : null)]))) : null),
      ProtocolVersionMajor: ((this.ProtocolVersionMajor != null) ? this.ProtocolVersionMajor : null),
      ProtocolVersionMinor: ((this.ProtocolVersionMinor != null) ? this.ProtocolVersionMinor : null),
      Login: ((this.Login != null) ? this.Login.toNumber() : null),
      Password: ((this.Password != null) ? this.Password : null),
      OTPToken: ((this.OTPToken != null) ? this.OTPToken : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return LoginWithPasswordRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!LoginWithPasswordRequest} other Object value
   * @returns {!LoginWithPasswordRequest} Created struct
   */
  static fromObject (other) {
    return new LoginWithPasswordRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!LoginWithPasswordRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return LoginWithPasswordRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!LoginWithPasswordRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6500
  }
}

export { LoginWithPasswordRequest };

/**
 * Fast Binary Encoding LoginWithPasswordRequest field model
 */
class FieldModelLoginWithPasswordRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.UserMetadata = new fbe.FieldModelMap(new fbe.FieldModelString(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), new fbe.FieldModelString(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4), buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.ProtocolVersionMajor = new fbe.FieldModelUInt16(buffer, this.UserMetadata.fbeOffset + this.UserMetadata.fbeSize)
    this.ProtocolVersionMinor = new fbe.FieldModelUInt16(buffer, this.ProtocolVersionMajor.fbeOffset + this.ProtocolVersionMajor.fbeSize)
    this.Login = new fbe.FieldModelUInt64(buffer, this.ProtocolVersionMinor.fbeOffset + this.ProtocolVersionMinor.fbeSize)
    this.Password = new fbe.FieldModelString(buffer, this.Login.fbeOffset + this.Login.fbeSize)
    this.OTPToken = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.Password.fbeOffset + this.Password.fbeSize), buffer, this.Password.fbeOffset + this.Password.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelLoginWithPasswordRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelLoginWithPasswordRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelLoginWithPasswordRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.UserMetadata.fbeSize + this.ProtocolVersionMajor.fbeSize + this.ProtocolVersionMinor.fbeSize + this.Login.fbeSize + this.Password.fbeSize + this.OTPToken.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelLoginWithPasswordRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.UserMetadata.fbeExtra + this.ProtocolVersionMajor.fbeExtra + this.ProtocolVersionMinor.fbeExtra + this.Login.fbeExtra + this.Password.fbeExtra + this.OTPToken.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelLoginWithPasswordRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelLoginWithPasswordRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelLoginWithPasswordRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6500
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelLoginWithPasswordRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelLoginWithPasswordRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.UserMetadata.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.UserMetadata.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UserMetadata.fbeSize

    if ((fbeCurrentSize + this.ProtocolVersionMajor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProtocolVersionMajor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolVersionMajor.fbeSize

    if ((fbeCurrentSize + this.ProtocolVersionMinor.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.ProtocolVersionMinor.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolVersionMinor.fbeSize

    if ((fbeCurrentSize + this.Login.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Login.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.Password.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.Password.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Password.fbeSize

    if ((fbeCurrentSize + this.OTPToken.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.OTPToken.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OTPToken.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelLoginWithPasswordRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelLoginWithPasswordRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelLoginWithPasswordRequest}
   * @param {!LoginWithPasswordRequest} fbeValue Default value, defaults is new LoginWithPasswordRequest()
   * @returns {!LoginWithPasswordRequest} LoginWithPasswordRequest value
   */
  get (fbeValue = new LoginWithPasswordRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelLoginWithPasswordRequest}
   * @param {!LoginWithPasswordRequest} fbeValue LoginWithPasswordRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.UserMetadata.fbeSize) <= fbeStructSize) {
      this.UserMetadata.get(fbeValue.UserMetadata)
    } else {
      fbeValue.UserMetadata.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.UserMetadata.fbeSize

    if ((fbeCurrentSize + this.ProtocolVersionMajor.fbeSize) <= fbeStructSize) {
      fbeValue.ProtocolVersionMajor = this.ProtocolVersionMajor.get()
    } else {
      fbeValue.ProtocolVersionMajor = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolVersionMajor.fbeSize

    if ((fbeCurrentSize + this.ProtocolVersionMinor.fbeSize) <= fbeStructSize) {
      fbeValue.ProtocolVersionMinor = this.ProtocolVersionMinor.get()
    } else {
      fbeValue.ProtocolVersionMinor = 0
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.ProtocolVersionMinor.fbeSize

    if ((fbeCurrentSize + this.Login.fbeSize) <= fbeStructSize) {
      fbeValue.Login = this.Login.get()
    } else {
      fbeValue.Login = new UInt64(0, 0)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Login.fbeSize

    if ((fbeCurrentSize + this.Password.fbeSize) <= fbeStructSize) {
      fbeValue.Password = this.Password.get()
    } else {
      fbeValue.Password = ''
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.Password.fbeSize

    if ((fbeCurrentSize + this.OTPToken.fbeSize) <= fbeStructSize) {
      fbeValue.OTPToken = this.OTPToken.get()
    } else {
      fbeValue.OTPToken = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.OTPToken.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelLoginWithPasswordRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelLoginWithPasswordRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelLoginWithPasswordRequest}
   * @param {!LoginWithPasswordRequest} fbeValue LoginWithPasswordRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelLoginWithPasswordRequest}
   * @param {LoginWithPasswordRequest} fbeValue LoginWithPasswordRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.UserMetadata.set(fbeValue.UserMetadata)
    this.ProtocolVersionMajor.set(fbeValue.ProtocolVersionMajor)
    this.ProtocolVersionMinor.set(fbeValue.ProtocolVersionMinor)
    this.Login.set(fbeValue.Login)
    this.Password.set(fbeValue.Password)
    this.OTPToken.set(fbeValue.OTPToken)
  }
}

export { FieldModelLoginWithPasswordRequest };

/**
 * Fast Binary Encoding LoginWithPasswordRequest model
 */
class LoginWithPasswordRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelLoginWithPasswordRequest(this.buffer, 4)
  }

  /**
   * Get the LoginWithPasswordRequest model
   * @this {!LoginWithPasswordRequestModel}
   * @returns {!FieldModelLoginWithPasswordRequest} model LoginWithPasswordRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!LoginWithPasswordRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!LoginWithPasswordRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return LoginWithPasswordRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!LoginWithPasswordRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelLoginWithPasswordRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!LoginWithPasswordRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!LoginWithPasswordRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!LoginWithPasswordRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!LoginWithPasswordRequestModel}
   * @param {!LoginWithPasswordRequest} value LoginWithPasswordRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!LoginWithPasswordRequestModel}
   * @param {!LoginWithPasswordRequest} value LoginWithPasswordRequest value, defaults is new LoginWithPasswordRequest()
   * @return {!object} Deserialized LoginWithPasswordRequest value and its size
   */
  deserialize (value = new LoginWithPasswordRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new LoginWithPasswordRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new LoginWithPasswordRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!LoginWithPasswordRequestModel}
   * @param {!number} prev Previous LoginWithPasswordRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { LoginWithPasswordRequestModel };
LoginWithPasswordRequest.__has_response = true;
LoginWithPasswordRequest.__response_class = LoginWithPasswordResponse;
