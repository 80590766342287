
import * as util from 'util'

import * as big from '../big'
import * as int64 from '../int64'
import * as uuid from '../uuid'

import * as fbe from '../fbe'
import * as core from '../core'
import * as config from '../config'
import * as feed from '../feed'
import * as history from '../history'
import * as storage from '../storage'
import * as client from '../client'
import * as exposed from '../exposed'
import * as execution from '../execution'
import * as backup from '../backup'
import * as inner from '../inner'

const Big = big.Big // eslint-disable-line
const Int64 = int64.Int64 // eslint-disable-line
const UInt64 = int64.UInt64 // eslint-disable-line
const UUID = uuid.UUID // eslint-disable-line
import {Request} from '../core/Request';
import {FieldModelRequest} from '../core/Request';
import {ClientLoginInfoGetResponse} from './ClientLoginInfoGetResponse';
import {RequestDirection} from '../core/RequestDirection';
import {FieldModelRequestDirection} from '../core/RequestDirection';
import {LoginType} from '../core/LoginType';
import {FieldModelLoginType} from '../core/LoginType';

/**
 * ClientLoginInfoGetRequest struct
 */
class ClientLoginInfoGetRequest extends Request {
  /**
   * Initialize struct
   * @param {!Request=} parent
   * @param {!RequestDirection=} requestDirection
   * @param {Date=} requestFrom
   * @param {Date=} requestTo
   * @param {number=} lastRecordId
   * @param {!number=} pageSize
   * @param {number=} accountLogin
   * @param {LoginType=} loginType
   * @param {UUID=} requestSessionId
   * @param {string=} iPAddress
   * @param {!Set=} accountGroups
   * @constructor
   */
  constructor (parent = new Request(), requestDirection = new RequestDirection(RequestDirection.FORWARD), requestFrom = null, requestTo = null, lastRecordId = null, pageSize = 1000, accountLogin = null, loginType = null, requestSessionId = null, iPAddress = null, accountGroups = new Set()) {
    super()
    super.copy(parent)
    this.RequestDirection = requestDirection
    this.RequestFrom = requestFrom
    this.RequestTo = requestTo
    this.LastRecordId = lastRecordId
    this.PageSize = pageSize
    this.AccountLogin = accountLogin
    this.LoginType = loginType
    this.RequestSessionId = requestSessionId
    this.IPAddress = iPAddress
    this.AccountGroups = accountGroups
  }

  /**
   * Copy struct (shallow copy)
   * @this {!ClientLoginInfoGetRequest}
   * @param {!ClientLoginInfoGetRequest} other Other struct
   * @returns {!ClientLoginInfoGetRequest} This struct
   */
  copy (other) {
    super.copy(other)
    if (other.RequestDirection != null) {
      this.RequestDirection = RequestDirection.fromObject(other.RequestDirection)
    } else {
      this.RequestDirection = null
    }
    if (other.RequestFrom != null) {
      if (other.RequestFrom instanceof Date) {
        this.RequestFrom = new Date(other.RequestFrom.getTime())
      } else {
        this.RequestFrom = new Date(Math.round(other.RequestFrom / 1000000))
      }
    } else {
      this.RequestFrom = null
    }
    if (other.RequestTo != null) {
      if (other.RequestTo instanceof Date) {
        this.RequestTo = new Date(other.RequestTo.getTime())
      } else {
        this.RequestTo = new Date(Math.round(other.RequestTo / 1000000))
      }
    } else {
      this.RequestTo = null
    }
    if (other.LastRecordId != null) {
      this.LastRecordId = UInt64.fromNumber(other.LastRecordId)
    } else {
      this.LastRecordId = null
    }
    if (other.PageSize != null) {
      this.PageSize = other.PageSize
    } else {
      this.PageSize = null
    }
    if (other.AccountLogin != null) {
      this.AccountLogin = UInt64.fromNumber(other.AccountLogin)
    } else {
      this.AccountLogin = null
    }
    if (other.LoginType != null) {
      this.LoginType = LoginType.fromObject(other.LoginType)
    } else {
      this.LoginType = null
    }
    if (other.RequestSessionId != null) {
      this.RequestSessionId = new UUID(other.RequestSessionId)
    } else {
      this.RequestSessionId = null
    }
    if (other.IPAddress != null) {
      this.IPAddress = other.IPAddress
    } else {
      this.IPAddress = null
    }
    if (other.AccountGroups != null) {
      this.AccountGroups = new Set()
      for (let item of other.AccountGroups) {
        if (item != null) {
          let tempItem
          tempItem = new UUID(item)
          this.AccountGroups.add(tempItem)
        } else {
          this.AccountGroups.add(null)
        }
      }
    } else {
      this.AccountGroups = null
    }
    return this
  }

  /**
   * Clone struct (deep clone)
   * @this {!ClientLoginInfoGetRequest}
   * @returns {!ClientLoginInfoGetRequest} Cloned struct
   */
  clone () {
    // Serialize the struct to the FBE stream
    let writer = new ClientLoginInfoGetRequestModel(new fbe.WriteBuffer())
    writer.serialize(this)

    // Deserialize the struct from the FBE stream
    let reader = new ClientLoginInfoGetRequestModel(new fbe.ReadBuffer())
    reader.attachBuffer(writer.buffer)
    return reader.deserialize().value
  }

  /**
   * Is this struct equal to other one?
   * @this {!ClientLoginInfoGetRequest}
   * @param {!ClientLoginInfoGetRequest} other Other struct
   * @returns {boolean} Equal result
   */
  eq (other) {
    if (!(other instanceof ClientLoginInfoGetRequest)) {
      throw new TypeError('Instance of ClientLoginInfoGetRequest is required!')
    }
    // noinspection RedundantIfStatementJS
    if (!super.eq(other)) {
      return false
    }
    return true
  }

  /**
   * Convert struct to JSON
   * @this {!ClientLoginInfoGetRequest}
   * @returns {!object} Struct value for JSON
   */
  toJSON () {
    let parent = super.toJSON()
    let current = {
      RequestDirection: ((this.RequestDirection != null) ? this.RequestDirection : null),
      RequestFrom: ((this.RequestFrom != null) ? (this.RequestFrom.getTime() * 1000000) : null),
      RequestTo: ((this.RequestTo != null) ? (this.RequestTo.getTime() * 1000000) : null),
      LastRecordId: ((this.LastRecordId != null) ? this.LastRecordId.toNumber() : null),
      PageSize: ((this.PageSize != null) ? this.PageSize : null),
      AccountLogin: ((this.AccountLogin != null) ? this.AccountLogin.toNumber() : null),
      LoginType: ((this.LoginType != null) ? this.LoginType : null),
      RequestSessionId: ((this.RequestSessionId != null) ? this.RequestSessionId.toString() : null),
      IPAddress: ((this.IPAddress != null) ? this.IPAddress : null),
      AccountGroups: ((this.AccountGroups != null) ? Array.from(this.AccountGroups, item => ((item != null) ? item.toString() : null)) : null)
    }
    return { ...parent, ...current }
  }

  /**
   * Convert JSON to struct
   * @param {!string} json JSON string
   * @returns {!object} Struct value for JSON
   */
  static fromJSON (json) {
    return ClientLoginInfoGetRequest.fromObject(JSON.parse(json))
  }

  /**
   * Create struct from object value
   * @param {!ClientLoginInfoGetRequest} other Object value
   * @returns {!ClientLoginInfoGetRequest} Created struct
   */
  static fromObject (other) {
    return new ClientLoginInfoGetRequest().copy(other)
  }

  /**
   * Get the FBE type
   * @this {!ClientLoginInfoGetRequest}
   * @returns {!number} FBE type
   */
  get fbeType () {
    return ClientLoginInfoGetRequest.fbeType
  }

  /**
   * Get the FBE type (static)
   * @this {!ClientLoginInfoGetRequest}
   * @returns {!number} FBE type
   */
  static get fbeType () {
    return 6473
  }
}

export { ClientLoginInfoGetRequest };

/**
 * Fast Binary Encoding ClientLoginInfoGetRequest field model
 */
class FieldModelClientLoginInfoGetRequest extends fbe.FieldModel {
  /**
   * Initialize field model with the given buffer and offset
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Buffer
   * @param {!number} offset Offset
   * @constructor
   */
  constructor (buffer, offset) {
    super(buffer, offset)
    this._parent = new FieldModelRequest(buffer, 4 + 4)
    this.RequestDirection = new FieldModelRequestDirection(buffer, this._parent.fbeOffset + this._parent.fbeBody - 4 - 4)
    this.RequestFrom = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.RequestDirection.fbeOffset + this.RequestDirection.fbeSize), buffer, this.RequestDirection.fbeOffset + this.RequestDirection.fbeSize)
    this.RequestTo = new fbe.FieldModelOptional(new fbe.FieldModelTimestamp(buffer, this.RequestFrom.fbeOffset + this.RequestFrom.fbeSize), buffer, this.RequestFrom.fbeOffset + this.RequestFrom.fbeSize)
    this.LastRecordId = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.RequestTo.fbeOffset + this.RequestTo.fbeSize), buffer, this.RequestTo.fbeOffset + this.RequestTo.fbeSize)
    this.PageSize = new fbe.FieldModelUInt32(buffer, this.LastRecordId.fbeOffset + this.LastRecordId.fbeSize)
    this.AccountLogin = new fbe.FieldModelOptional(new fbe.FieldModelUInt64(buffer, this.PageSize.fbeOffset + this.PageSize.fbeSize), buffer, this.PageSize.fbeOffset + this.PageSize.fbeSize)
    this.LoginType = new fbe.FieldModelOptional(new FieldModelLoginType(buffer, this.AccountLogin.fbeOffset + this.AccountLogin.fbeSize), buffer, this.AccountLogin.fbeOffset + this.AccountLogin.fbeSize)
    this.RequestSessionId = new fbe.FieldModelOptional(new fbe.FieldModelUUID(buffer, this.LoginType.fbeOffset + this.LoginType.fbeSize), buffer, this.LoginType.fbeOffset + this.LoginType.fbeSize)
    this.IPAddress = new fbe.FieldModelOptional(new fbe.FieldModelString(buffer, this.RequestSessionId.fbeOffset + this.RequestSessionId.fbeSize), buffer, this.RequestSessionId.fbeOffset + this.RequestSessionId.fbeSize)
    this.AccountGroups = new fbe.FieldModelSet(new fbe.FieldModelUUID(buffer, this.IPAddress.fbeOffset + this.IPAddress.fbeSize), buffer, this.IPAddress.fbeOffset + this.IPAddress.fbeSize)
  }

  /**
   * Get the core.Request field model
   * @this {!FieldModelClientLoginInfoGetRequest}
   * @returns {!FieldModelRequest} core.Request field model
   */
  get parent () {
    return this._parent
  }

  /**
   * Get the field size
   * @this {!FieldModelClientLoginInfoGetRequest}
   * @returns {!number} Field size
   */
  get fbeSize () {
    return 4
  }

  /**
   * Get the field body size
   * @this {!FieldModelClientLoginInfoGetRequest}
   * @returns {!number} Field body size
   */
  get fbeBody () {
    return 4 + 4 + this.parent.fbeBody - 4 - 4 + this.RequestDirection.fbeSize + this.RequestFrom.fbeSize + this.RequestTo.fbeSize + this.LastRecordId.fbeSize + this.PageSize.fbeSize + this.AccountLogin.fbeSize + this.LoginType.fbeSize + this.RequestSessionId.fbeSize + this.IPAddress.fbeSize + this.AccountGroups.fbeSize
  }

  /**
   * Get the field extra size
   * @this {!FieldModelClientLoginInfoGetRequest}
   * @returns {!number} Field extra size
   */
  get fbeExtra () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4) > this._buffer.size)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)

    let fbeResult = this.fbeBody + this.parent.fbeExtra + this.RequestDirection.fbeExtra + this.RequestFrom.fbeExtra + this.RequestTo.fbeExtra + this.LastRecordId.fbeExtra + this.PageSize.fbeExtra + this.AccountLogin.fbeExtra + this.LoginType.fbeExtra + this.RequestSessionId.fbeExtra + this.IPAddress.fbeExtra + this.AccountGroups.fbeExtra

    this._buffer.unshift(fbeStructOffset)

    return fbeResult
  }

  /**
   * Get the field type
   * @this {!FieldModelClientLoginInfoGetRequest}
   * @returns {!number} Field type
   */
  get fbeType () {
    return FieldModelClientLoginInfoGetRequest.fbeType
  }

  /**
   * Get the field type (static)
   * @this {!FieldModelClientLoginInfoGetRequest}
   * @returns {!number} Field type
   */
  static get fbeType () {
    return 6473
  }

  /**
   * Check if the struct value is valid
   * @this {!FieldModelClientLoginInfoGetRequest}
   * @param {!boolean} fbeVerifyType Verify model type flag, defaults is true
   * @returns {!boolean} Field model valid state
   */
  verify (fbeVerifyType = true) {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return true
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return false
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    if (fbeStructSize < (4 + 4)) {
      return false
    }

    let fbeStructType = this.readUInt32(fbeStructOffset + 4)
    if (fbeVerifyType && (fbeStructType !== this.fbeType)) {
      return false
    }

    this._buffer.shift(fbeStructOffset)
    let fbeResult = this.verifyFields(fbeStructSize)
    this._buffer.unshift(fbeStructOffset)
    return fbeResult
  }

  /**
   * Check if the struct fields are valid
   * @this {!FieldModelClientLoginInfoGetRequest}
   * @param {!number} fbeStructSize FBE struct size
   * @returns {!boolean} Field model valid state
   */
  verifyFields (fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) > fbeStructSize) {
      return true
    }
    if (!this.parent.verifyFields(fbeStructSize)) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.RequestDirection.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RequestDirection.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestDirection.fbeSize

    if ((fbeCurrentSize + this.RequestFrom.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RequestFrom.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestFrom.fbeSize

    if ((fbeCurrentSize + this.RequestTo.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RequestTo.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestTo.fbeSize

    if ((fbeCurrentSize + this.LastRecordId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LastRecordId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastRecordId.fbeSize

    if ((fbeCurrentSize + this.PageSize.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.PageSize.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PageSize.fbeSize

    if ((fbeCurrentSize + this.AccountLogin.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountLogin.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountLogin.fbeSize

    if ((fbeCurrentSize + this.LoginType.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.LoginType.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoginType.fbeSize

    if ((fbeCurrentSize + this.RequestSessionId.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.RequestSessionId.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestSessionId.fbeSize

    if ((fbeCurrentSize + this.IPAddress.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.IPAddress.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IPAddress.fbeSize

    if ((fbeCurrentSize + this.AccountGroups.fbeSize) > fbeStructSize) {
      return true
    }
    if (!this.AccountGroups.verify()) {
      return false
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroups.fbeSize

    return true
  }

  /**
   * Get the struct value (begin phase)
   * @this {!FieldModelClientLoginInfoGetRequest}
   * @returns {!number} Field model begin offset
   */
  getBegin () {
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructOffset = this.readUInt32(this.fbeOffset)
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + 4 + 4) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset === 0) || ((this._buffer.offset + fbeStructOffset + 4 + 4) > this._buffer.size)) {
      return 0
    }

    let fbeStructSize = this.readUInt32(fbeStructOffset)
    console.assert((fbeStructSize >= (4 + 4)), 'Model is broken!')
    if (fbeStructSize < (4 + 4)) {
      return 0
    }

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Get the struct value (end phase)
   * @this {!FieldModelClientLoginInfoGetRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  getEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Get the struct value
   * @this {!FieldModelClientLoginInfoGetRequest}
   * @param {!ClientLoginInfoGetRequest} fbeValue Default value, defaults is new ClientLoginInfoGetRequest()
   * @returns {!ClientLoginInfoGetRequest} ClientLoginInfoGetRequest value
   */
  get (fbeValue = new ClientLoginInfoGetRequest()) {
    let fbeBegin = this.getBegin()
    if (fbeBegin === 0) {
      return fbeValue
    }

    let fbeStructSize = this.readUInt32(0)
    this.getFields(fbeValue, fbeStructSize)
    this.getEnd(fbeBegin)
    return fbeValue
  }

  /**
   * Get the struct fields values
   * @this {!FieldModelClientLoginInfoGetRequest}
   * @param {!ClientLoginInfoGetRequest} fbeValue ClientLoginInfoGetRequest value
   * @param {!number} fbeStructSize Struct size
   */
  getFields (fbeValue, fbeStructSize) {
    let fbeCurrentSize = 4 + 4

    if ((fbeCurrentSize + this.parent.fbeBody - 4 - 4) <= fbeStructSize) {
      this.parent.getFields(fbeValue, fbeStructSize)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.parent.fbeBody - 4 - 4

    if ((fbeCurrentSize + this.RequestDirection.fbeSize) <= fbeStructSize) {
      fbeValue.RequestDirection = this.RequestDirection.get(new RequestDirection(RequestDirection.FORWARD))
    } else {
      fbeValue.RequestDirection = new RequestDirection(RequestDirection.FORWARD)
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestDirection.fbeSize

    if ((fbeCurrentSize + this.RequestFrom.fbeSize) <= fbeStructSize) {
      fbeValue.RequestFrom = this.RequestFrom.get()
    } else {
      fbeValue.RequestFrom = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestFrom.fbeSize

    if ((fbeCurrentSize + this.RequestTo.fbeSize) <= fbeStructSize) {
      fbeValue.RequestTo = this.RequestTo.get()
    } else {
      fbeValue.RequestTo = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestTo.fbeSize

    if ((fbeCurrentSize + this.LastRecordId.fbeSize) <= fbeStructSize) {
      fbeValue.LastRecordId = this.LastRecordId.get()
    } else {
      fbeValue.LastRecordId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LastRecordId.fbeSize

    if ((fbeCurrentSize + this.PageSize.fbeSize) <= fbeStructSize) {
      fbeValue.PageSize = this.PageSize.get(1000)
    } else {
      fbeValue.PageSize = 1000
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.PageSize.fbeSize

    if ((fbeCurrentSize + this.AccountLogin.fbeSize) <= fbeStructSize) {
      fbeValue.AccountLogin = this.AccountLogin.get()
    } else {
      fbeValue.AccountLogin = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountLogin.fbeSize

    if ((fbeCurrentSize + this.LoginType.fbeSize) <= fbeStructSize) {
      fbeValue.LoginType = this.LoginType.get()
    } else {
      fbeValue.LoginType = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.LoginType.fbeSize

    if ((fbeCurrentSize + this.RequestSessionId.fbeSize) <= fbeStructSize) {
      fbeValue.RequestSessionId = this.RequestSessionId.get()
    } else {
      fbeValue.RequestSessionId = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.RequestSessionId.fbeSize

    if ((fbeCurrentSize + this.IPAddress.fbeSize) <= fbeStructSize) {
      fbeValue.IPAddress = this.IPAddress.get()
    } else {
      fbeValue.IPAddress = null
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.IPAddress.fbeSize

    if ((fbeCurrentSize + this.AccountGroups.fbeSize) <= fbeStructSize) {
      this.AccountGroups.get(fbeValue.AccountGroups)
    } else {
      fbeValue.AccountGroups.clear()
    }
    // noinspection JSUnusedAssignment
    fbeCurrentSize += this.AccountGroups.fbeSize
  }

  /**
   * Set the struct value (begin phase)
   * @this {!FieldModelClientLoginInfoGetRequest}
   * @returns {!number} Field model begin offset
   */
  setBegin () {
    console.assert(((this._buffer.offset + this.fbeOffset + this.fbeSize) <= this._buffer.size), 'Model is broken!')
    if ((this._buffer.offset + this.fbeOffset + this.fbeSize) > this._buffer.size) {
      return 0
    }

    let fbeStructSize = this.fbeBody
    let fbeStructOffset = this._buffer.allocate(fbeStructSize) - this._buffer.offset
    console.assert((fbeStructOffset > 0) && ((this._buffer.offset + fbeStructOffset + fbeStructSize) <= this._buffer.size), 'Model is broken!')
    if ((fbeStructOffset <= 0) || ((this._buffer.offset + fbeStructOffset + fbeStructSize) > this._buffer.size)) {
      return 0
    }

    this.writeUInt32(this.fbeOffset, fbeStructOffset)
    this.writeUInt32(fbeStructOffset, fbeStructSize)
    this.writeUInt32(fbeStructOffset + 4, this.fbeType)

    this._buffer.shift(fbeStructOffset)
    return fbeStructOffset
  }

  /**
   * Set the struct value (end phase)
   * @this {!FieldModelClientLoginInfoGetRequest}
   * @param {!number} fbeBegin Field model begin offset
   */
  setEnd (fbeBegin) {
    this._buffer.unshift(fbeBegin)
  }

  /**
   * Set the struct value
   * @this {!FieldModelClientLoginInfoGetRequest}
   * @param {!ClientLoginInfoGetRequest} fbeValue ClientLoginInfoGetRequest value
   */
  set (fbeValue) {
    let fbeBegin = this.setBegin()
    if (fbeBegin === 0) {
      return
    }

    this.setFields(fbeValue)
    this.setEnd(fbeBegin)
  }

  /**
   * Set the struct fields values
   * @this {!FieldModelClientLoginInfoGetRequest}
   * @param {ClientLoginInfoGetRequest} fbeValue ClientLoginInfoGetRequest value
   */
  setFields (fbeValue) {
    this.parent.setFields(fbeValue)
    this.RequestDirection.set(fbeValue.RequestDirection)
    this.RequestFrom.set(fbeValue.RequestFrom)
    this.RequestTo.set(fbeValue.RequestTo)
    this.LastRecordId.set(fbeValue.LastRecordId)
    this.PageSize.set(fbeValue.PageSize)
    this.AccountLogin.set(fbeValue.AccountLogin)
    this.LoginType.set(fbeValue.LoginType)
    this.RequestSessionId.set(fbeValue.RequestSessionId)
    this.IPAddress.set(fbeValue.IPAddress)
    this.AccountGroups.set(fbeValue.AccountGroups)
  }
}

export { FieldModelClientLoginInfoGetRequest };

/**
 * Fast Binary Encoding ClientLoginInfoGetRequest model
 */
class ClientLoginInfoGetRequestModel extends fbe.Model {
  /**
   * Initialize model with the given buffer
   * @param {!fbe.ReadBuffer|!fbe.WriteBuffer} buffer Read/Write buffer, defaults is new fbe.WriteBuffer()
   * @constructor
   */
  constructor (buffer = new fbe.WriteBuffer()) {
    super(buffer)
    this._model = new FieldModelClientLoginInfoGetRequest(this.buffer, 4)
  }

  /**
   * Get the ClientLoginInfoGetRequest model
   * @this {!ClientLoginInfoGetRequestModel}
   * @returns {!FieldModelClientLoginInfoGetRequest} model ClientLoginInfoGetRequest model
   */
  get model () {
    return this._model
  }

  /**
   * Get the model size
   * @this {!ClientLoginInfoGetRequestModel}
   * @returns {!number} Model size
   */
  get fbeSize () {
    return this.model.fbeSize + this.model.fbeExtra
  }

  /**
   * Get the model type
   * @this {!ClientLoginInfoGetRequestModel}
   * @returns {!number} Model type
   */
  get fbeType () {
    return ClientLoginInfoGetRequestModel.fbeType
  }

  /**
   * Get the model type (static)
   * @this {!ClientLoginInfoGetRequestModel}
   * @returns {!number} Model type
   */
  static get fbeType () {
    return FieldModelClientLoginInfoGetRequest.fbeType
  }

  /**
   * Check if the struct value is valid
   * @this {!ClientLoginInfoGetRequestModel}
   * @returns {!boolean} Model valid state
   */
  verify () {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return false
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    if (fbeFullSize < this.model.fbeSize) {
      return false
    }

    return this.model.verify()
  }

  /**
   * Create a new model (begin phase)
   * @this {!ClientLoginInfoGetRequestModel}
   * @returns {!number} Model begin offset
   */
  createBegin () {
    return this.buffer.allocate(4 + this.model.fbeSize)
  }

  /**
   * Create a new model (end phase)
   * @this {!ClientLoginInfoGetRequestModel}
   * @param {!number} fbeBegin Model begin offset
   */
  createEnd (fbeBegin) {
    let fbeEnd = this.buffer.size
    let fbeFullSize = fbeEnd - fbeBegin
    this.writeUInt32(this.model.fbeOffset - 4, fbeFullSize)
    return fbeFullSize
  }

  /**
   * Serialize the struct value
   * @this {!ClientLoginInfoGetRequestModel}
   * @param {!ClientLoginInfoGetRequest} value ClientLoginInfoGetRequest value
   * @return {!number} Model begin offset
   */
  serialize (value) {
    let fbeBegin = this.createBegin()
    this.model.set(value)
    return this.createEnd(fbeBegin)
  }

  /**
   * Deserialize the struct value
   * @this {!ClientLoginInfoGetRequestModel}
   * @param {!ClientLoginInfoGetRequest} value ClientLoginInfoGetRequest value, defaults is new ClientLoginInfoGetRequest()
   * @return {!object} Deserialized ClientLoginInfoGetRequest value and its size
   */
  deserialize (value = new ClientLoginInfoGetRequest()) {
    if ((this.buffer.offset + this.model.fbeOffset - 4) > this.buffer.size) {
      return { value: new ClientLoginInfoGetRequest(), size: 0 }
    }

    let fbeFullSize = this.readUInt32(this.model.fbeOffset - 4)
    console.assert((fbeFullSize >= this.model.fbeSize), 'Model is broken!')
    if (fbeFullSize < this.model.fbeSize) {
      return { value: new ClientLoginInfoGetRequest(), size: 0 }
    }

    this.model.get(value)
    return { value: value, size: fbeFullSize }
  }

  /**
   * Move to the next struct value
   * @this {!ClientLoginInfoGetRequestModel}
   * @param {!number} prev Previous ClientLoginInfoGetRequest model size
   */
  next (prev) {
    this.model.fbeShift(prev)
  }
}

export { ClientLoginInfoGetRequestModel };
ClientLoginInfoGetRequest.__has_response = true;
ClientLoginInfoGetRequest.__response_class = ClientLoginInfoGetResponse;
